import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmptyNullUndefined } from '../../../utils/utils';

const parse = require('html-react-parser');

const EmpAssesmentStatic = ({data}) => {
    console.log('EmpAssesmentStatic data:', data);

    const ReadMoreLess = ({ text }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const toggleReadMore = () => setIsExpanded(!isExpanded);

        if (text.length <= 150) {
            return <div>{parse(text)}</div>;
        }

        return (
            <div>
                {isExpanded ? parse(text) : parse(text.slice(0, 150) + '...')}
                <button onClick={toggleReadMore} className="read-more-less">
                    {isExpanded ? 'Read Less' : 'Read More'}
                </button>
            </div>
        );
    };

    return (
        <div className="assessment-show-in-sidebar">
            <div className="main-heading">Self Assessment</div>
            {data?.filter(section => section?.visible).map((section) => {
                console.log('Rendering section:', section);
                return (
                    <div key={section?.id} className="section-main">
                        <div className="section-label" dangerouslySetInnerHTML={{ __html: section?.label }} />
                        {
                            !isEmptyNullUndefined(section?.subSectionReferences) ?
                        section?.subSectionReferences?.filter(subSection => subSection?.visible).map((subSection) => {
                            console.log('Rendering subSection:', subSection);
                            return (
                                <div key={subSection?.id} className="sub-section-main">
                                    <div className="question-label" dangerouslySetInnerHTML={{ __html: subSection?.label }} />
                                    {subSection?.feedbacks && (
                                        <div className='feedback-text'>
                                            {
                                                subSection?.feedbacks?.map((feedback, index) => {
                                                    return(
                                                        <div key={feedback.employeeId || index} className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) && feedback?.responseObject?.responseString?.length > 0 &&
                                                                <ReadMoreLess text={feedback?.responseObject?.responseString} />
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseBoolean) ? 
                                                                feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                feedback?.responseObject?.responseInteger
                                                                }                                                                
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.rating?.name) && 
                                                                <div className='ratings'>
                                                                    Rating : {feedback?.responseObject?.rating?.name}
                                                                </div>
                                                                }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    )}
                                </div>
                            );
                        })
                        :
                       
                                <div className="sub-section-main">
                                    {/* <div className="question-label" dangerouslySetInnerHTML={{ __html: section?.label }} /> */}
                                    {section?.feedbacks && (
                                        <div className='feedback-text'>
                                            {
                                                section?.feedbacks?.map((feedback, index) => {
                                                    return(
                                                        <div key={feedback.employeeId || index} className="emp-response">
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) &&  feedback?.responseObject?.responseString?.length > 0 &&
                                                                <div className="text-responses">
                                                                    <ReadMoreLess text={feedback?.responseObject?.responseString} />                                                                    
                                                                </div>
                                                            }
                                                            {
                                                                feedback?.responseObject?.responseBoolean !== null &&
                                                                <div className="text-responses">

                                                                    {                                                                    
                                                                    feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                    }
                                                                    
                                                                </div>
                                                            }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                <div className="text-responses">
                                                                    {                                                                    
                                                                    feedback?.responseObject?.responseInteger
                                                                    }
                                                                </div>
                                                            }

                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.rating?.name) && 
                                                                <div className='ratings'>
                                                                    Rating : {feedback?.responseObject?.rating?.name}
                                                                </div>
                                                                }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    )}
                                </div>
                            
                    }
                    </div>
                );
            })}
        </div>
    )
}

EmpAssesmentStatic.propTypes = {
    data: PropTypes.array,
};

export default EmpAssesmentStatic;