const { createAction } = require('redux-actions');
const GOALCYCLE_DATA_REQUEST = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST';
const GOALCYCLE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST_SUCCESS';
const GOALCYCLE_DATA_REQUEST_FAILED = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST_FAILED';

const GoalcycleDataRequest = createAction(GOALCYCLE_DATA_REQUEST);
const GoalcycleDataRequestSuccess = createAction(GOALCYCLE_DATA_REQUEST_SUCCESS);
const GoalcycleDataRequestFailed = createAction(GOALCYCLE_DATA_REQUEST_FAILED);

const ACTIVE_GOAL_CYCLE_DATA_REQUEST = 'MEDIBUDDY/ACTIVE_GOAL_CYCLE_DATA_REQUEST';
const ACTIVE_GOAL_CYCLE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/ACTIVE_GOAL_CYCLE_DATA_REQUEST_SUCCESS';
const ACTIVE_GOAL_CYCLE_DATA_REQUEST_FAILED = 'MEDIBUDDY/ACTIVE_GOAL_CYCLE_DATA_REQUEST_FAILED';

const ActiveGoalCycleDataRequest = createAction(ACTIVE_GOAL_CYCLE_DATA_REQUEST);
const ActiveGoalCycleDataRequestSuccess = createAction(ACTIVE_GOAL_CYCLE_DATA_REQUEST_SUCCESS);
const ActiveGoalCycleDataRequestFailed = createAction(ACTIVE_GOAL_CYCLE_DATA_REQUEST_FAILED);

export const actions = {
    GoalcycleDataRequest,
    GoalcycleDataRequestSuccess,
    GoalcycleDataRequestFailed,

    ActiveGoalCycleDataRequest,
    ActiveGoalCycleDataRequestSuccess,
    ActiveGoalCycleDataRequestFailed,
};

export const types = {
    GOALCYCLE_DATA_REQUEST,
    GOALCYCLE_DATA_REQUEST_SUCCESS,
    GOALCYCLE_DATA_REQUEST_FAILED,

    ACTIVE_GOAL_CYCLE_DATA_REQUEST,
    ACTIVE_GOAL_CYCLE_DATA_REQUEST_SUCCESS,
    ACTIVE_GOAL_CYCLE_DATA_REQUEST_FAILED,
};