import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import Button from '@mui/material/Button';
import InitialPage from './Initial-Page';
import PageTwo from './page-two';
import PageThree from './Page-three';
import PageFour from './page-four';
import { Route, useHistory, useLocation } from "react-router-dom";

const SelfAssesmentPage = () => {
    const [expanded, setExpanded] = useState(null);
    const [activePage, setActivePage] = useState(0)

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    return(
        <div>          
            <Route
            path="/self-assesment/initial-page"
            component={InitialPage}
          />
          <Route
            exact
            path="/self-assesment/page-two"
            component={PageTwo}
          />
          <Route
            exact
            path="/self-assesment/page-three"
            component={PageThree}
          />
          <Route
            exact
            path="/self-assesment/page-four"
            component={PageFour}
          />
        <Button variant="outlined"
        >Next</Button>
        </div>
    )
};

export default SelfAssesmentPage;
