import { toast } from "react-toastify";
import APIList from "../../../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import EmpInfoSkel from "./empInfoSkelton";
import Avatar from "react-avatar";
import EmpDetails from "./empDetails";
import { Skeleton } from "@mui/material";
import LineGraph from "./lineGraph";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import SelfAssesmentInfo from "./selfAssesment";
import RatingTable from "../../../../common/RatingTable";
import ManagerFeedback from "../../../managerFeedBack/index";

const EmpInfo = ({emp}) =>{

    const [empInfo, setEmpInfo] = useState(null);
    const [empInfoLoader, setEmpInfoLoader] = useState(false);
    const [assesmentData, setassesmentData] = useState(null);
    const [assesmentDataLoader, setAssesmentDataLoader] = useState(false);
    const [ratingsHistory, setRatingsHistory] = useState(null);
    const [ratingsHistoryLoader, setRatingsHistoryLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("Profile");

    useEffect(() => {
      setEmpInfoLoader(true);
      APIList.getEmployeeById({id: emp?.id})
      .then((res) => {
        setEmpInfo(res?.data);
        setEmpInfoLoader(false);
      })
      .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
        )
        setEmpInfoLoader(false);
      });
    }, [])

    useEffect(() => {

        if(!isEmptyNullUndefined(empInfo?.id)) {
            setRatingsHistoryLoader(true);
            APIList.getRatingHistory(empInfo?.id)
            .then((res) => {
                setRatingsHistory(res?.data);
                setRatingsHistoryLoader(false);
             }).catch((err) => {
               toast.error(
                   <div style={{display:"flex", flexDirection:"row"}}>
                     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                     &nbsp;&nbsp;{err?.title}
                   </div>
                 )
                 setRatingsHistoryLoader(false);
             })
        }
        
        if(!isEmptyNullUndefined(empInfo?.id)) {
            setAssesmentDataLoader(true);
            APIList.getAssessmentResponse({
            selectedFormFor: "SelfAppraisal",
            selectedEmployeeId: empInfo?.id,
          }).then((res) => {
             setassesmentData(res?.data);
             setAssesmentDataLoader(false);
          }).catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
              )
              setAssesmentDataLoader(false);
          })
        }
        
    }, [empInfo])
    
    return(
        <div className="emp-info-main">

            {
                empInfoLoader ? 
                <EmpInfoSkel />
                :
                <div className="empDetailass">
                    <div className="empPic">
                        {empInfo?.profilePhoto?.path ?
                            <img src={empInfo?.profilePhoto?.path} className="userImg" alt="User Image" />
                            :
                            <Avatar
                                name={empInfo?.name}
                                size="45"
                                className="userImg"
                                color={"#00425A"}
                            />
                        }
                    </div>
                    <div className="userInfo">
                        <p className="name">{empInfo?.name}</p>
                        <p className="designation">{empInfo?.employeeDesignation ? empInfo?.employeeDesignation : "Designation"}</p>
                    </div>
                </div>
               
            }

                <div className="main-content">

                    <div className="emp-info-tabs">
                        <div className={`tab ${activeTab === "Rating" ? "active" : ""}`} onClick={() => setActiveTab("Rating")}>Rating History</div>
                        <div className={`tab ${activeTab === "Assessment" ? "active" : ""}`} onClick={() => setActiveTab("Assessment")}>Assessment</div>
                        <div className={`tab ${activeTab === "Profile" ? "active" : ""}`} onClick={() => setActiveTab("Profile")}>Profile</div>
                    </div>

                    <div className="tab-content">
                        {
                            activeTab === "Rating" &&
                            <>
                            {
                            ratingsHistoryLoader ?
                            <>
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                            </>
                            :
                            // <LineGraph ratingsHistory={ratingsHistory} />
                            <RatingTable data={ratingsHistory} />
                            }
                            </>
                        }
                    </div>
                    <div className="tab-content">
                        {
                            activeTab === "Assessment" &&
                            <>
                            {
                            assesmentDataLoader ?
                            <>
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                            </>
                            :
                            <>
                                {/* <SelfAssesmentInfo empId={empInfo?.id && empInfo?.id} /> */}
                                <ManagerFeedback empId={empInfo?.id} isThisFormActive={true} isViewOnly={true} />
                            
                            </>
                            }
                            </>
                        }
                    </div>

                    <div className="tab-content">
                        {
                            activeTab === "Profile" &&
                            <>
                            {
                            empInfoLoader ?
                            <>
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                               <Skeleton variant="text" sx={{ fontSize: '10rem', width:"100%"  }} />
                            </>
                            :
                            <EmpDetails empInfo={empInfo} />
                            }
                            </>
                        }
                    </div>

                </div>

        </div>
    )
}

export default EmpInfo;