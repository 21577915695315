import { useEffect, useState } from "react";
import APIList from "../../../../../api";
import { useSelector } from "react-redux";
import TableSkel from "../../../../common/tableSkeleton";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import { CircularProgress } from "@mui/material";
import DialogPopup from "../../../../common/Dialog";
import checkIcon from "../../../../../assets/images/popup-check.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SlClose } from "react-icons/sl";
import PropTypes from 'prop-types';

const ChangedReport = ({sessionId, viewReport, approved}) => {

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [reportChangeData, setReportChangeData] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [approveOpenDialog, setApproveOpenDialog] = useState(false);
    const [alreadyApprovedEmps, setAlreadyApprovedEmps] = useState([]);
    const [newerRatedEmps, setNewerRatedEmps] = useState([]);
    const [elligibleForApproval, setElligibleForApproval] = useState([]);

    const checkApprove = (selected, type) => {
        APIList.checkBeforeApprove(
            {
                sessionId : sessionId,
                companyId : employeeDetails?.company?.id,
            }
        )
        .then((res) => {
            setApproveOpenDialog(true);
            setAlreadyApprovedEmps(res?.data?.employeesWithApprovedRatings);
            setNewerRatedEmps(res?.data?.employeesWithNewerRatings);
            setElligibleForApproval(res?.data?.eligibleEmployeesForApproval);
           
         }).catch((err) => {
           toast.error(
               <div style={{display:"flex", flexDirection:"row"}}>
                 <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                 &nbsp;&nbsp;{err?.title}
               </div>
             )                 
         })
    }

    useEffect(() => {
            getFinalReport();
      }, [])      
    
    const getFinalReport = () => {
        setDataLoader(true);
        APIList.getReportChanges({
            companyId: employeeDetails?.company?.id,
            sessionId : sessionId
        })
        .then((res) => {
            setReportChangeData(res?.data);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
      }

      const handleApproveSubmit = () => {
        setSubmitLoader(true);
        APIList.approveSessionFromReport({
            payload: viewReport,
            employeeId: employeeDetails?.id,
            sessionId : sessionId
        })
        .then((res) => {
            setApproveOpenDialog(false);
            setOpenDialog(true);
            setSubmitLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSubmitLoader(false);
        })
      }

      const getPromotionApprovedText = (data) => {
        if (data?.eligibleForPromotion !== "Yes") {
          return "-";
        }
        return isEmptyNullUndefined(data?.promotionApproved) ? "-" : data?.promotionApproved;
      };
    
    return(
        <div className="changed-report">
            <div className="tablee">
            <table className="feedback-emp-list-table"> 
            
                <tr className="table-head-emp-list"> 
                    <th className="e-name">
                      E name 
                    </th> 
                    <th className="mid-cols">
                      Func/ sub-func
                    </th> 
                    <th className="mid-cols-small">
                    Is Recommended for Promotion
                    </th> 
                    <th className="mid-cols-small">
                    Is Promotion Approved
                    </th> 
                    <th className="mid-cols-small">
                    Rating Revised
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR MBB 
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR MBB
                    </th> 
                    
                    <th className="last-col-large">Reason</th> 
                </tr>
                
                {
                     dataLoader ? 
                     <TableSkel />
                     :
                  
                        reportChangeData?.map((data) => {
                            return(
                                <tr key={data?.name}>
                                    <td>
                                        <div className="emp-i">
                                            <div className="name">{data?.name}</div>
                                            <div className="emp-id">{data?.employeeLevel}</div>
                                        </div>
                                    </td>
                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                    <td>
                                    <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                            {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                        </span>
                                    </td>
                                    <td>                                         
                                        <span className={`${(data?.promotionApproved === "Yes") ? "revised" :"not-revised"}`}>
                                            {getPromotionApprovedText(data)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                        {
                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                        }
                                        </span>
                                    </td>
                                    <td>{data?.preRating?.preformanceRating}</td>
                                    <td>{data?.preRating?.mbbRating}</td>
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.preformanceRating}</td>
                                    }
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.mbbRating}</td>
                                    }
                                    <td><div className="the-reason">{data?.reason}</div></td>
                                </tr>
                            )
                        })
                   
                
                
                }
                
            </table> 

          </div>
          {
            !approved && 
            <div className="fleader-approve-btn-outer">                  
            <button className="fleader-approve-btn"
                onClick={() => checkApprove()}
            >
                {
                    submitLoader ?
                        <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                        :
                        "Approve"
                }
            </button>
        </div>  
          }
          
                            {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon" alt="check"></img>
                <div className="content">
                    Approved successfully            
                </div>
                <button className="button"
                    onClick={() => { history.push("/functional-leader")}}
                >Done</button>
                </div>
            </DialogPopup>
        }

{
            approveOpenDialog && 
            <DialogPopup openDialog={approveOpenDialog} setOpenDialog={setApproveOpenDialog} redirect={true} path={false}>
                
                <div className="check-approved-popup-main">
                <SlClose onClick={() => setApproveOpenDialog(false)} />

                {
                   (isEmptyNullUndefined(alreadyApprovedEmps) && isEmptyNullUndefined(newerRatedEmps)) ?
                   <div className="messsage">
                        Are you sure you want to approve? Once approved you will not able to edit or make any further changes.
                    </div>
                  :
                    <div className="messsage">
                        The following employee's rating will not be approved as they are already approved or present in another session.
                    </div>
                }
                {
                    !isEmptyNullUndefined(alreadyApprovedEmps) &&
                    <>
                    <div className="table-heading">Already Approved - </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                alreadyApprovedEmps?.map((emp) => {
                                    return(
                                    <tr key={emp?.name}>
                                        <td>{emp?.name}</td>
                                    </tr> 
                                )
                            })
                            }
                            
                        </tbody>
                    </table>
                    </>
                }
                {
                    !isEmptyNullUndefined(newerRatedEmps) &&
                    <>
                    <div className="table-heading">Present in another session - </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                newerRatedEmps?.map((emp) => {
                                    return(
                                    <tr key={emp?.name}>
                                        <td>{emp?.name}</td>
                                    </tr> 
                                )
                            })
                            }
                            
                        </tbody>
                    </table>
                    </>
                }

                {
                    !isEmptyNullUndefined(elligibleForApproval) && 
                    <div className="btn-outer">
                        <button className="approve-btn" onClick={() => handleApproveSubmit()} disabled={submitLoader}>
                            {
                                submitLoader ? 
                                <CircularProgress size={27} />
                                :
                                "Approve"
                            }
                            
                        </button>
                    </div>
                }
                    
                </div>
            </DialogPopup>
        }
        </div>
    )
}

ChangedReport.propTypes = {
    sessionId: PropTypes.string,
    viewReport: PropTypes.object,
    approved: PropTypes.bool
  };

export default ChangedReport;