const { createAction } = require('redux-actions');
const PMSCYCLE_DATA_REQUEST = 'MEDIBUDDY/PMSCYCLE_DATA_REQUEST';
const PMSCYCLE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/PMSCYCLE_DATA_REQUEST_SUCCESS';
const PMSCYCLE_DATA_REQUEST_FAILED = 'MEDIBUDDY/PMSCYCLE_DATA_REQUEST_FAILED';

const PmscycleDataRequest = createAction(PMSCYCLE_DATA_REQUEST);
const PmscycleDataRequestSuccess = createAction(PMSCYCLE_DATA_REQUEST_SUCCESS);
const PmscycleDataRequestFailed = createAction(PMSCYCLE_DATA_REQUEST_FAILED);

export const actions = {
    PmscycleDataRequest,
    PmscycleDataRequestSuccess,
    PmscycleDataRequestFailed,
};

export const types = {
    PMSCYCLE_DATA_REQUEST,
    PMSCYCLE_DATA_REQUEST_SUCCESS,
    PMSCYCLE_DATA_REQUEST_FAILED,
};
