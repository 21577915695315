import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.SELF_APRAISAL_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.SELF_APRAISAL_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        selfApraisalData: payload || {},
    }),
    [types.SELF_APRAISAL_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),   

    [types.SAVE_APRAISAL_DATA_REQUEST]: (state, { payload }) => ({
        ...state,
        savedApraisalData: payload
    }),
      
};

export default handleActions(actionHandler, {
    loader: false,
    selfApraisalData: {}, 
    savedApraisalData: {},  
});
