import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Tooltip } from "@mui/material";
import User from "../../../../assets/images/user.png"; 
import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import Info from "../../../../assets/images/info.svg";
import UpArrow from "../../../../assets/images/upArrow.svg";
import Send from "../../../../assets/images/send.svg";
import Alert from "../../../../assets/images/alert.svg"
import Setting from "../../../../assets/images/setting.svg"
import BackArrow from "../../../../assets/images/backArrowSelf.svg"
import { Route, useHistory, useLocation } from "react-router-dom";
import { assesmentDummyData, isEmptyNullUndefined, accordianSVGstyle } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {actions as selfApraisalDataActions} from "../../../../redux/selfApraisal/actions";
import { Box } from "@mui/system";
import { IoMdSend } from "react-icons/io"
import AccSkeleton from "../../../common/akkSkeleton";
import { object } from "prop-types";

const InitialPage = () => {
    
    const history = useHistory();
    const dispatch = useDispatch();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const selfApraisalData = useSelector((state) => state?.selfApraisalData?.selfApraisalData);
    const selfApraisalDataLoader = useSelector((state) => state?.selfApraisalData?.loader);

    const [expanded, setExpanded] = useState(null);
    const [apraisalPayload, setApraisalPayload] = useState(null);

    useEffect(() => {
        dispatch(selfApraisalDataActions.SelfApraisalDataRequest({id:employeeDetails?.id}));
    }, [employeeDetails]);    

    useEffect(() => {
        if (isEmptyNullUndefined(selfApraisalData) || Object.keys(selfApraisalData)?.length === 0) {
            setApraisalPayload({                
                status: "Submitted",
                goalList: [],
                values: 
                    {
                        demonstrated: "",
                        notDemonstrated: "",
                        demonstratedValues: [],
                        notDemonstratedValues: [],
                        keyAchievement: "",
                        challenges: "",
                        learningSupport: ""
                    }
                ,
                employeeId: employeeDetails?.id            
        });
        } else {            
            setApraisalPayload(selfApraisalData);
        }
    }, [selfApraisalData]);    

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleNext = () => {
        dispatch(selfApraisalDataActions.SaveApraisalDataRequest(apraisalPayload));
        history.push('/self-assesment/page-two')
    }

    return(
        <div className="selfAssessment">
            <div className="row">
                <div className="col-12"> 
                {
                    selfApraisalDataLoader ? 
                     <AccSkeleton />
                     :
                      <div className="contentDiv borNone">
                        <div className="contentHead d-flex justify-content-between align-items-center">
                            <p className="title">OKR's</p>
                            <Tooltip title="Please Provider the content" placement="top-end">
                              <img src={Info}></img>
                            </Tooltip>                            
                        </div>
                        <div className="values">
                            <div>
                                {
                                    selfApraisalData?.goalList?.map((item, index) => {
                                        return(
                                    <Stack sx={{margin:"1rem",
                                    "& .MuiAccordion-rounded":{
                                        border:"1px solid #dad2d2",
                                        borderRadius:"1rem",
                                        boxShadow:"none"
                                    }
                                }}>
                                    <Accordion
                                    // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                    expanded={expanded === item.id} onChange={() => handleChange(item.id)} data-value={item.id}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                    sx={{
                                        width:"100%",   
                                        fontSize:"1.5rem",
                                        fontWeight:"600",
                                        "& .MuiAccordionSummary-content":{
                                            width:"100%", 
                                            display:"flex", 
                                            justifyContent:"space-between",
                                        }}}
                                    >
                                        <div className="accorTitle">{item?.name}
                                            {/* <img src={UpArrow} className="upArrowAccrodian"></img> */}
                                        </div>
                                        <BsChevronDown
                                            style={{...accordianSVGstyle ,transform: expanded !== item.id ? "rotate(0deg)" : "rotate(180deg)"}}
                                        /> 
                                                           
                                    </AccordionSummary>
                                    <AccordionDetails
                                    sx={{
                                        fontSize:"1rem"
                                    }}
                                    >
                                        <div className="accorDetails">
                                            <div className="accordian-data">
                                                <div className="dataDesc">
                                                    <p>{item?.description}</p>
                                                </div>
                                                <div className="comment">
                                                    <p className="accordian-heading">Comments</p>
                                                    

<                             div className='input-chat-section'>
                        
                                    <Box component="form"
                                        // onSubmit={HandelChat}
                                        // sx={{position:"absolute", bottom:"4.5rem", width:"95%"}}
                                        sx={{margin:"0px auto", bottom:"4.5rem", width:"100%"}}
                                    >
                                        <div
                                            className='messageInput'
                                        >
                                            <input
                                                placeholder='Add comments'
                                                // value={chatState?.message}
                                                // onChange={handelChatField}
                                            />
                                            <Button
                                                type="submit"
                                            >
                                                <IoMdSend />
                                            </Button>

                                        </div>
                                    </Box>
                                
                        </div>
                                                        
                                                </div>

                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                                </Stack>
                                        )
                                    })
                                }

                            </div>
                            <div className="d-flex justify-content-end gap-3 pt-5">
                                <button className="valuesBtn draft">Save as draft</button>
                                <button className="valuesBtn next"
                                 onClick={handleNext}
                                    >Next</button>
                            </div>
                        </div>
                        
                    </div>
                }
                    
                </div>
            </div>
        </div>
        )
    }

    export default InitialPage;