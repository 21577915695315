import { Backdrop, CircularProgress, IconButton, Popover, alpha, styled } from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../utils/utils";
import InputBase from '@mui/material/InputBase';
import PaginationMedi from "../../common/pagination";
import TableSkel from "../../common/tableSkeleton";
// import { dummy } from "../requestFeedback/dummyData.js";
import { BiDotsVerticalRounded } from "react-icons/bi";
import addsession from "../../../assets/images/ratingCalib/addSession.svg";
import { IoPlaySharp } from "react-icons/io5";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import SideDrawer from "../../common/sideDrawer/index.js";


const RatintCollab = () => {

    const sortObj = {
        employeeName:{
            active:false,
            value:""
        },
        appraisalStatus:{
            active:false,
            value:""
        },
        feedbackstatus:{
            active:false,
            value:""
        },
    }

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [expanded, setExpanded] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [empListLoader, setEmpListLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchLoader, setSearchLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [selectedPeer, setSelectedPeer] = useState(null);
    const [activeTab, setActiveTab] = useState("upComingSessions");

    const [draftCount, setDraftCount] = useState(0);
    const [doneCount, setDoneCount] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
    
    const handleClickAction = (event, emp) => {
        setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
      getCounts();
    }, [])

    
    

    useEffect(() => {   
        getPageData();
    }, [activeTab, currentPage]);  

    useEffect(() => {
        setSortEmpList(empList);
      }, [empList])

      const getCounts = () => {
        APIList.getDraftReportCount({empId: employeeDetails?.id})
        .then((res) => {
          setDraftCount(res?.data?.count);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
        })
  
        APIList.getDoneReportCount({empId: employeeDetails?.id})
        .then((res) => {
          setDoneCount(res?.data?.count);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
        })
    }

    const getPageData = () => {
        if(activeTab === "lastSessions") {
            setEmpListLoader(true);
            APIList.getDoneSession({
                empId: employeeDetails?.id,
                page: currentPage,
                companyId: employeeDetails?.company?.id,
                size: 10
            })        
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
                setEmpList(null);
            })
        } else {
            setEmpListLoader(true);
            APIList.getAllsessionByParticipant({
                empId: employeeDetails?.id,
                // status: "Due" ,
                // sessoinStatus: activeTab === "upComingSessions" ? "ACTIVE" : "Draft",
                page: currentPage,
                size: 10,
                companyId: employeeDetails?.company.id
            })        
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
                setEmpList(null);
            })
        }
    }
    

  const handlePeerList = (value) => {    
    setCurrentPage(0);
    setActiveTab(value);
  }

  const handleViewSession = () => {
    history.push({pathname:"/view-session", state:{id: selectedPeer?.id}})
  }

  const handleEditSession = (isActive) => {
    history.push({pathname:"/add-session", state:{id: selectedPeer?.id, isActive: isActive}})
  }

  const handleDeleteSession = () => {
    setDeleteLoader(true);
    APIList.deleteSession({id: selectedPeer?.id})
    .then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp; deleted successfully
            </div>
          );
          handleClose();
          setDeleteLoader(false);
          getPageData();
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDeleteLoader(false);
    })
  }

  const isSessionStarted = (startDate) => {
    const currentDate = new Date();
    const sDate = new Date(startDate)
    if(sDate.getDate() < currentDate.getDate()){
        return true;
    } else if(sDate.getDate() === currentDate.getDate()){
        if(sDate.getTime() < currentDate.getTime()){
            return true;
        } else {
            return false;
        }
    } else if(sDate.getDate() > currentDate.getDate()){
        return false;
    }
  }

  const isExpired = (endTime) => {
    const currentDate = new Date();
    const eDate = new Date(endTime)
    if(eDate.getDate() < currentDate.getDate()){
        return true;
    } else if(eDate.getDate() === currentDate.getDate()){
        if(eDate.getTime() < currentDate.getTime()){
            return true;
        } else {
            return false;
        }
    } else if(eDate.getDate() > currentDate.getDate()){
        return false;
    }
  }

// Get the user's time format preferences
const userTimeFormat = Intl.DateTimeFormat().resolvedOptions();
const is12HourFormat = userTimeFormat.hour12;
    return(
        <div className="rating-collab">

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={deleteLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
     
            <div className="search-wrapper">
                {/* <div className="r-f-tabs">
                    <div className={`tab ${activeTab === "upComingSessions" && "active"}`} onClick={() => handlePeerList("upComingSessions")}>Sessions</div>
                    <div className={`tab ${activeTab === "draftSessions" && "active"}`} onClick={() => handlePeerList("draftSessions")}>Draft session <span>{(draftCount > 0 && draftCount < 10) ? `0${draftCount}` : draftCount}</span></div>
                    <div className={`tab ${activeTab === "lastSessions" && "active"}`} onClick={() => handlePeerList("lastSessions")}>Last session report <span>{(doneCount > 0 && doneCount < 10) ? `0${doneCount}` : doneCount}</span></div>
                </div>
                <div className="second-div">
                        <button className="add-session" onClick={() => history.push("/add-session")}>
                            <img src={addsession} alt="" />
                            Add session
                        </button>
                
                    <div className="page-i-btn" 
                        onClick={() => setIsOpenDrawerForIbtn(true)}
                    >
                        i
                    </div>
                </div> */}
            
            </div>

         {
          <div className="row">
            <div className="col-12">
            <div className="session-tables">



            <div className="tablee">
            <table class="feedback-emp-list-table"> 
            {
                activeTab === "upComingSessions" ?
                <tr className="table-head-emp-list"> 
                    <th className="first-col">Session Title </th> 
                    <th className="mid-cols">HRBP</th> 
                    <th className="mid-cols">Total Panelist</th> 
                    <th className="mid-cols">Cohort Count</th> 
                    <th className="mid-cols">Schedule</th> 
                    <th className="mid-cols">Status</th> 
                    {/* <th className="mid-cols">Action</th>  */}
                    <th></th> 
                </tr>
                :
                <tr className="table-head-emp-list"> 
                    <th>Session Title </th> 
                    <th className="mid-cols">HRBP</th> 
                    <th className="mid-cols">Total Panelist</th> 
                    <th className="mid-cols">Schedule</th> 
                    {/* <th className="mid-cols">Action</th>  */}
                    <th></th> 
                </tr>
            }
                
                
                {
                     searchLoader || empListLoader ? 
                     <TableSkel />
                     :
                sortEmpList?.map((emp,index) => {
                        return(   
                            activeTab === "upComingSessions" ?
                                <tr> 
                                    <td>
                                        <div className="empDetaila">
                                           
                                            <div className="userInfo">
                                                <p className="name">{emp?.name}</p>
                                                <p className="designation">code - {emp?.meetingLink}</p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            <div>{emp?.organiser?.name}</div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            {/* <div>{emp?.participants?.length}</div> */}
                                            {/* <div>{(emp?.participants?.length ?? 0) + (emp?.coHost ? 1 : 0) + (emp?.organiser ? 1 : 0)}</div> */}
                                            <div>{emp?.participantCount ?? 0}</div>
                                        </div>                                    
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            {/* <div>{emp?.coHortEmployees?.length}</div> */}
                                            <div>{emp?.coHortCount ?? 0}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className= "btns">        
                                            <div>
                                                {emp?.startTime ? getFormattedDate(emp?.startTime, dateFormat) : "-"}
                                            </div>

                                            <div>
                                                {
                                                    (emp?.startTime && emp?.endTime) ? (

                                                        `${getFormattedTime(emp?.startTime, 
                                                            "hh:mm A"
                                                            )} - ${getFormattedTime(emp?.endTime, 
                                                                "hh:mm A"
                                                                )}`


                                                    ) : ("-")
                                                }
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            <div>{isExpired(emp?.endTime) ? "EXPIRED" : emp?.status}</div>
                                        </div>
                                    </td>

                                    {/* <td>                                    
                                        <div className= "btns">
                                            {
                                                isSessionStarted(emp?.startTime) ? 
                                                !isExpired(emp?.endTime) &&
                                                <button className="send-btn" onClick={() => history.push({pathname: "/view-report", state:{sessionid: emp?.id}})} >
                                                    <IoPlaySharp />
                                                        Start
                                                </button>
                                                :
                                                <button className="send-btn" onClick={() => history.push({pathname: "/view-audit-report", state:{sessionid: emp?.id}})} >
                                                        Audit
                                                </button>
                                            }
                                        
                                        </div>    
                                    </td>  */}
                                    {/* <td>                                    
                                        <div className= "btns">
                                            {
                                                activeTab === "draftSessions" ?
                                                <></>
                                                :
                                                <button className="send-btn" 
                                                onClick={() => history.push({pathname: "/read-report", state:{sessionid: emp?.id}})}
                                                >
                                                    View report
                                                </button>
                                            }
                                        
                                        </div>    
                                    </td>  */}
                                    {/* <td>
                                        <div className="action">
                                            <IconButton variant="outlined" onClick={(event) => handleClickAction(event, emp)}>
                                                <BiDotsVerticalRounded /> 
                                            </IconButton>
                                            <Popover
                                                id={id} 
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    }
                                                }}
                                            >
                                                <div className="popover-btns" onClick={() => handleViewSession()}>View session</div>
                                                <div className="popover-btns" onClick={() => handleEditSession(true)}>Reschedule </div>
                                                <div className="popover-btns red" onClick={() => handleDeleteSession()}>Delete</div>
                                            </Popover>
                                        </div>
                                    </td>  */}
                                </tr>
                                
                                :
                                <tr> 
                                    <td>
                                        <div className="empDetaila">
                                        
                                            <div className="userInfo">
                                                <p className="name">{emp?.name}</p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            <div>{emp?.organiser?.name}</div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">
                                            <div>{emp?.participants?.length + (emp?.coHost ? 1 : 0) + (emp?.organiser ? 1 : 0)}</div>
                                        </div>
                                    </td> 
                                    <td>
                                        <div className= "btns">        
                                            <div>{emp?.startTime ? getFormattedDate(emp?.startTime, dateFormat) : '-'}</div>
                                                <div>{emp?.startTime ? getFormattedTime(emp?.startTime, 
                                                "hh:mm A"
                                                ) : "-"}</div>
                                        </div>
                                    </td> 
                                    {/* <td>                                    
                                        <div className= "btns">
                                            {
                                                activeTab === "draftSessions" ?
                                                <></>
                                                :
                                                <button className="send-btn" 
                                                onClick={() => history.push({pathname: "/read-report", state:{sessionid: emp?.id}})}
                                                >
                                                    View report
                                                </button>
                                            }
                                        
                                        </div>    
                                    </td>  */}
                                    <td>
                                        <div className="action">
                                            <IconButton variant="outlined" onClick={(event) => handleClickAction(event, emp)}>
                                            <BiDotsVerticalRounded /> 
                                            </IconButton>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    }
                                                }}
                                            >
                                                <div className="popover-btns" onClick={() => handleViewSession()}>View session</div>
                                                {
                                                    activeTab !== "lastSessions" && 
                                                    <div className="popover-btns" onClick={() => handleEditSession(false)}>Edit</div>
                                                }                                            
    
                                            </Popover>
                                        </div>
                                    </td> 
                                </tr>
                            
                        )
                    }
                   
                // }
                )
                }
                
            </table> 
            {
                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
                    
                } 

                {
                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }

      
          </div>
          
          </div>
          </div>
          </div>
         }
         {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                <InfoContent pageName='rating-calibration'/>
            </SideDrawer>
         } 
        </div>
        
    )
};

export default RatintCollab;