import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Greeting1 from "../../../../../assets/images/MyCommunity/greeting1.png";
import Greeting2 from "../../../../../assets/images/MyCommunity/greeting2.png";
import Greeting3 from "../../../../../assets/images/MyCommunity/greeting3.png";


const GreetingCard = ({ }) => {
    const dispatch = useDispatch();

    const greetingCardList = [
        {
            id: 1,
            icon: Greeting1,
        },
        {
            id: 2,
            icon: Greeting2,
        },
        {
            id: 3,
            icon: Greeting3,
        },
        {
            id: 4,
            icon: Greeting1,
        },
        {
            id: 5,
            icon: Greeting2,
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} className="greeting-container">
                <Grid container direction="row" wrap="nowrap" className="greeting-scroll">
                    {greetingCardList.map((item, index) => (
                        <Grid key={index} item className="greeting-item">
                            <img src={item.icon} alt="Slider" className="greeting-image" />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(GreetingCard);
