import React, { useState } from "react";
import { Grid } from "@mui/material";
import FeedbackComparison from './components/FeedbackComparison';
import ScoringBehaviours from './components/ScoringBehaviours';
import SummaryOfCompetencies from './components/SummaryOfCompetencies';
import SummaryFeedback from './components/SummaryFeedback';



const ReportPerformance = () => {

  return (
    <div className="report-performance">
      <Grid container spacing={2}>
        {/*----- Feedback Comparison -----*/}
        <Grid item xs={12} sm={8} md={8}>
          <FeedbackComparison />
        </Grid>

        {/*----- Top 5 Scoring Behaviours -----*/}
        <Grid item xs={12} sm={4} md={4}>
          <ScoringBehaviours />
        </Grid>

        {/*----- Summary of Competencies -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <SummaryOfCompetencies />
        </Grid>

        {/*----- Summary feedback -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <SummaryFeedback />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportPerformance;
