import { useSelector } from "react-redux";
import APIList from "../../../../../api";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack } from "@mui/material";
import ManagerFeedback from "../../../managerFeedBack";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import ManFeedback from "../manFeedback";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";
import ManFeedbackPerformanceHistory from "../performanceHistoryApraisal";
import Trial from "../trial";
// import UserPerformanceHistory from "../performanceHistoryApraisal";
import { FiDownload } from "react-icons/fi";
import { BASE_URL } from "../../../../../api/apiServices";
import axios from "axios";

const CompHistory = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [expanded, setExpanded] = useState("");
    const [formData, setFormData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isDownloadPdf, setIsDownloadPdf] = useState(false);

    useEffect(() => {
        getPerformanceHistoryData();
       }, [])
    
      const getPerformanceHistoryData = () => {
        setisLoading(true);
        APIList.compHistory({employeeId: employeeDetails?.id})
        .then((res) => {
          setFormData(res?.data);
          setisLoading(false);
        })
        .catch((err) => {
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.message}
            </div>
          );
          setisLoading(false);
        })  
      }

    // useEffect(() => {

    //   if(isPdfGenerated) {
    //     getFormData();
    //   }
        
    // }, [isPdfGenerated])

    const handleGeneratePdf = async (fileId) => {
      setIsDownloadPdf(true);
      axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/letter/compensation-pdf-by-file-id/${fileId}`,
          
          {headers: {
              'Authorization': `Bearer ${userToken?.id_token}`,
              'Content-Type': 'application/json',
            },  'responseType': 'arraybuffer'      
          })
          .then((response) => 
          {
            if(response?.data){
             // Create a Blob from the byte array
              const blob = new Blob([response?.data], { type: 'application/pdf' });

              // Create a URL for the Blob
              const url = URL.createObjectURL(blob);

              // Create an anchor element and trigger a download
              const link = document.createElement('a');
              link.href = url;
              link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
              document.body.appendChild(link);
              link.click();

              // Clean up by removing the anchor element and revoking the object URL
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }

          setIsDownloadPdf(false);
              
          })
          .catch((err) => {
            console.log("errrrr",err)
                   toast.error(
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.message}
                  </div>
              );
              setIsDownloadPdf(false);
          });    

    };  

      const handleChange = (index) => {
        if (expanded === index) {
          setExpanded(null);
        } else {
          setExpanded(index);
        }
      };

      const handleDownloadPdf = (e, fileId) => {
        e.stopPropagation();
        handleGeneratePdf(fileId);
      }

    return(
        <div className="performance-history">

          <div className="header-box">
            <span>Cycle Name</span>
            {/* <span>OKR</span>
            <span>MBB</span>
            <span>MBSR Doc</span> */}
          </div>
            
            {
              isLoading ? 
              <CircularProgress />
              :
               formData?.length > 0 ? 
               formData?.map((data) => {
                return(
                  <div className="acc-wrapper">
                <Stack
                  sx={{
                    margin: "1rem 0px",
                    "& .MuiAccordion-rounded": {
                      border: "1px solid #dad2d2",
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === data?.fileId}
                    onChange={(e) => {
                      handleChange(data?.fileId);
                      e.stopPropagation();
                    }}
                    data-value={`Tab-1`}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        width: "100%",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        "& .MuiAccordionSummary-content": {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <p className="accordian-heading">
                        {data?.annualCycleName}
                      </p>
                     
                      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                      {
                        isDownloadPdf ? 
                        <CircularProgress />
                        :
                        <p className="download-btn-for-performance-history" onClick={(e) => handleDownloadPdf(e, data?.fileId)} style={{ marginRight:"1rem",}} >
                          <FiDownload />
                        </p>
                      }
                      
                      <BsChevronDown
                        style={{
                          ...accordianSVGstyle,
                          transform:
                          expanded !== data?.fileId
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                        }}
                      />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        fontSize: "1rem",
                      }}
                    >
                        <Trial fileId={data?.fileId} isDownloadPdf={isDownloadPdf} setIsDownloadPdf={setIsDownloadPdf} showFor="comp" />
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </div>
                )
               })
              
              :
              <div className="no-data" style={{color:"var(--primary)", textAlign:"center", margin:"1rem"}}>No data found</div>
            }
            

        </div>
    )
}

export default CompHistory;