import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, alpha, styled } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Avatar from "react-avatar";
import { IoIosArrowForward } from "react-icons/io";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../utils/utils";
import InputBase from '@mui/material/InputBase';
import { IoMdSearch } from "react-icons/io";
import { BiSort } from "react-icons/bi";
import PaginationMedi from "../../common/pagination";
import EmplistSkel from "./components/skel";
// import FilterSelect from "./components/filterSelect";
import TableSkel from "../../common/tableSkeleton";
import FilterSelect from "../../common/filterSelect";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import downloadIcon from "../../../assets/images/dashboard/download.png";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { BASE_URL } from "../../../api/apiServices";
import axios from "axios";
import SideDrawer from "../../common/sideDrawer";
import PerrFeedbackDrawer from "../myTeam/components/perrFeedbackDrawer";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    // border:"1px solid var(--primary)",
    boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.2rem !important",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));  

const MyTeam = () => {

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [expanded, setExpanded] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [empListLoader, setEmpListLoader] = useState(false);
    // const [showAppraisal, setshowAppraisal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [allCounts, setAllCounts] = useState([]);
    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState(null);
    const [subTeam, setSubTeam] = useState(null);
    const [openManId, setOpenManId] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [reportList, setReportList] = useState([]);
    const [annualCycle, setAnnualCycle] = useState(null);
    const [openStatistics, setOpenStatistics] = useState(false);
    const [activeTab, setActiveTab] = useState("My Team");

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState(null)

// console.log("currentPage",reportList, annualCycle);

     useEffect(() => {
       APIList.getDashboardData({id:employeeDetails?.id})
       .then((res) => {
        setAllCounts(res?.data);
        setTotalEmployees(res?.data?.calibrationEntityDTO?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));

            let Tempmbb = []; 
            let Tempperform = [];

            res?.data?.calibrationEntityDTO?.preCalibratedRatings?.forEach((item) => {
                if(!Tempmbb.includes(item?.mbbRating)){
                Tempmbb.push(item?.mbbRating);
                }
                if(!Tempperform.includes(item?.preformanceRating)){
                    Tempperform.push(item?.preformanceRating);
                }
            })
            setMbbRatings(Tempmbb);
            setPerformanceRatings(Tempperform);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
       })

       APIList.getAllReports({companyId: employeeDetails?.company?.id, reportType:"Feedback"})
       .then((res) => {
        setReportList(res?.data);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
       })

       APIList.getAnnualCycle({companyId: employeeDetails?.company?.id})
       .then((res) => {
        setAnnualCycle(res?.data[0]);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
       })
     }, [])
     

      useEffect(() => {
        
        !firstLoad && setSearchLoader(true);
        const getSearch = setTimeout(() => {
            if(!firstLoad){
            APIList.getMyTeamGrouped({
                payload : {
                    employeeEligibilityDTO : filterPayload,
                    keyword : search,
                    managerId : employeeDetails?.id,
                    companyId : employeeDetails?.company?.id
                },
                page: currentPage,
                size: itemsPerPage
            })  
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setSearchLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                setSearchLoader(false);
            }
            )
        }            
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [search])

      useEffect(() => {
        if(!firstLoad){
                setSearchLoader(true);   
            APIList.getMyTeamGrouped({
                payload : {
                    employeeEligibilityDTO : filterPayload,
                    keyword : search,
                    managerId : employeeDetails?.id,
                    companyId : employeeDetails?.company?.id
                },
                page: currentPage,
                size: itemsPerPage
            })  
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setSearchLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                setSearchLoader(false);
            }
            )
        }
      }, [currentPage])

  useEffect(() => {
   getPageData();
  }, [employeeDetails]);  

  useEffect(() => {
    setCurrentPage(0);
    getPageData();
  }, [itemsPerPage])
  

    const getPageData = () => {
        // console.log("getPageData");
        setEmpListLoader(true);
        APIList.getMyTeamGrouped({
            payload : {
                employeeEligibilityDTO : filterPayload,
                keyword : search,
                managerId : employeeDetails?.id,
                companyId : employeeDetails?.company?.id
            },
            page: currentPage,
            size: itemsPerPage
        })        
        .then((res) => {
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setEmpListLoader(false);
            setFirstLoad(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }
    
    useEffect(() => {
      setSortEmpList(empList);
    }, [empList])

    const handleSubmitFilter = () => {
        setSearchLoader(true);
        APIList.getMyTeamGrouped({
            payload : {
                employeeEligibilityDTO : filterPayload,
                keyword : search,
                managerId : employeeDetails?.id,
                companyId : employeeDetails?.company?.id
            },
            page: currentPage,
            size: itemsPerPage
        })   
        .then((res) => {
            // console.log("res",res)
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setSearchLoader(false);
            setExpanded(null)
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSearchLoader(false);
        })
    }
    

    const handleChange = (index) => {
        setCurrentPage(0);
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleFeedback = (emp) => {
        if(isEmptyNullUndefined(employeeDetails?.manager?.manager)) {
            // history.push({pathname:`/no-feedback/${emp?.employeeId}`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});
        } else {
            /////////// 10 jan hide//////////
            if(emp?.feedbackstatus === "Submitted"){
                // history.push({pathname:`/view-feedback/${emp?.employeeId}`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});
            } else {
                // history.push({pathname:`/emp-feedback/${emp?.employeeId}`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});
            }
        }
        /////////// 10 jan add////////// it will be on else condition but not showing

        if(emp?.appraisalStatus == "Not submitted") {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{"Employee Self Appraisal Not Submitted Yet ! "}
                </div>
              )
        } else {
            if(employeeDetails?.permissions?.includes("manager-feedback-view") || employeeDetails?.permissions?.includes("manager-feedback-create")) {
                history.push({pathname:`/manager-feedBack/${emp?.employeeId}`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});
            }            
        }
        
    };

  
  const autoSearchText = (e) => {
    setCurrentPage(0)
    setSearch(e.target.value);
  };


  const handleResetFilter = () => {
    setFilterPayload({}); 
    setCurrentPage(0); 
    setEmpListLoader(true);
    APIList.getMyTeamGrouped({
        payload : {
            employeeEligibilityDTO : {},
            keyword : search,
            managerId : employeeDetails?.id,
            companyId : employeeDetails?.company?.id
        },
        page: currentPage,
        size: itemsPerPage
    })        
    .then((res) => {
        setEmpList(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setEmpListLoader(false);
        setFirstLoad(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setEmpListLoader(false);
    })
  }

  const handleSubTeam = (man) =>{
       if(openManId === man?.employeeId){
        setOpenManId(null);
        setSubTeam(null);
       } else {
        setOpenManId(man?.employeeId);
        setSubTeam(man?.subTeam);
       }
  }

  const handleDownloadReport = async(e) => {

    axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download?cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}`,{headers: {
        'Authorization': `Bearer ${userToken?.id_token}`,
        'Content-Type': 'application/json',
      },  'responseType': 'arraybuffer' }).then((response) => 
    {
         const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Trigger the download
      saveAs(blob, `${e.target.value.reportName}.xlsx`);
        
    })
    .catch((err) => {
             toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
    });    
 
  }

  const handleViewFeedback = (emp) => {
    setSelectedTeamMember(emp);
    setIsOpenDrawer(true);
  }

    return(
        <div className="feedback-index-one user-profile-page px-10 container">

            <div className="tabs-outer">

                {/* <div className="tabs">
                    <div className={`tab ${activeTab === "My Team" ? "active" : ""}`} onClick={() => setActiveTab("My Team")}>My Team</div>
                    <div className={`tab ${activeTab === "My Org" ? "active" : ""}`} onClick={() => setActiveTab("My Org")}>My Org</div>
                    <div className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => setActiveTab("Entire Org")}>Entire Org</div>
                </div> */}

                <div className="report-download">
                    {
                        employeeDetails?.permissions?.includes("my-team-report-download") && 
                        // <div className="report-btn-div">
                        //     <div className="bottom">
                        //         <button>Download report <img src={downloadIcon} alt="" /></button>
                        //     </div>
                        // </div>
                        <div className="report-btn-div">
                            <div className="bottom">
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Download report</InputLabel>
                                    <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={null}
                                    label="Download report"
                                    onChange={handleDownloadReport}
                                    >
                                        {
                                            reportList?.map((report) => {
                                                return(
                                                    <MenuItem value={report}>{report?.reportName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                // border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={openStatistics} onChange={() => setOpenStatistics(!openStatistics)} data-value={"stats"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                }}}
                            >
                                <p className="accordian-heading" >Statistics</p>
                                <BsChevronDown
                                    style={{...accordianSVGstyle ,transform: !openStatistics ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem"
                            }}
                            >
                                {
                    // showAppraisal && 
                    <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={expanded === "filters"} onChange={() => handleChange("filters")} data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                }}}
                            >
                                <p className="accordian-heading" >Filters</p>
                                <BsChevronDown
                                    style={{...accordianSVGstyle ,transform: expanded !== "filters" ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem"
                            }}
                            >
                                <Stack sx={{display:"flex", flexDirection:"row" , flexWrap:"wrap"}}>
                                    {
                                      !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                        if(key === "employeeLevel"){
                                            return null;
                                        }
                                            return(
                                                <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                            )
                                        })
                                    }
                                </Stack>
                                <Stack sx={{
                                    display:"flex",
                                    flexDirection:"row",
                                    justifyContent:"flex-end",
                                    marginTop:"1rem"
                                    }}>

                                    <Button 
                                    variant="outlined"
                                    sx={{
                                        color:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        marginRight:"1rem",
                                        width:"7rem"
                                    }}
                                    onClick={() =>  handleResetFilter()}
                                    >
                                        Reset
                                    </Button>
                                    <Button 
                                    variant="contained"
                                    sx={{
                                        color:"var(--secondary)",
                                        backgroundColor:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        "&:hover":{
                                            backgroundColor:"#035c7c",
                                            borderColor:"#035c7c",
                                        },
                                        width:"7rem"
                                    }}
                                    disabled={searchLoader}
                                    onClick={() => handleSubmitFilter({})}
                                    >
                                        Submit                                     
                                    </Button>
                                    
                                </Stack>
                            </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </div>
                }

            <div className="team-info">
                <div className="all-counts">
                    <div className="head">Submission Status</div>
                    <div className="total-team">
                        <div className="title">Total Team Member</div>
                        <div className="team-count">{allCounts?.totalTeamMembers}</div>
                    </div>
                    <div className="total-team">
                        <div className="title">Manager feedback submitted</div>
                        <div className="team-count">
                            {((allCounts?.totalManagerFeedbackSubmitted / (allCounts?.totalSelfAppraisals !== 0 ? allCounts?.totalSelfAppraisals : 1)) * 100).toFixed(2)}
                            <span className="percent-sign">%</span>
                            <span className="vertical-line">|</span>
                            <span className="small-text">{allCounts?.totalManagerFeedbackSubmitted}</span>
                        </div>
                    </div>
                    <div className="total-team">
                        <div className="title">Total self appraisal</div>
                        <div className="team-count">
                            {((allCounts?.totalSelfAppraisals / (allCounts?.totalTeamMembers !== 0 ? allCounts?.totalTeamMembers : 1)) * 100).toFixed(2)}
                            <span className="percent-sign">%</span>
                            <span className="vertical-line">|</span>
                            <span className="small-text">{allCounts?.totalTeamMembers}</span>
                        </div>
                    </div>
                </div>
                <div className="team-rating-table">
                <div className="outer-layout mt-3">
                    <div className="first-column">
                        <div className="first-row">     
                        <div className="d-flex">
                            {/* <div className="w-80">Medibuddy Beliefs (MBB) Performance</div>
                            <div className="w-20">dropdown</div> */}
                        </div>   
                        <div className="tableDiv"> 
                                <div className="table-heading">
                                    <div className="col border-0 bgn" style={{width:
                                        // `${100/(mbbRatings?.length + 1)}%`
                                        "10%"
                                        }} >&nbsp;</div>
                                    {
                                        mbbRatings?.map((mbb) => {
                                            return(
                                                <div className="col" style={{width:`${100/(mbbRatings?.length + 1) - (10/mbbRatings?.length)}%`}} >{mbb}</div>
                                            )                                            
                                        })
                                    }
                                </div>
                                {
                                    performanceRatings?.map((performance) => {
                                        return(
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn">{performance}</div>
                                                {
                                                    mbbRatings?.map((mbb) => {
                                                        return(
                                                            <div className="col" >
                                                            
                                                                {
                                                                    allCounts?.calibrationEntityDTO?.preCalibratedRatings?.map((item) => {
                                                                        return(
                                                                            item?.mbbRating === mbb &&
                                                                            item?.preformanceRating === performance &&
                                                                            <div className="pre-post" >
                                                                              {((item?.employees?.length/totalEmployees)*100).toFixed(2)}% <spaan className="vertical-line">|</spaan> {item?.employees?.length} 
                                                                                
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            
                                                            </div>
                                                        )                                            
                                                    })
                                                }
                                           </div>
                                        )
                                    })
                                }
                               
                               
                            </div>  
                                                                 
                        </div>  
                        
                    </div>  
                                      
                </div>
                </div>
            </div>
                                
                            </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </div>
     
         {
            empListLoader ? 
            <EmplistSkel />
            :
          <div className="row">
            <div className="col-12">
            <div className="feedback-one">              
            

            <div className="search-wrapper">
                <Search>
                    <SearchIconWrapper>
                        <IoMdSearch />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search..."
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={autoSearchText}
                    />
                </Search>
                {
                    employeeDetails?.permissions?.includes("my-team-change-items-per-page") && 
                    <Stack sx={{display:"flex", flexDirection:"row", alignItems:"center", color:"var(--primary)"}}>
                        Items per page - &nbsp;
                        <FormControl style={{ width: '5rem' }}>
                            <Select
                            size="small"
                                labelId="select-label"
                                id="custom-select"
                                value={itemsPerPage} // Set a default value if needed
                                onChange={(e) => setItemsPerPage(e.target.value)}
                            >
                                {[...Array(20)].map((_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                }
                
            </div>
            
            

            <div className="tablee">
            <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list"> 
                    <th></th> 
                    <th>Employee
                         {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                         </th> 
                    <th className="mid-cols">
                        Self Appraisal 
                        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Peer Feedback</th> 
                    <th className="mid-cols">
                        Manager Feedback 
                        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Rating</th> 
                    <th className="mid-cols">Promotion Recommdation</th> 
                    <th></th> 
                </tr>
                
                {
                     searchLoader ? 
                     <TableSkel />
                     :
                sortEmpList?.map((emp,index) => {
                        return(            
                            <>             
                                    <tr> 
                                    <td>
                                        {
                                            emp?.subTeam?.length > 0 ?
                                            openManId === emp?.employeeId ?                                            
                                            <AiOutlineMinusSquare onClick={() => handleSubTeam(emp)} style={{cursor:"pointer", fontSize:"1.25rem"}} />
                                            :
                                            <AiOutlinePlusSquare onClick={() => handleSubTeam(emp)} style={{cursor:"pointer", fontSize:"1.25rem"}} />
                                            :
                                            ""
                                        }                                        
                                    </td>
                                    <td>
                                        <div className="empDetaila">
                                            <div className="empPic">
                                            {emp?.profilePhotoPath ? 
                                                <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                :
                                                <Avatar 
                                                    name={emp.employeeName} 
                                                    size="45" 
                                                    className="userImg"
                                                    color={"#00425A"}    
                                                />
                                            }
                                            </div>
                                            <div className="userInfo">
                                                <p className="name">{emp?.employeeName}</p>
                                                <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                    {/* <p className= {emp?.appraisalStatus === "Submitted" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                                        {emp?.appraisalStatus === "Draft" ? "Not submitted" : emp?.appraisalStatus}
                                    </p> */}
                                    {/* /////////////self appresal///////////// */}
                                    {/* <p className= {emp?.appraisalStatus === "ACTIVE" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                                        {emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'not Submitted'}
                                    </p> */}
                                     <p className= {emp?.isEligible ? emp?.appraisalStatus === "ACTIVE" ? "btns green" : "btns grey" : "btns red"}>
                                        {emp?.isEligible ? emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'Not Submitted' : "Ineligible"}
                                    </p>
                                    </td> 
                                    <td>
                                    {/* <p className= "btns grey">
                                        Not submitted
                                    </p> */}
                                    {/* ///////////////peer-feedback//////////////////// */}
                                    {/* <p className= {emp?.peerFeedbackstatus[0] === "0" ? "btns yellow" : "btns grey" }>
                                        {emp?.peerFeedbackstatus}
                                    </p> */}
                                    <p className={emp?.isEligible ? "btns grey" : "btns red"}>
                                        {
                                            emp?.isEligible ? 
                                            <div className="view-feedback-btn" onClick={() => handleViewFeedback(emp)}>
                                            {
                                                
                                                !isEmptyNullUndefined(emp?.peerFeedbackstatus) ? 
                                                <div className="status">
                                                    <div>Received{`(${emp?.peerFeedbackstatus?.split("-")[0]})`}</div>
                                                    <div>Pending{`(${emp?.peerFeedbackstatus?.split("-")[1]})`}</div>
                                                </div>
                                                :
                                                <div className="status">
                                                    No Feedbacks
                                                </div>
                                                
                                            }
                                            
                                            <div>
                                               <IoIosArrowForward /> 
                                            </div>
                                        </div>
                                            :
                                                "Ineligible"
                                        }
                                        
                                    </p>
                                    </td> 
                                    <td>
                                    {/* <p className= {emp?.feedbackstatus === "Submitted" ? "btns green" : emp?.feedbackstatus === "Not submitted" ? "btns grey" : "btns yellow"}>
                                        {emp?.feedbackstatus}
                                    </p> */}
                                    {/* ///////////////manager-feedback//////////////////// */}
                                    <p className= {emp?.isEligible ? emp?.managerFeedbackstatus === "ACTIVE" ? "btns green" : "btns grey" : "btns red"}>
                                        {emp?.isEligible ? emp?.managerFeedbackstatus === "ACTIVE" ? "Submitted" : 'Not submitted' : "Ineligible"}
                                    </p>
                                    </td> 
                                    <td>                                    
                                    <p className= "btns grey">
                                    {
                                            emp?.rating?.split("/")?.map((rating,index) => {
                                                return(
                                                    <div style={{textWrap: 'nowrap'}}>{rating}</div>
                                                )
                                            })
                                        }
                                    </p>    
                                    </td> 
                                    <td>
                                    <p className= {"btns grey"}>
                                        {emp?.eligibleForPromotion ? 'Yes' : "No"}
                                    </p>
                                    </td> 
                                    <td>
                                        <div className="action">
                                            {
                                                emp?.isEligible ? 
                                                <IconButton variant="outlined" onClick={() => handleFeedback(emp)}>
                                            {/* Provide Feedback */}
                                            <IoIosArrowForward /> 
                                            </IconButton>
                                            :
                                            ""
                                            }
                                            
                                        </div>
                                    </td> 
                                </tr>

                                {
                                    openManId === emp?.employeeId && 
                                    subTeam?.map((emp) => {
                                        return(
                                            <tr> 
                                            <td style={{textAlign:"right"}}>
                                                                                     
                                            </td>
                                            <td>
                                                <div className="empDetaila" style={{paddingLeft:"1rem", borderLeft:"1px solid #cdcdcd"}}>
                                                    <div className="empPic">
                                                    {emp?.profilePhotoPath ? 
                                                        <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                        :
                                                        <Avatar 
                                                            name={emp.employeeName} 
                                                            size="45" 
                                                            className="userImg"
                                                            color={"#00425A"}    
                                                        />
                                                    }
                                                    </div>
                                                    <div className="userInfo">
                                                        <p className="name">{emp?.employeeName}</p>
                                                        <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                                    </div>
                                                </div>
                                            </td> 
                                            <td>
                                            {/* <p className= {emp?.appraisalStatus === "Submitted" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                                                {emp?.appraisalStatus === "Draft" ? "Not submitted" : emp?.appraisalStatus}
                                            </p> */}
                                            {/* /////////////self appresal///////////// */}
                                            {/* <p className= {emp?.appraisalStatus === "ACTIVE" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                                                {emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'not Submitted'}
                                            </p> */}
                                             <p className= {emp?.appraisalStatus === "ACTIVE" ? "btns green" : "btns grey"}>
                                                {emp?.isEligible ? emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'Not Submitted' : "Ineligible"}
                                            </p>
                                            </td> 
                                            <td>
                                            {/* <p className= "btns grey">
                                                Not submitted
                                            </p> */}
                                            {/* ///////////////peer-feedback//////////////////// */}
                                            {/* <p className= {emp?.peerFeedbackstatus[0] === "0" ? "btns yellow" : "btns grey" }>
                                                {emp?.peerFeedbackstatus}
                                            </p> */}
                                            <p className="btns grey">
                                                {emp?.peerFeedbackstatus}
                                            </p>
                                            </td> 
                                            <td>
                                            {/* <p className= {emp?.feedbackstatus === "Submitted" ? "btns green" : emp?.feedbackstatus === "Not submitted" ? "btns grey" : "btns yellow"}>
                                                {emp?.feedbackstatus}
                                            </p> */}
                                            {/* ///////////////manager-feedback//////////////////// */}
                                            <p className= {emp?.isEligible ? emp?.managerFeedbackstatus === "ACTIVE" ? "btns green" : "btns grey" : "btns red"}>
                                                {emp?.isEligible ? emp?.managerFeedbackstatus === "ACTIVE" ? "Submitted" : 'Not submitted' : "Ineligible"}
                                            </p>
                                            </td> 
                                            <td>                                    
                                            <p className= "btns grey">
                                            {
                                                    emp?.rating?.split("/")?.map((rating,index) => {
                                                        return(
                                                            <div style={{textWrap: 'nowrap'}}>{rating}</div>
                                                        )
                                                    })
                                                }
                                            </p>    
                                            </td> 
                                            <td>
                                            <p className= {"btns grey"}>
                                                {emp?.eligibleForPromotion ? 'Yes' : "No"}
                                            </p>
                                            </td>
                                            <td>
                                                <div className="action">

                                                {/* {
                                                    emp?.isEligible ? 
                                                    <IconButton variant="outlined" onClick={() => handleFeedback(emp)}>                                            
                                                    <IoIosArrowForward /> 
                                                    </IconButton>
                                                    :
                                                    ""
                                                } */}

                                                </div>
                                            </td> 
                                        </tr>
                                        )
                                    })
                                  

                                }
                                
                                </>  
                        )
                    }
                   
                // }
                )
                }
                
            </table> 
            {
                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
                    
                } 

                {
                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }
        {
            isOpenDrawer && 
            <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                <PerrFeedbackDrawer member={selectedTeamMember} />
            </SideDrawer>
         }
      
          </div>
          
          </div>
          </div>
          </div>
         }
        </div>
        
    )
};

export default MyTeam;