import Avatar from "react-avatar";
import { getFormattedDate, isEmptyNullUndefined } from "../../../utils/utils";
import { useSelector } from "react-redux";

const parse = require("html-react-parser");

const ViewRequest = ({req}) => {

    const hardMessageIfNull = () => {
        console.log('showing hard code message')
        return (
            <div class="msg">
                <p>{`Dear ${req?.requestedTo?.name},`}</p>
                {/* <p> I hope this message finds you well.</p> */}
                {/* <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p> */}
                <p>This request is generated by system.</p>
                <p>   Best regards,   </p>
                <p>{req?.requestedBy?.name}</p>
            </div>
        )
    }
    
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    console.log("reqreqreqreqreq",req)
    return(
        <div className="view-feedback-request">

            <div className="empDetaila">
              <div className="empPic">
              {req?.requestedTo?.profilePhoto?.path ? 
                  <img src={req?.requestedTo?.profilePhoto?.path} className="userImg" alt="User Image" />
                  :
                  <Avatar 
                      name={req?.requestedTo?.name} 
                      size="45" 
                      className="userImg"
                      color={"#00425A"}    
                  />
              }
              </div>
              <div className="userInfo">
                  <p className="name">{req?.requestedTo?.name}</p>
                  {/* <p className="designation">{req?.requestedTo?.employeeDesignation ? req?.requestedTo?.employeeDesignation : "Designation"}</p> */}
                  <p className="designation">{req?.requestedTo?.employeeDesignation ? req?.requestedTo?.employeeDesignation : ""}</p>
              </div>
          </div>
            <div className="msg">{isEmptyNullUndefined(req?.message) ? hardMessageIfNull() :  parse(req?.message)}</div>
            <div className="expiry-date"><span>Expiry Date : </span>{getFormattedDate(req?.expiryDate, dateFormat)}</div> 
        </div>
    )
}

export default ViewRequest;