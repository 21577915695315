import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import pencile from "../../../../assets/images/pencile.png";

const PersonalInformation = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div className="first-row">
      <div className="header-section">
        <Typography className="aboutheading">Personal Details</Typography>
        <img src={pencile} alt="MediBuddy" />
      </div>
      <div className="main-b eeppage">
        <div className="row">
          <div className="accordian-data">
            <div className="d-flex justify-content-between">
              <p className="title">Employee Name</p>:
              <p className="subTitle">Harry mustain</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Employee Id</p>:
              <p className="subTitle">AB703</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Email Id</p>:
              <p className="subTitle">Harry@gmail.com</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Employee type</p>:
              <p className="subTitle">Employee Type 1</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Date of joining</p>:
              <p className="subTitle">30 Sep 2023</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Level</p>:
              <p className="subTitle">L2</p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-section mt-4">
        <Typography className="aboutheading">Organizational Details</Typography>
      </div>
      <div className="main-b eeppage">
        <div className="row">
          <div className="accordian-data">
            <div className="d-flex justify-content-between">
              <p className="title">Employee Function</p>:
              <p className="subTitle">-</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Employee Sub function</p>:
              <p className="subTitle">-</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Manager Name</p>:
              <p className="subTitle">David D.</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Manager Id</p>:
              <p className="subTitle">Employee Type 2</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Manager Email Id</p>:
              <p className="subTitle">daivd@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-section mt-4">
        <Typography className="aboutheading">Rating History</Typography>
      </div>
      <div className="main-b eeppage">
        <div className="row">
          <div className="accordian-data">
            <div className="d-flex justify-content-between">
              <p className="title">Performance Cycle</p>:
              <p className="subTitle">2022-2023</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">Performance</p>:
              <p className="subTitle">Average</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="title">MBB Rating</p>:
              <p className="subTitle">1.0</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PersonalInformation);
