import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import AccomplishmentsIcon from "../../../../assets/images/successionPlan/accomplishmentsIcon.svg";
import SliderIndicatorIcon from "../../../../assets/images/successionPlan/sliderIndicatorIcon.svg";
import UserProfileIcon from "../../../../assets/images/successionPlan/userProfile.svg";

const Accomplishments = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {/*----- Accomplishments 10 Awards -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading competencies-mb-12">Accomplishments <span> | 10 Awards</span></Typography>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
              <div class="accompli-card">
                <img src={AccomplishmentsIcon} alt="MediBuddy" className='accompli-icon' />
                <div className='icon-bottom-border' />
                <Typography className="accompli-title">{"Beyond Performance Awards, Special innitiation"}</Typography>
                <Typography className="accompli-date">{"22 Dec 2023"}</Typography>
              </div>

              <div class="accompli-card">
                <img src={AccomplishmentsIcon} alt="MediBuddy" className='accompli-icon' />
                <div className='icon-bottom-border' />
                <Typography className="accompli-title">{"Beyond Performance Awards, Special innitiation"}</Typography>
                <Typography className="accompli-date">{"22 Dec 2023"}</Typography>
              </div>

              <div class="accompli-card">
                <img src={AccomplishmentsIcon} alt="MediBuddy" className='accompli-icon' />
                <div className='icon-bottom-border' />
                <Typography className="accompli-title">{"Beyond Performance Awards, Special innitiation"}</Typography>
                <Typography className="accompli-date">{"22 Dec 2023"}</Typography>
              </div>

              <div class="accompli-card">
                <img src={AccomplishmentsIcon} alt="MediBuddy" className='accompli-icon' />
                <div className='icon-bottom-border' />
                <Typography className="accompli-title">{"Beyond Performance Awards, Special innitiation"}</Typography>
                <Typography className="accompli-date">{"22 Dec 2023"}</Typography>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
              <img src={SliderIndicatorIcon} alt="MediBuddy" className='slider-indicator-icon' />
            </div>
          </div>
        </div>
      </div>

      {/*----- Appreciation 2 Appreciations -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading competencies-mb-12">Appreciation <span> | 2 Appreciations</span></Typography>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
              <div class="appreciation-inner-card">
                <img src={UserProfileIcon} alt="MediBuddy" className='accompli-icon' />
                <Typography className="appreciation-title">{"Kitty William"}</Typography>
                <Typography className="appreciation-post">{"Manager @TCS, USA"}</Typography>
                <Typography className="appreciation-desc">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the on meaningful without relying on meaningful without without relying on meaningful content meaningful ipsum content. Lorem ipsum ipsum may be used as a placeholder before final copy is available."}</Typography>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
              <img src={SliderIndicatorIcon} alt="MediBuddy" className='slider-indicator-icon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Accomplishments);
