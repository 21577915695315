import { useEffect, useState } from "react";
import APIList from "../../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { CircularProgress } from "@mui/material";
import DialogPopup from "../../../../common/Dialog";
import checkIcon from "../../../../../assets/images/popup-check.png";
import { isEmptyNullUndefined } from "../../../../utils/utils";

const ChangedReportPageForFunctionalLeader = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [sessionInfo, setSessionInfo] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [viewReport, setViewReport] = useState([]);    

    useEffect(() => {
        APIList.getCountOnlySessionById({id: location?.state?.sessionid})
        .then((res) => {
           setSessionInfo(res?.data);
        })
        .catch((err) => {
         toast.error(
             <div style={{display:"flex", flexDirection:"row"}}>
             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
             &nbsp;&nbsp;{err?.title}
             </div>
         );
        });

        getFinalReport();
        getFullReport();
    
      }, [])
    
    const getFinalReport = () => {
        APIList.getReportChanges({
            companyId: employeeDetails?.company?.id,
            sessionId : location?.state?.sessionid
        })
        .then((res) => {
            setViewReport(res?.data);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
        })
      }

    const getFullReport = () => {

        APIList.getSessionReportData({
            employeeEligibilityDTO: {},
            keyword: null,
            companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid
        })
        .then((res) => {
            setViewReport(res?.data?.postCalibratedRatings);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
        })
    }

  const handleSubmit = () => {
    setSubmitLoader(true);
    APIList.approveSessionFromReport({
        payload: viewReport,
        employeeId: employeeDetails?.id,
        sessionId : location?.state?.sessionid
    })
    .then((res) => {
        setOpenDialog(true);
        setSubmitLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setSubmitLoader(false);
    })
  }

  const getPromotionApprovedText = (data) => {
    if (data?.eligibleForPromotion !== "Yes") {
      return "-";
    }
    return isEmptyNullUndefined(data?.promotionApproved) ? "-" : data?.promotionApproved;
  };

    return(

        <div className="dashboard-main">
            <div className="rating-calibration-report-view">
                <div className="view-report-row">        
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br/> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br/> Count</div>
                    </div>             
                </div>

                <div className="final-report-heading">Final report</div>
                    
                <div className="changed-report">
            <div className="tablee">
            <table className="feedback-emp-list-table"> 
            
                <tr className="table-head-emp-list"> 
                    <th className="e-name">
                      E name 
                    </th> 
                    <th className="mid-cols">
                      Func/ sub-func
                    </th> 
                    <th className="mid-cols-small">
                    Is Recommended for Promotion
                    </th> 
                    <th className="mid-cols-small">
                    Is Promotion Approved
                    </th> 
                    <th className="mid-cols-small">
                    Rating Revised
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR MBB
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR MBB
                    </th> 
                    
                    <th className="last-col-large">Reason</th> 
                </tr>
                
                {
                  
                        viewReport?.map((data) => {
                            return(
                                <tr key={data?.name}>
                                    <td>
                                        <div className="emp-i">
                                            <div className="name">{data?.name}</div>
                                            <div className="emp-id">{data?.employeeLevel}</div>
                                        </div>
                                    </td>
                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                    <td>
                                         <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                            {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                        </span>
                                    </td>
                                    <td>
                                        <span className={`${(data?.promotionApproved === "Yes") ? "revised" :"not-revised"}`}>
                                            {getPromotionApprovedText(data)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                        {
                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                        }
                                        </span>
                                    </td>
                                    <td>{data?.preRating?.preformanceRating}</td>
                                    <td>{data?.preRating?.mbbRating}</td>
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.preformanceRating}</td>
                                    }
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.mbbRating}</td>
                                    }
                                    <td><div className="the-reason">{data?.reason}</div></td>
                                </tr>
                            )
                        })
                   
                
                
                }
                
            </table> 

          </div>
        </div>

                <div className="d-flex justify-content-end gap-3 pt-2 pb-4 main-btn">
                  
                    <button className="valuesBtn next"
                        onClick={() => handleSubmit()}
                    >
                        {
                            submitLoader ?
                                <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                                :
                                "Approve"
                        }
                    </button>
                </div>

            </div>


        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon" alt="check"></img>
                <div className="content">
                    Session Report successfully submitted. Thank you for your dedication to the growth and development of team.
                </div>
                <button className="button"
                    onClick={() => { history.push("/functional-leader")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        </div>
    )
}

export default ChangedReportPageForFunctionalLeader;