import React, { useState } from 'react';
import { Grid, Autocomplete, Typography, TextField, Box, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { HiOutlineChevronDown } from "react-icons/hi2";
import MinusIcon from "../../../../../assets/images/successionPlan/minusIcon.svg";
import PlusIcon from "../../../../../assets/images/successionPlan/plusIcon.svg";
import RoleCloseIcon from "../../../../../assets/images/successionPlan/roleClose.svg";

const filterOptions = [
  { title: 'Filter 1' },
  { title: 'Filter 2' },
  { title: 'Filter 3' },
  { title: 'Filter 4' },
];


const SuccessionAnalytics = ({ }) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({ title: 'Filter' });
  const [positionsToBeSelected, setPositionsToBeSelected] = useState([]);

  const positionsToBeSelectedOptions = [
    { title: 'CEO (Savannah Nguyen)' }
  ];

  const analyticsList = [
    { id: 1, title: 'CEO', successionCoverage: '1', benchStrength: '0', pipelineDiversity: '0' },
    { id: 2, title: 'CFO', successionCoverage: '3+', benchStrength: '4+', pipelineDiversity: '20-40%' },
    { id: 3, title: 'COO', successionCoverage: '0', benchStrength: '4+', pipelineDiversity: '<20%' },
    { id: 4, title: 'CRO', successionCoverage: '3+', benchStrength: '2-4', pipelineDiversity: '>40%' },
    { id: 5, title: 'VP India', successionCoverage: '2', benchStrength: '0', pipelineDiversity: '0' },
    { id: 6, title: 'VP Finance', successionCoverage: '0', benchStrength: '1-2', pipelineDiversity: '>40%' }
  ];

  return (
    <Grid container className="succession-analytics-grid">

      {/*----- Succession Analytics Title & Filter -----*/}
      <Grid item xs={12} sm={12} md={12} className="heading-div">
        <Typography className="analytics-heading">{"Succession Analytics"}</Typography>

        <Autocomplete
          single
          id="checkboxes-tags-demo"
          options={filterOptions}
          getOptionLabel={(option) => option.title}
          value={filters}
          onChange={(event, newValue) => {
            setFilters(newValue);
          }}
          popupIcon={<HiOutlineChevronDown />}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              size="small"
              fullWidth
              sx={{
                width: '140px',
                mt: '6px',
                "& fieldset": {
                  borderRadius: '8px',
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid container className="succession-analytics-card">

        {/*----- Positions to be selected -----*/}
        <Grid item xs={12} sm={12} md={12} className="p-24">
          <Typography className="analytics-field-label">{"Positions to be selected"}</Typography>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={positionsToBeSelectedOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            value={positionsToBeSelected}
            onChange={(event, newValue) => {
              setPositionsToBeSelected(newValue);
            }}
            popupIcon={<HiOutlineChevronDown />}
            renderOption={(props, option, { selected }) => (
              <Grid container {...props} className='dropdown-roles-container'>
                <Grid sx={{ marginLeft: "20px" }}>
                  <img
                    src={MinusIcon}
                    alt="MinusIcon"
                    height={20}
                    width={20}
                  />
                  <Box sx={{
                    width: "1px",
                    height: "100%",
                    backgroundColor: "#000000",
                    opacity: "10%",
                    marginLeft: "10px",
                  }} />
                </Grid>

                {/*----- CEO (Savannah Nguyen) -----*/}
                <Grid>
                  <div className="d-flex">
                    <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CEO (Savannah Nguyen)"}</Typography>
                    <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                  </div>

                  <Grid container className='dropdown-roles-container'>
                    <Grid sx={{ marginLeft: "10px" }}>
                      <img
                        src={MinusIcon}
                        alt="MinusIcon"
                        height={20}
                        width={20}
                      />
                      <Box sx={{
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#000000",
                        opacity: "10%",
                        marginLeft: "10px",
                      }} />
                    </Grid>

                    {/*----- CFO (John Doe) -----*/}
                    <Grid>
                      <div className="d-flex">
                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CFO (John Doe)"}</Typography>
                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                      </div>

                      <Grid container className='dropdown-roles-container'>
                        <Grid sx={{ marginLeft: "10px" }}>
                          <img
                            src={MinusIcon}
                            alt="MinusIcon"
                            height={20}
                            width={20}
                          />
                          <Box sx={{
                            width: "1px",
                            height: "100%",
                            backgroundColor: "#000000",
                            opacity: "10%",
                            marginLeft: "10px",
                          }} />
                        </Grid>

                        {/*----- Financial controller (Kristin Watson) -----*/}
                        <Grid>
                          <div className="d-flex">
                            <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"Financial controller (Kristin Watson)"}</Typography>
                            <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                          </div>

                          <Grid container className='dropdown-roles-container'>
                            <Grid sx={{ marginLeft: "10px" }}></Grid>
                            <Grid>
                              {/*----- VP Fin. India (NA) -----*/}
                              <div className="d-flex">
                                <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px" }}>{"VP Fin. India (NA)"}</Typography>
                                <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                              </div>

                              {/*----- VP Fin. UK (Jerome Bell) -----*/}
                              <div className="d-flex">
                                <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px" }}>{"VP Fin. UK (Jerome Bell)"}</Typography>
                                <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*----- CRO (Cameron Williamson) -----*/}
                  <Grid container className='dropdown-roles-container'>
                    <Grid sx={{ marginLeft: "10px" }}>
                      <img
                        src={PlusIcon}
                        alt="PlusIcon"
                        height={20}
                        width={20}
                      />
                      {/* <Box sx={{
                            width: "1px",
                            height: "100%",
                            backgroundColor: "#000000",
                            opacity: "10%",
                            marginLeft: "10px",
                          }} /> */}
                    </Grid>

                    <Grid>
                      <div className="d-flex">
                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CRO (Cameron Williamson)"}</Typography>
                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                      </div>
                    </Grid>
                  </Grid>


                  {/*----- COO (Albert Flores) -----*/}
                  <Grid container className='dropdown-roles-container'>
                    <Grid sx={{ marginLeft: "10px" }}>
                      <img
                        src={PlusIcon}
                        alt="PlusIcon"
                        height={20}
                        width={20}
                      />
                      {/* <Box sx={{
                            width: "1px",
                            height: "100%",
                            backgroundColor: "#000000",
                            opacity: "10%",
                            marginLeft: "10px",
                          }} /> */}
                    </Grid>

                    <Grid>
                      <div className="d-flex">
                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"COO (Albert Flores)"}</Typography>
                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                      </div>
                    </Grid>
                  </Grid>

                  {/*----- COO (NA) -----*/}
                  <Grid container className='dropdown-roles-container'>
                    <Grid sx={{ marginLeft: "10px" }}>
                      <img
                        src={PlusIcon}
                        alt="PlusIcon"
                        height={20}
                        width={20}
                      />
                      {/* <Box sx={{
                            width: "1px",
                            height: "100%",
                            backgroundColor: "#000000",
                            opacity: "10%",
                            marginLeft: "10px",
                          }} /> */}
                    </Grid>

                    <Grid>
                      <div className="d-flex">
                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"COO (NA)"}</Typography>
                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                      </div>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select"
                size="small"
                fullWidth
                sx={{
                  width: '100%',
                  mt: '6px',
                  "& fieldset": {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />

          {/* Selected values shown as chips under the input */}
          <div className='role-container'>
            {/* {positionsToBeSelected.map((option, index) => ( */}
            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"CEO"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>

            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"CFO"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>

            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"COO"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>

            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"CRO"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>

            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"VP India"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>

            <div className='role-border-div'>
              <div className='d-flex ai-center'>
                <Typography className='role-type'>{"VP Finance"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='role-close-Icon'
                />
              </div>
            </div>
            {/* ))} */}
          </div>
        </Grid>

        {/*----- Table Title & Description -----*/}
        <Grid container spacing={4} className="head-grid">
          <Grid item xs={12} sm={3} md={3}></Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography className="list-title">{"Succession Coverage"}</Typography>
            <Typography className="list-description">{"Number successors in ready now and <12 months"}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography className="list-title">{"Bench Strength"}</Typography>
            <Typography className="list-description">{"Number of successors in 12+ months categories"}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography className="list-title">{"Succ. Pipeline Diversity"}</Typography>
            <Typography className="list-description">{"Number of female successors/ overall successors"}</Typography>
          </Grid>
        </Grid>

        {/*----- Table Title & Description Value -----*/}
        {analyticsList?.map((item, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} className="analytics-item">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography className="item-title">{item.title}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Grid className={`count-grid ${item.successionCoverage === "3+" && "green-bg"} ${item.successionCoverage === "0" && "dark-pink-bg"} ${item.successionCoverage === "1" && "dark-orange-bg"} ${item.successionCoverage === "2" && "yellow-bg"}`}>
                      <Typography className="item-count">{item.successionCoverage}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Grid className={`count-grid ${item.benchStrength === "4+" && "green-bg"} ${item.benchStrength === "0" && "dark-pink-bg"} ${item.benchStrength === "1-2" && "dark-orange-bg"} ${item.benchStrength === "2-4" && "yellow-bg"}`}>
                      <Typography className="item-count">{item.benchStrength}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Grid className={`count-grid ${item.pipelineDiversity === ">40%" && "green-bg"} ${item.pipelineDiversity === "0" && "dark-pink-bg"} ${item.pipelineDiversity === "<20%" && "dark-orange-bg"} ${item.pipelineDiversity === "20-40%" && "yellow-bg"}`}>
                      <Typography className="item-count">{item.pipelineDiversity}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="item-b-border"></Grid>
            </>
          )
        })}
      </Grid>
    </Grid>
  );
};

export default React.memo(SuccessionAnalytics);
