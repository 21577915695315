import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, alpha, styled } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../utils/utils";
import InputBase from '@mui/material/InputBase';
import { IoMdSearch } from "react-icons/io";
import PaginationMedi from "../../common/pagination";
import EmplistSkel from "./components/skel";
import TableSkel from "../../common/tableSkeleton";
import { saveAs } from 'file-saver';
import { BASE_URL } from "../../../api/apiServices";
import axios from "axios";
import SideDrawer from "../../common/sideDrawer";
import PerrFeedbackDrawer from "./components/perrFeedbackDrawer";
import MultiLevelTable from "./components/multiLevelTable";
import MultiLevelTableForMyOrg from "./components/multilevelTableForMyOrg";
import FilterSelectForMyTeam from "../../common/filterSelectForMyTeam";
import MultiLevelTableForEntireOrg from "./components/multilevelTableForEntireOrg";
import checkIcon from "../../../assets/images/popup-check.png";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    // border:"1px solid var(--primary)",
    boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.2rem !important",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));  

const MyTeam = () => {

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [expanded, setExpanded] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [empListLoader, setEmpListLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [allCounts, setAllCounts] = useState([]);
    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [reportList, setReportList] = useState([]);
    const [annualCycle, setAnnualCycle] = useState(null);
    const [openStatistics, setOpenStatistics] = useState(false);
    const [activeTab, setActiveTab] = useState(null);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState(null);
    const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
    const [dropdownLoader, setDropdownLoader] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);

useEffect(() => {
    if(employeeDetails?.permissions?.includes("my-team-my-team-toggle")){
        setActiveTab('My Team');
    } else if(!employeeDetails?.permissions?.includes("my-team-my-team-toggle") && employeeDetails?.permissions?.includes("my-team-my-org-toggle")){
        setActiveTab('My Org');
    } else if (!employeeDetails?.permissions?.includes("my-team-my-team-toggle") && !employeeDetails?.permissions?.includes("my-team-my-org-toggle") && employeeDetails?.permissions?.includes("my-team-entire-org-toggle")){
        setActiveTab('Entire Org');
    } else if (!employeeDetails?.permissions?.includes("my-team-my-team-toggle") && !employeeDetails?.permissions?.includes("my-team-my-org-toggle") && !employeeDetails?.permissions?.includes("my-team-entire-org-toggle")){
        setActiveTab(null);
    }
}, [])

     useEffect(() => {

       APIList.getAllReports({companyId: employeeDetails?.company?.id, reportType:"Feedback"})
       .then((res) => {
        setReportList(res?.data);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
       })

       APIList.getAnnualCycle({companyId: employeeDetails?.company?.id})
       .then((res) => {
        setAnnualCycle(res?.data[0]);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
       })     

     }, [])

     useEffect(() => {           

        setSearch("");
        if(!isEmptyNullUndefined(activeTab)){
            if(currentPage !== 0){
                setCurrentPage(0);
            } else {
                getPageData();
            }                        

        }
      }, [activeTab]);  
    
      useEffect(() => {
        if(!isEmptyNullUndefined(activeTab)){
            if(currentPage !== 0){
                setCurrentPage(0);
            } else {
                getPageData();
            } 
        }
      }, [itemsPerPage]);     

      useEffect(() => {
        !firstLoad && setSearchLoader(true);
        const getSearch = setTimeout(() => {
            if(!firstLoad){
                
            getDashboard();
            APIList.getNewMyTeamGrouped({
                payload : {
                    employeeEligibilityDTO : filterPayload,
                    filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
                    keyword : search,
                    managerId : employeeDetails?.id,
                    companyId : employeeDetails?.company?.id,
                    page: activeTab
                },
                page: currentPage,
                size: itemsPerPage
            })  
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setSearchLoader(false);
            })
            .catch((err) => {
                setSearchLoader(false);
            }
            )
        }            
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [search])

      useEffect(() => {
        if(!firstLoad){
                setSearchLoader(true);   
            APIList.getNewMyTeamGrouped({
                payload : {
                    employeeEligibilityDTO : filterPayload,
                    filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
                    keyword : search,
                    managerId : employeeDetails?.id,
                    companyId : employeeDetails?.company?.id,
                    page: activeTab
                },
                page: currentPage,
                size: itemsPerPage
            })  
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setSearchLoader(false);
            })
            .catch((err) => {
                setSearchLoader(false);
            }
            )
        }
      }, [currentPage])

    const getPageData = () => {
        setEmpListLoader(true);
        APIList.getNewMyTeamGrouped({
            payload : {
                employeeEligibilityDTO : filterPayload,
                filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
                keyword : search,
                managerId : employeeDetails?.id,
                companyId : employeeDetails?.company?.id,
                page: activeTab
            },
            page: currentPage,
            size: itemsPerPage
        })
        .then((res) => {
            if(res?.data?.data){
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
            }            
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }
    const getDropdownData = () => {
        setDropdownLoader(true);
        APIList.getMyTeamDropdowns({
            range: activeTab,                                                                // My Team Or My Org
            companyId: employeeDetails?.company?.id,
            managerId: employeeDetails?.id
        })
        .then((res) => {
            console.log("res",res)
            setDropdownData(res?.data);
            setDropdownLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDropdownLoader(false);
        })
    }

    useEffect(() => {
        if((Object.keys(filterPayload).length === 0)){
            getDashboard();
        }
    }, [filterPayload])
    

    const getDashboard = () => {
        if(activeTab !== null){
        setDashboardDataLoader(true);
        // APIList.getNewDashboardData({id:employeeDetails?.id, range: activeTab})
        APIList.getNewDashboardData({
                employeeEligibilityDTO: filterPayload,
                filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
                keyword: search,
                managerId: employeeDetails?.id,
                companyId: employeeDetails?.company?.id,
                page: activeTab
        })
        .then((res) => {

            let tempRes = structuredClone(res.data)
            // tempRes.totalTeamMembers = null
            tempRes.totalTeamMembers = tempRes.totalTeamMembers ?? 0

         setAllCounts(tempRes);

          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = tempRes?.calibrationEntityDTO?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = tempRes?.calibrationEntityDTO?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

             setMbbRatings(Tempmbb);
             setPerformanceRatings(Tempperform);
             setDashboardDataLoader(false);
        })
        .catch((err) => {
         toast.error(
             <div style={{display:"flex", flexDirection:"row"}}>
             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
             &nbsp;&nbsp;{err?.title}
             </div>
         );
         setDashboardDataLoader(false);
        })
    }
    }
    
    useEffect(() => {
      setSortEmpList(empList);
    }, [empList])

    const handleSubmitFilter = () => {
        getDashboard();
        setSearchLoader(true);
        APIList.getNewMyTeamGrouped({
            payload : {
                employeeEligibilityDTO : filterPayload,
                filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
                keyword : search,
                managerId : employeeDetails?.id,
                companyId : employeeDetails?.company?.id,
                page: activeTab
            },
            page: currentPage,
            size: itemsPerPage
        })   
        .then((res) => {
            // console.log("res",res)
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setSearchLoader(false);
            setExpanded(null)
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSearchLoader(false);
        })
    }
    

    const handleChange = (index) => {
        setCurrentPage(0);
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleFeedback = (emp) => {
        
        if(emp?.appraisalStatus == "Not submitted") {          
              if(employeeDetails?.permissions?.includes("manager-feedback-view") || employeeDetails?.permissions?.includes("manager-feedback-create")) {
                history.push({pathname:`/manager-feedBack/${emp?.employeeId}/no`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}}); // at end /isAppreasalSubmited(yes/no)
            } 
        } else {
            if(employeeDetails?.permissions?.includes("manager-feedback-view") || employeeDetails?.permissions?.includes("manager-feedback-create")) {
                history.push({pathname:`/manager-feedBack/${emp?.employeeId}/yes`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});  //at end /isAppreasalSubmited(yes/no)
            }            
        }
        
    };

  
  const autoSearchText = (e) => {
    setCurrentPage(0)
    setSearch(e.target.value);
  };


  const handleResetFilter = () => {
    setFilterPayload({}); 
    setCurrentPage(0); 
    setEmpListLoader(true);
    APIList.getNewMyTeamGrouped({
        payload : {
            employeeEligibilityDTO : {},
            filterData: false, // Default is false. Set to true if filtering is required.
            keyword : search,
            managerId : employeeDetails?.id,
            companyId : employeeDetails?.company?.id,
            page: activeTab
        },
        page: currentPage,
        size: itemsPerPage
    })        
    .then((res) => {
        setEmpList(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setEmpListLoader(false);
        setFirstLoad(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setEmpListLoader(false);
    })
  }

  const [downloadLoader, setDownloadLoader] = useState(false);

  const handleDownloadReport = async(e) => {
    setDownloadLoader(true);
    // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
    axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/download/filter`,
    {
        employeeEligibilityDTO: filterPayload,
        filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
        reportId: e.target.value?.id,
        email: null,
        queryId: null,
        cycleId: annualCycle?.id,
        range: activeTab,
        companyId: employeeDetails?.company?.id,
        managerId: employeeDetails?.id
},
    {headers: {
        'Authorization': `Bearer ${userToken?.id_token}`,
        'Content-Type': 'application/json',
      },  'responseType': 'arraybuffer'      
    }).then((response) => 
    {
         const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
      // Trigger the download
      saveAs(blob, `${e.target.value.reportName}.xlsx`);
      setDownloadLoader(false);
        
    })
    .catch((err) => {
             toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDownloadLoader(false);
    });    
 
  }

  const handleViewFeedback = (emp) => {
    setSelectedTeamMember(emp);
    setIsOpenDrawer(true);
  }

  const handleOpenStatistics = () => {
    if(!openStatistics) {
    getDropdownData(); 
    getDashboard(); 
    }
    setOpenStatistics(!openStatistics)
}

const getRecurssiveTable = () => {
    let content;

    if (searchLoader || empListLoader) {
        content = <TableSkel />;
    } else if (!isEmptyNullUndefined(sortEmpList) && activeTab === "My Org") {
        content = <MultiLevelTableForMyOrg 
                    sortEmpList={sortEmpList} 
                    handleViewFeedback={handleViewFeedback} 
                    handleFeedback={handleFeedback} 
                    activeTab={activeTab} />;
    } else if (!isEmptyNullUndefined(sortEmpList) && activeTab === "Entire Org") {
        content = <MultiLevelTableForEntireOrg 
                    sortEmpList={sortEmpList} 
                    handleViewFeedback={handleViewFeedback} 
                    handleFeedback={handleFeedback} 
                    activeTab={activeTab} />;
    } else if (!isEmptyNullUndefined(sortEmpList)) {
        content = <MultiLevelTable 
                    sortEmpList={sortEmpList} 
                    handleViewFeedback={handleViewFeedback} 
                    handleFeedback={handleFeedback} 
                    activeTab={activeTab} />;
    }

    return content;
}

    return(
        <div className="feedback-index-one user-profile-page px-10 container">
            {
                activeTab !== null && 
                <>
        <div className="tabs-outer">

            <div className="tabs">

                {
                    employeeDetails?.permissions?.includes("my-team-my-team-toggle") && 
                        <button className={`tab ${activeTab === "My Team" ? "active" : ""}`} onClick={() => {setActiveTab("My Team"); setOpenStatistics(false)}}>My Team</button>
                }
                {
                    employeeDetails?.permissions?.includes("my-team-my-org-toggle") && 
                        <button className={`tab ${activeTab === "My Org" ? "active" : ""}`} onClick={() => {setActiveTab("My Org"); setOpenStatistics(false)}}>My Org</button>
                }
                {
                    employeeDetails?.permissions?.includes("my-team-entire-org-toggle") && 
                    <button className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => {setActiveTab("Entire Org"); setOpenStatistics(false)}}>Entire Org</button>
                }

                {/* <div className={`tab ${activeTab === "My Team" ? "active" : ""}`} onClick={() => setActiveTab("My Team")}>My Team</div>
                <div className={`tab ${activeTab === "My Org" ? "active" : ""}`} onClick={() => setActiveTab("My Org")}>My Org</div>
                <div className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => setActiveTab("Entire Org")}>Entire Org</div> */}
            </div>

            <div className="report-download">
                {
                    employeeDetails?.permissions?.includes("my-team-report-download") && 
                    // <div className="report-btn-div">
                    //     <div className="bottom">
                    //         <button>Download report <img src={downloadIcon} alt="" /></button>
                    //     </div>
                    // </div>
                    <div className="report-btn-div">
                        <div className="bottom">
                            {
                                downloadLoader ? 
                                <>
                                Downloading... <CircularProgress size={29} />
                                </>
                                :
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Download report</InputLabel>
                                <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={null}
                                label="Download report"
                                onChange={handleDownloadReport}
                                >
                                    {
                                        reportList?.map((report) => {
                                            return(
                                                <MenuItem value={report} key={report?.reportName}>{report?.reportName}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            }
                            
                        </div>
                    </div>
                }
            </div>
        </div>

<div className="acc-wrapper">
        <Stack sx={{
            margin:"1rem 0px",
            "& .MuiAccordion-rounded":{
                // border:"1px solid #dad2d2",
                borderRadius:"10px !important",
                boxShadow:"none"
            }                           
        }}>
            <Accordion
            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
            expanded={openStatistics} onChange={() => handleOpenStatistics()} data-value={"stats"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
            sx={{
                width:"100%",   
                fontSize:"1.25rem",
                fontWeight:"600",
                "& .MuiAccordionSummary-content":{
                    width:"100%", 
                    display:"flex", 
                    justifyContent:"space-between",
                }}}
            >
                <p className="accordian-heading" >Statistics</p>
                <BsChevronDown
                    style={{...accordianSVGstyle ,transform: !openStatistics ? "rotate(0deg)" : "rotate(180deg)"}}
                />          
            </AccordionSummary>
            <AccordionDetails
            sx={{
                fontSize:"1rem"
            }}
            >
                {
    // showAppraisal && 
    <div className="acc-wrapper">
        {
            dropdownLoader ? 
            <CircularProgress />
            :
            <Stack sx={{
                margin:"1rem 0px",
                "& .MuiAccordion-rounded":{
                    border:"1px solid #dad2d2",
                    borderRadius:"10px !important",
                    boxShadow:"none"
                }                           
            }}>
                <Accordion
                // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                expanded={expanded === "filters"} onChange={() => handleChange("filters")} data-value={"filters"}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                sx={{
                    width:"100%",   
                    fontSize:"1.25rem",
                    fontWeight:"600",
                    "& .MuiAccordionSummary-content":{
                        width:"100%", 
                        display:"flex", 
                        justifyContent:"space-between",
                    }}}
                >
                    <p className="accordian-heading" >Filters</p>
                    <BsChevronDown
                        style={{...accordianSVGstyle ,transform: expanded !== "filters" ? "rotate(0deg)" : "rotate(180deg)"}}
                    />          
                </AccordionSummary>
                <AccordionDetails
                sx={{
                    fontSize:"1rem"
                }}
                >
                        <Stack sx={{display:"flex", flexDirection:"row" , flexWrap:"wrap"}}>
                            {
                            !isEmptyNullUndefined(dropdownData) 
                            && Object.keys(dropdownData).map((key) => {
                                    return(
                                        <FilterSelectForMyTeam title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} dropdownData={dropdownData} />
                                    )
                                })
                            }
                        </Stack>
                    
                    <Stack sx={{
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"flex-end",
                        marginTop:"1rem"
                        }}>

                        <Button 
                        variant="outlined"
                        sx={{
                            color:"var(--primary)",
                            borderColor:"var(--primary)",
                            marginRight:"1rem",
                            width:"7rem"
                        }}
                        onClick={() =>  handleResetFilter()}
                        >
                            Reset
                        </Button>
                        <Button 
                        variant="contained"
                        sx={{
                            color:"var(--secondary)",
                            backgroundColor:"var(--primary)",
                            borderColor:"var(--primary)",
                            "&:hover":{
                                backgroundColor:"#035c7c",
                                borderColor:"#035c7c",
                            },
                            width:"7rem"
                        }}
                        disabled={searchLoader}
                        onClick={() => handleSubmitFilter({})}
                        >
                            Submit                                     
                        </Button>
                        
                    </Stack>
                </AccordionDetails>
                </Accordion>
            </Stack>
        }
    </div>
}

{
    dashboardDataLoader ? 
    <>
        <Skeleton variant="text" sx={{ fontSize: '2.5rem', width:"100%"  }} />
        <Skeleton variant="text" sx={{ fontSize: '2.5rem', width:"100%"  }} />
        <Skeleton variant="text" sx={{ fontSize: '2.5rem', width:"100%"  }} />
        <Skeleton variant="text" sx={{ fontSize: '2.5rem', width:"100%"  }} />
        <Skeleton variant="text" sx={{ fontSize: '2.5rem', width:"100%"  }} />
    </>
    :
    <div className="team-info">
    <div className="all-counts">
        <div className="head">Submission Status</div>
        <div className="total-team">
            <div className="title">Total Team Member</div>
            <div className="team-count">{allCounts?.totalTeamMembers}</div>
        </div>
        <div className="total-team">
            <div className="title">Manager Feedback Submitted</div>
            <div className="team-count">
                {((allCounts?.totalManagerFeedbackSubmitted / (allCounts?.totalTeamMembers !== 0 ? allCounts?.totalTeamMembers : 1)) * 100).toFixed(2)}
                <span className="percent-sign">%</span>
                <span className="vertical-line">|</span>
                <span className="small-text">{allCounts?.totalManagerFeedbackSubmitted}</span>
            </div>
        </div>
        <div className="total-team">
            <div className="title">Total Self Appraisal</div>
            <div className="team-count">
                {((allCounts?.totalSelfAppraisals / (allCounts?.totalTeamMembers !== 0 ? allCounts?.totalTeamMembers : 1)) * 100).toFixed(2)}
                <span className="percent-sign">%</span>
                <span className="vertical-line">|</span>
                <span className="small-text">{allCounts?.totalSelfAppraisals}</span>
            </div>
        </div>
    </div>
    <div className="team-rating-table">
    <div className="outer-layout mt-3">
        <div className="first-column">
            <div className="first-row">     
            <div className="d-flex">
                {/* <div className="w-80">Medibuddy Beliefs (MBB) Performance</div>
                <div className="w-20">dropdown</div> */}
            </div>   
            <div className="tableDiv"> 
                    <div className="table-heading">
                        <div className="col border-0 bgn" style={{width:
                            // `${100/(mbbRatings?.length + 1)}%`
                            "10%"
                            }} >&nbsp;</div>
                        {
                            performanceRatings?.map((performance) => {
                                return(
                                    <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`}} key={performance} >{performance}</div>
                                )                                            
                            })
                        }
                    </div>
                    {
                        mbbRatings?.map((mbb) => {
                            return(
                                <div className="table-row-full" key={mbb}>
                                    <div className="col border-0 bgn">{mbb}</div>
                                    {
                                        performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" key={performance} >
                                                
                                                    {
                                                        allCounts?.calibrationEntityDTO?.map((item) => {
                                                            return(
                                                                item?.mbbRating === mbb &&
                                                                item?.preformanceRating === performance &&
                                                                <div className="pre-post" >
                                                                  {allCounts?.totalTeamMembers ? ((item?.employees?.length/allCounts?.totalTeamMembers)*100).toFixed(0) : 0}% <spaan className="vertical-line">|</spaan> {item?.employees?.length} 
                                                                    
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                
                                                </div>
                                            )                                            
                                        })
                                    }
                               </div>
                            )
                        })
                    }
                   
                   
                </div>  
                                                     
            </div>  
            
        </div>  
                          
    </div>
    </div>
</div>
}


                
            </AccordionDetails>
            </Accordion>
        </Stack>
    </div>

{
empListLoader ? 
<EmplistSkel />
:
<div className="row">
<div className="col-12">
<div className="feedback-one">              


<div className="search-wrapper">
<Search>
    <SearchIconWrapper>
        <IoMdSearch />
    </SearchIconWrapper>
    <StyledInputBase
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search' }}
        onChange={autoSearchText}
    />
</Search>
{
    employeeDetails?.permissions?.includes("my-team-change-items-per-page") && 
    <Stack sx={{display:"flex", flexDirection:"row", alignItems:"center", color:"var(--primary)"}}>
        Items per page - &nbsp;
        <FormControl style={{ width: '5rem' }}>
            <Select
            size="small"
                labelId="select-label"
                id="custom-select"
                value={itemsPerPage} // Set a default value if needed
                onChange={(e) => setItemsPerPage(e.target.value)}
            >
                {[10,20,50].map((item, index) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Stack>
}

</div>

                    <div className="color-legends-block">
                        <div className="default"><img className="check-team" src={checkIcon} alt="check" /> <span> - Approved</span></div>
                    </div>

<div className="tablee-myteam">
<table className="feedback-emp-list-table"> 
<tr className="table-head-emp-list"> 
    <th></th> 
    <th>Employee
         {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
         </th> 
    <th className="mid-cols">
        Self Appraisal 
        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
        </th> 
    <th className="mid-cols">Peer Feedback</th> 
    <th className="mid-cols">
        Manager Feedback 
        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
        </th> 
    <th className="mid-cols">Manager Rating</th> 
    <th className="mid-cols">Calibration Rating</th> 
    <th className="mid-cols">Promotion Recommdation</th> 
    <th></th> 
</tr>

{
    //  searchLoader || empListLoader ? 
    //  <TableSkel />
    //  :
    //  (!isEmptyNullUndefined(sortEmpList) && activeTab === "My Org") ? 
    //   <MultiLevelTableForMyOrg sortEmpList={sortEmpList} handleViewFeedback={handleViewFeedback} handleFeedback={handleFeedback} activeTab={activeTab} />
    //  :
    //  (!isEmptyNullUndefined(sortEmpList) && activeTab === "Entire Org") ? 
    //   <MultiLevelTableForEntireOrg sortEmpList={sortEmpList} handleViewFeedback={handleViewFeedback} handleFeedback={handleFeedback} activeTab={activeTab} />
    //  :
    //  !isEmptyNullUndefined(sortEmpList) && <MultiLevelTable sortEmpList={sortEmpList} handleViewFeedback={handleViewFeedback} handleFeedback={handleFeedback} activeTab={activeTab} />

    getRecurssiveTable()

}

</table> 
{
    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
    
} 

{
    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
}
{
isOpenDrawer && 
<SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
<PerrFeedbackDrawer member={selectedTeamMember} />
</SideDrawer>
}

</div>

</div>
</div>
</div>
}
                </>
            }


        </div>
        
    )
};

export default MyTeam;