import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const BarChart = ({bar, average, drawDottedLines, chartId }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current;

        if (chart && drawDottedLines) {
            const drawDottedLine = (chart) => {
                const ctx = chart.ctx;
                const dataset = chart?.data?.datasets[0];
                const meta = chart.getDatasetMeta(0);
                const bar1 = meta?.data[0];
                const bar2 = meta?.data[1];
                const bar3 = meta?.data[2];
                const bar4 = meta?.data[3];

                const bar1Top = { x: bar1?.x, y: bar1?.y };
                const bar2Top = { x: bar2?.x, y: bar2?.y };
                const bar3Top = { x: bar3?.x, y: bar3?.y };
                const bar4Top = { x: bar4?.x, y: bar4?.y };

                ctx.save();
                ctx.setLineDash([5, 5]);
                ctx.strokeStyle = 'rgba(0, 0, 0, 0.6)';
                ctx.beginPath();
                ctx.moveTo(bar1Top?.x, bar1Top?.y);
                ctx.lineTo(bar2Top?.x, bar2Top?.y);
                // ctx.moveTo(bar2Top?.x, bar2Top?.y);
                // ctx.lineTo(bar3Top?.x, bar3Top?.y);
                ctx.moveTo(bar3Top?.x, bar3Top?.y);
                ctx.lineTo(bar4Top?.x, bar4Top?.y);
                ctx.stroke();

                const diff = dataset?.data[1] - dataset?.data[0];
                const percentDiff = ((diff / dataset?.data[0]) * 100).toFixed(2);

                const diff2 = dataset?.data[3] - dataset?.data[2];
                const percentDiff2 = ((diff2 / dataset?.data[2]) * 100).toFixed(2);

                ctx.font = '12px Arial';
                ctx.fillStyle = 'black';
                ctx.fillText(`${percentDiff}%`, (bar1Top?.x + bar2Top?.x) / 2, (bar1Top?.y + bar2Top?.y) / 2 - 10);
                ctx.fillText(`${percentDiff2}%`, (bar3Top?.x + bar4Top?.x) / 2, (bar3Top?.y + bar4Top?.y) / 2 - 10);

                ctx.restore();
            };

            const plugin = {
                id: 'dottedLinePlugin',
                afterDraw: (chart) => {
                    drawDottedLine(chart);
                },
            };

            ChartJS.register(plugin);

            return () => {
                ChartJS.unregister(plugin);
            };
        }
    }, [drawDottedLines, chartId]);

    const data = {
        labels: bar,
        datasets: [{
            label: 'Cost',
            data: average,
            backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(75, 192, 192, 0.2)'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(75, 192, 192, 1)'],
            borderWidth: 1
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                type: 'linear',
                position: 'left',
                title: {
                  display: true,
                  text: "Cost",
                  font: {
                      size: 16,
                      family:'poppins'
                  },
                },
                ticks: {
                  beginAtZero: true
                }
            }
        }
    };

    return <Bar ref={chartRef} id={chartId} data={data} options={options} />;
};

export default BarChart;

// import React from 'react';
// import './BarChart.css';

// const BarChart = ({ data, drawDottedLines }) => {
//   const barWidth = 40;
//   const barMargin = 20;
//   const chartHeight = 200;
//   const chartWidth = data?.length * (barWidth + barMargin);
//   const maxValue = Math.max(...data.map(d => d.value));

//   return (
//     <svg width={chartWidth} height={chartHeight} className="bar-chart">
//       {data.map((d, i) => {
//         const barHeight = (d.value / maxValue) * chartHeight;
//         const x = i * (barWidth + barMargin);
//         const y = chartHeight - barHeight;

//         return (
//           <g key={i}>
//             <rect x={x} y={y} width={barWidth} height={barHeight} fill={d.color} />
//             <text x={x + barWidth / 2} y={y - 5} textAnchor="middle">
//               {d.value}
//             </text>
//             {drawDottedLines && i < data?.length - 1 && (
//               <>
//                 <line
//                   x1={x + barWidth / 2}
//                   y1={y}
//                   x2={x + barWidth + barMargin + barWidth / 2}
//                   y2={chartHeight - (data[i + 1].value / maxValue) * chartHeight}
//                   stroke="black"
//                   strokeDasharray="5,5"
//                 />
//                 <text
//                   x={(x + (i + 1) * (barWidth + barMargin)) / 2}
//                   y={(y + chartHeight - (data[i + 1].value / maxValue) * chartHeight) / 2 - 10}
//                   textAnchor="middle"
//                   fontSize="12px"
//                   fill="black"
//                 >
//                   {((data[i + 1].value - d.value) / d.value * 100).toFixed(2)}%
//                 </text>
//               </>
//             )}
//           </g>
//         );
//       })}
//     </svg>
//   );
// };

// export default BarChart;