import { useDispatch, useSelector } from "react-redux";
import { actions as logInActions } from "../../../../redux/login/actions";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isEmptyNullUndefined } from "../../../utils/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginBg from "../../../../assets/images/loginImg.png"
import ButtonM from "../../../common/Button";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "../../../../api";

const LogIn = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const initialEmptyChangePasswordForm = {
    newPassword: null,
    confirmNewPassword: null,
  };
  const initialEmptyChangePasswordFormErrorList = {
    newPassword: { isError: false, errorMessage: "" },
    confirmNewPassword: { isError: false, errorMessage: "" },
    unmatch: { isError: false, errorMessage: "" },
  };
  const [password, setPassword] = useState(initialEmptyChangePasswordForm);
  const [passwordError, setPasswordError] = useState(
    initialEmptyChangePasswordFormErrorList
  );

  const key = location.search.split("=")[1];

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const handleChangePasswordForm = async (event, fieldType) => {
    const tempPassword = JSON.parse(JSON.stringify(password));
    const tempError = JSON.parse(JSON.stringify(passwordError));

    if (fieldType === "newPassword") {
      tempPassword[event.target.name] = event.target.value;
      tempError.newPassword.isError = false;
      tempError.newPassword.errorMessage = "";
    }
    if (fieldType === "confirmNewPassword") {
      tempPassword[event.target.name] = event.target.value;
      tempError.confirmNewPassword.isError = false;
      tempError.confirmNewPassword.errorMessage = "";
    }

    setPassword(tempPassword);
    setPasswordError(tempError);
  };

  const handleValidatePassword = async () => {
    let isValid = true;
    const tempPassword = JSON.parse(JSON.stringify(password));
    const tempError = JSON.parse(JSON.stringify(passwordError));

    if (isEmptyNullUndefined(tempPassword.newPassword)) {
      tempError.newPassword.isError = true;
      tempError.newPassword.errorMessage = "*Please enter your new Password";
      isValid = false;
    }

    if (!isEmptyNullUndefined(tempPassword.newPassword)) {
      if (
        !tempPassword.newPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        )
      ) {
        tempError.newPassword.isError = true;
        tempError.newPassword.errorMessage =
          "must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        isValid = false;
      }
    }
    if (isEmptyNullUndefined(tempPassword.confirmNewPassword)) {
      tempError.confirmNewPassword.isError = true;
      tempError.confirmNewPassword.errorMessage =
        "*Please confirm your new Password";
      isValid = false;
    }
    if (!isEmptyNullUndefined(tempPassword.confirmNewPassword)) {
      if (
        !tempPassword.confirmNewPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        )
      ) {
        tempError.confirmNewPassword.isError = true;
        tempError.confirmNewPassword.errorMessage =
          "must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        isValid = false;
      }
    }

    if (
      !isEmptyNullUndefined(tempPassword.newPassword) &&
      !isEmptyNullUndefined(tempPassword.confirmNewPassword) &&
      JSON.stringify(tempPassword.newPassword) !==
        JSON.stringify(tempPassword.confirmNewPassword)
    ) {
      tempError.unmatch.isError = true;
      tempError.unmatch.errorMessage = "Passwords do not match";
      isValid = false;
    }

    //do Submit
    if (isValid) {
      submitButtonClick();
    } else {
      setPasswordError(tempError);
    }
  };

  const submitButtonClick = () => {
    APIList.resetPassword({ key, newPassword: password.newPassword })
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {"Password reset done successfully! Please login"}
          </div>
        );
        setPassword(initialEmptyChangePasswordForm);
        setPasswordError(initialEmptyChangePasswordFormErrorList);
        history.push("/login");
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.detail}
          </div>
        );
      });
  };

  const [username, setUsername] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameText, setUsernameText] = useState(true);

  const userToken = useSelector((state) => state?.user?.userToken);
  const loading = useSelector((state) => state?.user?.loader);
  
  const checkValidation = () => {
    setUsernameText(false);
    if (username && password) {
      dispatch(logInActions.LogInRequest({
        username: username,
        password: password
      }));
    } 
      setSubmit(true);
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkValidation();
    }
  }
  return (
    <div className="login-main">
      <div className="row">
        <div className="col-6 left">
          <img src={LoginBg} style={{width: '100%'}} className="loginBg" alt="loginbg"></img>
        </div>
        <div className="col-6 right">
          <div className="loginBlock">
            <div className="loginBlockInner">
              <div className="loginHeadingOuter">
                <p className="loginHeading">Reset Your Password</p>
              </div>
              
              <div className="password-wrap">
                <div className="password-ip">
                  <div className="password-outer">
                  <input
                    value={password.newPassword}
                    onChange={(event) =>
                      handleChangePasswordForm(event, "newPassword")
                    }
                    name="newPassword"
                    // onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                    className={(submit && (password.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    type = {showPassword ? "text" : "password"}
                    placeholder="Password"
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                 
                  <button className="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                    {
                      showPassword ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </button>
                  </div>
                  {passwordError.newPassword.isError && (
                    <p 
                      // className="mt-2 text-sm" 
                      style={{color:"#ff0000"}}
                    >
                      {passwordError.newPassword.errorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="password-wrap">
                <div className="password-ip">
                  <div className="password-outer">
                  <input
                    value={password.confirmNewPassword}
                    name="confirmNewPassword"
                    onChange={(event) =>
                      handleChangePasswordForm(event, "confirmNewPassword")
                    }
                    // onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                    className={(submit && (password.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    type = {showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  {/* <span class="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                    {
                      showPassword ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </span> */}
                  </div>
                   {passwordError.confirmNewPassword.isError && (
                      <p 
                        // className="mt-2 text-sm" 
                        // style={{color:"var(--warning)"}}
                        style={{color:"#ff0000"}}
                      >
                        {passwordError.confirmNewPassword.errorMessage}
                      </p>
                    )}
                    {passwordError.unmatch.errorMessage && (
                      <p 
                        // className="mt-2 text-sm" 
                        // style={{color:"var(--warning)"}}
                        style={{color:"#ff0000"}}
                      >
                        {passwordError.unmatch.errorMessage}
                      </p>
                    )}
                </div>
              </div>

              <div className="flex flex-wrap pb-2">
                <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                  <ButtonM text='Reset Password' onpress={() => handleValidatePassword()} loading={loading} disabled={loading} bgcolor="#2e8004" color="#ffffff" bghover="#2e8004" />       
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default LogIn;
