import { Button, IconButton, Popover, Stack } from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Avatar from "react-avatar";
import { Search, SearchIconWrapper, StyledInputBase, getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../utils/utils";
import { IoMdSearch, IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import PaginationMedi from "../../common/pagination";
import TableSkel from "../../common/tableSkeleton";
import FilterSelect from "../../common/filterSelect/index.js";
import SideFilters from "../../common/sideFilters/index.js";
import SideDrawer from "../../common/sideDrawer/index.js";
import checkIcon from "../../../assets/images/popup-check.png";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import { FaSort } from "react-icons/fa6";

const GivePeerFedback = () => {

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [empListLoader, setEmpListLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState(null);
    const [filterPayload, setFilterPayload] = useState({});
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);

    const [selectedPeer, setSelectedPeer] = useState(null);

    const [activeTab, setActiveTab] = useState("Pending");
    const [requestCounts, setRequestCounts] = useState({
        completedRequestCount: 0,
        declinedRequestCount: 0,
        expiredRequestCount: 0,
        pendingRequestCount: 0,
    });
    
    const [sortByy, setSortByy] = useState("requestDate")
    const [sortOrderr, setSortOrderr] = useState("asc");
    
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickAction = (event, emp) => {
        setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

useEffect(() => {
 if(!employeeDetails?.permissions?.includes("create-give-peer-feedback")){
    setActiveTab("Completed");
 }
}, [])

useEffect(() => {
 
    getPageData(filterPayload, search, 0);

}, [sortByy, sortOrderr])


const autoSearchText = (e) => {
    setCurrentPage(0)
    setSearch(e.target.value);
  };

  const handlePeerList = (value) => {
        setActiveTab(value);
  };

useEffect(() => {
    if(currentPage !== 0 && currentPage !== null) {
        setCurrentPage(0);
    } else {
        getPageData(filterPayload, search, 0);
    }
}, [activeTab]);

useEffect(() => {
    setEmpListLoader(true);
    const getSearch = setTimeout(() => {
        if(currentPage !== 0 && currentPage !== null) {
            setCurrentPage(0);
        } else if(search !== null) {
            getPageData(filterPayload, search, 0);
        } else {
            setEmpListLoader(false);
        }
        
    }, 1000);    

    return () => clearTimeout(getSearch);

}, [search]);

useEffect(() => {
    if(currentPage !== null) {
         getPageData(filterPayload, search, currentPage);
    }
}, [currentPage]);


useEffect(() => {
    getSentCount();
  }, [employeeDetails]);  

  const getSentCount = () => {
    APIList.getReceivedRequestCount({id: employeeDetails?.id})
        .then((res) => {
            setRequestCounts(res?.data);
        })
        .catch((err) => {
        console.log(err);
        })
  }

    const getPageData = (filters, key, pagenumber) => {
        console.log("getPageData");
        setEmpListLoader(true);
        APIList.getFeedbackRequests({
            payload : {
                employeeEligibilityDTO : filters,
                keyword : key,
                employeeId : employeeDetails?.id,
                status: activeTab,
            },
            page: pagenumber || 0,
            size: 10,
            sortBy: sortByy,
            sortOrder: sortOrderr
        })        
        .then((res) => {
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setEmpListLoader(false);
            setIsOpenFilter(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }
    
    useEffect(() => {
      setSortEmpList(empList);
    }, [empList])

    const handleSubmitFilter = () => {
        if(Object.keys(filterPayload).length === 0){
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;Please select some filters 
                </div>
            );
        } else {
        setCurrentPage(0);
        setEmpListLoader(true);
        APIList.getFeedbackRequests({
            payload : {
                employeeEligibilityDTO : filterPayload,
                keyword : search,
                employeeId : employeeDetails?.id,
                status: activeTab,
            },
            page: currentPage,
            size: 10,
        })        
        .then((res) => {
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setEmpListLoader(false);
            setIsOpenFilter(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }
    }

  const handleResetFilters = () => {
    setFilterPayload({}); 
    setCurrentPage(0); 
    setEmpListLoader(true);
    APIList.getFeedbackRequests({
        payload : {
            employeeEligibilityDTO : {},
            keyword : search,
            employeeId : employeeDetails?.id,
            status: activeTab,
        },
        page: 0,
        size: 10,
    })        
    .then((res) => {
        setEmpList(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setEmpListLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setEmpListLoader(false);
    })
  };

  const handleDecline = () => {
    handleClose();
    APIList.declineFeedbackRequests({requestId: selectedPeer?.id})
    .then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Declined.
            </div>
          );
        getPageData(filterPayload, search, currentPage); 
        getSentCount();       
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
    })
  }

  const handleSort = (sortBy) => {
  
    if(sortOrderr === "asc"){
        setSortOrderr("desc");
    } else {
        setSortOrderr("asc");
    }

    setSortByy(sortBy);

  }

    return(
        <div className="give-peer-feedback-request-one">

          <div className="search-wrapper">
            <div className="r-f-tabs">
                   
            {
                employeeDetails?.permissions?.includes("create-give-peer-feedback") && (
                    <button 
                        className={`tab ${activeTab === "Pending" ? "active" : ""}`} 
                        onClick={() => handlePeerList("Pending")}
                    >
                        Pending
                        <span>
                            {(requestCounts?.pendingRequestCount > 0 && requestCounts?.pendingRequestCount < 10) 
                                ? `0${requestCounts?.pendingRequestCount}` 
                                : requestCounts?.pendingRequestCount
                            }
                        </span>
                    </button>
                )
            }
            
            <button className={`tab ${activeTab === "Completed" && "active"}`} onClick={() => handlePeerList("Completed")}>Completed
            <span>{(requestCounts?.completedRequestCount > 0 && requestCounts?.completedRequestCount < 10) ? `0${requestCounts?.completedRequestCount}` : requestCounts?.completedRequestCount}</span>
            </button>
            <button className={`tab ${activeTab === "Declined" && "active"}`} onClick={() => handlePeerList("Declined")}>Declined
            <span>{(requestCounts?.declinedRequestCount > 0 && requestCounts?.declinedRequestCount < 10) ? `0${requestCounts?.declinedRequestCount}` : requestCounts?.declinedRequestCount}</span>
            </button>
            <button className={`tab ${activeTab === "Expired" && "active"}`} onClick={() => handlePeerList("Expired")}>Expired
            <span>{(requestCounts?.expiredRequestCount > 0 && requestCounts?.expiredRequestCount < 10) ? `0${requestCounts?.expiredRequestCount}` : requestCounts?.expiredRequestCount}</span>
            </button>
          </div>

            <div className="second-div">
          <Search>
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by username or email"
              inputProps={{ 'aria-label': 'search' }}
              onChange={autoSearchText}
              value={search}
            />
          </Search>
          {/* <div className="filter-tab" onClick={() => setIsOpenFilter(true)}> 
            <img src={filterIcon} alt="" />
            Filter
            {
                isFilterOn && 
                <div className="red-dot"></div>
            }
          </div> */}
          <button className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
            i
          </button>
          </div>
          </div>
     
         {
          <div className="row">
            <div className="col-12">
            <div className="feedback-one">

               

            <div className="tablee">
            <table className="feedback-emp-list-table"> 
            {
                // activeTab === "Pending" ?
                <tr className="table-head-emp-list"> 
                    <th>Name 
                        {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                    </th> 
                    <th className="mid-cols">
                     Request Date
                        &nbsp;<FaSort onClick={() => handleSort("requestDate")} style={{cursor:"pointer"}} />
                        </th> 
                    <th className="mid-cols">Request End Date &nbsp;<FaSort onClick={() => handleSort("expiryDate")} style={{cursor:"pointer"}} /></th> 
                    {/* <th className="mid-cols">
                    Relationship */}
                        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
                        {/* </th>  */}
                    <th className="last-col head">Status</th> 
                    {/* <th></th>  */}
                </tr>
            }
                
                
                {
                    empListLoader ? 
                     <TableSkel />
                     :
                sortEmpList?.map((emp,index) => {
                        return(   
                                <tr key={emp?.requestedBy?.name}> 
                                    <td>
                                        <div className="empDetaila">
                                            <div className="empPic">
                                            {emp?.requestedBy?.profilePhoto ? 
                                                <img src={emp?.requestedBy?.profilePhoto} className="userImg" alt="User" />
                                                :
                                                <Avatar 
                                                    name={emp?.requestedBy?.name} 
                                                    size="45" 
                                                    className="userImg"
                                                    color={"#00425A"}    
                                                />
                                            }
                                            </div>
                                            <div className="userInfo">
                                                <p className="name">{emp?.requestedBy?.name}</p>
                                                {/* <p className="designation">{emp?.requestedBy?.employeeDesignation ? emp?.requestedBy?.employeeDesignation : "Designation"}</p> */}
                                                <p className="designation">{emp?.requestedBy?.employeeDesignation ? emp?.requestedBy?.employeeDesignation : ""}</p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{getFormattedDate(emp?.requestDate, dateFormat)}</div>
                                        <div>{getFormattedTime(emp?.requestDate, "hh:mm A")}</div>
                                    </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{getFormattedDate(emp?.expiryDate, dateFormat)}</div>
                                        {/* <div>{getFormattedDate(emp?.requestDate, "hh:mm a")}</div> */}
                                        <div>{getFormattedTime(emp?.expiryDate, "hh:mm A")}</div>
                                    </div>
                                    </td> 
                                    {/* <td>
                                    <div className= "btns">
                                    
                                    {emp?.relationship}
                                    </div>
                                    </td>  */}
                                    {
                                        ((activeTab === "Pending") || (activeTab === "Declined") || (activeTab === "Expired")) && (
                                    <td className="last-col">          
                                    {
                                        activeTab === "Pending" ?
                                            <div className= "btnss" onClick={(event) => handleClickAction(event, emp)}>
                                            <button className="send-btn">                                        
                                                Action <IoIosArrowDown />
                                            </button>                                    
                                            </div>   
                                            :
                                            <div className="status-contain">
                                            {
                                                emp?.status === "Completed" && 
                                                <img src={checkIcon} className="checkIcon" alt="check"></img>
                                            }
                                            {
                                                emp?.status === "Completed" ?
                                                <div className= "feedback-table-status">
                                                Feedback Given
                                                </div>
                                                :
                                                <div 
                                                className= "feedback-table-status"
                                                style={{color: (emp?.status === "Expired" || emp?.status === "Declined") ? "red" : "" }}
                                                >
                                                {emp?.status}
                                                </div>
                                            }
                                            </div>
                                    }                          
                                     
                                    <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    }
                                                }}
                                            >
                                                <div onClick={() => history.push(`/peer-feedBack/${selectedPeer.id}`)} className="popover-btns" >Give Feedback</div>
                                                <div className="popover-btns red" onClick={() => handleDecline()}>Decline</div>
                                            </Popover>
                                    </td> 
                                        )
                                    }
                                    {((activeTab === "Completed") && (
                                        <>
                                            <td>
                                            <div className="status-contain">
                                            <img src={checkIcon} className="checkIcon"></img>
                                                <div className= "feedback-table-status">
                                                Feedback Given
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <div className="action">
                                                    <IconButton variant="outlined" 
                                                        onClick={() => history.push(`/peer-feedBack/${emp.id}`)}
                                                        >
                                                    {/* Provide Feedback */}
                                                    <IoIosArrowForward /> 
                                                    </IconButton>
                                                </div>
                                            </td> 
                                        </>
                                    ))}
                 
                                </tr>
              
                        )
                    }
                )
                }
                
            </table> 
            {
                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
                    
                } 

                {
                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }

          
      
          </div>
          
          </div>
          </div>
          </div>
         }
          {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                {/* <div className="i-btn-cintent">
                    Please provide content
                </div> */}
                <InfoContent pageName='give-peer-feedback'/>
            </SideDrawer>
            }


      {
            isOpenFilter && 
            <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} >
            <div className="filter-wrapper">                
            
              <div className="filters-heading">Filters</div>

            <Stack>
                                    {
                                      !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                            return(
                                                <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                            )
                                        })
                                    }
                                </Stack>
                                <Stack sx={{
                                    display:"flex",
                                    flexDirection:"row",
                                    justifyContent:"space-between",
                                    marginTop:"1rem",
                                    width:"60%",
                                    float:"right" 
                                    }}>

                                    <Button 
                                    variant="outlined"
                                    disabled={Object.keys(filterPayload).length === 0}
                                    sx={{
                                        color:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        // marginRight:"1rem",
                                        width:"48.5%"
                                    }}
                                    onClick={() => {handleResetFilters()}}
                                    >
                                        Reset
                                    </Button>
                                    <Button 
                                    variant="contained"
                                    sx={{
                                        // color:"var(--secondary)",
                                        backgroundColor:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        "&:hover":{
                                            backgroundColor:"#035c7c",
                                            borderColor:"#035c7c",
                                        },
                                        width:"48.5%"
                                    }}
                                    disabled={empListLoader}
                                    onClick={() => handleSubmitFilter()}
                                    >
                                        Submit                                     
                                    </Button>
                                    </Stack>
            </div>
            </SideFilters>
         }      
        </div>
        
    )
};

export default GivePeerFedback;