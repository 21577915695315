import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.NOTIFICATION_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.NOTIFICATION_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        notification: payload || {},
    }),
    [types.NOTIFICATION_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }), 
      
    [types.NOTIFICATION_COUNT_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.NOTIFICATION_COUNT_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        count: payload || 0,
    }),
    [types.NOTIFICATION_COUNT_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),   
};

export default handleActions(actionHandler, {
    loader: false,
    notification: {},
    count:0,   
});
