import { useSelector } from "react-redux";
import { getFormattedDate } from "../../../../../utils/utils";
import PropTypes from 'prop-types';

const EmpDetails = ({empInfo}) => {
    
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    return(
        <>
        <div className="employee-details">
                            <div className="heading">Personal Details </div>
                            <div className="row-of-content">
                                <div className="title">Employee Name</div>
                                <div className="value">{empInfo?.name}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Employee Id</div>
                                <div className="value">{empInfo?.employeeId}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Email Id</div>
                                <div className="value">{empInfo?.emailId}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Employee type</div>
                                <div className="value">{empInfo?.employeeType?.name}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Date of joining</div>
                                <div className="value">{getFormattedDate(empInfo?.dateOfJoining, dateFormat)}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Level</div>
                                <div className="value">{empInfo?.employeeLevel?.name}</div>
                            </div>
                        </div>
                        <div className="employee-details">
                            <div className="heading">Personal Details </div>
                            <div className="row-of-content">
                                <div className="title">Manager Name</div>
                                <div className="value">{empInfo?.manager?.name}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Manager Id</div>
                                <div className="value">{empInfo?.manager?.employeeId}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Email Id</div>
                                <div className="value">{empInfo?.manager?.emailId}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Function</div>
                                {/* <div className="value">{empInfo?.manager?.employeeFunction?.name}</div> */}
                                <div className="value">{empInfo?.employeeFunction?.name}</div>
                            </div>
                            <div className="row-of-content">
                                <div className="title">Sub function</div>
                                {/* <div className="value">{empInfo?.manager?.employeeSubFunction?.name}</div> */}
                                <div className="value">{empInfo?.employeeSubFunction?.name}</div>
                            </div>
                        </div></>
    )
}

EmpDetails.propTypes = {
    empInfo: PropTypes.object
  };

export default EmpDetails;