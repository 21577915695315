import { useState } from "react";
import PropTypes from 'prop-types';

const MediTooltip = ({msg, children}) => {

    const [showMsg, setShowMsg] = useState(false);
    
    return(
        <div className="tooltip-main">
            
            {
                showMsg && 
                <div className="msg">{msg}</div>
            }            
            
            <div className="child-name" onMouseOver={() => setShowMsg(true)} onMouseOut={() => setShowMsg(false)}>
              {children} 
            </div>

        </div>
    )
}

MediTooltip.propTypes = {
    msg: PropTypes.string,
    children: PropTypes.node,
  };

export default MediTooltip;