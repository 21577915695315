const { createAction } = require('redux-actions');
const DROPDOWN_DATA_REQUEST = 'MEDIBUDDY/DROPDOWN_DATA_REQUEST';
const DROPDOWN_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/DROPDOWN_DATA_REQUEST_SUCCESS';
const DROPDOWN_DATA_REQUEST_FAILED = 'MEDIBUDDY/DROPDOWN_DATA_REQUEST_FAILED';

const DropdownDataRequest = createAction(DROPDOWN_DATA_REQUEST);
const DropdownDataRequestSuccess = createAction(DROPDOWN_DATA_REQUEST_SUCCESS);
const DropdownDataRequestFailed = createAction(DROPDOWN_DATA_REQUEST_FAILED);

export const actions = {
    DropdownDataRequest,
    DropdownDataRequestSuccess,
    DropdownDataRequestFailed,
};

export const types = {
    DROPDOWN_DATA_REQUEST,
    DROPDOWN_DATA_REQUEST_SUCCESS,
    DROPDOWN_DATA_REQUEST_FAILED,
};
