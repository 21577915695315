export const dummy = {
    "data": [
        {
            "employeeId": "380834fb-b529-47c6-8da9-8a6667c1f1d0",
            "employeeName": "kbwboofvnbs",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "5da2b782-8c8d-4a02-852c-41d8a4c44cd6",
            "feedbackstatus": "Not submitted",
            "function": "Operations",
            "subFunction": null,
            "employeeLevel": "A3.1",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Team Lead",
            "rating": "Not submitted",
            "employeeNo": "10607",
            "emailId": "qbwboclvnbs@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "edb432d9-7045-410c-bc58-8e06ed6a3df9",
            "employeeName": "fjtipsfonfmwbn",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "40979f66-5b20-4eae-a7ee-4e801dfc13cd",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "M2.1",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Manager",
            "rating": "Not submitted",
            "employeeNo": "10614",
            "emailId": "fjtipsfctfmwbn@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "d95c2875-e762-41ea-a02a-31bcb0f2bace",
            "employeeName": "wbmblsjtiobom",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "3cf5ad7a-a6cf-4e96-8008-09d29c4fbd1e",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A2.2",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Senior Associate",
            "rating": "Not submitted",
            "employeeNo": "10680",
            "emailId": "cbmblsjtiobcs@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "7f771f2b-aaa9-464b-b7a8-e1324972c07c",
            "employeeName": "xifubooebjo",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "d1819108-a33b-424c-8cbe-a8b0d9627b36",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "M2.1",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Manager",
            "rating": "Not submitted",
            "employeeNo": "10681",
            "emailId": "difubockbjo@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "30b53dbb-2ebf-4f20-9e0c-bfd62ed1f56a",
            "employeeName": "xiboesblbmbog",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "1e0c4425-0eb3-4864-bcc1-f5af8ca5e402",
            "feedbackstatus": "Submitted",
            "function": "Operations",
            "subFunction": null,
            "employeeLevel": "A2.2",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Senior Associate",
            "rating": "OKR: In/MBB: E",
            "employeeNo": "10684",
            "emailId": "diboesblbmbcm@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "48099662-be7c-472a-9cbd-3eaef92dc3a4",
            "employeeName": "gplftiom",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "1461ab24-b464-452a-9d15-b560771dda48",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A3.2",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Assistant Manager",
            "rating": "Not submitted",
            "employeeNo": "10691",
            "emailId": "mplfticsbk@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":false
        },
        {
            "employeeId": "39db497e-8162-4b02-814a-9d604a86824c",
            "employeeName": "nisvuijow",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "53fb8a1b-4552-4054-98b9-53e55cb24851",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A2.2",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Senior Associate",
            "rating": "Not submitted",
            "employeeNo": "10700",
            "emailId": "tisvuijcc@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":true
        },
        {
            "employeeId": "6f49ba81-a189-4edd-bf8c-ad35cf54e8b3",
            "employeeName": "kbwboofvnbsok",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "8efbf0ae-f24e-4463-a8c2-9cc3d2fdb9ad",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A2.2",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Senior Associate",
            "rating": "Not submitted",
            "employeeNo": "10701",
            "emailId": "qbwbolvnbscc@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":true
        },
        {
            "employeeId": "02a4a960-f6f3-4d8a-a714-4e0267bffe67",
            "employeeName": "nboubovofvnbsombvmp",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "c334a42e-6006-4d3b-b709-d60dd212fea1",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A3.1",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Team Lead",
            "rating": "Not submitted",
            "employeeNo": "10724",
            "emailId": "tboubovcsbvmp@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":true
        },
        {
            "employeeId": "02a4a960-f6f3-4d8a-a714-4e0267bffe60",
            "employeeName": "tracy jordan",
            "profilePhotoPath": null,
            "appraisalStatus": "Draft",
            "appraisalId": "c334a42e-6006-4d3b-b709-d60dd212fea1",
            "feedbackstatus": "Not submitted",
            "function": "Inside Sales",
            "subFunction": null,
            "employeeLevel": "A3.1",
            "employeeType": "On Roll",
            "empstatus": null,
            "company": "Name",
            "employeeGrade": null,
            "employeeDesignation": "Team Lead",
            "rating": "Not submitted",
            "employeeNo": "10727",
            "emailId": "tracyjordan@medibuddy-mask.in",
            "relationship": "Manager",
            "sentStatus":true
        }
    ],
    "totalPages": 116,
    "totalRows": 1154
}