export const dummyApproveData = [
    {
        "id": "ad6393b6-111b-481e-a3cc-d5e4e5d30baa",
        "requestDate": "2024-01-29T18:13:17.355+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "John Doe"
        },
        "requestedTo": {
          "name": "Emily Johnson"
        }
      },
      {
        "id": "2c1e7b16-539f-4f95-ae2d-8a2c30aef51a",
        "requestDate": "2024-01-29T18:15:21.123+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Alex Brown"
        },
        "requestedTo": {
          "name": "Sophie Davis"
        }
      },
      {
        "id": "5eafac13-8962-4a9f-858a-9087258c7d9b",
        "requestDate": "2024-01-29T18:18:45.789+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Michael Johnson"
        },
        "requestedTo": {
          "name": "Olivia Smith"
        }
      },
      {
        "id": "b0c3f12f-4d64-4e17-862f-8a5a798491aa",
        "requestDate": "2024-01-29T18:22:33.456+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Sophia Miller"
        },
        "requestedTo": {
          "name": "Matthew Taylor"
        }
      },
      {
        "id": "ad6393b6-111b-481e-a3cc-d5e4e5d30baa",
        "requestDate": "2024-01-29T18:13:17.355+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "John Doe jr"
        },
        "requestedTo": {
          "name": "Emily Johnson sr"
        }
      },
      {
        "id": "2c1e7b16-539f-4f95-ae2d-8a2c30aef51a",
        "requestDate": "2024-01-29T18:15:21.123+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Alex Brown jr"
        },
        "requestedTo": {
          "name": "Sophie Davis sr"
        }
      },
      {
        "id": "5eafac13-8962-4a9f-858a-9087258c7d9b",
        "requestDate": "2024-01-29T18:18:45.789+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Michael Johnson jr"
        },
        "requestedTo": {
          "name": "Olivia Smith sr"
        }
      },
      {
        "id": "b0c3f12f-4d64-4e17-862f-8a5a798491aa",
        "requestDate": "2024-01-29T18:22:33.456+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Sophia Miller jr"
        },
        "requestedTo": {
          "name": "Matthew Taylor sr"
        }
      },
      {
        "id": "8e7f79b3-482d-4b08-bc1b-80b8a05af7f2",
        "requestDate": "2024-01-29T18:25:40.789+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Ella Martinez"
        },
        "requestedTo": {
          "name": "Daniel Rodriguez"
        }
      },
      {
        "id": "e6c1499a-8568-4d76-b7f5-3e416f5f6013",
        "requestDate": "2024-01-29T18:28:55.123+05:30",
        "expiryDate": "2024-02-01T00:00:00.000+05:30",
        "status": "Panding",
        "requestedBy": {
          "name": "Liam Thompson"
        },
        "requestedTo": {
          "name": "Ava Wilson"
        }
      }
    ]