import { Dialog } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PropTypes from 'prop-types';

const DialogPopup = ({openDialog, setOpenDialog, redirect, path, children}) => {

  const history = useHistory();
  
  const handleClose = () => { 

    if (redirect) {
      history.push(path);
    } 
      setOpenDialog(false);       
  };

    return(
      
        <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root':{
            borderRadius:"1rem"
          }
          
        }}
      >
        <div className="common-dialog">
          {children}
        </div>
      </Dialog>
    )
}

DialogPopup.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  redirect: PropTypes.bool,
  path: PropTypes.string,
  children: PropTypes.node,
};

export default DialogPopup;