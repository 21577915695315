import React, { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MinusIcon from "../../../../assets/images/successionPlan/minusIcon.svg";
import PlusIcon from "../../../../assets/images/successionPlan/plusIcon.svg";
import VpnDotIcon from "../../../../assets/images/successionPlan/vpnDot.svg";
import HeadIcon from "../../../../assets/images/successionPlan/headIcon.svg";


const ViewSuccessionPlan = ({ }) => {
  const dispatch = useDispatch();

  const roleDataList = [
    { roleType: "Customer Obsession" },
    { roleType: "Ownership" },
    { roleType: "Invent And Simplify" },
    { roleType: "Invent And Simplify" },
    { roleType: "Hire and Develop the Best" },
    { roleType: "Ownership" },
    { roleType: "Customer Obsession" },
    { roleType: "Customer Obsession" },
    { roleType: "Customer Obsession" },
  ];

  return (
    <div>
      {/*----- Strategies/Initiatives -----*/}
      <div className='d-flex mt-20'>
        <Typography className='succession-sub-heading'>{"Strategies/Initiatives"}</Typography>
        <img
          src={HeadIcon}
          alt="HeadIcon"
          height={15}
          width={15}
          className='headIcon'
        />
      </div>
      <div className="goals-form-page-container">
        <div className="field-row">
          <div className="text-field">
            <Typography className="field-label">{"What are the top business strategies/initiatives in the next 3 years?"}</Typography>
            <Typography className="field-description-label">{"I'll be sharing over 200 business names ideas and examples to help you come up with business names that are catchy, personal, cryptic, thought-provoking, bold"}</Typography>
          </div>
        </div>
      </div>

      {/*----- Critical roles -----*/}
      <div className='d-flex mt-20'>
        <Typography className='succession-sub-heading'>{"Critical roles"}</Typography>
        <img
          src={HeadIcon}
          alt="HeadIcon"
          height={15}
          width={15}
          className='headIcon'
        />
      </div>
      <Grid className='critical-roles-container'>
        <Grid className='mt-20'>
          <img
            src={MinusIcon}
            alt="MinusIcon"
            height={20}
            width={20}
            className='minusPlusIcon'
          />
          <Box className='vertical-border' />
        </Grid>

        {/*----- CEO| -----*/}
        <Grid>
          <Grid className="goals-form-page-container">
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className='ceo-view-text'>{"CEO"}</Typography>
              </Grid>
            </Grid>

            <Grid className='horizontal-border mtb-20 mt-8' />

            {/*----- Role Competencies -----*/}
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Role Competencies"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-container'>
                  {roleDataList.map((item, index) => (
                    <div className='role-border-div'>
                      <div className='d-flex ai-center'>
                        <Typography className='role-type'>{item.roleType}</Typography>
                      </div>
                    </div>
                  ))}
                </div>
                <Grid className='horizontal-border mtb-5' />
              </Grid>
            </Grid>

            {/*----- Incumbent -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Incumbent"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Savannah Nguyen"}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- Ready Now -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready Now"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='d-flex'>
                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Floyd Miles"}</Typography>
                    </div>
                  </div>

                  <div className='role-border-div ml-10'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Bessie Cooper"}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- Ready in 12 months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready in 12 months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Dianne Russell"}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- Ready in 24 Months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready in 24 Months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Courtney Henry"}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- 24+ Months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"24+ Months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='d-flex'>
                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Brooklyn Simmons"}</Typography>
                    </div>
                  </div>

                  <div className='role-border-div ml-10'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Ronald Richards"}</Typography>
                    </div>
                  </div>
                </div>

                <Grid className='horizontal-border mtb-5' />
              </Grid>
            </Grid>

            {/*----- Mitigation Plan -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Mitigation Plan"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='mt-5'>
                  <Typography className='role-type'>{"Will continue to keep his focus on his primarily development areas that he has been working over the last couple of years. Additionally, I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus). Development area carried from previous LPA -- Ability to Earn Trust across organizational boundaries and think outside the standard Amazon model and ability to understand the emotional."}</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/*----- CFO Add Position -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              <Box className='vertical-border' />
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className='ceo-view-text'>{"CFO"}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/*----- Financial controller -----*/}
              <Grid className='critical-roles-container mt-20'>
                <Grid className='mt-20'>
                  <img
                    src={PlusIcon}
                    alt="PlusIcon"
                    height={20}
                    width={20}
                    className='minusPlusIcon'
                  />
                  <Box className='vertical-border' />
                </Grid>
                <Grid>
                  <Grid className="goals-form-page-container">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography className='ceo-view-text'>{"Financial controller"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*----- VP Fin. India -----*/}
                  <Grid className='critical-roles-container mt-20'>
                    <Grid className='mt-20'>
                      <img
                        src={VpnDotIcon}
                        alt="VpnDotIcon"
                        height={10}
                        width={10}
                        className='dotIcon'
                      />
                    </Grid>
                    <Grid className="goals-form-page-container">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography className='ceo-view-text'>{"VP Fin. India"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*----- VP Fin. UK -----*/}
                  <Grid className='critical-roles-container mt-20'>
                    <Grid className='mt-20'>
                      <img
                        src={VpnDotIcon}
                        alt="VpnDotIcon"
                        height={10}
                        width={10}
                        className='dotIcon'
                      />
                    </Grid>
                    <Grid className="goals-form-page-container">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography className='ceo-view-text'>{"VP Fin. UK"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*----- SVP shared services -----*/}
              <Grid className='critical-roles-container mt-20'>
                <Grid className='mt-20'>
                  <img
                    src={PlusIcon}
                    alt="PlusIcon"
                    height={20}
                    width={20}
                    className='minusPlusIcon'
                  />
                  {/* <Box className='vertical-border' /> */}
                </Grid>
                <Grid>
                  <Grid className="goals-form-page-container">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography className='ceo-view-text'>{"SVP shared services"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*----- CFO -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              {/* <Box className='vertical-border' /> */}
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className='ceo-view-text'>{"CFO"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/*----- CRO -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              {/* <Box className='vertical-border' /> */}
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className='ceo-view-text'>{"CRO"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ViewSuccessionPlan);
