import { useEffect, useState } from "react";
// import BarGraph from "../../../common/barGraph";
// import { viewReportData } from "../dummyData";
import APIList from "../../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import SideDrawer from "../../../../common/sideDrawer";
// import EmpInfo from "./empInfo";
import { IoIosSave } from "react-icons/io";
import { Button, CircularProgress, FormHelperText, TextField } from "@mui/material";
import DialogPopup from "../../../../common/Dialog";
import checkIcon from "../../../../../assets/images/popup-check.png";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import ChangedReport from "../changedReport";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import downloadIcon from "../../../../../assets/images/dashboard/download.png";
import { BASE_URL } from "../../../../../api/apiServices";
import axios from "axios";

const ChangedReportPage = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [sessionInfo, setSessionInfo] = useState(null);
    const [viewReport, setViewReport] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDraftPopup, setIsDraftPopup] = useState(false);   
    const [submitLoader, setSubmitLoader] = useState(false);
    
    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);
    const [dataForReport, setDataForReport] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);

    useEffect(() => {
        setDataLoader(true);

        APIList.getSessionReportData1({
            companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            // employeeEligibilityDTO: {},
            // keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            // setViewReport(res?.data?.postCalibratedRatings);
            // /////use for graph
            // setPostCalibratedRatings(res?.data?.postCalibratedRatings)
            // } else {
            //     setViewReport(res?.data?.preCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            // setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
                        
            setDataForReport(res?.data?.postCalibratedRatings);

            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            // setAllPreEmployees(all);

            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

            // let Tempmbb = []; 
            // let Tempperform = [];

            // res?.data?.preCalibratedRatings?.forEach((item) => {
            //     if(!Tempmbb.includes(item?.mbbRating)){
            //     Tempmbb.push(item?.mbbRating);
            //     }
            //     if(!Tempperform.includes(item?.preformanceRating)){
            //         Tempperform.push(item?.preformanceRating);
            //     }
            // })

            setMbbRatings(Tempmbb);
            setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })

    //     setViewReport(report);
 
    }, [])

useEffect(() => {
    
    setViewReport(location?.state?.viewReport);

    APIList.getCountOnlySessionById({id: location?.state?.sessionid})
    .then((res) => {
       setSessionInfo(res?.data);
    })
    .catch((err) => {
     toast.error(
         <div style={{display:"flex", flexDirection:"row"}}>
         <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
         &nbsp;&nbsp;{err?.title}
         </div>
     );
    })

  }, [viewReport])
console.log("viewReport",viewReport)

  const handleSubmit = () => {
    // if(location?.state?.table){
        setSubmitLoader(true);
        APIList.saveSessionReportDataForTable({
        companyId: employeeDetails?.company?.id,
        payload: [],
        sessionId : location?.state?.sessionid,
        status:"Approved "
    })
    .then((res) => {
        setOpenDialog(true);
        setSubmitLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setSubmitLoader(false);
    })
    // } else {
    //     setSubmitLoader(true);
    //     APIList.saveSessionReport({
    //         companyId: employeeDetails?.company?.id,
    //         payload: viewReport,
    //         sessionId : location?.state?.sessionid
    //     })
    //     .then((res) => {
    //         setOpenDialog(true);
    //         setSubmitLoader(false);
    //     })
    //     .catch((err) => {
    //         toast.error(
    //             <div style={{display:"flex", flexDirection:"row"}}>
    //             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //             &nbsp;&nbsp;{err?.title}
    //             </div>
    //         );
    //         setSubmitLoader(false);
    //     })
    // }
    
  }

  const getYaxis = () => {
    let arr = [];
    mbbRatings?.forEach((mbb) => {
        let inArr = [];
        inArr.push(mbb);

        performanceRatings?.forEach((performance) => {
            dataForReport?.forEach((item) => {
                
                if (item?.mbbRating === mbb && item?.preformanceRating === performance && item?.employees?.length > 0) {
                    let empArr = [];
                    item?.mbbRating === mbb &&
                    item?.preformanceRating === performance &&
                    item?.employees.forEach((emp) => {
                        if (emp?.name) {
                            empArr.push(emp?.name)
                        }
                    }
                    )

                inArr.push(empArr.join(`, \n`));
                } else {
                    if (item?.mbbRating === mbb && item?.preformanceRating === performance) {
                        inArr.push("-");

                    }
                }
                
            }
            )
        }
        )

        arr.push(inArr);
    })
    return arr;
}

const data = [
    [" ", ...performanceRatings?.map((performance) => performance)],
    ...getYaxis()
  ];

//   console.log("data",data)

const handleDownloadReport = async(e) => {
    
    // const worksheet = XLSX.utils.aoa_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // const excelBuffer = XLSX.write(workbook, {
    //   bookType: 'xlsx',
    //   type: 'array',
    // });
    // const dataBlob = new Blob([excelBuffer], {
    //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // });
    // saveAs(dataBlob, `${sessionInfo?.name}.xlsx`);

    setDownloadLoader(true);
    // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
    axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/session/download/report/${location?.state?.sessionid}`,
    {
},
    {headers: {
        'Authorization': `Bearer ${userToken?.id_token}`,
        'Content-Type': 'application/json',
      },  'responseType': 'arraybuffer'      
    }).then((response) => 
    {
         const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
      // Trigger the download
      saveAs(blob, `${sessionInfo?.name}.xlsx`);
      setDownloadLoader(false);
        
    })
    .catch((err) => {
             toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDownloadLoader(false);
    });    
 
  }

    return(

        <div className="dashboard-main">
            <div className="rating-calibration-report-view">
                <div className="view-report-row">        
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br/> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br/> Count</div>
                    </div>           
                </div>

                <div className="tabs-outer">
                    <div className="final-report-heading">Session changes</div>
                    <div className="down-report">
                        {
                            downloadLoader ? 
                                <>
                                Downloading... <CircularProgress size={29} />
                                </>
                            :
                                <button onClick={handleDownloadReport}>Download report <img src={downloadIcon} alt="" /></button>
                        }
                    </div>
                </div>
                    
                <ChangedReport sessionId={location?.state?.sessionid} />

                <div className="d-flex justify-content-end gap-3 pt-2 pb-4 main-btn">
                  
                    <button className="valuesBtn next"
                        onClick={() => handleSubmit()}
                    >
                        {
                            submitLoader ?
                                <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                                :
                                "Submit"
                        }
                    </button>
                </div>

            </div>


        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    {
                        isDraftPopup ? 
                        "Your Session Report has been securely saved as a draft. Continue editing whenever you're ready!"
                        :
                        "Session Report successfully submitted. Thank you for your dedication to the growth and development of team."
                    }                
                </div>
                <button className="button"
                    onClick={() => { history.push("/hrb")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        </div>
    )
}

export default ChangedReportPage;