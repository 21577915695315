import React, { useState } from 'react';
import profileBanner from "../../../assets/images/profileBanner.png";
import managerIcon from "../../../assets/images/manager.png";
import locationIcon from "../../../assets/images/location.png";
import pencile from "../../../assets/images/pencile.png";
import alertIcon from "../../../assets/images/alert.png";
import goalIcon from "../../../assets/images/goal.png";
import userProfile from "../../../assets/images/userProfile.png";
import sidebarSlider from "../../../assets/images/slider.png";
import PersonalInformation from './components/PersonalInformation';
import WorkAndEducation from './components/WorkAndEducation';
import Competencies from './components/Competencies';
import Certifications from './components/Certifications';
import Accomplishments from './components/Accomplishments';
import { Typography } from '@mui/material';

const EEPPage = () => {
  const [personalInformationView, setPersonalInformationView] = useState(true);
  const [workAndEducationView, setWorkAndEducationView] = useState(false);
  const [competenciesView, setCompetenciesView] = useState(false);
  const [certificationsView, setCertificationsView] = useState(false);
  const [accomplishmentsView, setAccomplishmentsView] = useState(false);

  const handlePersonalInformation = () => {
    setPersonalInformationView(true);
    setWorkAndEducationView(false);
    setCompetenciesView(false);
    setCertificationsView(false);
    setAccomplishmentsView(false);
  };

  const handleWorkAndEducation = () => {
    setPersonalInformationView(false);
    setWorkAndEducationView(true);
    setCompetenciesView(false);
    setCertificationsView(false);
    setAccomplishmentsView(false);
  };

  const handleCompetencies = () => {
    setPersonalInformationView(false);
    setWorkAndEducationView(false);
    setCompetenciesView(true);
    setCertificationsView(false);
    setAccomplishmentsView(false);
  };

  const handleCertifications = () => {
    setPersonalInformationView(false);
    setWorkAndEducationView(false);
    setCompetenciesView(false);
    setCertificationsView(true);
    setAccomplishmentsView(false);
  };

  const handleAccomplishments = () => {
    setPersonalInformationView(false);
    setWorkAndEducationView(false);
    setCompetenciesView(false);
    setCertificationsView(false);
    setAccomplishmentsView(true);
  };

  return (
    <div className="dashboard-main">
      {/*----- Top Banner Section -----*/}
      <section class="col-12">
        <div class="pb-3 topbannerGrid">
          <div class="card-header border-0 pt-3 pb-5 p-relative">
            <img className='img-resposive'
              src={profileBanner}
              alt="MediBuddy"
            />

            <img
              src={pencile}
              alt="Pencile"
              className='pencileIcon'
            />
          </div>
          <div class="cardBody">
            <div class="row">
              <div class="col-12 profile-d-flex">
                <div class="profile-div">
                  <img
                    src={userProfile}
                    alt="MediBuddy"
                    width="140" height="140"
                    className="user-profile"
                  />
                  <div class="bottom-right">
                    <img
                      src={pencile}
                      alt="MediBuddy"
                      width="24" height="24"
                    />
                  </div>
                </div>

                <div className='imageRightText'>
                  <Typography class="userTitle">
                    Harry mustain <span>(Emp Id: AB703)</span>
                  </Typography>
                  <div class="d-flex flex-row justify-content-left mt-1">
                    <span class="px-3 postionTitle">
                      <img
                        src={managerIcon}
                        alt="MediBuddy"
                      /> Manager
                    </span>
                    <span class="px-3 postionTitle">
                      <img
                        src={locationIcon}
                        alt="MediBuddy"
                      /> Las Vegas, USA
                    </span>
                    <span class="px-3 postionTitle">
                      <a class="btn contactInfoBtn" href="#" role="button">Contact Info</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----- About Section -----*/}
      <div className="outer-layout">
        <div className="first-column">
          <div className="first-row">
            <div className="header-section">
              <Typography className="aboutheading">About</Typography>
              <img src={pencile} alt="MediBuddy" />
            </div>
            <div className="row">
              <Typography className="description desc-color">
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the on meaningful without relying on meaningful without without relying on meaningful content meaningful ipsum content. Lorem ipsum ipsum may be used as a placeholder before final copy is available...<span style={{color: "#1874ff", cursor: "pointer"}}>View More</span>
              </Typography>
            </div>
          </div>

          {/*----- Button Section -----*/}
          <div class="btnList">
            <a onClick={handlePersonalInformation} href="#" class={personalInformationView === true ? "btn profilebtnActive px-3" : "btn profilebtn px-3"} role="button">Personal Information</a>
            <a onClick={handleWorkAndEducation} href="#" class={workAndEducationView === true ? "btn profilebtnActive px-3" : "btn profilebtn px-3"} role="button">Work & Education</a>
            <a onClick={handleCompetencies} href="#" class={competenciesView === true ? "btn profilebtnActive px-3" : "btn profilebtn px-3"} role="button">Competencies</a>
            <a onClick={handleCertifications} href="#" class={certificationsView === true ? "btn profilebtnActive px-3" : "btn profilebtn px-3"} role="button">Certifications</a>
            <a onClick={handleAccomplishments} href="#" class={accomplishmentsView === true ? "btn profilebtnActive px-3" : "btn profilebtn px-3"} role="button">Accomplishments</a>
          </div>

          {personalInformationView === true &&
            <PersonalInformation />
          }

          {workAndEducationView === true &&
            <WorkAndEducation />
          }

          {competenciesView === true &&
            <Competencies />
          }

          {certificationsView === true &&
            <Certifications />
          }

          {accomplishmentsView === true &&
            <Accomplishments />
          }
        </div>
        <div className="second-column1">
          {/*----- Complete your profile -----*/}
          <div className="completeProfileCard">
            <Typography className="sidebarheading">Complete your profile</Typography>
            <div className="first-row">
              <div class="progressBarGrid">
                <div class="progress1" data-percentage="74">
                  <span class="progress-left">
                    <span class="progress-bar"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar"></span>
                  </span>
                  <div class="progress-value">
                    <Typography className="percentage-txt">{"80%"}</Typography>
                  </div>
                </div>
                <Typography className="your-profile-txt">Your profile is <br />incomplete.</Typography>
                <img className='alertIcon' src={alertIcon} alt="MediBuddy" />
              </div>
              <Typography className="your-profile-description">Pleae add below details to make your profile 100% complete</Typography>
              <div class="btnList">
                <a href="#" class="btn profilebtn px-3" role="button">Add Certifications</a>
                <a href="#" class="btn profilebtn px-3" role="button">Add Accomplishments</a>
              </div>
            </div>
          </div>

          {/*----- Harry -----*/}
          <div className="harry-Card">
            <Typography className="hey-txt">{"Hey!"}</Typography>
            <Typography className="harry-txt">{"Harry"}</Typography>
            <Typography className="harry-description">{"We have added few courses for you to guide your career growth and development. Take a look..."}</Typography>
            <a href="#" className="btn explore-btn" role="button">{"Explore"}</a>
          </div>

          {/*----- My Aspiration -----*/}
          <div className="completeProfileCard">
            <div className="sidebarheading mb-0 pb-0">My Aspiration
              <img className='goalIcon' src={goalIcon} alt="MediBuddy" />
            </div>
            <div className="bottom-border">
              <Typography className="sidevarAspiration">Aspired Role</Typography>
              <Typography className="sidevarAspirationSub">Senior Manager</Typography>
            </div>
            <div className="bottom-border">
              <Typography className="sidevarAspiration">Aspired Role Specialization</Typography>
              <Typography className="sidevarAspirationSub">Service Management</Typography>
            </div>
            <div className="mt-3 mb-3">
              <Typography className="sidevarAspiration">Aspired Role Timeline</Typography>
              <Typography className="sidevarAspirationSub">NA</Typography>
            </div>
            <a href="#" class="btn profilebtn px-3" role="button">View IDP</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EEPPage;