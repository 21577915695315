const { createAction } = require('redux-actions');
const NOTIFICATION_DATA_REQUEST = 'MEDIBUDDY/NOTIFICATION_DATA_REQUEST';
const NOTIFICATION_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/NOTIFICATION_DATA_REQUEST_SUCCESS';
const NOTIFICATION_DATA_REQUEST_FAILED = 'MEDIBUDDY/NOTIFICATION_DATA_REQUEST_FAILED';

const NOTIFICATION_COUNT_DATA_REQUEST = 'MEDIBUDDY/NOTIFICATION_COUNT_DATA_REQUEST';
const NOTIFICATION_COUNT_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/NOTIFICATION_COUNT_DATA_REQUEST_SUCCESS';
const NOTIFICATION_COUNT_DATA_REQUEST_FAILED = 'MEDIBUDDY/NOTIFICATION_COUNT_DATA_REQUEST_FAILED';

const NotificationDataRequest = createAction(NOTIFICATION_DATA_REQUEST);
const NotificationDataRequestSuccess = createAction(NOTIFICATION_DATA_REQUEST_SUCCESS);
const NotificationDataRequestFailed = createAction(NOTIFICATION_DATA_REQUEST_FAILED);

const NotificationCountDataRequest = createAction(NOTIFICATION_COUNT_DATA_REQUEST);
const NotificationCountDataRequestSuccess = createAction(NOTIFICATION_COUNT_DATA_REQUEST_SUCCESS);
const NotificationCountDataRequestFailed = createAction(NOTIFICATION_COUNT_DATA_REQUEST_FAILED);

export const actions = {
    NotificationDataRequest,
    NotificationDataRequestSuccess,
    NotificationDataRequestFailed,

    NotificationCountDataRequest,
    NotificationCountDataRequestSuccess,
    NotificationCountDataRequestFailed

};

export const types = {
    NOTIFICATION_DATA_REQUEST,
    NOTIFICATION_DATA_REQUEST_SUCCESS,
    NOTIFICATION_DATA_REQUEST_FAILED,

    NOTIFICATION_COUNT_DATA_REQUEST,
    NOTIFICATION_COUNT_DATA_REQUEST_SUCCESS,
    NOTIFICATION_COUNT_DATA_REQUEST_FAILED
};
