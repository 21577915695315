import { useEffect, useRef, useState } from "react";
// import BarGraph from "../../../common/barGraph";
// import { viewReportData } from "../dummyData";
import APIList from "../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import SideDrawer from "../../../common/sideDrawer";
import EmpInfo from "./empInfo";
import { IoIosSave, IoMdClose, IoMdSearch } from "react-icons/io";
import { Button, CircularProgress, FormHelperText, InputBase, Stack, TextField, alpha, styled } from "@mui/material";
import DialogPopup from "../../../common/Dialog";
import checkIcon from "../../../../assets/images/popup-check.png";
import { isEmptyNullUndefined } from "../../../utils/utils";
import ChangedReport from "./changedReport";
import filterIcon from "../../../../assets/images/filter.svg";
import FilterSelect2 from "../../../common/filterSelect2";
import { BsChevronDown, BsGrid } from "react-icons/bs";
import downloadIcon from "../../../../assets/images/dashboard/download.png";
import { BASE_URL } from "../../../../api/apiServices";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import BarGraph from "../../../common/BarGraph";
import MultiBarGraph from "../../../common/MultiBarGraph";
import MultiBarGraphRow from "../../../common/MultiBarGraphRow";
import PaginationMedi from "../../../common/pagination";
import { CiBoxList } from "react-icons/ci";
import TableSkel from "../../../common/tableSkeleton";
import PaginationMediBulk from "../../../common/paginationBulk";
import LineGraph from "../../../common/lineGraph";
import LineGraphForAll from "../../../common/lineGraphForAll";

// import { viewReport as report } from "../dummyData";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 
 
const ViewReport = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [arr1, setArr1] = useState(["Bhupendar Jogi", "Laila Sahay"]);
    const [arr2, setArr2] = useState(["Phil Dunphy", "Bhupendar Jogi 1"]);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [empToView, setempToView] = useState(null);
    
    const [sessionInfo, setSessionInfo] = useState(null);
    const [totalEmployees, setTotalEmployees] = useState(null);
    const [draged, setDraged] = useState(null);
    const [movedFrom, setMovedFrom] = useState(null);
    const [movedTo, setMovedTo] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);

    const [viewReport, setViewReport] = useState([]);
    const [preReport, setPreReport] = useState([]);

    const [autoSaveLoaer, setAutoSaveLoaer] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDraftPopup, setIsDraftPopup] = useState(false);   

    const [activeTab, setActiveTab] = useState("");

    const [isOpenReasonDialog, setIsOpenReasonDialog] = useState(false);
    const [targetDiv, setTargetDiv] = useState({mbb:"", perform:""});
    const [reasonErr, setReasonErr] = useState(false);

    const [allPostEmployees, setAllPostEmployees] = useState([]);
    const [allPostFilteredEmployees, setAllPostFilteredEmployees] = useState([]);
    const [allPreEmployees, setAllPreEmployees] = useState([]);
    const [search, setSearch] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);
    const [filteredReport, setFilteredReport] = useState([]);
    const [filteredPostReport, setFilteredPostReport] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [postCalibratedRatings, setPostCalibratedRatings] = useState([]);
    const [preCalibratedRatings, setPreCalibratedRatings] = useState([]);
    const [dataForReport, setDataForReport] = useState([]);
        
    const [topTab, setTopTab] = useState("Report");

    const [viewType, setViewType] = useState("DND");
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    
    const [preCalibratedRatingsForBulkGraph, setPreCalibratedRatingsForBulkGraph] = useState([]);
    const [graphDataForBulkView, setgraphDataForBulkView] = useState([]);
// console.log(allPostEmployees);

useEffect(() => {
    let all = [];
    viewReport?.forEach((item) => {
        item?.employees?.forEach((itemq) => {
            itemq["mbbRating"] = item?.mbbRating;
            itemq["preformanceRating"] = item?.preformanceRating;
            all.push(itemq);
        })
    })
    setAllPostEmployees(all);
}, [viewReport])
useEffect(() => {
    let all = [];
    filteredPostReport?.forEach((item) => {
        item?.employees?.forEach((itemq) => {
            itemq["mbbRating"] = item?.mbbRating;
            itemq["preformanceRating"] = item?.preformanceRating;
            all.push(itemq);
        })
    })
    setAllPostFilteredEmployees(all);
}, [filteredPostReport])

useEffect(() => {
    setDataLoader(true);
    const getSearch = setTimeout(() => {
        if(viewType === "DND"){
        APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filterPayload,
            keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            // setViewReport(res?.data?.postCalibratedRatings);
            // setFilteredReport(res?.data?.postCalibratedRatings);
            // } else {
                // setViewReport(res?.data?.preCalibratedRatings);
                setFilteredReport(res?.data?.preCalibratedRatings);
                setFilteredPostReport(res?.data?.postCalibratedRatings);
                setDataForReport(res?.data?.postCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            // setAllPreEmployees(all);

            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

            // setMbbRatings(Tempmbb);
            // setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    } else {
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getTableReportData(0, filterPayload);
        }
        
         // this is for list view graph only
         APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filterPayload,
            keyword: search
        })
        .then((res) => {
                setgraphDataForBulkView(res?.data?.postCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }

    }, 1000);    

    return () => clearTimeout(getSearch);

}, [search]);

useEffect(() => {
 setgraphDataForBulkView(viewReport);
}, [viewReport])


const handleSubmitFilter = () => {
    if(viewType === "DND"){
    setDataLoader(true);  
    APIList.getSessionReportData({
        // companyId: employeeDetails?.company?.id,
        sessionId: location?.state?.sessionid,
        employeeEligibilityDTO: filterPayload,
        keyword: search
    })
    .then((res) => {
        // console.log("res",res)
        // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
        // setViewReport(res?.data?.postCalibratedRatings);
        // setFilteredReport(res?.data?.postCalibratedRatings);
        // } else {
            // setViewReport(res?.data?.preCalibratedRatings);
            setFilteredReport(res?.data?.preCalibratedRatings);
            setFilteredPostReport(res?.data?.postCalibratedRatings);
            setPostCalibratedRatings(res?.data?.postCalibratedRatings);
            setPreCalibratedRatings(res?.data?.preCalibratedRatings);
        // }
        // setPreReport(res?.data?.preCalibratedRatings);
        setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
        let all = [];
        res?.data?.preCalibratedRatings?.forEach((item) => {
            item?.employees?.forEach((itemq) => {
                itemq["mbbRating"] = item?.mbbRating;
                itemq["preformanceRating"] = item?.preformanceRating;
                all.push(itemq);
            })
        })
        // setAllPreEmployees(all);

        
      // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
      const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
        return a?.mbbRanking - b?.mbbRanking;
    });

      const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
        return a?.preformanceRanking - b?.preformanceRanking;
    });

         let Tempmbb = []; 
         let Tempperform = [];

         mbbRankingSortedData?.forEach((item) => {
             if(!Tempmbb.includes(item?.mbbRating)){
             Tempmbb.push(item?.mbbRating);
             }
         })

         preformanceRankingSortedData?.forEach((item) => {
             if(!Tempperform.includes(item?.preformanceRating)){
                 Tempperform.push(item?.preformanceRating);
             }
         })

        // setMbbRatings(Tempmbb);
        // setPerformanceRatings(Tempperform);
        setDataLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDataLoader(false);
    })     
} else {
    if(currentPage !== 0) {
        setCurrentPage(0);
    } else {
        getTableReportData(0, filterPayload);
    }
    
         // this is for list view graph only
         APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filterPayload,
            keyword: search
        })
        .then((res) => {
                setgraphDataForBulkView(res?.data?.postCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
}
} 

useEffect(() => {
    getTableReportData(currentPage, filterPayload);
 }, [currentPage])

    useEffect(() => {
       APIList.getCountOnlySessionById({id: location?.state?.sessionid})
       .then((res) => {
          setSessionInfo(res?.data);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
    })
    }, [])

    useEffect(() => {

        setSearch("");
        setFilterPayload({});

        if(viewType === "DND"){

            setDataLoader(true);

            APIList.getSessionReportData1({
                companyId: employeeDetails?.company?.id,
                sessionId: location?.state?.sessionid,
                // employeeEligibilityDTO: {},
                // keyword: search
            })
            .then((res) => {
                // console.log("res",res)
                if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
                setViewReport(res?.data?.postCalibratedRatings);
                /////use for graph
                setPostCalibratedRatings(res?.data?.postCalibratedRatings);
                setPreCalibratedRatings(res?.data?.preCalibratedRatings);
                } else {
                    setViewReport(res?.data?.preCalibratedRatings);
                }
                setPreReport(res?.data?.preCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
                let all = [];
                res?.data?.preCalibratedRatings?.forEach((item) => {
                    item?.employees?.forEach((itemq) => {
                        itemq["mbbRating"] = item?.mbbRating;
                        itemq["preformanceRating"] = item?.preformanceRating;
                        all.push(itemq);
                    })
                })
                setAllPreEmployees(all);
    
                
              // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
              const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
                return a?.mbbRanking - b?.mbbRanking;
            });
    
              const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
                return a?.preformanceRanking - b?.preformanceRanking;
            });
        
                 let Tempmbb = []; 
                 let Tempperform = [];
        
                 mbbRankingSortedData?.forEach((item) => {
                     if(!Tempmbb.includes(item?.mbbRating)){
                     Tempmbb.push(item?.mbbRating);
                     }
                 })
    
                 preformanceRankingSortedData?.forEach((item) => {
                     if(!Tempperform.includes(item?.preformanceRating)){
                         Tempperform.push(item?.preformanceRating);
                     }
                 })
    
                // let Tempmbb = []; 
                // let Tempperform = [];
    
                // res?.data?.preCalibratedRatings?.forEach((item) => {
                //     if(!Tempmbb.includes(item?.mbbRating)){
                //     Tempmbb.push(item?.mbbRating);
                //     }
                //     if(!Tempperform.includes(item?.preformanceRating)){
                //         Tempperform.push(item?.preformanceRating);
                //     }
                // })
    
                setMbbRatings(Tempmbb);
                setPerformanceRatings(Tempperform);
                setDataLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setDataLoader(false);
            })
    
        //     setViewReport(report);
        } else {
            setFilterPayload({});
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getTableReportData(0, {});
        }
        }
    
    }, [viewType])

    const handleResetFilters = () => {
        if(viewType === "DND"){
        setFilterPayload({});
        setDataLoader(true);  
        APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: {},
            keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            // setViewReport(res?.data?.postCalibratedRatings);
            setFilteredReport(res?.data?.postCalibratedRatings);
            setFilteredPostReport(res?.data?.postCalibratedRatings);
            // } else {
                // setViewReport(res?.data?.preCalibratedRatings);
            //     setFilteredReport(res?.data?.preCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            // setAllPreEmployees(all);
    
            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });
    
          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })
    
             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })
    
            // setMbbRatings(Tempmbb);
            // setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })   
    } else {
        setFilterPayload({});
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getTableReportData(0, {});
        }
        
         // this is for list view graph only
         APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: {},
            keyword: search
        })
        .then((res) => {
                setgraphDataForBulkView(res?.data?.postCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }  
    }


    useEffect(() => {
        setDataLoader(true);

        APIList.getSessionReportData1({
            companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            // employeeEligibilityDTO: {},
            // keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            setViewReport(res?.data?.postCalibratedRatings);
            /////use for graph
            setPostCalibratedRatings(res?.data?.postCalibratedRatings);
            setPreCalibratedRatings(res?.data?.preCalibratedRatings);
            } else {
                setViewReport(res?.data?.preCalibratedRatings);
            }
            setPreReport(res?.data?.preCalibratedRatings);
            setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            setAllPreEmployees(all);

            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

            // let Tempmbb = []; 
            // let Tempperform = [];

            // res?.data?.preCalibratedRatings?.forEach((item) => {
            //     if(!Tempmbb.includes(item?.mbbRating)){
            //     Tempmbb.push(item?.mbbRating);
            //     }
            //     if(!Tempperform.includes(item?.preformanceRating)){
            //         Tempperform.push(item?.preformanceRating);
            //     }
            // })

            setMbbRatings(Tempmbb);
            setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })

    //     setViewReport(report);
 
    }, [])

    const getDiviserPre = (mbb, performance) => {
        if(activeTab === "Column") {
            return Number(allPreEmployees?.filter(x => x?.preformanceRating === performance)?.length);
        } else if(activeTab === "Row") {
            return Number(allPreEmployees?.filter(x => x?.mbbRating === mbb)?.length);
        } else {
            return totalEmployees;
        } 
    }

    const getDiviserPost = (mbb, performance) => {
        if(activeTab === "Column") {
            return Number(allPostEmployees?.filter(x => x?.preformanceRating === performance)?.length);
        } else if(activeTab === "Row") {
            return Number(allPostEmployees?.filter(x => x?.mbbRating === mbb)?.length);
        } else {
            return totalEmployees;
        } 
    }

    const autoSearchText = (e) => {
        setSearch(e.target.value);
      };

    const isInFiltered = (item) => {
        let all = [];
        filteredReport?.forEach((item) => {
            item?.employees?.forEach((itemq) => {
                all.push(itemq?.id);
            })
        })
// console.log("all", all, item)
        if(all?.includes(item?.id)){
            return true;
        } else {
            return false;
        }
    }  

    const getFilteredCount = (emps) => {
        let count = 0;
        if(emps?.length === 0){
          return 0;
        } else {
          emps?.forEach((item) => {
            if(isInFiltered(item)){
                count = count + 1;
            }
          })
        }

        return count;
    }

    const getYaxis = () => {
        let arr = [];
        mbbRatings?.forEach((mbb) => {
            let inArr = [];
            inArr.push(mbb);

            performanceRatings?.forEach((performance) => {
                dataForReport?.forEach((item) => {
                    
                    if (item?.mbbRating === mbb && item?.preformanceRating === performance && item?.employees?.length > 0) {
                        let empArr = [];
                        item?.mbbRating === mbb &&
                        item?.preformanceRating === performance &&
                        item?.employees.forEach((emp) => {
                            if (emp?.name) {
                                empArr.push(emp?.name)
                            }
                        }
                        )

                    inArr.push(empArr.join(`, \n`));
                    } else {
                        if (item?.mbbRating === mbb && item?.preformanceRating === performance) {
                            inArr.push("-");

                        }
                    }
                    
                }
                )
            }
            )

            arr.push(inArr);
        })
        return arr;
    }
    
    const data = [
        [" ", ...performanceRatings?.map((performance) => performance)],
        ...getYaxis()
      ];

    //   console.log("data",data)

    const handleDownloadReport = async(e) => {

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const dataBlob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(dataBlob, `${sessionInfo?.name}.xlsx`);

    //     setDownloadLoader(true);
    //     // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
    //     axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/session/download/report/${location?.state?.sessionid}`,
    //     {
    // },
    //     {headers: {
    //         'Authorization': `Bearer ${userToken?.id_token}`,
    //         'Content-Type': 'application/json',
    //       },  'responseType': 'arraybuffer'      
    //     }).then((response) => 
    //     {
    //          const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
           
    //       // Trigger the download
    //       saveAs(blob, `${sessionInfo?.name}.xlsx`);
    //       setDownloadLoader(false);
            
    //     })
    //     .catch((err) => {
    //              toast.error(
    //             <div style={{display:"flex", flexDirection:"row"}}>
    //             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //             &nbsp;&nbsp;{err?.title}
    //             </div>
    //         );
    //         setDownloadLoader(false);
    //     });    
     
      }
      const handleDownloadReport2 = async(e) => {
    
        // const worksheet = XLSX.utils.aoa_to_sheet(data);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // const excelBuffer = XLSX.write(workbook, {
        //   bookType: 'xlsx',
        //   type: 'array',
        // });
        // const dataBlob = new Blob([excelBuffer], {
        //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // });
        // saveAs(dataBlob, `${sessionInfo?.name}.xlsx`);
    
        setDownloadLoader(true);
        // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
        axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/session/download/report/${location?.state?.sessionid}`,
        {
    },
        {headers: {
            'Authorization': `Bearer ${userToken?.id_token}`,
            'Content-Type': 'application/json',
          },  'responseType': 'arraybuffer'      
        }).then((response) => 
        {
             const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
           
          // Trigger the download
          saveAs(blob, `${sessionInfo?.name}.xlsx`);
          setDownloadLoader(false);
            
        })
        .catch((err) => {
                 toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDownloadLoader(false);
        });    
     
      }

      const getTableReportData = (page, filters) => {
        setDataLoader(true);
        APIList.getSessionReportForTable({
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filters,
            filterData: filters?.length > 0 ? true : false,
            keyword: search,
            managerId: employeeDetails?.id,
            companyId: employeeDetails?.company?.id,
            page: page,
            size: 10
        })
        .then((res) => {
           setTableData(res?.data?.data);
           setTotalPages(res?.data?.totalPages);
           setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }

    const filteredDataForGraphs = () => {
        let temp = structuredClone(viewReport);

        temp?.forEach((item) => {
            item?.employees?.forEach((emp) => {
                if(!isInFiltered(emp)){
                item["employees"] = item?.employees?.filter(x => x?.id !== emp?.id)
            }
            })
            
        })

        return temp;
    }

    return(

        <div className="dashboard-main">
            <div className="rating-calibration-report-view">

            <div className="readonly-tabs">
                    <div className={`tab ${topTab === "Report" ? "active" : ""}`} onClick={() => setTopTab("Report")}>Report</div>
                    <div className={`tab ${topTab === "Final Report" ? "active" : ""}`} onClick={() => setTopTab("Final Report")}>Session changes</div>
                </div>

                <div className="view-report-row">        
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br/> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br/> Count</div>
                    </div>           
                </div>

                

                

                {
                    topTab === "Final Report" ? 
                    <>
                    <div className="tabs-outer">
                    <div className="final-report-heading">Session changes</div>
                    <div className="down-report">
                        {
                            downloadLoader ? 
                                <>
                                Downloading... <CircularProgress size={29} />
                                </>
                            :
                                <button onClick={handleDownloadReport2}>Download report <img src={downloadIcon} alt="" /></button>
                        }
                    </div>
                </div>
                    <ChangedReport sessionId={location?.state?.sessionid} />
                    </>
                    :
                    <>
                    <div className="outer-layout mt-3">

                    <div className="second-div">
                    <Search>
                        <SearchIconWrapper>
                        <IoMdSearch />
                        </SearchIconWrapper>
                        <StyledInputBase
                        placeholder="Search by username or email"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={autoSearchText}
                        value={search}
                        />
                    </Search>
                    <div className="filter-tab" 
                    onClick={() => setShowFilters(!showFilters)}
                    > 
                        <img src={filterIcon} alt="" />
                        Filter
                        <BsChevronDown
                        style={{ transform: !showFilters ? "rotate(0deg)" : "rotate(180deg)", marginLeft:"0.5rem" }}
                    />
                    </div>
                    {/* <div className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
                        i
                    </div> */}
                    </div>

                    {
                showFilters && 
                <div className="filters-block">
                    <div className="filters">
                        {
                            !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                return (
                                    <FilterSelect2 title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                )
                            })
                        }
                        </div>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={dataLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                        
                </div>
            }

                    <div className="tabs-outer">
                    <div className="down-report">
                        {
                            downloadLoader ? 
                                <>
                                Downloading... <CircularProgress size={29} />
                                </>
                            :
                                <button onClick={handleDownloadReport}>Download report <img src={downloadIcon} alt="" /></button>
                        }
                    </div>
                    <div className="tabs">
                        <div className={`tab ${activeTab === "All" ? "active" : ""}`} onClick={() => setActiveTab("All")}>All</div>
                        <div className={`tab ${activeTab === "Column" ? "active" : ""}`} onClick={() => setActiveTab("Column")}>Column</div>
                        <div className={`tab ${activeTab === "Row" ? "active" : ""}`} onClick={() => setActiveTab("Row")}>Row</div>
                    </div>
                    </div>
                    {
                        dataLoader ? 
                        <div className="loader-outer">
                        <CircularProgress size={29} />
                        </div>
                        :
                    <div className="first-column">
                        <div className="first-row">     
                        <div className="d-flex">
                            {/* <div className="w-80">Medibuddy Beliefs (MBB) Performance</div>
                            <div className="w-20">dropdown</div> */}
                        </div>   
                        {/* <div className="tableDiv"> 
                                <div className="table-heading">
                                    <div className="col border-0 bgn" style={{width:"10%"}} >&nbsp;</div>
                                    {
                                        performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`}} >{performance}</div>
                                            )                                            
                                        })
                                    }
                                    {
                                        activeTab === "Row" && 
                                        <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`, fontWeight:"500", fontSize:"0.9rem"}} >Total ({totalEmployees})</div> 
                                    }
                                </div>
                                <div className="table-heading">
                                    <div className="col border-0 bgn" style={{width: "10%"}} >&nbsp;</div>
                                    {
                                         performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - 10}%`}} >
                                                    <div className="pre-post">
                                                        <div className="col-pre" style={{width:`50%`}} >Pre</div>
                                                        <div className="col-post" style={{width:`50%`}} >Post</div>
                                                    </div>
                                                </div>
                                            )                                            
                                        })
                                    }
                                    {
                                        activeTab === "Row" && 
                                        <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - 10}%`}} >
                                        <div className="pre-post">
                                            <div className="col-pre" style={{width:`50%`}} >Pre</div>
                                            <div className="col-post" style={{width:`50%`}} >Post</div>
                                        </div>
                                    </div>
                                    }
                                    
                                </div>
                                {
                                    mbbRatings?.map((mbb) => {
                                    
                                        return(
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn">{mbb}</div>
                                                {
                                                   performanceRatings?.map((performance) => { 
                                                        return (
                                                            <div className="col" >

                                                                {
                                                                    preReport?.map((item) => {
                                                                        return (
                                                                            item?.mbbRating === mbb &&
                                                                            item?.preformanceRating === performance &&
                                                                            <div className="pre-post" >
                                                                                {
                                                                                    item?.employees?.length === 0 ?
                                                                                    <div className="col-pre" style={{ width: `50%` }} > - </div>
                                                                                    :
                                                                                    <div className="col-pre" style={{ width: `50%` }} > {item?.employees?.length} ({(isNaN((Number(item?.employees?.length) / getDiviserPre(mbb, performance))) ? 0 : Number(item?.employees?.length) / getDiviserPre(mbb, performance) * 100).toFixed(0)}%)</div>
                                                                                }
                                                                                
                                                                                <div className="col-post" style={{ width: `50%` }} >
                                                                                    {
                                                                                        viewReport?.map((item) => {
                                                                                            return (
                                                                                                item?.mbbRating === mbb &&
                                                                                                item?.preformanceRating === performance && 
                                                                                                
                                                                                                <> {getFilteredCount(item?.employees) === 0 ? "-" : getFilteredCount(item?.employees)} {getFilteredCount(item?.employees) !== 0 && `(${((isNaN((Number(getFilteredCount(item?.employees)) / getDiviserPost(mbb, performance))) ? 0 : Number(getFilteredCount(item?.employees)) / getDiviserPost(mbb, performance)) * 100).toFixed(0)}%)`}</>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }

                                                {
                                                    activeTab === "Row" && 
                                                    <div className="col" >

                                                        <div className="pre-post" >
                                                            <div className="col-pre" style={{ width: `50%` }} >
                                                            {
                                                                allPreEmployees?.filter(x => x?.mbbRating === mbb)?.length
                                                            }
                                                            </div>
                                                            <div className="col-post" style={{ width: `50%` }} >
                                                            {
                                                                allPostFilteredEmployees?.filter(x => x?.mbbRating === mbb)?.length
                                                            }
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                                }

                                        
                                                
                                           </div>
                                        )
                                    })
                                } 
                                {
                                    activeTab === "Column" && 
                                    // performanceRatings?.map((performance) => {
                                    //     return(
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn" style={{fontWeight:"500", fontSize:"0.9rem"}}>Total ({totalEmployees})</div>
                                                {
                                                    performanceRatings?.map((performance) => { 
                                                        return(
                                                            <div className="col" > 
                                                            
                                                                

                                                                            <div className="pre-post" >
                                                                            <div className="col-pre" style={{width:`50%`}} >
                                                                                {
                                                                                    allPreEmployees?.filter(x => x?.preformanceRating === performance)?.length
                                                                                }
                                                                            </div>
                                                                            <div className="col-post" style={{width:`50%`}} > 
                                                                                {
                                                                                    allPostFilteredEmployees?.filter(x => x?.preformanceRating === performance)?.length
                                                                                }
                                                                            </div>
                                                                            </div>
                                                            
                                                            </div>
                                                        )                                            
                                                    })
                                                }
                                                
                                           </div>
                                    //     )
                                    // })
                                }
                               
                            </div>   */}
                            {
                                dataLoader ? 
                                <CircularProgress />
                                :
                                <div>
                                {/* {(activeTab === "All") && (
                                    // <BarGraph data={postCalibratedRatings} />
                                    viewType === "DND" ?
                                    <LineGraphForAll data={filteredDataForGraphs()} preData={filteredReport} />
                                    :
                                    <LineGraphForAll data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} />
                                ) } */}
                                {(activeTab === "Column") && (
                                    // <MultiBarGraph data={postCalibratedRatings} />

                                    // <MultiBarGraphRow data={postCalibratedRatings} />
                                    
                                        viewType === "DND" ? 
                                        <LineGraph data={filteredDataForGraphs()} preData={filteredReport} ratingType="performance" />
                                        :                                        
                                        <LineGraph data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} ratingType="performance" />

                                ) }
                                {(activeTab === "Row") && (
                                    // <MultiBarGraphRow data={postCalibratedRatings} />

                                    // <MultiBarGraph data={postCalibratedRatings} preData={preCalibratedRatings} />
                                    viewType === "DND" ? 
                                    <LineGraph data={filteredDataForGraphs()} preData={filteredReport} ratingType="mbb" />
                                    :
                                    <LineGraph data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} ratingType="mbb" />
                                ) }
                            </div>
                            }
                            
                                                                 
                        </div>  
                        
                    </div>
}
                      
                </div>             

                {/* <div className="bar-charts">
                    <BarGraph viewReport={viewReportData} />
                </div> */}

                <div className="tabs-outer-1 mt-4 p-0">
                    {/* <div className="tabs">
                        <div className={`tab ${viewType === "DND" ? "active" : ""}`} onClick={() => setViewType("DND")}>DND</div>
                        <div className={`tab ${viewType === "Table" ? "active" : ""}`} onClick={() => setViewType("Table")}>Table</div>
                    </div> */}
                    <div className="icon-tabs">
                        <div className={`tab tab-1 ${viewType === "DND" ? "active" : ""}`} onClick={() => setViewType("DND")}><BsGrid /></div>
                        <div className={`tab tab-2 ${viewType === "Table" ? "active" : ""}`} onClick={() => setViewType("Table")}><CiBoxList /></div>
                    </div>
                </div> 

                <div className="outer-layout mt-3">
                    {
                        (viewType === "DND") ? 
                        dataLoader ? 
                        <div className="loader-outer">
                        <CircularProgress size={29} />
                        </div>
                        :
                        <div className="first-column">
                        <div className="first-row">     
                        <div className="d-flex">
                            {/* <div className="w-80">Medibuddy Beliefs (MBB) Performance</div>
                            <div className="w-20">dropdown</div> */}
                        </div>   
                        <div className="tableDiv">
                                <div className="table-heading" 
                                // style={{top : ((expandedBlock?.mbb !== "") && (expandedBlock?.performance !== "")) ? "12.9rem" : "-12px"}}
                                >
                                    <div className="col border-0 bgn" style={{width:
                                        // `${100/(mbbRatings?.length + 1)}%`
                                        "10%"
                                        }} >&nbsp;</div>
                                    {
                                        performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`}} >{performance}</div>
                                            )                                            
                                        })
                                    }
                                </div>
                                {
                                     mbbRatings?.map((mbb) => {                                    
                                        return(
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn">{mbb}</div>
                                                {
                                                   performanceRatings?.map((performance) => {
                                                        return(
                                                            <div className={`col tblData-dnd ${(activeRow === `${mbb}-${performance}`) ? "active" : ""}`} 
                                                            // onDragOver={(e) => handleDragOver(e, mbb, performance)} onDrop={() => handleDrop(mbb, performance)}
                                                            >
                                                                {/* {
                                                                    ((expandedBlock?.mbb === mbb) && (expandedBlock?.performance === performance)) &&
                                                                    <div className="expand-heading">{mbb} - {performance}</div>
                                                                } */}
                                                                <div className="inner-col">
                                                                    {
                                                                        viewReport?.map((item) => {

                                                                            return (
                                                                                item?.mbbRating === mbb &&
                                                                                item?.preformanceRating === performance &&
                                                                                item?.employees.map((emp) => {
                                                                                    if (!isInFiltered(emp)) {
                                                                                        return null;
                                                                                    }
                                                                                    return (
                                                                                        <div>
                                                                                            
                                                                                            <div
                                                                                                className={`dragable-emp ${emp?.reason?.length > 0 ? "green-border" : emp?.defaultRatings ? "yellow-border" : ""}`}
                                                                                                // draggable
                                                                                                // onDragStart={(e) => handleDrag(emp, mbb, performance)}
                                                                                                // onDragEnd={() => onEnd(emp)}
                                                                                                onClick={() => { setIsOpenDrawer(true); setempToView(emp) }}
                                                                                            >
                                                                                                {emp?.name}

                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            )
                                                                        })
                                                                    }



                                                                </div>
                                                                {/* {
                                                                    viewReport?.map((item) => {

                                                                        return (
                                                                            item?.mbbRating === mbb &&
                                                                            item?.preformanceRating === performance &&
                                                                            item?.employees.map((emp) => {
                                                                                if (!isInFiltered(emp)) {
                                                                                    return null;
                                                                                }
                                                                                return (
                                                                                    isOpenReasonDialog && mbb === targetDiv?.mbb && performance === targetDiv?.perform && (emp?.id === draged?.id) &&
                                                                                    <div className="reason-input">
                                                                                        <div className="heading">Reason <span onClick={() => handleCancelReason()}> <IoMdClose /> </span></div>
                                                                                        <TextField
                                                                                            id="outlined-multiline-flexible"
                                                                                            label="Reason"
                                                                                            placeholder="Enter reason"
                                                                                            multiline
                                                                                            rows={3}
                                                                                            autoFocus={true}
                                                                                            value={item?.employees?.filter(x => x?.id === draged?.id)[0]?.reason ? item?.employees?.filter(x => x?.id === draged?.id)[0]?.reason : ""}
                                                                                            onChange={(e) => handleChangeReason(e)}
                                                                                            sx={{ width: "100%", bgcolor: "var(--graybg)" }}
                                                                                        />

                                                                                        {
                                                                                            reasonErr &&
                                                                                            <FormHelperText sx={{ fontSize: "0.75rem", color: "red" }}>Please provide reason</FormHelperText>
                                                                                        }

                                                                                        <Button onClick={handleSubmitReason}>Submit</Button>
                                                                                    </div>

                                                                                )
                                                                            })
                                                                        )
                                                                    })
                                                                } */}
                                                                {/* {
                                                                    ((expandedBlock?.mbb === mbb) && (expandedBlock?.performance === performance)) ?
                                                                    <IoMdContract onClick={() => handleBlockExpansion(mbb, performance)} />
                                                                    :
                                                                    <AiOutlineExpand onClick={() => handleBlockExpansion(mbb, performance)} /> 
                                                                } */}
                                                             
                                                            </div>
                                                        )                                            
                                                    })
                                                }                                                  
                                               
                                           </div>
                                        )
                                    })
                                }
                               
                               
                            </div>                             
                                                                 
                        </div>  
                        
                        </div>
                        :
                        <div className="changed-report">
                        <div className="tablee">
                        <table class="feedback-emp-list-table"> 
                        
                            <tr className="table-head-emp-list"> 
                                <th className="e-name">
                                E name 
                                </th> 
                                <th className="mid-cols">
                                Func/ sub-func
                                </th> 
                                <th className="mid-cols">
                                Is Recommended for Promotion
                                </th> 
                                <th className="mid-cols">
                                Rating Revised
                                </th> 
                                <th className="mid-cols">
                                Pre MBSR Performance
                                </th> 
                                <th className="mid-cols">
                                Pre MBSR MBB
                                </th> 
                                <th className="mid-cols">
                                Post MBSR Performance
                                </th> 
                                <th className="mid-cols">
                                Post MBSR MBB
                                </th> 
                                
                                <th className="last-col">Reason</th> 
                            </tr>
                            
                            {
                                dataLoader ? 
                                <TableSkel />
                                :
                                tableData?.length > 0 ?
                                tableData?.map((data) => {
                                        return(
                                            <tr>
                                                <td>
                                                    <div className="emp-i">
                                                        <div className="name">{data?.name}</div>
                                                        <div className="emp-id">{data?.employeeLevel}</div>
                                                    </div>
                                                </td>
                                                <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                                <td>
                                                    <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                                    {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                                    </span>
                                                </td>
                                                <td>
                                                <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                                    {
                                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                                    }
                                                </span>
                                                </td>
                                                <td>{data?.preRating?.preformanceRating}</td>
                                                <td>{data?.preRating?.mbbRating}</td>
                                                <td>
                                                    {data?.postRating?.preformanceRating}
                                                    {/* <Select
                                                        name="preformanceRating"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={data?.postRating?.preformanceRating} 
                                                        // label="Mbb rating"
                                                        onChange={(e) => handleChange(e, data)}
                                                        sx={{
                                                            // width:"100%", 
                                                            color:"var(--teryiary)",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border:"none"
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            performanceRatings?.map((rating) => {
                                                                return(
                                                                    <MenuItem value={rating}>{rating}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select> */}
                                                </td>
                                                <td>
                                                    {data?.postRating?.mbbRating}
                                                    {/* <Select
                                                        name="mbbRating"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={data?.postRating?.mbbRating}
                                                        // label="Mbb rating"
                                                        onChange={(e) => handleChange(e, data)}
                                                        sx={{
                                                            // width:"100%", 
                                                            color:"var(--teryiary)",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border:"none"
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            mbbRatings?.map((rating) => {
                                                                return(
                                                                    <MenuItem value={rating}>{rating}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select> */}
                                                </td>
                                                <td>
                                                <div className="the-reason">
                                                {data?.reason}
                                                </div>
                                                {/* <input
                                                    placeholder="Type here"
                                                    type="text"
                                                    value={data?.reason}
                                                    onChange={(e) => handleChangeReasonInTable(e,data)}
                                                    style={{ backgroundColor: 'var(--graybg)', border:"none", padding:"5px", borderRadius:"5px" }}
                                                    /> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                :
                                <div className="no-result">No result found</div>
                            
                            
                            }
                            
                        </table> 

                        </div>
                        {
                            !isEmptyNullUndefined(tableData) && (totalPages > 1) &&
                            <PaginationMediBulk currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                        }
                        </div>
                    }
                      
                                      
                </div>
          
               
                    </>
                }


            </div>

            {
            isOpenDrawer && 
            <div className="calibration-side-drawer">
                <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                    <EmpInfo emp={empToView} />
                </SideDrawer>
            </div>
            }

        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    {
                        isDraftPopup ? 
                        "Your Session Report has been securely saved as a draft. Continue editing whenever you're ready!"
                        :
                        "Session Report successfully submitted. Thank you for your dedication to the growth and development of team."
                    }                
                </div>
                <button className="button"
                    onClick={() => { history.push("/hrb")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        {/* {
            isOpenReasonDialog && 
            <DialogPopup openDialog={isOpenReasonDialog} setOpenDialog={setIsOpenReasonDialog} redirect={false} >
                <div className="feedback-dialog-wrap">
                dialog opening
                </div>
            </DialogPopup>
        } */}

       

        </div>
    )
}

export default ViewReport;