import { Skeleton } from "@mui/material";

const AccSkeleton = () => {
    return(
        <div style={{backgroundColor:"#ffffff",padding:"1rem", display:"flex", flexDirection:"column", alignItems:"center", borderRadius:"1rem"}}>
            <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} sx={{marginTop:"1rem"}} />
            <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} sx={{marginTop:"1rem"}} />
            <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} sx={{marginTop:"1rem"}} />
        </div>
    )
}

export default AccSkeleton;