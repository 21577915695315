import { Dialog } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';

const ChangePassPopup = ({openDialog, setOpenDialog, children}) => {

    return(
      
        <Dialog
        open={openDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root':{
            borderRadius:"1rem"
          }
          
        }}
      >
        <div className="common-dialog">
          {children}
        </div>
      </Dialog>
    )
}

ChangePassPopup.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  children: PropTypes.node,
};

export default ChangePassPopup;