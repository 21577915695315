import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// import profileBanner from "../../../../assets/images/profileBanner.png";


const SummaryFeedback = ({ }) => {
    const dispatch = useDispatch();

    const itemStrengths = [
        { title: "1. Visionary Thinking", description: "[Leader's Name] has a strong ability to articulate a clear vision for the team and the organization. Multiple respondents noted that they feel inspired and motivated by [his/her/their] ideas and direction." },
        { title: "2. Decision-Making", description: "[Leader's Name] is decisive and makes well-informed decisions. Several respondents appreciate [his/her/their] ability to analyze situations quickly and take appropriate action." },
        { title: "3. Communication", description: " [Leader's Name] communicates effectively with the team and stakeholders. Feedback indicates that [he/she/they] is approachable and maintains open lines of communication." },
        { title: "4. Areas for Improvement", description: "[Leader's Name] has a strong ability to articulate a clear vision for the team and the organization. Multiple respondents noted that they feel inspired and motivated by [his/her/their] ideas and direction." },
        { title: "5. Delegation", description: "Some team members feel that [Leader's Name] could improve in delegating tasks. There are instances where [he/she/they] takes on too much personally, which can limit the team's opportunities to develop." },
        { title: "6. Feedback Delivery", description: "A few respondents mentioned that while [Leader's Name] provides constructive feedback, the delivery can sometimes be perceived as too direct. A more balanced approach might help in fostering a supportive environment." },
        { title: "7. Work-Life Balance", description: "There are concerns about [Leader's Name]'s work-life balance. A few team members noted that [he/she/they] often works late and expects the same from others, which can lead to burnout." },
    ];

    return (
        <Grid className="chart-report-card">
            <Grid className="summary-feedback-grid">
                <Typography className="summary-feedback">{"Summary Feedback"}</Typography>

                <Typography className="strengths">{"Strengths:"}</Typography>

                {itemStrengths.map((item, index) => (
                    <Grid className="item-strengths-bt-border">
                        <Typography className="item-title">{item.title}</Typography>
                        <Typography className="item-description">{item.description}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(SummaryFeedback);
