import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const Movement = ({data}) => {
    

    // Prepare data for Radar Chart
    const labels = Object.keys(data);
    const expectedRatings = labels.map(label => data[label].expectedRating);
    const actualRatingCurrentYear = labels.map(label => data[label].actualRatingCurrentYear);
    const actualRatingLastYear = labels.map(label => data[label].actualRatingLastYear);

    // actualRatingCurrentYear
    // actualRatingLastYear
    // expectedRating

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Expected Rating',
                data: expectedRatings,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            // {
            //     label: 'Actual Rating',
            //     data: actualRatings,
            //     backgroundColor: 'rgba(54, 162, 235, 0.2)',
            //     borderColor: 'rgba(54, 162, 235, 1)',
            //     borderWidth: 1,
            // },
            {
                label: 'Actual Rating Current Year',
                data: actualRatingCurrentYear,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Actual Rating Last Year',
                data: actualRatingLastYear,
                backgroundColor: 'rgba(23, 78, 235, 0.2)',
                borderColor: 'rgba(23, 78, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <Radar data={chartData} />
        </div>
    );
};

export default Movement;
