import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { actions as logInActions } from "../login/actions";
// import { actions as logInActions } from "../login/actions";

const NotificationDataRequest = function* NotificationDataRequest({ payload }) {
    try {
        const { data } = yield call(api.getNotifications, payload);
        if (data) {
            yield put(actions.NotificationDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.NotificationDataRequestFailed());
            // yield put(logInActions.clearLogInToken());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.NotificationDataRequestFailed());
        // yield put(logInActions.clearLogInToken());

        // logout user if error
        // yield put(logInActions.clearLogInToken());
        // window.location.reload();        
    }
};

const NotificationCountDataRequest = function* NotificationCountDataRequest({payload}) {
    try {
        const { data } = yield call(api.getNotificationCount, payload);
        if (data) {
            yield put(actions.NotificationCountDataRequestSuccess(data));

        } else {
            yield put(actions.NotificationCountDataRequestFailed());

        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.NotificationCountDataRequestFailed());    
    }
};

// export default function* sagas() {
//     yield takeLatest(types.NOTIFICATION_DATA_REQUEST, NotificationDataRequest);
// }

export default function* sagas() {
    yield all([
        takeLatest(types.NOTIFICATION_DATA_REQUEST, NotificationDataRequest),
        takeLatest(types.NOTIFICATION_COUNT_DATA_REQUEST, NotificationCountDataRequest),
    ]);
}
