import Checked from "../../../assets/images/checked.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DialogPopup from "../../common/Dialog";
import Radio from "@mui/material/Radio";
import checkIcon from "../../../assets/images/popup-check.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import {
  isEmptyNullUndefined,
  textAreaStyle,
  myAssesmentvalues as values,
  accordianSVGstyle,
  onlyAcceptWholeNumbers,
  getFormattedDate,
  getPlainText,
} from "../../utils/utils";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import AccSkeleton from "../../common/akkSkeleton";
import { useSelector } from "react-redux";
import Info from "../../../assets/images/info.svg";
import SideDrawer from "../../common/sideDrawer";
import { useLocation } from 'react-router-dom'
import Avatar from "react-avatar";
import EmpProfile from "../../common/employeeProfile";
import moment from "moment";
import EmpOldFeedback from "./viewOldFeedback";
import axios from "axios";
import { BASE_URL } from "../../../api/apiServices";
import JoditEditor from "jodit-react";
import EmpAssesmentStatic from "./employeeAssesmentStatic";
import PeerFeedbackStatic from "./peerFeedbackStatic";
import downloadIcon from "../../../assets/images/dashboard/download.png"

const parse = require("html-react-parser");

const ManagerFeedback = ({empId, isThisFormActive, isViewOnly}) => {
  const currentDate = moment().startOf('day');
  const drawerObj = {
    profile: false,
    info: false,
    peer: false,
    data: null,
  }
  const history = useHistory();
  // const router = useRouter();
  let params = useParams();
  if(params?.id) {

  } else {
    params = {

    }
    params.id = empId

  }
  // const {id} = router.query;

    const location = useLocation();
    // selfAppraisal: {id:location?.state?.aid} 
    // setFeedbackStatus(location?.state?.feedbackStatus);
    // APIList.getFeedbackSelfApraisalData({id: location?.state?.aid, type:"peer"})
    // selfAppraisal: {id:location?.state?.aid} 
    // APIList.getFeedbackById({id: location?.state?.employeeId ,type:"manager"})

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);
  const isManFeedbackCloseLoop = useSelector((state) => state?.annualcycleReducer?.isManFeedbackCloseLoop);
  const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
  const userToken = useSelector((state) => state?.user?.userToken);

  const [expanded, setExpanded] = useState(null);
  const [expandedTile, setExpandedTile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerData, setdrawerData] = useState(null);
  const [formData, setFormData] = useState([]);
  const [vertualFormPayload, setVertualPayload] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [vertualError, setVertualError] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [status, setStatus] = useState('ACTIVE')
  const [isDraftPopup, setIsDraftPopup] = useState(false); 
  const [empInfo, setEmpInfo] = useState(null);
  const [empInfoLoader, setEmpInfoLoader] = useState(false);
  const [viewProfileDrawerData, setviewProfileDrawerData] = useState(drawerObj)
  const [allPeers, setAllPeers] = useState(null)
  const [readMoreId, setReadMoreId] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [openOldFeedback, setOpenOldFeedback] = useState(false);

  const [isShowAssesmentResponse, setIsShowAssesmentResponse] = useState(false);
  const [assesmentDataToShowInSidebar, setAssesmentDataToShowInSidebar] = useState([]);

  const [isShowPeerResponse, setIsShowPeerResponse] = useState(false);
  const [peerDataToShowInSidebar, setPeerDataToShowInSidebar] = useState([]);

  const [files, setFiles] = useState([

  ])

  
  const [PDFData, setPDFData] = useState([]);
  const [getPDFLoader, setGetPDFLoader] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const [checkPdfLoader, setCheckPdfLoader] = useState(false);

  const [isSubmitTrigger, setIsSubmitTrigger] = useState(false);

  const handleViewPreFeedback = () => {
    // history.push({pathname:`/view-feedback/d4e59fe9-9554-4f13-9118-081724fb73aa`, state: {feedbackStatus: "Submitted", aid: "9400a23f-ed68-49cb-ab15-2e8750a0215a", employeeId:"d4e59fe9-9554-4f13-9118-081724fb73aa"}});
    setOpenOldFeedback(true);  
}

const handleShowAssesment = (data) => {
  setAssesmentDataToShowInSidebar(data);
  setIsShowAssesmentResponse(true);
}
const handleShowPeerFeedback = (data) => {
  setPeerDataToShowInSidebar(data);
  setIsShowPeerResponse(true);
}
  
  // const [showReadMore, setShowReadMore] = useState(false);

  const handleReadMore = (id) => {
    // console.log('id', id)
    if(id == readMoreId) {
      setReadMoreId(null)
    } else {
      setReadMoreId(id)

    }
  }

  const editor = useRef(null);

  const options = [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "paragraph",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    // "|",
    // "table",
    // "link",
    "|",
    "undo",
    "redo",
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder:`Provide your feedback here...`,
      // defaultActionOnPaste: "insert_as_html",
      defaultActionOnPaste: "insert_only_text", 
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      showCharsCounter: true,    // Show character count in the footer
      showWordsCounter: true,    // Show word count in the footer (optional)
      showLinesCounter: true,    // Show line count (optional)
      statusbar: true,           // Enable the status bar
      toolbarAdaptive: false,
    }),
    []
  );


  const openDoc = (index) => {
    const tempFiles = structuredClone(files)
    if(tempFiles?.[index]?.path) {
      window.open(tempFiles[index].path);
    } 

  }

  useEffect(() => {
   checkPdfGenerated();
  }, [])

  const checkPdfGenerated = () => {
    setCheckPdfLoader(true);
    APIList.checkIfPdfIsGenarated({employeeId: params?.id, annualCycleId: annualcycleData?.id, formFor: "ManagerFeedBack"})
    .then((res) => {
      setIsPdfGenerated(res?.data);
      setCheckPdfLoader(false);     
    })
    .catch(() => {
      setCheckPdfLoader(false);
    })
    .finally(() => {
      setCheckPdfLoader(false);
    })
  }
  

  const checkIsStringUnderLimit = (event, tab, tabIndes, tileIndex, nestedSubSectionIndex, type, tempvertualFormError,) => {
    let isValid = true
    if (type == 'tab') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            // tab.assessmentResponseObjectDTO.responseString.length
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            // tab.assessmentResponseObjectDTO.responseString.length
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            ].assessmentResponseObjectDTO.responseString.errorMessage = 
            // `${tab.configObj.maxCharacterLimit - getPlainText(tab.assessmentResponseObjectDTO.responseString).length} char left .`;
            `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit} .`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            // tab.configObj.minCharacterLimit >
            //   tab.assessmentResponseObjectDTO.responseString.length ||
            // tab.configObj.maxCharacterLimit <
            //   tab.assessmentResponseObjectDTO.responseString.length
            tab.configObj.minCharacterLimit >
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length ||
            tab.configObj.maxCharacterLimit <
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit} .`;
          // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit} .`;
          ].assessmentResponseObjectDTO.responseString.errorMessage = `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit} .`;
            isValid = false;
          }
        }
      }
    } else if (type == 'tile') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            // tab.assessmentResponseObjectDTO.responseString.length
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            // tab.assessmentResponseObjectDTO.responseString.length
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
          // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
          ].assessmentResponseObjectDTO.responseString.errorMessage = `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit}`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            // tab.configObj.minCharacterLimit >
            //   tab.assessmentResponseObjectDTO.responseString
            //     .length ||
            // tab.configObj.maxCharacterLimit <
            //   tab.assessmentResponseObjectDTO.responseString.length
            tab.configObj.minCharacterLimit >
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length ||
            tab.configObj.maxCharacterLimit <
            getPlainText(tab.assessmentResponseObjectDTO.responseString).length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
          // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
          ].assessmentResponseObjectDTO.responseString.errorMessage = `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit}`;
            isValid = false;
          }
        }
      }
    } else if (type == 'nestedSubSection') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            tab.assessmentResponseObjectDTO
              .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            tab.assessmentResponseObjectDTO
              .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            ].assessmentResponseObjectDTO.responseString.errorMessage = 
            // `${tab.configObj.maxCharacterLimit - getPlainText(tab.assessmentResponseObjectDTO.responseString).length} char left .`;
            `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit} .`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
              tab.assessmentResponseObjectDTO
                .responseString.length ||
            tab.configObj.maxCharacterLimit <
              tab.assessmentResponseObjectDTO
                .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            // ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            ].assessmentResponseObjectDTO.responseString.errorMessage = 
            // `${tab.configObj.maxCharacterLimit - getPlainText(tab.assessmentResponseObjectDTO.responseString).length} char left .`;
            `${getPlainText(tab.assessmentResponseObjectDTO.responseString).length}/${tab.configObj.maxCharacterLimit} .`;
            isValid = false;
          }
        }
      }
    }
    if(isValid) {
      if(type == 'tab') {
        // tempvertualFormError[tabIndes].assessmentResponseObjectDTO[
        //     event.target.name
        //   ].isError = false;
          tempvertualFormError[tabIndes].assessmentResponseObjectDTO[
            event.target.name
          ].errorMessage = "";

      } else if (type == 'tile') {
        // tempvertualFormError[tabIndes].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO.responseString.isError = false;
        tempvertualFormError[tabIndes].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.responseString.errorMessage = '';
      } else if (type == 'nestedSubSection') {
        // tempvertualFormError[tabIndes].subSections[tileIndex].subSections[nestedSubSectionIndex].assessmentResponseObjectDTO.responseString.isError = false;
        tempvertualFormError[tabIndes].subSections[tileIndex].subSections[nestedSubSectionIndex].assessmentResponseObjectDTO.responseString.errorMessage = '';
      }
    }
    setVertualError(tempvertualFormError);
  }

  const handleTextfieldRow = (id) => {
    if(isEmptyNullUndefined(id) || isEmptyNullUndefined(readMoreId)) {
      return 3
    } else {
      if(id == readMoreId) {
        return null
      } else {
        return 3
      }
      
    }
  }

  const handleChangeVertualPayload = (
    event,
    inputType,
    tabIndex,
    tileIndex,
    nestedSubsectionI
  ) => {
    const tempvertualFormPayload = structuredClone(vertualFormPayload);
    const tempvertualFormError = structuredClone(vertualError);
    if (nestedSubsectionI == null && tileIndex == null) {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = event.target.value;

        //added for editor character counter error handling 
        // const plainText = event.target.value
        // .replace(/<\/?[^>]+(>|$)/g, "")    // Remove HTML tags
        // .replace(/&nbsp;/g, "")           // Replace non-breaking space with regular space
        // .replace(/\s+/g, "")              // Normalize multiple spaces into a single space
        // .replace(/[^\S\r\n]/g, "")        // Convert all non-visible characters to spaces
        // .trim();  
        const plainText = getPlainText(event.target.value); 
         let tempNestedObj=structuredClone(tempvertualFormPayload[tabIndex]);
         tempNestedObj.assessmentResponseObjectDTO.responseString=plainText;

        checkIsStringUnderLimit(
          event, 
          // tempvertualFormPayload[tabIndex], 
          tempNestedObj,
          tabIndex, 
          null, 
          null, 
          'tab', 
          tempvertualFormError
        )
        // tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ].isError = false;
        // tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {
        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }


        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = event.target.value;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].configObj.selectionType === "Single"
        ) {


          
          
          if (
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection = [event];
          }
            
          



        } else if (
          tempvertualFormPayload[tabIndex].configObj.selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO
              .selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{"You have reached the maximum selection limit."}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[
                      tabIndex
                    ].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[
                    tabIndex
                  ].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[
          tabIndex
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[
          tabIndex
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    } else if (nestedSubsectionI == null) {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;

        //added for editor character counter error handling 
        // const plainText = event.target.value
        // .replace(/<\/?[^>]+(>|$)/g, "")    // Remove HTML tags
        // .replace(/&nbsp;/g, "")           // Replace non-breaking space with regular space
        // .replace(/\s+/g, "")              // Normalize multiple spaces into a single space
        // .replace(/[^\S\r\n]/g, "")        // Convert all non-visible characters to spaces
        // .trim();   
        const plainText = getPlainText(event.target.value);
         let tempNestedObj=structuredClone(tempvertualFormPayload[tabIndex].subSections[tileIndex]);
         tempNestedObj.assessmentResponseObjectDTO.responseString=plainText;

        checkIsStringUnderLimit(
          event, 
          // tempvertualFormPayload[tabIndex].subSections[tileIndex], 
          tempNestedObj,
          tabIndex, 
          tileIndex, 
          null, 
          'tile', 
          tempvertualFormError
        )

        // tempvertualFormError[tabIndex].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO[event.target.name].isError = false;
        // tempvertualFormError[tabIndex].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] =
          onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {

        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }

        // tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ] = (event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj
            .selectionType === "Single"
        ) {



          // tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];


          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];
          }



        } else if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj
            .selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex]
              .assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{'You have reached the maximum selection limit.'}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[tabIndex].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    } else {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;

         //added for editor character counter error handling 
        // const plainText = event.target.value
        // .replace(/<\/?[^>]+(>|$)/g, "")    // Remove HTML tags
        // .replace(/&nbsp;/g, "")           // Replace non-breaking space with regular space
        // .replace(/\s+/g, "")              // Normalize multiple spaces into a single space
        // .replace(/[^\S\r\n]/g, "")        // Convert all non-visible characters to spaces
        // .trim();
        const plainText = getPlainText(event.target.value);   
         let tempNestedObj=structuredClone(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI]);
         tempNestedObj.assessmentResponseObjectDTO.responseString=plainText;

        checkIsStringUnderLimit(
          event, 
          // tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI], 
          tempNestedObj,
          tabIndex, 
          tileIndex, 
          nestedSubsectionI, 
          'nestedSubSection', 
          tempvertualFormError
        )


        // tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
        //   nestedSubsectionI
        // ].assessmentResponseObjectDTO[event.target.name].isError = false;
        // tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
        //   nestedSubsectionI
        // ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name] =
          onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {
        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }




        // tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
            nestedSubsectionI
          ].configObj.selectionType === "Single"
        ) {
          // tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
          }
        } else if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
            nestedSubsectionI
          ].configObj.selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].subSections[
                  nestedSubsectionI
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].subSections[
                  nestedSubsectionI
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{'You have reached the maximum selection limit.'}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    }
    setVertualPayload(tempvertualFormPayload);
    setVertualError(tempvertualFormError);
  };

  const getPeers = async () => {
    try {
      const response = await APIList.getAllPeer(params.id)
      if(response.status == 200) {
        setAllPeers(response.data)
      }
    } catch (error) {
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;{error?.message}
        </div>
      );
    }
  }

  const getFormData = async (selectedFormFor) => {
    console.log('params', params?.id, isThisFormActive)
    setisLoading(true);
    try {
      const response = await APIList.getAssessmentResponse({
        selectedFormFor: selectedFormFor,
        selectedEmployeeId: params?.id,
      });
      if (response.status === 200) {
        if(response?.data?.files && response.data.files.length) {
          setFiles(() => response?.data?.files)
        }
        let tempvertualFormError = structuredClone(
          response.data.assessmentFormDTO.sections
        ).map((tab, tabIndex) => {
          tab.subSections.length &&
            tab.subSections.map((tile, tileIndex) => {
              tile.subSections.length &&
                tile.subSections.map((nestedSubsection, nestedIndex) => {
                  // for response
                  nestedSubsection.assessmentResponseObjectDTO.responseString =
                    { isError: false, errorMessage: "" };
                  nestedSubsection.assessmentResponseObjectDTO.responseBoolean =
                    { isError: false, errorMessage: "" };
                  nestedSubsection.assessmentResponseObjectDTO.responseInteger =
                    { isError: false, errorMessage: "" };

                  //for selection
                  nestedSubsection.assessmentResponseObjectDTO.selection = {
                    isError: false,
                    errorMessage: "",
                  }; // array
                  nestedSubsection.assessmentResponseObjectDTO.rating = {
                    isError: false,
                    errorMessage: '',
                  };
                  return nestedSubsection;
                });

              tile.assessmentResponseObjectDTO.responseString = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.responseBoolean = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.responseInteger = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.selection = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.rating = {
                isError: false,
                errorMessage: '',
              };
              return tile;
            });

          tab.assessmentResponseObjectDTO.responseString = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.responseBoolean = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.responseInteger = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.selection = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.rating = {
            isError: false,
            errorMessage: '',
          };
          return tab;
        });

                    let tempResponse = JSON.parse(JSON.stringify(response))

                    // if(isCloseLoop){
                    if(isManFeedbackCloseLoop){

                      if(tempResponse.data.status === "ACTIVE") {
                        setStatus(tempResponse.data.status);
                      } else {
                        setStatus('INACTIVE'); // for close loop
                      }
                        
                    } else {
                      console.log('s=================')
                    if (isThisFormActive == true) {
                      setStatus('ACTIVE')
                    } else if (currentDate.isBefore(annualcycleData.pmsCycle.managerAppraisalEndDate)) {
                      console.log("Current date is before self appraisal end date");
                      // setStatus('INACTIVE')
                      setStatus(response.data.status)
                    } else if (currentDate.isAfter(annualcycleData.pmsCycle.managerAppraisalEndDate)) {
                      console.log("Current date is after self appraisal end date");
                      
                      tempResponse.data.status = "ACTIVE"
                      setStatus('ACTIVE')

                      
                    } else {
                      console.log("Current date is equal to self appraisal end date");
                      // setStatus('INACTIVE')
                      setStatus(response.data.status)
                    }
                    console.log('e=================')
                    }

                    



        setVertualError(tempvertualFormError);
        setVertualPayload(response.data.assessmentFormDTO.sections);
        setFormData(response.data);
        // setStatus(response.data.status)
      } else {
      }
    } catch (error) {
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;{error?.message}
        </div>
      );
      // try {
      //   const response = await APIList.getAssessment({
      //     selectedFormFor: selectedFormFor,
      //     selectedCompanyId: employeeDetails?.company?.id,
      //   });
      //   if (response.status === 200) {
      //     let tempvertualFormError = structuredClone(
      //       response.data.sections
      //     ).map((tab, tabIndex) => {
      //       tab.subSections.length &&
      //         tab.subSections.map((tile, tileIndex) => {
      //           tile.subSections.length &&
      //             tile.subSections.map((nestedSubsection, nestedIndex) => {
      //               // for response
      //               nestedSubsection.assessmentResponseObjectDTO.responseString =
      //                 { isError: false, errorMessage: "" };
      //               nestedSubsection.assessmentResponseObjectDTO.responseBoolean =
      //                 { isError: false, errorMessage: "" };
      //               nestedSubsection.assessmentResponseObjectDTO.responseInteger =
      //                 { isError: false, errorMessage: "" };

      //               //for selection
      //               nestedSubsection.assessmentResponseObjectDTO.selection = {
      //                 isError: false,
      //                 errorMessage: "",
      //               }; // array
      //               nestedSubsection.assessmentResponseObjectDTO.rating = {
      //                 isError: false,
      //                 errorMessage: '',
      //               };
      //               return nestedSubsection;
      //             });

      //           tile.assessmentResponseObjectDTO.responseString = {
      //             isError: false,
      //             errorMessage: "",
      //           };
      //           tile.assessmentResponseObjectDTO.responseBoolean = {
      //             isError: false,
      //             errorMessage: "",
      //           };
      //           tile.assessmentResponseObjectDTO.responseInteger = {
      //             isError: false,
      //             errorMessage: "",
      //           };
      //           tile.assessmentResponseObjectDTO.selection = {
      //             isError: false,
      //             errorMessage: "",
      //           };
      //           tile.assessmentResponseObjectDTO.rating = {
      //             isError: false,
      //             errorMessage: '',
      //           };
      //           return tile;
      //         });

      //       tab.assessmentResponseObjectDTO.responseString = {
      //         isError: false,
      //         errorMessage: "",
      //       };
      //       tab.assessmentResponseObjectDTO.responseBoolean = {
      //         isError: false,
      //         errorMessage: "",
      //       };
      //       tab.assessmentResponseObjectDTO.responseInteger = {
      //         isError: false,
      //         errorMessage: "",
      //       };
      //       tab.assessmentResponseObjectDTO.selection = {
      //         isError: false,
      //         errorMessage: "",
      //       };
      //       tab.assessmentResponseObjectDTO.rating = {
      //         isError: false,
      //         errorMessage: '',
      //       };
      //       return tab;
      //     });
      //     setVertualError(tempvertualFormError);
      //     setVertualPayload(response.data.sections);
      //     setFormData(response.data);
      //     setStatus('INACTIVE')
      //   }
      // } catch (error) {}
    }
    setisLoading(false);
  };

  useEffect(() => {
    if(isPdfGenerated) {
      getPDFData();
    }
  }, [isPdfGenerated])
  

  useEffect(() => {
    if (annualcycleData?.pmsCycle?.managerAppraisalEndDate) {
      getFormData("ManagerFeedBack");
      getPeers()
    }
  }, [annualcycleData]);

  useEffect(() => {
    // this effect use for save as draft
    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (!isEmptyNullUndefined(formData?.id)) {
        //  'update calls -------------'
        // handleUpdate('Draft');
        setIsSubmitDisabled(true)
      } else {
        // 'submit calls--------------'
        // handleSubmit('Draft');
        setIsSubmitDisabled(true)
      }
    }
    let handleClear = setTimeout(() => {
      if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
        if (!isEmptyNullUndefined(formData?.id)) {
          //  'update calls -------------'
          handleUpdate('Draft');
        } else {
          // 'submit calls--------------'
          handleSubmit('Draft');
        }
      }
    }, 10000)

    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (!isEmptyNullUndefined(formData?.id)) {

      } else {
        // clease submit timeout if already called
        if(submitLoader) {
          clearTimeout(handleClear)
        }
      }
    }
    
    if(isSubmitTrigger){
      console.log("canceled")
      clearTimeout(handleClear)
      setIsSubmitDisabled(false)
    }

    return () => {
      clearTimeout(handleClear)
      setIsSubmitDisabled(false)
    }
  }, [vertualFormPayload, isSubmitTrigger])

  useEffect(() => {
    // setFeedbackStatus(location?.state?.feedbackStatus);
    setEmpInfoLoader(true);        
    APIList.getEmployeeById({id:params?.id})
    .then((res) => {
      setEmpInfo(res?.data);
      if(isEmptyNullUndefined(res?.data?.manager)){
          history.push({pathname:`/no-feedback/${res?.data?.id}`, state: {employeeId:res?.data?.employeeId}});
      }
      setEmpInfoLoader(false);
    })
    .catch((err) => {
      toast.error(
          <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.message}
          </div>
      )
      setEmpInfoLoader(false);
    });
  }, []);  

  const handleOpenviewProfileDrawer = (data, toShow) => {
    setdrawerData(() => data);
          
    if (toShow === "profile") {
        let temp = {
          ...drawerObj, 
          profile: true, 
          data: data && data,
          peer: false,
          info: false
        };
        setviewProfileDrawerData(temp);
    }
    if (toShow === "peer") {
        let temp = {
          ...drawerObj, 
          peer: true, 
          data: data && data,
          profile: false, 
          info: false
        };
        setviewProfileDrawerData(temp);
    }
    if (toShow === "info") {
        let temp = {
          ...drawerObj, 
          info: true, 
          data: data && data,
          profile: false, 
          peers: false, 
        };
        setviewProfileDrawerData(temp);
    }

    setIsOpenDrawer(true);
  };

  const handleSaveAsDraft = (submitType) => {
    setIsDraftPopup(true);
    // if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
    //   if (formData?.assessmentFormDTO) {
    //     //  'update calls -------------'
    //     handleUpdate('Draft', submitType);
    //   } else {
    //     // 'submit calls--------------'
    //     handleSubmit('Draft', submitType);
    //   }
    // }
    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (!isEmptyNullUndefined(formData.id)) {
        //  'update calls -------------'
        // console.log('handleSaveAsDraft ')
        // console.log(formData)
        handleUpdate('Draft', submitType);
      } else {
        // 'submit calls--------------'
        handleSubmit('Draft', submitType);
      }
    }
  }

  const handleValidate = () => {
    setIsSubmitTrigger(true);
    let isValid = true;
    let tempFormData = structuredClone(formData);
    let tempvertualFormError = structuredClone(vertualError);
    let tempvertualFormPayload = structuredClone(vertualFormPayload);

    // check validation

    tempvertualFormPayload.forEach((tab, tabIndes) => {
      if (!tab.subSections.length) {
        if (
          tab.configObj.doesThisQuestionHaveResponse === true ||
          tab.configObj.doesThisQuestionHaveResponse === "true"
        ) {
          if (tab.configObj.responseType === "String") {
            if (
              // isEmptyNullUndefined(
              //   tab.assessmentResponseObjectDTO.responseString
              // )
              isEmptyNullUndefined(getPlainText(tab.assessmentResponseObjectDTO.responseString))
            ) {
              if (
                tab.configObj.isResponseMandatory === true ||
                tab.configObj.isResponseMandatory === "true"
              ) {
                tempvertualFormError[
                  tabIndes
                ].assessmentResponseObjectDTO.responseString.isError = true;
                tempvertualFormError[
                  tabIndes
                ].assessmentResponseObjectDTO.responseString.errorMessage =
                  "Required field";
                isValid = false;
              }
            } else if (
              tab.configObj.minCharacterLimit ||
              tab.configObj.maxCharacterLimit
            ) {
              if (
                tab.configObj.minCharacterLimit &&
                !tab.configObj.maxCharacterLimit
              ) {
                if (
                  tab.configObj.minCharacterLimit >
                  // tab.assessmentResponseObjectDTO.responseString.length
                  getPlainText(tab.assessmentResponseObjectDTO.responseString).length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                  isValid = false;
                }
              } else if (
                !tab.configObj.minCharacterLimit &&
                tab.configObj.maxCharacterLimit
              ) {
                if (
                  tab.configObj.maxCharacterLimit <
                  // tab.assessmentResponseObjectDTO.responseString.length
                  getPlainText(tab.assessmentResponseObjectDTO.responseString).length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters.`;
                  isValid = false;
                }
              } else if (
                tab.configObj.minCharacterLimit &&
                tab.configObj.maxCharacterLimit
              ) {
                if (
                  // tab.configObj.minCharacterLimit >
                  //   tab.assessmentResponseObjectDTO.responseString.length ||
                  // tab.configObj.maxCharacterLimit <
                  //   tab.assessmentResponseObjectDTO.responseString.length
                  tab.configObj.minCharacterLimit >
                  getPlainText(tab.assessmentResponseObjectDTO.responseString).length ||
                  tab.configObj.maxCharacterLimit <
                  getPlainText(tab.assessmentResponseObjectDTO.responseString).length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                  isValid = false;
                }
              }
            }
          } else if (
            tab.configObj.responseType === "Integer" &&
            (tab.configObj.isResponseMandatory === true ||
              tab.configObj.isResponseMandatory === "true") &&
            isEmptyNullUndefined(
              tab.assessmentResponseObjectDTO.responseInteger
            )
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseInteger.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseInteger.errorMessage =
              "Required field";
            isValid = false;
          } else if (
            tab.configObj.responseType === "Boolean" &&
            (tab.configObj.isResponseMandatory === true ||
              tab.configObj.isResponseMandatory === "true") &&
            (tab.assessmentResponseObjectDTO.responseBoolean == null ||
              tab.assessmentResponseObjectDTO.responseBoolean == undefined)
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseBoolean.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
              "Please select yes / no";
            isValid = false;
          }
        }
        if (
          (tab.configObj.doesThisQuestionHaveSelection === true ||
            tab.configObj.doesThisQuestionHaveSelection === "true") &&
          (tab.configObj.isSelectionMandatory === true ||
            tab.configObj.isSelectionMandatory === "true")
        ) {
          if (tab.configObj.selectionType === "Single") {
            if (tab.assessmentResponseObjectDTO.selection.length == 0) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.errorMessage =
                "Please select any one";
              isValid = false;
            }
          } else if (tab.configObj.selectionType === "Multiple") {
            if (tab.assessmentResponseObjectDTO.selection.length == 0) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.errorMessage =
                "Please select at least one. ";
              isValid = false;
            }
          }
        }
        if((tab.configObj.isRatingRequired === true ||
          tab.configObj.isRatingRequired === 'true') &&
        (tab.configObj.isRatingMandatory === true ||
          tab.configObj.isRatingMandatory === 'true')) {
            if(isEmptyNullUndefined(tab.assessmentResponseObjectDTO.rating)) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.rating.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.rating.errorMessage =
                'Please select rating. ';
              isValid = false;
            }
          }
      } else {
        tab.subSections.forEach((tile, tileIndex) => {
          if (!tile.subSections.length) {
            if (
              tile.configObj.doesThisQuestionHaveResponse === true ||
              tile.configObj.doesThisQuestionHaveResponse === "true"
            ) {
              if (tile.configObj.responseType === "String") {
                if (
                  // isEmptyNullUndefined(
                  //   tile.assessmentResponseObjectDTO.responseString
                  // )
                  isEmptyNullUndefined(getPlainText(tile.assessmentResponseObjectDTO.responseString))
                ) {
                  if (
                    tile.configObj.isResponseMandatory === true ||
                    tile.configObj.isResponseMandatory === "true"
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].assessmentResponseObjectDTO.responseString.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].assessmentResponseObjectDTO.responseString.errorMessage =
                      "Required field";
                    isValid = false;
                  }
                } else if (
                  tile.configObj.minCharacterLimit ||
                  tile.configObj.maxCharacterLimit
                ) {
                  if (
                    tile.configObj.minCharacterLimit &&
                    !tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      tile.configObj.minCharacterLimit >
                      // tile.assessmentResponseObjectDTO.responseString.length
                      getPlainText(tile.assessmentResponseObjectDTO.responseString).length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tile.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                      isValid = false;
                    }
                  } else if (
                    !tile.configObj.minCharacterLimit &&
                    tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      tile.configObj.maxCharacterLimit <
                      // tile.assessmentResponseObjectDTO.responseString.length
                      getPlainText(tile.assessmentResponseObjectDTO.responseString).length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tile.configObj.maxCharacterLimit} characters.`;
                      isValid = false;
                    }
                  } else if (
                    tile.configObj.minCharacterLimit &&
                    tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      // tile.configObj.minCharacterLimit >
                      //   tile.assessmentResponseObjectDTO.responseString
                      //     .length ||
                      // tile.configObj.maxCharacterLimit <
                      //   tile.assessmentResponseObjectDTO.responseString.length
                      tile.configObj.minCharacterLimit >
                      getPlainText(tile.assessmentResponseObjectDTO.responseString).length ||
                      tile.configObj.maxCharacterLimit <
                      getPlainText(tile.assessmentResponseObjectDTO.responseString).length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tile.configObj.minCharacterLimit} and ${tile.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                      isValid = false;
                    }
                  }
                }
              } else if (
                tile.configObj.responseType === "Integer" &&
                (tile.configObj.isResponseMandatory === true ||
                  tile.configObj.isResponseMandatory === "true") &&
                isEmptyNullUndefined(
                  tile.assessmentResponseObjectDTO.responseInteger
                )
              ) {
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseInteger.isError = true;
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseInteger.errorMessage =
                  "Required field";
                isValid = false;
              } else if (
                tile.configObj.responseType === "Boolean" &&
                (tile.configObj.isResponseMandatory === true ||
                  tile.configObj.isResponseMandatory === "true") &&
                (tile.assessmentResponseObjectDTO.responseBoolean == null ||
                  tile.assessmentResponseObjectDTO.responseBoolean == undefined)
              ) {
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseBoolean.isError = true;
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
                  "Please select yes / no";
                isValid = false;
              }
            }
            if (
              (tile.configObj.doesThisQuestionHaveSelection === true ||
                tile.configObj.doesThisQuestionHaveSelection === "true") &&
              (tile.configObj.isSelectionMandatory === true ||
                tile.configObj.isSelectionMandatory === "true")
            ) {
              if (tile.configObj.selectionType === "Single") {
                if (tile.assessmentResponseObjectDTO.selection.length == 0) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.errorMessage =
                    "Please select any one";
                  isValid = false;
                }
              } else if (tile.configObj.selectionType === "Multiple") {
                if (tile.assessmentResponseObjectDTO.selection.length == 0) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.errorMessage =
                    "Please select at least one. ";
                  isValid = false;
                }
              }
            }
            if((tile.configObj.isRatingRequired === true ||
              tile.configObj.isRatingRequired === 'true') &&
            (tile.configObj.isRatingMandatory === true ||
              tile.configObj.isRatingMandatory === 'true')) {
                if(isEmptyNullUndefined(tile.assessmentResponseObjectDTO.rating)) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.rating.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.rating.errorMessage =
                    'Please select rating. ';
                  isValid = false;
                }
              }
          } else {
            tile.subSections.forEach(
              (nestedSubSection, nestedSubSectionIndex) => {
                if (
                  nestedSubSection.configObj.doesThisQuestionHaveResponse ===
                    true ||
                  nestedSubSection.configObj.doesThisQuestionHaveResponse ===
                    "true"
                ) {
                  if (nestedSubSection.configObj.responseType === "String") {
                    if (
                      // isEmptyNullUndefined(
                      //   nestedSubSection.assessmentResponseObjectDTO
                      //     .responseString
                      // )
                      isEmptyNullUndefined(getPlainText(nestedSubSection.assessmentResponseObjectDTO.responseString))
                    ) {
                      if (
                        nestedSubSection.configObj.isResponseMandatory ===
                          true ||
                        nestedSubSection.configObj.isResponseMandatory ===
                          "true"
                      ) {
                        tempvertualFormError[tabIndes].subSections[
                          tileIndex
                        ].subSections[
                          nestedSubSectionIndex
                        ].assessmentResponseObjectDTO.responseString.isError = true;
                        tempvertualFormError[tabIndes].subSections[
                          tileIndex
                        ].subSections[
                          nestedSubSectionIndex
                        ].assessmentResponseObjectDTO.responseString.errorMessage =
                          "Required field";
                        isValid = false;
                      }
                    } else if (
                      nestedSubSection.configObj.minCharacterLimit ||
                      nestedSubSection.configObj.maxCharacterLimit
                    ) {
                      if (
                        nestedSubSection.configObj.minCharacterLimit &&
                        !nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          nestedSubSection.configObj.minCharacterLimit >
                          // nestedSubSection.assessmentResponseObjectDTO
                          //   .responseString.length
                          getPlainText(nestedSubSection.assessmentResponseObjectDTO.responseString).length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${nestedSubSection.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                          isValid = false;
                        }
                      } else if (
                        !nestedSubSection.configObj.minCharacterLimit &&
                        nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          nestedSubSection.configObj.maxCharacterLimit <
                          // nestedSubSection.assessmentResponseObjectDTO
                          //   .responseString.length
                          getPlainText(nestedSubSection.assessmentResponseObjectDTO.responseString).length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${nestedSubSection.configObj.maxCharacterLimit} characters.`;
                          isValid = false;
                        }
                      } else if (
                        nestedSubSection.configObj.minCharacterLimit &&
                        nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          // nestedSubSection.configObj.minCharacterLimit >
                          //   nestedSubSection.assessmentResponseObjectDTO
                          //     .responseString.length ||
                          // nestedSubSection.configObj.maxCharacterLimit <
                          //   nestedSubSection.assessmentResponseObjectDTO
                          //     .responseString.length
                          nestedSubSection.configObj.minCharacterLimit >
                          getPlainText(nestedSubSection.assessmentResponseObjectDTO.responseString).length ||
                          nestedSubSection.configObj.maxCharacterLimit <
                          getPlainText(nestedSubSection.assessmentResponseObjectDTO.responseString).length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${nestedSubSection.configObj.minCharacterLimit} and ${nestedSubSection.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                          isValid = false;
                        }
                      }
                    }
                  } else if (
                    nestedSubSection.configObj.responseType === "Integer" &&
                    (nestedSubSection.configObj.isResponseMandatory === true ||
                      nestedSubSection.configObj.isResponseMandatory ===
                        "true") &&
                    isEmptyNullUndefined(
                      nestedSubSection.assessmentResponseObjectDTO
                        .responseInteger
                    )
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseInteger.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseInteger.errorMessage =
                      "Required field";
                    isValid = false;
                  } else if (
                    nestedSubSection.configObj.responseType === "Boolean" &&
                    (nestedSubSection.configObj.isResponseMandatory === true ||
                      nestedSubSection.configObj.isResponseMandatory ===
                        "true") &&
                    (nestedSubSection.assessmentResponseObjectDTO
                      .responseBoolean == null ||
                      nestedSubSection.assessmentResponseObjectDTO
                        .responseBoolean == undefined)
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseBoolean.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
                      "Please select yes / no";
                    isValid = false;
                  }
                }
                if (
                  (nestedSubSection.configObj.doesThisQuestionHaveSelection ===
                    true ||
                    nestedSubSection.configObj.doesThisQuestionHaveSelection ===
                      "true") &&
                  (nestedSubSection.configObj.isSelectionMandatory === true ||
                    nestedSubSection.configObj.isSelectionMandatory === "true")
                ) {
                  if (nestedSubSection.configObj.selectionType === "Single") {
                    if (
                      nestedSubSection.assessmentResponseObjectDTO.selection
                        .length == 0
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.errorMessage =
                        "Please select any one";
                      isValid = false;
                    }
                  } else if (
                    nestedSubSection.configObj.selectionType === "Multiple"
                  ) {
                    if (
                      nestedSubSection.assessmentResponseObjectDTO.selection
                        .length == 0
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.errorMessage =
                        "Please select at least one. ";
                      isValid = false;
                    }
                  }
                }
                if((nestedSubSection.configObj.isRatingRequired === true ||
                  nestedSubSection.configObj.isRatingRequired === 'true') &&
                (nestedSubSection.configObj.isRatingMandatory === true ||
                  nestedSubSection.configObj.isRatingMandatory === 'true')) {
                    if(isEmptyNullUndefined(nestedSubSection.assessmentResponseObjectDTO.rating)) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.rating.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.rating.errorMessage =
                        'Please select rating. ';
                      isValid = false;
                    }
                  }
              }
            );
          }
        });
      }
    });

    if (isValid) {
      //handle submit
        setShowConfirmationPopup(true);
     
    } else {
      // set form error
      setVertualError(tempvertualFormError);
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          {/* &nbsp;&nbsp;{"Please enter all mandatory field"} */}
          &nbsp;&nbsp;{"Please check validation errors in the form."}
        </div>
      );
    }
  };

  const handleUpdate = (status, submitType) => {
    const payload = {
      employeeId: params.id,
      assessmentFormDTO: {
        id: formData.assessmentFormDTO.id,
        isPaginationRequired: formData.assessmentFormDTO.isPaginationRequired,
        status: formData.assessmentFormDTO.state,
        formFor: formData.assessmentFormDTO.formFor,
        company: formData.assessmentFormDTO.company,
        sections: vertualFormPayload,
        // pmsCycle: formData.assessmentFormDTO.pmsCycle,
        pmsCycleId: formData.assessmentFormDTO.pmsCycleId
      },
      appraisalCycle: "2023-2024",
      formFor: formData.assessmentFormDTO.formFor,
      status: status,
      reviewedBy: {
        id: employeeDetails.id,
        name: employeeDetails.name
      },
      pmsCycle: {id: formData.assessmentFormDTO.pmsCycleId}
    };

    setSubmitLoader(() => true);
    APIList.putAssessmentResponse({
      responseFormId: formData.id,
      data: payload,
    })
      .then((res) => {
        if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
          setOpenDialog(true);
        } else {
          // setFormData(() => res.data)
        }
        // if(status == 'ACTIVE') {
        //   setOpenDialog(true);
        // }
        setSubmitLoader(() => false);
        setStatus(res.data.status)
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setSubmitLoader(() => false);
      });
    // setSubmitLoader(false);
    setIsSubmitDisabled(false)
  };

  const handleSubmit = (status, submitType) => {
    // const payload = {
    //   employeeId: params.id,
    //   assessmentFormDTO: {
    //     id: formData.id,
    //     isPaginationRequired: formData.isPaginationRequired,
    //     status: formData.state,
    //     formFor: formData.formFor,
    //     company: formData.company,
    //     sections: vertualFormPayload,
    //   },
    //   appraisalCycle: "2023-2024",
    //   formFor: formData.formFor,
    //   status: status,
    //   reviewedBy: {
    //     id: employeeDetails.id
    //   }
    const payload = {
      employeeId: params.id,
      assessmentFormDTO: {
        id: formData.assessmentFormDTO.id,
        isPaginationRequired: formData.assessmentFormDTO.isPaginationRequired,
        status: formData.assessmentFormDTO.state,
        formFor: formData.assessmentFormDTO.formFor,
        company: formData.assessmentFormDTO.company,
        sections: vertualFormPayload,
        // pmsCycle: formData.assessmentFormDTO.pmsCycle,
        pmsCycleId: formData.assessmentFormDTO.pmsCycleId
      },
      appraisalCycle: "2023-2024",
      formFor: formData.assessmentFormDTO.formFor,
      status: status,
      reviewedBy: {
        id: employeeDetails.id,
        name: employeeDetails.name
      },
      pmsCycle: {id: formData.assessmentFormDTO.pmsCycleId}
    }
    // };
    

    setSubmitLoader(true);
    APIList.postAssessmentResponse(payload)
      .then((res) => {
        if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
          setOpenDialog(true);
        } else {
          setFormData(() => res.data)
          ///////////  not working
          // let tempFormData = structuredClone(formData)
          // let tempRes = structuredClone(res)
          // tempRes.data.assessmentFormDTO.sections = tempFormData.sections
          // setFormData(() => tempFormData)
        }
        setSubmitLoader(false);
        setStatus(res.data.status)
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setSubmitLoader(false);
      });
    // setSubmitLoader(false);
    setIsSubmitDisabled(false)
  };

  const handleChange = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  const handleChangeTile = (index) => {
    if (expandedTile === index) {
      setExpandedTile(null);
    } else {
      setExpandedTile(index);
    }
  };

  const filterSectionByReferredPeerFeedback = (referredPeerFeedbackSection) => {
    if(isEmptyNullUndefined(allPeers)) {
      return null
    }
    let selectedReferredPeerFeedbackSection = referredPeerFeedbackSection
    let selectedPeers = allPeers.findIndex((e) => e.id === referredPeerFeedbackSection?.referredPeerFeedbackSection?.id)
    if (selectedPeers !== -1) {
      return allPeers[selectedPeers]
    } else {
      return null
    }
  }


const handleSubmitFeedback = () => {
  setShowConfirmationPopup(false);
  if (formData?.id) {
    //  'update calls -------------'
    handleUpdate('ACTIVE');
  } else {
    // 'submit calls--------------'
    handleSubmit('ACTIVE');
  }
}
const handleSubmitDraft = () => {
  setShowConfirmationPopup(false);
  if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
    if (!isEmptyNullUndefined(formData.id)) {
      //  'update calls -------------'
      // console.log('handleSaveAsDraft ')
      // console.log(formData)
      handleUpdate('Draft', 'submitAsDraft');
    } else {
      // 'submit calls--------------'
      handleSubmit('Draft', 'submitAsDraft');
    }
  }
}

  const handleReadmore = (id) => {
    if(readMoreId === id) {
        setReadMoreId(null);
    } else {
        setReadMoreId(id);
    }
};

const getPDFData = () => {
  setGetPDFLoader(true);

  // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/assessment-response/feedback-pdf/0f218429-f9d0-4173-ad23-abce397471c9/17600704-ecb4-4e0d-b0e5-30832d09c2b5/ManagerFeedBack`,
      
  //     {headers: {
  //         'Authorization': `Bearer ${userToken?.id_token}`,
  //         'Content-Type': 'application/json',
  //       },  'responseType': 'arraybuffer'      
  //     })
  axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/assessment-response/feedback-pdf/${params.id}/${annualcycleData?.id}/ManagerFeedBack`,
      
      {headers: {
          'Authorization': `Bearer ${userToken?.id_token}`,
          'Content-Type': 'application/json',
        },  'responseType': 'arraybuffer'      
      })
      .then((response) => 
      {

         // Create a Blob from the byte array
          const blob = new Blob([response?.data], { type: 'application/pdf' });

          // Create a URL for the Blob
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);

          // // Create an anchor element and trigger a download
          // const link = document.createElement('a');
          // link.href = url;
          // link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
          // document.body.appendChild(link);
          // link.click();

          // // Clean up by removing the anchor element and revoking the object URL
          // document.body.removeChild(link);
          // URL.revokeObjectURL(url);

      setPDFData(response?.data);
        setGetPDFLoader(false);
          
      })
      .catch((err) => {
               toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
          setGetPDFLoader(false);
      });    
}

  const createDynamicForm = (data) => {
    if (!data) {
      return;
    }

    const tempData = structuredClone(data);
    let dom = [];

    if (tempData.sections && tempData.sections.length > 0) {
      
      return tempData.sections.map((TabElement, tabIndex) => (       
        <div className={`tile-element ${(status === 'ACTIVE' || (employeeDetails?.permissions?.includes("manager-feedback-view") && !employeeDetails?.permissions?.includes("manager-feedback-create"))) ? 'disabledInput' : ''}`} key={TabElement.id}>
          {TabElement.subSections.length ? (
            <>
              <div className="acc-wrapper">
                <Stack
                  sx={{
                    margin: "1rem",
                    "& .MuiAccordion-rounded": {
                      border: "1px solid #dad2d2",
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === `Tab-${tabIndex}`}
                    onChange={(e) => {
                      handleChange(`Tab-${tabIndex}`);
                      e.stopPropagation();
                    }}
                    data-value={`Tab-${tabIndex}`}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        width: "100%",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        "& .MuiAccordionSummary-content": {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <p className="accordian-heading">
                        {/* {TabElement.configObj.label} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: TabElement.configObj.label,
                          }}
                        ></div>
                      </p>
                     
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div className="assesment-re-btn-in-mannager-feedback-container"> 
                          {!isEmptyNullUndefined(TabElement?.referencePeerSections) && <button className="assesment-re-btn-in-mannager-feedback me-2" onClick={(e) => { e.stopPropagation(); handleShowPeerFeedback(TabElement?.referencePeerSections)}}>Peer Feedback</button> }
                          {/* {!isEmptyNullUndefined(TabElement?.referenceEmployeeSections) && <button className="assesment-re-btn-in-mannager-feedback mt-2" onClick={() => handleShowAssesment(TabElement?.referenceEmployeeSections)}>Assesment response</button> } */}
                        </div>
                     

                        {(TabElement.configObj.isInfoRequired == true ||
                          TabElement.configObj.isInfoRequired == "true") &&
                          TabElement.configObj.info && (
                            <Tooltip title="Info" placement="top-end">
                              <img
                                onClick={(e) => {
                                  // handleOpenSideDrawer(
                                  //   TabElement.configObj.info
                                  // );
                                  handleOpenviewProfileDrawer(TabElement.configObj.info, "info")
                                  e.stopPropagation();
                                }}
                                style={{ marginRight: "10px" }}
                                src={Info}
                              ></img>
                            </Tooltip>
                          )}
                          
                        <BsChevronDown
                          style={{
                            ...accordianSVGstyle,
                            transform:
                              expanded !== `Tab-${tabIndex}`
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                          }}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        fontSize: "1rem",
                      }}
                    >   

                        {
                        !isEmptyNullUndefined(TabElement?.referenceEmployeeSections) && 
                        <div className="self-assesment-inmanager-feedback">
                          <EmpAssesmentStatic data={TabElement?.referenceEmployeeSections} />
                        </div>
                        }
                      
                      {TabElement.subSections.map((tile, tileIndex) => (
                        <div className="tab-element" key={tile.id}>
                          {tile.subSections.length ? (
                            <>
                              <Stack
                                sx={{
                                  margin: "1rem",
                                  "& .MuiAccordion-rounded": {
                                    border: "1px solid #dad2d2",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                <Accordion
                                  expanded={
                                    expandedTile === `Tile-${tileIndex}`
                                  }
                                  onChange={(e) => {
                                    handleChangeTile(`Tile-${tileIndex}`);
                                    e.stopPropagation();
                                  }}
                                  data-value={`Tile-${tileIndex}`}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    sx={{
                                      width: "100%",
                                      fontSize: "1.25rem",
                                      fontWeight: "600",
                                      "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      },
                                    }}
                                  >
                                    <p className="accordian-heading">
                                      {/* {tile.configObj.label} */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: tile.configObj.label,
                                        }}
                                      ></div>
                                    </p>
                                    <div>
                                      {(tile.configObj.isInfoRequired == true ||
                                        tile.configObj.isInfoRequired ==
                                          "true") &&
                                        tile.configObj.info && (
                                          <Tooltip
                                            title="Info"
                                            placement="top-end"
                                          >
                                            <img
                                              onClick={(e) => {
                                                // handleOpenSideDrawer(
                                                //   tile.configObj.info
                                                // );
                                                handleOpenviewProfileDrawer(tile.configObj.info, "info")
                                                e.stopPropagation();
                                              }}
                                              style={{ marginRight: "10px" }}
                                              src={Info}
                                            ></img>
                                          </Tooltip>
                                        )}
                                      <BsChevronDown
                                        style={{
                                          ...accordianSVGstyle,
                                          transform:
                                            expandedTile !== `Tile-${tileIndex}`
                                              ? "rotate(0deg)"
                                              : "rotate(180deg)",
                                        }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {tile.subSections.map(
                                      (
                                        nestedSubsectionE,
                                        nestedSubsectionI
                                      ) => (
                                        <div
                                          className="tab-element"
                                          key={nestedSubsectionE.id}
                                        >
                                          {(((nestedSubsectionE.configObj
                                            .doesThisQuestionHaveSelection ===
                                            true ||
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveSelection ===
                                              "true") &&
                                            nestedSubsectionE.configObj
                                              .selectionType) ||
                                            ((nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse ===
                                              true ||
                                              nestedSubsectionE.configObj
                                                .doesThisQuestionHaveResponse ===
                                                "true") &&
                                              (nestedSubsectionE.configObj
                                                .responseType === "String" ||
                                                nestedSubsectionE.configObj
                                                  .responseType === "Boolean" ||
                                                nestedSubsectionE.configObj
                                                  .responseType ===
                                                  "Integer")) || (
                                                    ((nestedSubsectionE.configObj.isRatingRequired == true) || (nestedSubsectionE.configObj.isRatingRequired === 'true'))
                                                  )) && (
                                            <div className="accDetail-questions">
                                              <div style={{ display: "flex" }}>
                                                <p>
                                                  {/* {
                                                    nestedSubsectionE.configObj
                                                      .label
                                                  } */}
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: nestedSubsectionE.configObj.label,
                                                    }}
                                                  ></div>
                                                </p>
                                                {(nestedSubsectionE.configObj
                                                  .isResponseMandatory ===
                                                  true ||
                                                  nestedSubsectionE.configObj
                                                    .isResponseMandatory ===
                                                    "true" ||
                                                  nestedSubsectionE.configObj
                                                    .isSelectionMandatory ===
                                                    true ||
                                                  nestedSubsectionE.configObj
                                                    .isSelectionMandatory ===
                                                    "true" || 
                                                  nestedSubsectionE.configObj.isRatingMandatory === true ||
                                                  nestedSubsectionE.configObj.isRatingMandatory === 'true'
                                                    ) && (
                                                  <div style={{ color: "red" }}>
                                                    *
                                                  </div>
                                                )}
                                              </div>
                                              
                                              <div className="peer-info-container">
                                                {(nestedSubsectionE.configObj
                                                  .isInfoRequired == true ||
                                                  nestedSubsectionE.configObj
                                                    .isInfoRequired == "true") &&
                                                  nestedSubsectionE.configObj
                                                    .info && (
                                                    <Tooltip
                                                      title="Info"
                                                      placement="top-end"
                                                    >
                                                      <img
                                                        onClick={(e) => {
                                                          // handleOpenSideDrawer(
                                                          //   nestedSubsectionE
                                                          //     .configObj.info
                                                          // );
                                                          handleOpenviewProfileDrawer(nestedSubsectionE.configObj.info, "info")
                                                          e.stopPropagation();
                                                        }}
                                                        style={{
                                                          marginRight: "10px",
                                                          cursor: "pointer",
                                                        }}
                                                        src={Info}
                                                      ></img>
                                                    </Tooltip>
                                                  )}
                                                  {filterSectionByReferredPeerFeedback(nestedSubsectionE) && <p onClick={() => handleOpenviewProfileDrawer({peers: filterSectionByReferredPeerFeedback(nestedSubsectionE), label: nestedSubsectionE.configObj.label}, 'peer')} className="peer-btn">Peer Feedback</p>}
                                                  {/* <p onClick={() => filterSectionByReferredPeerFeedback(nestedSubsectionE)} className="peer-btn">testing Peer Feedback</p> */}
                                                  {/* <p onClick={() => handleOpenviewProfileDrawer(nestedSubsectionE, 'peer')} className="peer-btn">Peer Feedback</p> */}
                                                </div>
                                            </div>
                                          )}

{/* 
                                          {(((nestedSubsectionE?.referredAppraisalSection?.configObj
                                            .doesThisQuestionHaveSelection ===
                                            true ||
                                            nestedSubsectionE?.referredAppraisalSection?.configObj
                                              .doesThisQuestionHaveSelection ===
                                              "true") &&
                                            nestedSubsectionE?.referredAppraisalSection?.configObj
                                              .selectionType) ||
                                            ((nestedSubsectionE?.referredAppraisalSection?.configObj
                                              .doesThisQuestionHaveResponse ===
                                              true ||
                                              nestedSubsectionE?.referredAppraisalSection?.configObj
                                                .doesThisQuestionHaveResponse ===
                                                "true") &&
                                              (nestedSubsectionE?.referredAppraisalSection?.configObj
                                                .responseType === "String" ||
                                                nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .responseType === "Boolean" ||
                                                nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .responseType ===
                                                  "Integer")) || (
                                                    ((nestedSubsectionE?.referredAppraisalSection?.configObj.isRatingRequired == true) || (nestedSubsectionE?.referredAppraisalSection?.configObj.isRatingRequired === 'true'))
                                                  )) && (

                                                    <p className="q-title-values">Employee Self Appraisal</p>

                                                  )} */}

                                          <div className="disabledInput">

                                            {/* //////////////////////// */}
                                            {(nestedSubsectionE?.referredAppraisalSection?.configObj
                                              .doesThisQuestionHaveSelection ===
                                              true ||
                                              nestedSubsectionE?.referredAppraisalSection?.configObj
                                                .doesThisQuestionHaveSelection ===
                                                "true") &&
                                              nestedSubsectionE?.referredAppraisalSection?.configObj
                                                .selectionType && (
                                                <>
                                                  <div className="rateOuter Self-Appraisal-desibled">
                                                    {nestedSubsectionE.referredAppraisalSection.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                                      (item, index) => {
                                                        return (
                                                          <div
                                                            className={`rate ${
                                                              vertualFormPayload[
                                                                tabIndex
                                                              ].subSections[
                                                                tileIndex
                                                              ].subSections[
                                                                nestedSubsectionI
                                                              ].referredAppraisalResponse?.selection?.some(
                                                                (e) =>
                                                                  e.id == item.id
                                                              )
                                                                ? "active"
                                                                : ""
                                                            } `}
                                                          >
                                                            <p className="rateTitle">
                                                              {item.name}
                                                            </p>
                                                            {vertualFormPayload[
                                                              tabIndex
                                                            ].subSections[
                                                              tileIndex
                                                            ].subSections[
                                                              nestedSubsectionI
                                                            ].referredAppraisalResponse?.selection?.some(
                                                              (e) =>
                                                                e.id == item.id
                                                            ) && (
                                                              <img
                                                                src={Checked}
                                                                className="checked"
                                                              ></img>
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </>
                                              )
                                            }

                                            {/* ////////////////////////// */}

                                            {nestedSubsectionE?.referredAppraisalSection?.configObj
                                              .doesThisQuestionHaveResponse &&
                                              nestedSubsectionE?.referredAppraisalSection?.configObj
                                                .responseType === "String" ? (
                                                <>
                                                  {/* <TextField
                                                    name="responseString"
                                                    value={
                                                      vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.responseString
                                                    }
                                                    id="outlined-multiline-static"
                                                    label=""
                                                    variant="outlined"
                                                    placeholder={`Provide your feedback here ${
                                                      nestedSubsectionE.referredAppraisalSection.configObj
                                                        .minCharacterLimit ||
                                                      nestedSubsectionE.referredAppraisalSection.configObj
                                                        .maxCharacterLimit
                                                        ? `(limit: ${
                                                            nestedSubsectionE
                                                              .referredAppraisalSection.configObj
                                                              .minCharacterLimit
                                                              ? ` min: ${nestedSubsectionE.referredAppraisalSection.configObj.minCharacterLimit}`
                                                              : ""
                                                          }  ${
                                                            nestedSubsectionE
                                                              .referredAppraisalSection.configObj
                                                              .maxCharacterLimit
                                                              ? `max: ${nestedSubsectionE.referredAppraisalSection.configObj.maxCharacterLimit} `
                                                              : ""
                                                          }char)`
                                                        : ""
                                                    }`}
                                                    multiline
                                                    rows={3}
                                                    sx={textAreaStyle}
                                                  /> */}
                                                  {
                                                  (params?.isappraisalsubmitted == 'no') ? (
                                                    <div className="accDetal-xFeedback">
                                                      <div className="q-text like-text-field Self-Appraisal-desibled">
                                                        Self appraisal is not submitted yet
                                                      </div>
                                                    </div>
                                                  ) : (
                                                      ((vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI]?.referredAppraisalResponse?.responseString?.length ?? 0) < 150) ? (
                                                          <div className="accDetal-xFeedback">
                                                            <div className="q-text like-text-field Self-Appraisal-desibled">
                                                            {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.responseString)} */}
                                                            </div>
                                                          </div>
    
                                                      ) : (
                                                        (vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.id != readMoreId) ? (
                                                          <div className="accDetal-xFeedback">
                                                            <div className="q-text like-text-field Self-Appraisal-desibled">
                                                            {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.responseString.substring(0, 140))} */}
                                                            <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.id)}>
                                                                ...read more
                                                            </span>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="accDetal-xFeedback">
                                                            <div className="q-text like-text-field Self-Appraisal-desibled">
                                                            {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.responseString)} */}
                                                            <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].referredAppraisalResponse.id)}>
                                                                read less
                                                            </span>
                                                            </div>
                                                          </div>
                                                        )
                                                      )

                                                  )
                                                  
                                                  }
                                                </>
                                              ) : nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .doesThisQuestionHaveResponse &&
                                                nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .responseType === "Boolean" ? (
                                                <>
                                                  <Stack sx={{ width: "50%" }}>
                                                    <RadioGroup
                                                      name="responseBoolean"
                                                      value={
                                                        vertualFormPayload[tabIndex]
                                                          .subSections[tileIndex]
                                                          .subSections[
                                                          nestedSubsectionI
                                                        ]
                                                          .referredAppraisalResponse
                                                          .responseBoolean
                                                      }
                                                      row
                                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                                    >
                                                      <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label={"Yes"}
                                                      />
                                                      <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label={"No"}
                                                      />
                                                    </RadioGroup>
                                                  </Stack>
                                                </>
                                              ) : (
                                                nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .doesThisQuestionHaveResponse &&
                                                nestedSubsectionE?.referredAppraisalSection?.configObj
                                                  .responseType === "Integer" && (
                                                  <>
                                                    <TextField
                                                      name="responseInteger"
                                                      value={
                                                        vertualFormPayload[tabIndex]
                                                          .subSections[tileIndex]
                                                          .subSections[
                                                          nestedSubsectionI
                                                        ]
                                                          .referredAppraisalResponse
                                                          .responseInteger
                                                      }
                                                      id="outlined-multiline-static"
                                                      label=""
                                                      variant="outlined"
                                                      placeholder="Provide your feedback here "
                                                      multiline
                                                      rows={1}
                                                      sx={textAreaStyle}
                                                    />
                                                  </>
                                                )
                                              )
                                            }

                                            {/* //////////////////////////// */}

                                              {(nestedSubsectionE?.referredAppraisalSection?.configObj.isRatingRequired === true ||
                                                  nestedSubsectionE?.referredAppraisalSection?.configObj.isRatingRequired === 'true') && (
                                                  <>
                                                    <FormControl
                                                      sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                                    >
                                                      <InputLabel id='demo-simple-select-label'>
                                                        Rating
                                                      </InputLabel>
                                                      <Select
                                                        name='rating'
                                                        labelId='demo-simple-select-label'
                                                        id='demo-simple-select'
                                                        value={
                                                          vertualFormPayload[tabIndex]
                                                          .subSections[tileIndex]
                                                          .subSections[
                                                          nestedSubsectionI
                                                        ].referredAppraisalResponse?.rating
                                                        }
                                                        renderValue={(selected) =>
                                                          selected.name
                                                        }
                                                        label='Rating'
                                                      >
                                                        {nestedSubsectionE.referredAppraisalSection.configObj?.selectableRatings?.tableData?.map(
                                                          (e) => {
                                                            return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                              .subSections[tileIndex]
                                                              .subSections[
                                                              nestedSubsectionI
                                                            ].referredAppraisalResponse
                                                              ?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                          },
                                                        )}
                                                      </Select>
                                                    </FormControl>
                                                  </>
                                                )
                                              }
                                          </div>

                                          <p className="q-title-values">Manager Feedback</p>



                                          {(nestedSubsectionE.configObj
                                            .doesThisQuestionHaveSelection ===
                                            true ||
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveSelection ===
                                              "true") &&
                                            nestedSubsectionE.configObj
                                              .selectionType && (
                                              <>
                                                <div className="rateOuter">
                                                  {nestedSubsectionE.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                                    (item, index) => {
                                                      return (
                                                        <div
                                                          className={`rate ${
                                                            vertualFormPayload[
                                                              tabIndex
                                                            ].subSections[
                                                              tileIndex
                                                            ].subSections[
                                                              nestedSubsectionI
                                                            ].assessmentResponseObjectDTO.selection.some(
                                                              (e) =>
                                                                e.id == item.id
                                                            )
                                                              ? "active"
                                                              : ""
                                                          } `}
                                                          onClick={(event) =>
                                                            {
                                                              nestedSubsectionE.isSelectionEditable && 
                                                            handleChangeVertualPayload(
                                                              item,
                                                              "selection",
                                                              tabIndex,
                                                              tileIndex,
                                                              nestedSubsectionI
                                                            )
                                                          }
                                                          }
                                                        >
                                                          <p className="rateTitle">
                                                            {item.name}
                                                          </p>
                                                          {vertualFormPayload[
                                                            tabIndex
                                                          ].subSections[
                                                            tileIndex
                                                          ].subSections[
                                                            nestedSubsectionI
                                                          ].assessmentResponseObjectDTO.selection.some(
                                                            (e) =>
                                                              e.id == item.id
                                                          ) && (
                                                            <img
                                                              src={Checked}
                                                              className="checked"
                                                            ></img>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                                {vertualError[tabIndex]
                                                  .subSections[tileIndex]
                                                  .subSections[
                                                  nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                  .selection.isError && (
                                                  <FormHelperText className="Mui-error">
                                                    {
                                                      vertualError[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ]
                                                        .assessmentResponseObjectDTO
                                                        .selection.errorMessage
                                                    }
                                                  </FormHelperText>
                                                )}
                                              </>
                                            )}
                                          {nestedSubsectionE.configObj
                                            .doesThisQuestionHaveResponse &&
                                          nestedSubsectionE.configObj
                                            .responseType === "String" ? (
                                            <>
                                            {(status === 'ACTIVE') ? (
                                              ((vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO?.responseString?.length ?? 0) < 150) ? (
                                                <div className="accDetal-xFeedback">
                                                  <div className="q-text like-text-field">
                                                  { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO?.responseString)}
                                                  </div>
                                                </div>
          
                                              ) : (
                                                (vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id != readMoreId) ? (
                                                  <div className="accDetal-xFeedback">
                                                    <div className="q-text like-text-field">
                                                    { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString.substring(0, 140))}
                                                    <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id)}>
                                                        ...read more
                                                    </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="accDetal-xFeedback">
                                                    <div className="q-text like-text-field">
                                                    { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString)}
                                                    <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id)}>
                                                        read less
                                                    </span>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <>
                                                {/* <TextField
                                                  name="responseString"
                                                  disabled={!nestedSubsectionE.isResponseEditable}
                                                  value={
                                                    vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO
                                                      .responseString
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      "textField",
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI
                                                    )
                                                  }
                                                  id="outlined-multiline-static"
                                                  label=""
                                                  variant="outlined"
                                                  placeholder={`Provide your feedback here ${
                                                    nestedSubsectionE.configObj
                                                      .minCharacterLimit ||
                                                    nestedSubsectionE.configObj
                                                      .maxCharacterLimit
                                                      ? `(limit: ${
                                                          nestedSubsectionE
                                                            .configObj
                                                            .minCharacterLimit
                                                            ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                                            : ""
                                                        }  ${
                                                          nestedSubsectionE
                                                            .configObj
                                                            .maxCharacterLimit
                                                            ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                                            : ""
                                                        }char)`
                                                      : ""
                                                  }`}
                                                  multiline
                                                  rows={3}
                                                  sx={textAreaStyle}
                                                  error={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.isError
                                                  }
                                                  helperText={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.isError &&
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.errorMessage
                                                  }
                                                />
                                                {
                                                  !vertualError[tabIndex].subSections[tileIndex]
                                                    .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .responseString.isError && (
                                                      <FormHelperText
                                                        sx={{
                                                          display: 'flex',
                                                          flexDirection: 'row-reverse'
                                                        }}
                                                      >
                                                        {vertualError[tabIndex].subSections[tileIndex]
                                                          .subSections[
                                                          nestedSubsectionI
                                                        ].assessmentResponseObjectDTO.responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                } */}

                                                <div
                                                    style={{
                                                      marginTop: "2rem",
                                                    }}
                                                  >
                                                    <JoditEditor
                                                      ref={editor}
                                                      config={config}
                                                      name="responseString"
                                                      value={
                                                        vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO
                                                          .responseString
                                                      }
                                                      onBlur={(newContent) =>
                                                        handleChangeVertualPayload(
                                                          {
                                                            target: {
                                                              name: "responseString",
                                                              value: newContent,
                                                            },
                                                          },
                                                          "textField",
                                                          tabIndex,
                                                          tileIndex,
                                                          nestedSubsectionI
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {/* {
                                                  !vertualError[tabIndex].subSections[tileIndex]
                                                    .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .responseString.isError && (
                                                      <FormHelperText
                                                        sx={{
                                                          display: 'flex',
                                                          flexDirection: 'row-reverse',
                                                          mt:1
                                                        }}
                                                      >
                                                        {vertualError[tabIndex].subSections[tileIndex]
                                                          .subSections[
                                                          nestedSubsectionI
                                                        ].assessmentResponseObjectDTO.responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                }  */}
                                                {
                                                  vertualError[tabIndex]
                                                  .subSections[tileIndex]
                                                  .subSections[
                                                  nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                  .responseString.isError && (
                                                      <FormHelperText
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection:"row",
                                                        justifyContent:'flex-start',
                                                        color:'#D32F2F',
                                                        fontSize:'12px',
                                                        mt:1
                                                      }}
                                                      >
                                                        {vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.isError &&
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                }
                                              </>
                                              
                                            )}
                                            </>
                                          ) : nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse &&
                                            nestedSubsectionE.configObj
                                              .responseType === "Boolean" ? (
                                            <>
                                              <Stack sx={{ width: "50%" }}>
                                                <RadioGroup
                                                  name="responseBoolean"
                                                  disabled={!nestedSubsectionE.isResponseEditable}
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseBoolean
                                                  }
                                                  onChange={(event) =>{
                                                    nestedSubsectionE.isResponseEditable &&
                                                    handleChangeVertualPayload(
                                                      event,
                                                      "ratio",
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI
                                                    )
                                                  }
                                                  }
                                                  row
                                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                                >
                                                  <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label={"Yes"}
                                                  />
                                                  <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label={"No"}
                                                  />
                                                </RadioGroup>
                                              </Stack>
                                              {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[nestedSubsectionI]
                                                .assessmentResponseObjectDTO
                                                .responseBoolean.isError && (
                                                <FormHelperText className="Mui-error">
                                                  {
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseBoolean
                                                      .errorMessage
                                                  }
                                                </FormHelperText>
                                              )}
                                            </>
                                          ) : (
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse &&
                                            nestedSubsectionE.configObj
                                              .responseType === "Integer" && (
                                              <>
                                                <TextField
                                                  name="responseInteger"
                                                  disabled={!nestedSubsectionE.isResponseEditable}
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      "numberField",
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI
                                                    )
                                                  }
                                                  id="outlined-multiline-static"
                                                  label=""
                                                  variant="outlined"
                                                  placeholder="Provide your feedback here "
                                                  multiline
                                                  rows={1}
                                                  sx={textAreaStyle}
                                                  error={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger.isError
                                                  }
                                                  helperText={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                      .isError &&
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                      .errorMessage
                                                  }
                                                />
                                              </>
                                            )
                                          )}
                                          
                                            {(nestedSubsectionE.configObj.isRatingRequired === true ||
                                            nestedSubsectionE.configObj.isRatingRequired === 'true') && (
                                            <>
                                              <FormControl
                                                sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                              >
                                                <InputLabel id='demo-simple-select-label'>
                                                  Rating
                                                </InputLabel>
                                                <Select
                                                  name='rating'
                                                  disabled={!nestedSubsectionE.isRatingEditable}
                                                  labelId='demo-simple-select-label'
                                                  id='demo-simple-select'
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                  ].assessmentResponseObjectDTO?.rating
                                                  }
                                                  renderValue={(selected) =>
                                                    selected.name
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      'dropDownSelect',
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI,
                                                    )
                                                  }
                                                  label='Rating'
                                                >
                                                  {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                    (e) => {
                                                      return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                        ?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                    },
                                                  )}
                                                </Select>
                                                {vertualError[tabIndex]
                                                  .subSections[tileIndex]
                                                  .subSections[
                                                  nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                  .rating.isError && (
                                                  <FormHelperText className='Mui-error'>
                                                    {
                                                      vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                        .rating.errorMessage
                                                    }
                                                  </FormHelperText>
                                                )}
                                              </FormControl>
                                            </>
                                          )}
                                          <Divider sx={{
                                            padding: '20px',
                                            borderColor: 'rgba(0, 0, 0, 0.1490196078)',
                                            opacity: '100%',
                                          }} />
                                        </div>
                                      )
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </Stack>
                            </>
                          ) : (
                            <>
                              {(((tile.configObj
                                .doesThisQuestionHaveSelection === true ||
                                tile.configObj.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile.configObj.selectionType) ||
                                ((tile.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                  (tile.configObj.responseType === "String" ||
                                    tile.configObj.responseType === "Boolean" ||
                                    tile.configObj.responseType === "Integer")) || 
                                    ((tile.configObj.isRatingRequired == true) || (tile.configObj.isRatingRequired === 'true'))
                                    ) && (
                                <div className="accDetail-questions">
                                  <div style={{ display: "flex" }}>
                                    {/* <p>{tile.configObj.label}</p> */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: tile.configObj.label,
                                      }}
                                    ></div>
                                    
                                    {(tile.configObj.isResponseMandatory ===
                                      true ||
                                      tile.configObj.isResponseMandatory ===
                                        "true" ||
                                      tile.configObj.isSelectionMandatory ===
                                        true ||
                                      tile.configObj.isSelectionMandatory ===
                                        "true" || 
                                      tile.configObj.isRatingMandatory === true ||
                                      tile.configObj.isRatingMandatory === 'true'
                                        ) && (
                                      <div style={{ color: "red" }}>*</div>
                                    )}
                                  </div>
                                  <div className="peer-info-container">
                                  {(tile.configObj.isInfoRequired == true ||
                                    tile.configObj.isInfoRequired == "true") &&
                                    tile.configObj.info && (
                                      <Tooltip title="Info" placement="top-end">
                                        <img
                                          onClick={(e) => {
                                            // handleOpenSideDrawer(
                                            //   tile.configObj.info
                                            // );
                                            handleOpenviewProfileDrawer(tile.configObj.info, "info")
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          src={Info}
                                        ></img>
                                      </Tooltip>
                                    )}
                                    {filterSectionByReferredPeerFeedback(tile) && <p onClick={() => handleOpenviewProfileDrawer({peers: filterSectionByReferredPeerFeedback(tile), label: tile.configObj.label}, 'peer')} className="peer-btn">Peer Feedback</p>}
                                    
                                      <div className="assesment-re-btn-in-mannager-feedback-container"> 
                                        {/* {!isEmptyNullUndefined(tile?.referenceEmployeeSections) && <button className="assesment-re-btn-in-mannager-feedback mt-2" onClick={() => handleShowAssesment(tile?.referenceEmployeeSections)}>Assesment response</button> } */}
                                        {!isEmptyNullUndefined(tile?.referencePeerSections) && <button className="assesment-re-btn-in-mannager-feedback" onClick={() => handleShowPeerFeedback(tile?.referencePeerSections)}>Peer Feedback</button> }
                                      </div>
                                    
                                    {/* <p onClick={() => filterSectionByReferredPeerFeedback(tile)} className="peer-btn">testing Peer Feedback</p> */}
                                    {/* <p onClick={() => handleOpenviewProfileDrawer(null, 'peer')} className="peer-btn">Peer Feedback</p> */}
                                    </div>
                                </div>
                              )}

                                {
                                  !isEmptyNullUndefined(tile?.referenceEmployeeSections) && 
                                  <div className="self-assesment-inmanager-feedback">
                                    <EmpAssesmentStatic data={tile?.referenceEmployeeSections} />
                                  </div>
                                }


                                {/* {(((tile?.referredAppraisalSection?.configObj
                                .doesThisQuestionHaveSelection === true ||
                                tile?.referredAppraisalSection?.configObj.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile?.referredAppraisalSection?.configObj.selectionType) ||
                                ((tile?.referredAppraisalSection?.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile?.referredAppraisalSection?.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                  (tile?.referredAppraisalSection?.configObj.responseType === "String" ||
                                    tile?.referredAppraisalSection?.configObj.responseType === "Boolean" ||
                                    tile?.referredAppraisalSection?.configObj.responseType === "Integer")) || 
                                    ((tile?.referredAppraisalSection?.configObj.isRatingRequired == true) || (tile.configObj.isRatingRequired === 'true'))
                                    ) && (
                                      <p className="q-title-values">Employee Self Appraisal</p>

                                    )} */}



                              <div className="disabledInput">
                              {(tile?.referredAppraisalSection?.configObj.doesThisQuestionHaveSelection ===
                                true ||
                                tile?.referredAppraisalSection?.configObj?.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile?.referredAppraisalSection?.configObj?.selectionType && (
                                  <>
                                    <div className="rateOuter Self-Appraisal-desibled">
                                      {tile.referredAppraisalSection.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className={`rate ${
                                                vertualFormPayload[
                                                  tabIndex
                                                ].subSections[
                                                  tileIndex
                                                ].referredAppraisalResponse?.selection?.some(
                                                  (e) => e.id == item.id
                                                )
                                                  ? "active"
                                                  : ""
                                              } `}
                                            >
                                              <p className="rateTitle">
                                                {item.name}
                                              </p>
                                              {vertualFormPayload[
                                                tabIndex
                                              ].subSections[
                                                tileIndex
                                              ].referredAppraisalResponse?.selection?.some(
                                                (e) => e.id == item.id
                                              ) && (
                                                <img
                                                  src={Checked}
                                                  className="checked"
                                                ></img>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                )
                              }

                              {/* //////////////////////////////// */}

                              {(tile?.referredAppraisalSection?.configObj.doesThisQuestionHaveResponse ===
                                true ||
                                tile?.referredAppraisalSection?.configObj.doesThisQuestionHaveResponse ===
                                  "true") &&
                              tile?.referredAppraisalSection?.configObj.responseType === "String" ? (
                                <>
                                  {/* <TextField
                                    name="responseString"
                                    value={
                                      vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.responseString
                                    }
                                    id="outlined-multiline-static"
                                    label=""
                                    variant="outlined"
                                    placeholder={`Provide your feedback here ${
                                      tile.referredAppraisalSection.configObj.minCharacterLimit ||
                                      tile.referredAppraisalSection.configObj.maxCharacterLimit
                                        ? `(limit: ${
                                            tile.referredAppraisalSection.configObj.minCharacterLimit
                                              ? ` min: ${tile.referredAppraisalSection.configObj.minCharacterLimit}`
                                              : ""
                                          }  ${
                                            tile.configObj.maxCharacterLimit
                                              ? `max: ${tile.referredAppraisalSection.configObj.maxCharacterLimit} `
                                              : ""
                                          }char)`
                                        : ""
                                    }`}
                                    multiline
                                    rows={handleTextfieldRow(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id)}
                                    sx={textAreaStyle}
                                  />
                                  {
                                    
                                    (
                                    <FormHelperText style={{cursor:'pointer'}} onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id)} className="Mui-error">
                                      {(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id != readMoreId) ? ' ...read more' : '...read less'}
                                     
                                    </FormHelperText>

                                    )
                                  } */}

                                  {
                                  (params?.isappraisalsubmitted == 'no') ? (
                                        <div className="accDetal-xFeedback">
                                          <div className="q-text like-text-field Self-Appraisal-desibled">
                                            Self appraisal is not submitted yet
                                          </div>
                                        </div>
                                      ) : (
                                        ((vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse?.responseString?.length ?? 0) < 150) ? (
                                            <div className="accDetal-xFeedback">
                                              <div className="q-text like-text-field Self-Appraisal-desibled">
                                              {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse?.responseString)} */}
                                              </div>
                                            </div>
      
                                        ) : (
                                          (vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id != readMoreId) ? (
                                            <div className="accDetal-xFeedback">
                                              <div className="q-text like-text-field Self-Appraisal-desibled">
                                              {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse?.responseString?.substring(0, 140))} */}
                                              <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id)}>
                                                  ...read more
                                              </span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="accDetal-xFeedback">
                                              <div className="q-text like-text-field Self-Appraisal-desibled">
                                              {/* {parse(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse?.responseString)} */}
                                              <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].referredAppraisalResponse.id)}>
                                                  read less
                                              </span>
                                              </div>
                                            </div>
                                          )
                                        )

                                  )
                                  
                                  
                                  }
                                  
                                </>
                              ) : (tile?.referredAppraisalSection?.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile?.referredAppraisalSection?.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile?.referredAppraisalSection?.configObj.responseType === "Boolean" ? (
                                <>
                                  <Stack sx={{ width: "50%" }}>
                                    <RadioGroup
                                      name="responseBoolean"
                                      disabled={!tile.isResponseEditable}
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .referredAppraisalResponse
                                          .responseBoolean
                                      }
                                      onChange={(event) =>{
                                        tile.isResponseEditable &&
                                        handleChangeVertualPayload(
                                          event,
                                          "ratio",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      }
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={"Yes"}
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={"No"}
                                      />
                                    </RadioGroup>
                                  </Stack>
                                </>
                              ) : (
                                (tile?.referredAppraisalSection?.configObj.doesThisQuestionHaveResponse ===
                                  true ||
                                  tile?.referredAppraisalSection?.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile?.referredAppraisalSection?.configObj.responseType === "Integer" && (
                                  <>
                                    <TextField
                                      name="responseInteger"
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .referredAppraisalResponse
                                          .responseInteger
                                      }
                                      id="outlined-multiline-static"
                                      label=""
                                      variant="outlined"
                                      multiline
                                      placeholder="Provide your feedback here "
                                      rows={1}
                                      sx={textAreaStyle}
                                    />
                                  </>
                                )
                              )}

                              {/* /////////////////////////// */}

                              {(tile?.referredAppraisalSection?.configObj.isRatingRequired === true ||
                                tile?.referredAppraisalSection?.configObj.isRatingRequired === 'true') && (
                                <>
                                  <FormControl
                                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                  >
                                    <InputLabel id='demo-simple-select-label'>
                                      Rating
                                    </InputLabel>
                                    <Select
                                      name='rating'
                                      labelId='demo-simple-select-label'
                                      id='demo-simple-select'
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .referredAppraisalResponse
                                          ?.rating
                                      }
                                      renderValue={(selected) =>
                                        selected.name
                                      }
                                      label='Rating'
                                    >
                                      {tile.referredAppraisalSection.configObj?.selectableRatings?.tableData?.map(
                                        (e) => {
                                          return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                            .subSections[tileIndex]
                                            .referredAppraisalResponse
                                            ?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                        },
                                      )}
                                    </Select>
                                  </FormControl>
                                </>
                              )
                            }
                              </div>

                              <p className="q-title-values">Manager Feedback</p>


                              {(tile.configObj.doesThisQuestionHaveSelection ===
                                true ||
                                tile.configObj.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile.configObj.selectionType && (
                                  <>
                                    <div className="rateOuter">
                                      {tile.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className={`rate ${
                                                vertualFormPayload[
                                                  tabIndex
                                                ].subSections[
                                                  tileIndex
                                                ].assessmentResponseObjectDTO.selection.some(
                                                  (e) => e.id == item.id
                                                )
                                                  ? "active"
                                                  : ""
                                              } `}
                                              onClick={(event) =>
                                                {
                                                  tile.isSelectionEditable && 
                                                handleChangeVertualPayload(
                                                  item,
                                                  "selection",
                                                  tabIndex,
                                                  tileIndex,
                                                  null
                                                )
                                              }
                                              }
                                            >
                                              <p className="rateTitle">
                                                {item.name}
                                              </p>
                                              {vertualFormPayload[
                                                tabIndex
                                              ].subSections[
                                                tileIndex
                                              ].assessmentResponseObjectDTO.selection.some(
                                                (e) => e.id == item.id
                                              ) && (
                                                <img
                                                  src={Checked}
                                                  className="checked"
                                                ></img>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    {vertualError[tabIndex].subSections[
                                      tileIndex
                                    ].assessmentResponseObjectDTO.selection
                                      .isError && (
                                      <FormHelperText className="Mui-error">
                                        {
                                          vertualError[tabIndex].subSections[
                                            tileIndex
                                          ].assessmentResponseObjectDTO
                                            .selection.errorMessage
                                        }
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                              {(tile.configObj.doesThisQuestionHaveResponse ===
                                true ||
                                tile.configObj.doesThisQuestionHaveResponse ===
                                  "true") &&
                              tile.configObj.responseType === "String" ? (
                                <>
                                {/* this is original */}
                                {(status === 'ACTIVE') ? (  
                                // this is conditional for close loop 
                                // {{(status === 'ACTIVE' || true) ? ( 
                                    ((vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString?.length ?? 0) < 150) ? (
                                      <div className="accDetal-xFeedback">
                                        <div className="q-text like-text-field">
                                        { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString)}
                                        </div>
                                      </div>

                                    ) : (
                                      (vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id != readMoreId) ? (
                                        <div className="accDetal-xFeedback">
                                          <div className="q-text like-text-field">
                                          { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString?.substring(0, 140))}
                                          <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id)}>
                                              ...read more
                                          </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="accDetal-xFeedback">
                                          <div className="q-text like-text-field">
                                          { !isEmptyNullUndefined(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString)}
                                          <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id)}>
                                              read less
                                          </span>
                                          </div>
                                        </div>
                                      )
                                    )
                                ) : (
                                  <>
                                    {/* <TextField
                                      name="responseString"
                                      disabled={!tile.isResponseEditable}
                                      value={
                                        vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO
                                          .responseString
                                      }
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          "textField",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      id="outlined-multiline-static"
                                      label=""
                                      variant="outlined"
                                      placeholder={`Provide your feedback here ${
                                        tile.configObj.minCharacterLimit ||
                                        tile.configObj.maxCharacterLimit
                                          ? `(limit: ${
                                              tile.configObj.minCharacterLimit
                                                ? ` min: ${tile.configObj.minCharacterLimit}`
                                                : ""
                                            }  ${
                                              tile.configObj.maxCharacterLimit
                                                ? `max: ${tile.configObj.maxCharacterLimit} `
                                                : ""
                                            }char)`
                                          : ""
                                      }`}
                                      multiline
                                      rows={3}
                                      sx={textAreaStyle}
                                      error={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError
                                      }
                                      helperText={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError &&
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.errorMessage
                                      }
                                    />
                                    {
                                      !vertualError[tabIndex].subSections[
                                        tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError && (
                                          <FormHelperText
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row-reverse'
                                            }}
                                          >
                                            {vertualError[tabIndex].subSections[
                                              tileIndex
                                            ].assessmentResponseObjectDTO.responseString.errorMessage}
                                          </FormHelperText>
                                        )
                                    } */}

<div
                                                    style={{
                                                      marginTop: "2rem",
                                                    }}
                                                  >
                                                    <JoditEditor
                                                      ref={editor}
                                                      config={config}
                                                      name="responseString"
                                                      value={
                                                        vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO
                                                          .responseString
                                                      }
                                                      onBlur={(newContent) =>
                                                        handleChangeVertualPayload(
                                                          {
                                                            target: {
                                                              name: "responseString",
                                                              value: newContent,
                                                            },
                                                          },
                                                          "textField",
                                                          tabIndex,
                                                          tileIndex,
                                                          null
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {/* {
                                                  !vertualError[tabIndex].subSections[
                                                    tileIndex
                                                    ].assessmentResponseObjectDTO
                                                      .responseString.isError && (
                                                      <FormHelperText
                                                        sx={{
                                                          display: 'flex',
                                                          flexDirection: 'row-reverse',
                                                          mt:1
                                                        }}
                                                      >
                                                        {vertualError[tabIndex].subSections[
                                                          tileIndex
                                                        ].assessmentResponseObjectDTO.responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                }  */}
                                                { vertualError[tabIndex].subSections[
                                                    tileIndex
                                                  ].assessmentResponseObjectDTO
                                                    .responseString.isError && (
                                                      <FormHelperText
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection:"row",
                                                        justifyContent:'flex-start',
                                                        color:'#D32F2F',
                                                        fontSize:'12px',
                                                        mt:1
                                                      }}
                                                      >
                                                        {vertualError[tabIndex].subSections[
                                                            tileIndex
                                                          ].assessmentResponseObjectDTO
                                                            .responseString.isError &&
                                                          vertualError[tabIndex].subSections[
                                                            tileIndex
                                                          ].assessmentResponseObjectDTO
                                                            .responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                }
                                  </>
                                  
                                )}
                                </>
                              ) : (tile.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile.configObj.responseType === "Boolean" ? (
                                <>
                                  <Stack sx={{ width: "50%" }}>
                                    <RadioGroup
                                      name="responseBoolean"
                                      disabled={!tile.isResponseEditable}
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          .responseBoolean
                                      }
                                      onChange={(event) => {
                                        tile.isResponseEditable &&
                                        handleChangeVertualPayload(
                                          event,
                                          "ratio",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      }
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={"Yes"}
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={"No"}
                                      />
                                    </RadioGroup>
                                  </Stack>
                                  {vertualError[tabIndex].subSections[tileIndex]
                                    .assessmentResponseObjectDTO.responseBoolean
                                    .isError && (
                                    <FormHelperText className="Mui-error">
                                      {
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseBoolean.errorMessage
                                      }
                                    </FormHelperText>
                                  )}
                                </>
                              ) : (
                                (tile.configObj.doesThisQuestionHaveResponse ===
                                  true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile.configObj.responseType === "Integer" && (
                                  <>
                                    <TextField
                                      name="responseInteger"
                                      disabled={!tile.isResponseEditable}
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          .responseInteger
                                      }
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          "numberField",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      id="outlined-multiline-static"
                                      label=""
                                      variant="outlined"
                                      multiline
                                      placeholder="Provide your feedback here "
                                      rows={1}
                                      sx={textAreaStyle}
                                      error={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.isError
                                      }
                                      helperText={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.isError &&
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.errorMessage
                                      }
                                    />
                                  </>
                                )
                              )}
                              
                                {(tile.configObj.isRatingRequired === true ||
                                tile.configObj.isRatingRequired === 'true') && (
                                <>
                                  <FormControl
                                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                  >
                                    <InputLabel id='demo-simple-select-label'>
                                      Rating
                                    </InputLabel>
                                    <Select
                                      name='rating'
                                      disabled={!tile.isRatingEditable}
                                      labelId='demo-simple-select-label'
                                      id='demo-simple-select'
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          'dropDownSelect',
                                          tabIndex,
                                          tileIndex,
                                          null,
                                        )
                                      }
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          ?.rating
                                      }
                                      renderValue={(selected) =>
                                        selected.name
                                      }
                                      label='Rating'
                                      // disabled={isCloseLoop} // for close loop
                                    >
                                      {tile.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                        (e) => {
                                          return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                            .subSections[tileIndex]
                                            .assessmentResponseObjectDTO
                                            ?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                        },
                                      )}
                                    </Select>
                                    { vertualError[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                      .rating.isError && (
                                      <FormHelperText className='Mui-error'>
                                        {
                                           vertualError[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                            .rating.errorMessage
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </>
                              )}
                              <Divider sx={{
                                padding: '20px',
                                borderColor: 'rgba(0, 0, 0, 0.1490196078)',
                                opacity: '100%',
                              }} />
                            </>
                          )}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </div>
            </>
          ) : (
            <>

              {(((TabElement.configObj.doesThisQuestionHaveSelection === true ||
                TabElement.configObj.doesThisQuestionHaveSelection ===
                  "true") &&
                TabElement.configObj.selectionType) ||
                ((TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                  (TabElement.configObj.responseType === "String" ||
                    TabElement.configObj.responseType === "Boolean" ||
                    TabElement.configObj.responseType === "Integer")) || (
                      ((TabElement.configObj.isRatingRequired == true) || (TabElement.configObj.isRatingRequired === 'true'))
                    )) && (
                <div className="accDetail-questions">
                  <div style={{ display: "flex" }}>
                    {/* <p>{TabElement.configObj.label}</p> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: TabElement.configObj.label,
                      }}
                    ></div>
                    {(TabElement.configObj.isResponseMandatory === true ||
                      TabElement.configObj.isResponseMandatory === "true" ||
                      TabElement.configObj.isSelectionMandatory === true ||
                      TabElement.configObj.isSelectionMandatory === "true" ||
                      TabElement.configObj.isRatingMandatory === true ||
                      TabElement.configObj.isRatingMandatory === 'true'
                      ) && (
                      <div style={{ color: "red" }}>*</div>
                    )}
                  </div>
                    <div className="peer-info-container">
                      {(TabElement.configObj.isInfoRequired === true ||
                        TabElement.configObj.isInfoRequired === "true") &&
                        TabElement.configObj.info && (
                          <Tooltip title="Info" placement="top-end">
                            <img
                              onClick={(e) => {
                                // handleOpenSideDrawer(TabElement.configObj.info);
                                handleOpenviewProfileDrawer(TabElement.configObj.info, "info")
                                e.stopPropagation();
                              }}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              src={Info}
                              ></img>
                          </Tooltip>
                        )}
                        <div className="assesment-re-btn-in-mannager-feedback-container"> 
                          {/* {!isEmptyNullUndefined(tile?.referenceEmployeeSections) && <button className="assesment-re-btn-in-mannager-feedback mt-2" onClick={() => handleShowAssesment(tile?.referenceEmployeeSections)}>Assesment response</button> } */}
                          {!isEmptyNullUndefined(TabElement?.referencePeerSections) && <button className="assesment-re-btn-in-mannager-feedback" onClick={() => handleShowPeerFeedback(TabElement?.referencePeerSections)}>Peer Feedback</button> }
                        </div>
                    </div>
                </div>
              )}

                      {
                        !isEmptyNullUndefined(TabElement?.referenceEmployeeSections) && 
                        <div className="self-assesment-inmanager-feedback">
                          <EmpAssesmentStatic data={TabElement?.referenceEmployeeSections} />
                        </div>
                        }

                {/* {(((TabElement.configObj.doesThisQuestionHaveSelection === true ||
                  TabElement.configObj.doesThisQuestionHaveSelection ===
                    "true") &&
                  TabElement.configObj.selectionType) ||
                  ((TabElement.configObj.doesThisQuestionHaveResponse === true ||
                    TabElement.configObj.doesThisQuestionHaveResponse ===
                      "true") &&
                    (TabElement.configObj.responseType === "String" ||
                      TabElement.configObj.responseType === "Boolean" ||
                      TabElement.configObj.responseType === "Integer")) || (
                      ((TabElement.configObj.isRatingRequired == true) || (TabElement.configObj.isRatingRequired === 'true'))
                    )) && (

                      <p className="q-title-values">Employee Self Appraisal</p>

                    )} */}


              <div className="disabledInput">

              

                {(TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveSelection === true ||
                  TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveSelection ===
                    "true") &&
                  TabElement.referredAppraisalSection.configObj.selectionType && (
                    <>
                      <div className="rateOuter Self-Appraisal-desibled">
                        {TabElement.referredAppraisalSection.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                          (item, index) => {
                            return (
                              <div
                                className={`rate ${
                                  vertualFormPayload[
                                    tabIndex
                                  ].referredAppraisalResponse?.selection.some(
                                    (e) => e.id == item.id
                                  )
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <p className="rateTitle">{item.name}</p>
                                {vertualFormPayload[
                                  tabIndex
                                ].referredAppraisalResponse?.selection.some(
                                  (e) => e.id == item.id
                                ) && (
                                  <img src={Checked} className="checked"></img>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                      
                    </>
                  )
                }

                {/* //////////////////////////////////////////////// */}

                {(TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse === true ||
                  TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse === "true") &&
                TabElement?.referredAppraisalSection?.configObj?.responseType === "String" ? (
                  <>
                                                            
                    {/* <TextField
                      name="responseString"
                      value={
                        vertualFormPayload[tabIndex].referredAppraisalResponse
                          .responseString
                      }
                      id="outlined-multiline-static"
                      label=""
                      variant="outlined"
                      placeholder={`Provide your feedback here ${
                        TabElement.referredAppraisalSection.configObj.minCharacterLimit ||
                        TabElement.referredAppraisalSection.configObj.maxCharacterLimit
                          ? `(limit: ${
                              TabElement.referredAppraisalSection.configObj.minCharacterLimit
                                ? ` min: ${TabElement.referredAppraisalSection.configObj.minCharacterLimit}`
                                : ""
                            }  ${
                              TabElement.referredAppraisalSection.configObj.maxCharacterLimit
                                ? `max: ${TabElement.referredAppraisalSection.configObj.maxCharacterLimit} `
                                : ""
                            }char)`
                          : ""
                      }`}
                      multiline
                      rows={3}
                      sx={textAreaStyle}
                    /> */}
                    {
                      (params?.isappraisalsubmitted == 'no') ? (
                        <div className="accDetal-xFeedback">
                          <div className="q-text like-text-field Self-Appraisal-desibled">
                            Self appraisal is not submitted yet
                          </div>
                        </div>
                      ) : (
                          ((vertualFormPayload[tabIndex].referredAppraisalResponse?.responseString?.length ?? 0) < 150) ? (
                              <div className="accDetal-xFeedback">
                                <div className="q-text like-text-field Self-Appraisal-desibled">
                                {/* {parse(vertualFormPayload[tabIndex].referredAppraisalResponse?.responseString)} */}
                                </div>
                              </div>
      
                          ) : (
                            (vertualFormPayload[tabIndex].referredAppraisalResponse.id != readMoreId) ? (
                              <div className="accDetal-xFeedback">
                                <div className="q-text like-text-field Self-Appraisal-desibled">
                                {/* {parse(vertualFormPayload[tabIndex].referredAppraisalResponse?.responseString.substring(0, 140))} */}
                                <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].referredAppraisalResponse.id)}>
                                    ...read more
                                </span>
                                </div>
                              </div>
                            ) : (
                              <div className="accDetal-xFeedback">
                                <div className="q-text like-text-field Self-Appraisal-desibled">
                                {/* {parse(vertualFormPayload[tabIndex].referredAppraisalResponse?.responseString)} */}
                                <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].referredAppraisalResponse.id)}>
                                    read less
                                </span>
                                </div>
                              </div>
                            )
                          )

                      )
                    
                    
                    }
                  </>
                ) : (TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse === true ||
                    TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse ===
                      "true") &&
                  TabElement.referredAppraisalSection.configObj.responseType === "Boolean" ? (
                  <>
                    <Stack sx={{ width: "50%" }}>
                      <RadioGroup
                        name="responseBoolean"
                        value={
                          vertualFormPayload[tabIndex].referredAppraisalResponse
                            .responseBoolean
                        }
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={"Yes"}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={"No"}
                        />
                      </RadioGroup>
                    </Stack>
                  </>
                ) : (
                  (TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse === true ||
                    TabElement?.referredAppraisalSection?.configObj?.doesThisQuestionHaveResponse ===
                      "true") &&
                  TabElement.referredAppraisalSection.configObj.responseType === "Integer" && (
                    <>
                      <TextField
                        name="responseInteger"
                        value={
                          vertualFormPayload[tabIndex].referredAppraisalResponse
                            .responseInteger
                        }
                        id="outlined-multiline-static"
                        label=""
                        variant="outlined"
                        multiline
                        placeholder="Provide your feedback here "
                        rows={1}
                        sx={textAreaStyle}
                      />
                    </>
                  )
                )}

                {/* //////////////////////////////////////////////// */}

                {(TabElement?.referredAppraisalSection?.configObj?.isRatingRequired === true ||
                TabElement?.referredAppraisalSection?.configObj?.isRatingRequired === 'true') && (
                <>
                  <FormControl
                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}} 
                  >
                    <InputLabel id='demo-simple-select-label'>
                      Rating
                    </InputLabel>
                    <Select
                      name='rating'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={
                        vertualFormPayload[tabIndex].referredAppraisalResponse
                          ?.rating
                      }
                      renderValue={(selected) =>
                        selected.name
                      }
                      label='Rating'
                    >
                      {TabElement.referredAppraisalSection.configObj?.selectableRatings?.tableData?.map(
                        (e) => {
                          return <MenuItem style={{background: (vertualFormPayload[tabIndex].referredAppraisalResponse?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                        },
                      )}
                    </Select>
                  </FormControl>
                </>
              )}

              </div>

              <p className="q-title-values">Manager Feedback</p>

              
              {(TabElement.configObj.doesThisQuestionHaveSelection === true ||
                TabElement.configObj.doesThisQuestionHaveSelection ===
                  "true") &&
                TabElement.configObj.selectionType && (
                  <>
                    <div className="rateOuter">
                      {TabElement.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                        (item, index) => {
                          return (
                            <div
                              className={`rate ${
                                vertualFormPayload[
                                  tabIndex
                                ].assessmentResponseObjectDTO.selection.some(
                                  (e) => e.id == item.id
                                )
                                  ? "active"
                                  : ""
                              } `}
                              onClick={(event) =>
                                {
                                  TabElement.isSelectionEditable && 
                                handleChangeVertualPayload(
                                  item,
                                  "selection",
                                  tabIndex,
                                  null,
                                  null
                                )
                              }
                              }
                            >
                              <p className="rateTitle">{item.name}</p>
                              {vertualFormPayload[
                                tabIndex
                              ].assessmentResponseObjectDTO.selection.some(
                                (e) => e.id == item.id
                              ) && (
                                <img src={Checked} className="checked"></img>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .selection.isError && (
                      <FormHelperText className="Mui-error">
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .selection.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </>
                )}
              {(TabElement.configObj.doesThisQuestionHaveResponse === true ||
                TabElement.configObj.doesThisQuestionHaveResponse === "true") &&
              TabElement.configObj.responseType === "String" ? (
                <>
                {(status === 'ACTIVE') ? (
                  ((vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString?.length ?? 0) < 150) ? (
                    <div className="accDetal-xFeedback">
                      <div className="q-text like-text-field">
                      { !isEmptyNullUndefined(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString)}
                      </div>
                    </div>

                  ) : (
                    (vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id != readMoreId) ? (
                      <div className="accDetal-xFeedback">
                        <div className="q-text like-text-field">
                        {!isEmptyNullUndefined(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString?.substring(0, 140))}
                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id)}>
                            ...read more
                        </span>
                        </div>
                      </div>
                    ) : (
                      <div className="accDetal-xFeedback">
                        <div className="q-text like-text-field">
                        { !isEmptyNullUndefined(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString) && parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString)}
                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id)}>
                            read less
                        </span>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <>
                    {/* <TextField
                      name="responseString"
                      disabled={!TabElement.isResponseEditable}
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          "textField",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      id="outlined-multiline-static"
                      label=""
                      variant="outlined"
                      placeholder={`Provide your feedback here ${
                        TabElement.configObj.minCharacterLimit ||
                        TabElement.configObj.maxCharacterLimit
                          ? `(limit: ${
                              TabElement.configObj.minCharacterLimit
                                ? ` min: ${TabElement.configObj.minCharacterLimit}`
                                : ""
                            }  ${
                              TabElement.configObj.maxCharacterLimit
                                ? `max: ${TabElement.configObj.maxCharacterLimit} `
                                : ""
                            }char)`
                          : ""
                      }`}
                      multiline
                      rows={3}
                      sx={textAreaStyle}
                      error={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.isError
                      }
                      helperText={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.isError &&
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.errorMessage
                      }
                    />
                    {
                      !vertualError[tabIndex].assessmentResponseObjectDTO
                        .responseString.isError && (
                          <FormHelperText
                            sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse'
                            }}
                          >
                            {vertualError[tabIndex].assessmentResponseObjectDTO.responseString.errorMessage}
                          </FormHelperText>
                        )
                    } */}

                                              <div
                                                    style={{
                                                      marginTop: "2rem",
                                                    }}
                                                  >                              
                                                    <JoditEditor
                                                      ref={editor}
                                                      config={config}
                                                      name="responseString"
                                                      value={
                                                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.responseString
                                                      }
                                                      onBlur={(newContent) =>
                                                        handleChangeVertualPayload(
                                                          {
                                                            target: {
                                                              name: "responseString",
                                                              value: newContent,
                                                            },
                                                          },
                                                          "textField",
                                                          tabIndex,
                                                          null,
                                                          null
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {/* {
                                                    !vertualError[tabIndex].assessmentResponseObjectDTO
                                                      .responseString.isError && (
                                                        <FormHelperText
                                                          sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row-reverse',
                                                            mt:1
                                                          }}
                                                        >
                                                          {vertualError[tabIndex].assessmentResponseObjectDTO.responseString.errorMessage}
                                                        </FormHelperText>
                                                      )
                                                  } */}
                                                {vertualError[tabIndex].assessmentResponseObjectDTO
                                                  .responseString.isError && (
                                                      <FormHelperText
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection:"row",
                                                        justifyContent:'flex-start',
                                                        color:'#D32F2F',
                                                        fontSize:'12px',
                                                        mt:1
                                                      }}
                                                      >
                                                        {vertualError[tabIndex].assessmentResponseObjectDTO
                                                            .responseString.isError &&
                                                          vertualError[tabIndex].assessmentResponseObjectDTO
                                                            .responseString.errorMessage}
                                                      </FormHelperText>
                                                    )
                                                }
                  
                  </>
                  
                )}
                </>
              ) : (TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                TabElement.configObj.responseType === "Boolean" ? (
                <>
                  <Stack sx={{ width: "50%" }}>
                    <RadioGroup
                      name="responseBoolean"
                      disabled={!TabElement.isResponseEditable}
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .responseBoolean
                      }
                      onChange={(event) =>
                        {
                          TabElement.isResponseEditable &&
                        handleChangeVertualPayload(
                          event,
                          "ratio",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      }
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .responseBoolean.isError && (
                      <FormHelperText className="Mui-error">
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .responseBoolean.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </Stack>
                </>
              ) : (
                (TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                TabElement.configObj.responseType === "Integer" && (
                  <>
                    <TextField
                      name="responseInteger"
                      disabled={!TabElement.isResponseEditable}
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .responseInteger
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          "numberField",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      id="outlined-multiline-static"
                      label=""
                      variant="outlined"
                      multiline
                      placeholder="Provide your feedback here "
                      rows={1}
                      sx={textAreaStyle}
                      error={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.isError
                      }
                      helperText={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.isError &&
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.errorMessage
                      }
                    />
                  </>
                )
              )}
              
                {(TabElement.configObj.isRatingRequired === true ||
                TabElement.configObj.isRatingRequired === 'true') && (
                <>
                  <FormControl
                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                  >
                    <InputLabel id='demo-simple-select-label'>
                      Rating
                    </InputLabel>
                    <Select
                      name='rating'
                      disabled={!TabElement.isRatingEditable}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          ?.rating
                      }
                      renderValue={(selected) =>
                        selected.name
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          'dropDownSelect',
                          tabIndex,
                          null,
                          null,
                        )
                      }
                      label='Rating'
                    >
                      {TabElement.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                        (e) => {
                          return <MenuItem style={{background: (vertualFormPayload[tabIndex].assessmentResponseObjectDTO?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                        },
                      )}
                    </Select>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .rating.isError && (
                      <FormHelperText className='Mui-error'>
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .rating.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}


              <Divider sx={{
                padding: '20px',
                borderColor: 'rgba(0, 0, 0, 0.1490196078)',
                opacity: '100%',
              }} />
            </>
          )}
        </div>
      ))
    }
  };

  const styleSubmit = () => {
    if(status === 'ACTIVE') {
      return ({
        pointerEvents: 'none',
        opacity: '50%',
        transition: 'opacity 0.3s',
        visibility: 'hidden',
      })
    } else {
      if(
        // isSubmitDisabled || 
        submitLoader) {
        return ({
          pointerEvents: 'none',
          opacity: '50%',
          transition: 'opacity 0.3s'
        })
      } else {
        return ({
          pointerEvents: 'auto',
          opacity: '100%',
          transition: 'opacity 0.3s'
        })
      }
    }
  }

  const styleSaveAsDraft = () => {
    if(status === 'ACTIVE') {
      return ({
        pointerEvents: 'none',
        opacity: '50%',
        transition: 'opacity 0.3s',
        visibility: 'hidden',
      })
    } else {
      if(submitLoader) {
        return ({
          pointerEvents: 'none',
          opacity: '50%',
          transition: 'opacity 0.3s'
        })
      } else {
        return ({
          pointerEvents: 'auto',
          opacity: '100%',
          transition: 'opacity 0.3s'
        })
      }
    }
  }

  const sideDrawer = () => {
    return (
      isOpenDrawer && (
        <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
          
            {
              viewProfileDrawerData?.profile &&
              // <EmpProfile empInfo={viewProfileDrawerData?.data} />
              <EmpProfile empInfo={drawerData} />
            }
            {viewProfileDrawerData?.info && (
              <div className="info">

              <div dangerouslySetInnerHTML={{ __html: drawerData }} />
              </div>
            )}
            {viewProfileDrawerData?.peer && (
              <div className="sidedrawer-peer-info feedbackAssessment">
                {/* {console.log('viewProfileDrawerData',viewProfileDrawerData)} */}
              <div className="card-title">Feedback <span>({viewProfileDrawerData?.data?.peers?.feedbackComments.length ?? 0})</span></div>
              <div className="description">
                {<div
                  dangerouslySetInnerHTML={{
                    __html: viewProfileDrawerData.data.label,
                  }}
                ></div>}
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of tyLorem Ipsum is simply dummy  */}
              </div>
              
              {(viewProfileDrawerData?.data?.peers?.feedbackComments.length > 0) ? (
                  viewProfileDrawerData.data.peers.feedbackComments.map(element => {
                    return (
                      <div className="peer-card">
                    <div className="emp-details">
                      <div className="empDetaila">
                      <div className="empPic">
                        {false ? 
                            <img src={element?.profilePhotoPath} className="userImg" alt="User Image" />
                            :
                            <Avatar 
                                name={element?.employeeName} 
                                size="30" 
                                className="userImg"
                                color={"#00425A"}    
                            />
                        }
                        </div>
                        <div className="userInfo">
                            <p className="name">{element?.employeeName}</p>
                            <p className="designation">{element?.employeeDesignation}</p>
                        </div>
                        </div>
                        <div className="time">{getFormattedDate(element?.lastModifiedDate, dateFormat)}</div>
                      </div>
                    <div className="emp-feedback disabledInput">
    
                      {/* ////////////////////// */}
    
                    {(viewProfileDrawerData.data.peers?.configObj?.doesThisQuestionHaveSelection === true ||
                      viewProfileDrawerData.data.peers?.configObj?.doesThisQuestionHaveSelection ===
                        "true") &&
                        viewProfileDrawerData.data.peers?.configObj.selectionType && (
                        <>
                          <div className="rateOuter">
                            {viewProfileDrawerData?.data?.peers?.configObj?.selectableValues.sort((a, b) => a?.id.localeCompare(b?.id))?.map(
                              (item, index) => {
                                return (
                                  <div
                                    className={`rate ${
                                      element.responseObject.selection.some(
                                        (e) => e?.id == item?.id
                                      )
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    <p className="rateTitle">{item.name}</p>
                                    {element?.responseObject?.selection.some(
                                      (e) => e?.id == item?.id
                                    ) && (
                                      <img src={Checked} className="checked"></img>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          
                        </>
                      )
                    }
    
                    {/* ///////////////////// */}
    
                    {(viewProfileDrawerData?.data?.peers?.configObj?.doesThisQuestionHaveResponse === true ||
                    viewProfileDrawerData?.data?.peers.configObj.doesThisQuestionHaveResponse === "true") &&
                  viewProfileDrawerData.data.peers.configObj.responseType === "String" ? (
                    <>
                      {/* <TextField
                        name="responseString"
                        value={
                          element.responseObject.responseString
                        }
                        id="outlined-multiline-static"
                        label=""
                        variant="outlined"
                        placeholder={`Provide your feedback here ${
                          viewProfileDrawerData.data.peers?.configObj.minCharacterLimit ||
                          viewProfileDrawerData.data.peers?.configObj.maxCharacterLimit
                            ? `(limit: ${
                                viewProfileDrawerData.data.peers?.configObj.minCharacterLimit
                                  ? ` min: ${viewProfileDrawerData.data.peers?.configObj.minCharacterLimit}`
                                  : ""
                              }  ${
                                viewProfileDrawerData.data.peers?.configObj.maxCharacterLimit
                                  ? `max: ${viewProfileDrawerData.data.peers?.configObj.maxCharacterLimit} `
                                  : ""
                              }char)`
                            : ""
                        }`}
                        multiline
                        rows={3}
                        sx={textAreaStyle}
                      /> */}
                      {((element.responseObject?.responseString?.length ?? 0) < 150) ? (
                          <div className="accDetal-xFeedback">
                            <div className="q-text like-text-field">
                            {/* {parse(element.responseObject?.responseString)} */}
                            </div>
                          </div>
    
                      ) : (
                        (element.responseObject.id != readMoreId) ? (
                          <div className="accDetal-xFeedback">
                            <div className="q-text like-text-field">
                            {/* {parse(element.responseObject?.responseString?.substring(0, 140))} */}
                            <span className="readmore-text" onClick={() => handleReadMore(element.responseObject.id)}>
                                ...read more
                            </span>
                            </div>
                          </div>
                        ) : (
                          <div className="accDetal-xFeedback">
                            <div className="q-text like-text-field">
                            {/* {parse(element.responseObject?.responseString)} */}
                            <span className="readmore-text" onClick={() => handleReadMore(element.responseObject.id)}>
                                read less
                            </span>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (viewProfileDrawerData.data.peers?.configObj.doesThisQuestionHaveResponse === true ||
                      viewProfileDrawerData.data.peers?.configObj.doesThisQuestionHaveResponse ===
                        "true") &&
                    viewProfileDrawerData.data.peers?.configObj.responseType === "Boolean" ? (
                    <>
                      <Stack sx={{ width: "50%" }}>
                        <RadioGroup
                          name="responseBoolean"
                          value={
                            element.responseObject.responseBoolean
                          }
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={"Yes"}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={"No"}
                          />
                        </RadioGroup>
                      </Stack>
                    </>
                  ) : (
                    (viewProfileDrawerData.data.peers?.configObj.doesThisQuestionHaveResponse === true ||
                      viewProfileDrawerData.data.peers?.configObj.doesThisQuestionHaveResponse ===
                        "true") &&
                    viewProfileDrawerData.data.peers?.configObj.responseType === "Integer" && (
                      <>
                        <TextField
                          name="responseInteger"
                          value={
                            element.responseObject.responseInteger
                          }
                          id="outlined-multiline-static"
                          label=""
                          variant="outlined"
                          multiline
                          placeholder="Provide your feedback here "
                          rows={1}
                          sx={textAreaStyle}
                        />
                      </>
                    )
                  )}
    
                  {/* /////////////////////////// */}
    
                      {(viewProfileDrawerData?.data?.peers?.configObj.isRatingRequired === true ||
                        viewProfileDrawerData?.data?.peers?.configObj.isRatingRequired === 'true') && (
                        <>
                          <FormControl
                            sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                          >
                            <InputLabel id='demo-simple-select-label'>
                              Rating
                            </InputLabel>
                            <Select
                              name='rating'
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={
                                element.responseObject?.rating
                              }
                              renderValue={(selected) =>
                                selected.name
                              }
                              label='Rating'
                            >
                              {viewProfileDrawerData.data.peers?.configObj?.selectableRatings?.tableData?.map(
                                (e) => {
                                  return <MenuItem style={{background: (element.responseObject?.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                },
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}
    
                      {/* //////////////// */}
                    </div>
                      </div>
                    )
                  })

              ) : (
                <>No Peer feedback received.</>
              )
              }

              {/* <div className="peer-card">
                <div className="emp-details">
                  <div className="empDetaila">
                  <div className="empPic">
                    {false ? 
                        <img src="" className="userImg" alt="User Image" />
                        :
                        <Avatar 
                            name={"Harry mustain"} 
                            size="30" 
                            className="userImg"
                            color={"#00425A"}    
                        />
                    }
                    </div>
                    <div className="userInfo">
                        <p className="name">Harry mustain</p>
                        <p className="designation">liver</p>
                    </div>
                    </div>
                    <div className="time">5 min ago</div>
                  </div>
                <div className="emp-feedback">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of tyLorem Ipsum is simply dummy 
                </div>
              </div> */}
              
            </div>


            )}
            {/* <EmpProfile empInfo={drawerData} /> */}
        </SideDrawer>
      )
    )
  }

  return (
    <div className="selfAssesmentAllinone">
      <div className="feedback-main">
        
        {isLoading ? (
          <AccSkeleton />
        ) : (
          <div className="feedbackAssessment m-3">
            {/* ////////////// */}
            {empInfo && !isViewOnly &&
            <div className={`d-flex justify-content-between align-items-center bor-b sticky-row-manager-feedback ${window.location.pathname.split("/").includes("manager-feedBack") ? "" : "feedback-at-other-pages"}`}>
                <div className="left d-flex align-items-center gap-3">
                    {empInfo?.profilePhoto ?
                        <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
                        :
                        <Avatar 
                            name={empInfo?.name} 
                            size="75" 
                            className="userImg"
                            color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                        />
                    }
                        <div>
                            <p className="title">{empInfo?.name}</p>
                            <p className="id">{empInfo?.employeeDesignation}</p>
                        </div>
                </div>
                <div className="btns-tops">    
                {/* <div className="view-old-feedback-in"> */}
                  {/* as we are removing old questions API, so we will not be able to view old feedback will not be able, that is why we are hiding this button for now */}
                  {/* <button onClick={() => handleViewPreFeedback()}>View Feedback</button> */} 
                  {/* <div className="assesment-re-btn-in-mannager-feedback-container">  */}
                          {!isEmptyNullUndefined(formData?.assessmentFormDTO?.referenceEmployeeSections) && <button className="viewBtn" onClick={() => handleShowAssesment(formData?.assessmentFormDTO?.referenceEmployeeSections)}>Self Assesment</button> }
                          {!isEmptyNullUndefined(formData?.assessmentFormDTO?.referencePeerSections) && <button className="viewBtn" onClick={() => handleShowPeerFeedback(formData?.assessmentFormDTO?.referencePeerSections)}>Peer Feedback</button> }
                        {/* </div> */}
              {/* </div>                             */}
                <button className="viewBtn" onClick={() => {
                  handleOpenviewProfileDrawer(empInfo, "profile")
                  setdrawerData(empInfo)
                  setIsOpenDrawer(true)
                }}
                >View Profile</button>
                </div>
            </div>}
            {/* <div className="peer-feed-btn" onClick={() => handleOpenviewProfileDrawer(null, 'peer')}>Peer Feedback</div> */}
            <>
            
              {
                !checkPdfLoader ? 
                <>
                  {
                  isPdfGenerated ?
                  <>
                    {
                      getPDFLoader ? 
                      (
                          <CircularProgress />
                      )
                      :
                      (
                          pdfUrl &&
                          <div className="pdf-viewer">
                              <iframe
                                  src={pdfUrl}
                                  width="100%"
                                  height="600px"
                                  title="PDF Viewer"
                              />
                          </div>
                      )
                      }
                    </>
                
                :
                <>
                 
                  {
                    (formData.assessmentFormDTO || formData) &&
                    <>
                    <div className="form-builder-portal-form-header">{formData?.assessmentFormDTO?.displayName}</div> 
                      {
                       createDynamicForm(
                        formData.assessmentFormDTO
                          ? formData.assessmentFormDTO
                          : formData
                      )}
                    </>
                  }
                </>
                }
                </>
                :
                <>
                <CircularProgress />
                </>
                }
            </>

            <> 
              {
                (files.length > 0) && (
                  <div style={{
                    width: '50%',
                    // height: '13rem',
                    position: 'relative',

                    fontSize: '0.65rem',

                    minHeight: '15rem',
                    paddingBottom: '5rem',
                  }} className="upload-document-container" >
                    <div style={{
                      borderBottom: '1px solid #dad2d2',
                      justifyContent: 'center',
                      marginTop: '1rem',
                      display: 'flex',
                    }}>
                    Attachment's
                    </div>
                    

                    {(files.length > 0) && <div style={{
                      
                    }} className="card-container">

                      <>
                        <div 
                        
                          style={{
                            display: 'flex',
                            width: '100%',
                            overflow: 'auto',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            columnGap: '10px',
                            rowGap: '10px',
                            marginTop: '1rem',
                            cursor: "pointer"
                          }} 
                          class="doc-distibute"
                          
                        >
                          {
                            files.map((e, index) => (
                              <>
                                <div style={{ marginRight: 0,}} onClick={() => openDoc(index)} className="documents">
                                  <div className="documents-name">
                                    {/* {e?.fname} */}
                                    {e?.orignalFileName}
                                  </div>
                                  <img style={{width:"0.75rem"}} src={downloadIcon} alt="" />
                                </div>
                              </>
                            ))
                          }
                        </div>
                      </>

                    </div>}

                  </div>

                )
              }
            </>


            <div className="d-flex justify-content-end gap-3 pt-5 main-btn">
              <div className="valuesBtn draft" style={styleSaveAsDraft()}>Auto Save 10 sec</div>
              <button
                style={styleSaveAsDraft()}
                className="valuesBtn save-draft"
                onClick={() => handleSaveAsDraft('submitAsDraft')}
              >
                {submitLoader ? (
                  <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                ) : (
                  "Save as draft"
                )}
              </button>
              <button
                style={styleSubmit()}
                className="valuesBtn next"
                onClick={() => handleValidate()}
              >
                {submitLoader ? (
                  <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
        {sideDrawer()}

        {
            showConfirmationPopup && 
            <DialogPopup openDialog={showConfirmationPopup} setOpenDialog={setShowConfirmationPopup} redirect={false} >
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                Are you sure you want to submit? Once submitted you will not be able to Edit or make any further changes. Please click on Confirm to proceed or Cancel to edit.
                {/* Thank you for completing the appraisal. Your feedback is important. Please note that once submitted, revisions will not be feasible. Click 'Confirm' if you intend to proceed. If you want to further edit or revise, click 'Cancel' */}
                </div>
                <div className="multi-btn" >
                <button className="button"
                    onClick={() => {
                      // if(isDraftPopup){
                      //   handleSubmitDraft()
                      // } else {
                        handleSubmitFeedback()
                      // }
                    }}
                >Confirm</button>
                <button className="button"
                onClick={() => setShowConfirmationPopup(false)}
                >Cancel</button>
                </div>
                </div>
            </DialogPopup>
        }

        {openDialog && (
          <DialogPopup
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            redirect={true}
            path="/my-team"
          >
            <div className="feedback-dialog-wrap">
              <img src={checkIcon} className="checkIcon"></img>
              <div className="content">
              {!isDraftPopup ? 'You have successfully submitted your feedback' : 'You have successfully saved this as a draft.'}
              </div>
              <button
                className="button"
                onClick={() => {
                  setOpenDialog(false)
                  if(isDraftPopup) {
                    setIsDraftPopup(false)
                    history.push("/my-team");
                  } else {
                    history.push("/my-team");
                  }
                }}
              >
                Done
              </button>
            </div>
          </DialogPopup>
        )}
        
      </div>
{
    openOldFeedback && 
    <div className="old-feedback-show-manager-in-sidebar">
        <SideDrawer isOpen={openOldFeedback} setIsOpen={setOpenOldFeedback} >
            <EmpOldFeedback feedbackStatus={"Submitted"} employeeId={empInfo?.id} />
        </SideDrawer>
    </div>
}
{
    isShowAssesmentResponse && 
        <SideDrawer isOpen={isShowAssesmentResponse} setIsOpen={setIsShowAssesmentResponse} >
            <EmpAssesmentStatic data={assesmentDataToShowInSidebar} />
        </SideDrawer>
}

{
    isShowPeerResponse && 
        <SideDrawer isOpen={isShowPeerResponse} setIsOpen={setIsShowPeerResponse} >
            <PeerFeedbackStatic data={peerDataToShowInSidebar} />
        </SideDrawer>
}
      
    </div>
  );
};

export default ManagerFeedback;
