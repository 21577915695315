
import { useState } from "react";
import UpArrow from "../../../../assets/images/upArrow.svg"
import Checked from "../../../../assets/images/checked.svg"
import Alert from "../../../../assets/images/alert.svg"
import Setting from "../../../../assets/images/setting.svg"
import BackArrow from "../../../../assets/images/backArrowSelf.svg"

const UserProfilePage = () => {

    const [expanded, setExpanded] = useState(null);

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    return(
        <div className="selfAssessment user-profile-page px-10 container">
        {/* <div className="page-header-main-heading">Self Assesment</div> */}
             {/* <div className="d-flex justify-content-between align-items-center headingPage">
                <div className="d-flex align-items-center gap-3">
                    <img src={BackArrow} className="backArrow"></img>
                    <p className="userName">Self Assesment</p>
                </div>
                <div>
                    <img src={Alert} className="alert"></img>
                    <img src={Setting} className="setting"></img>
                </div>
            </div> */}
            <div className="row">
                
               
               
            <div className="feedbackAssessment m-3">
                        {/* <div className="d-flex justify-content-between align-items-center bor-b">
                            <div className="left d-flex align-items-center gap-3">
                                <img src={User} className="userImg"></img>
                                <div>
                                    <p className="title">Micheal william</p>
                                    <p className="id">Employee Id - 234</p>
                                </div>
                            </div>
                            <button className="viewBtn">View Assesment</button>
                        </div> */}
                        {/* <p className="heading">OKR</p> */}
                        <div className="trendingRating p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="title"></p>
                                <img src={UpArrow} ></img>
                            </div>
                            <div className="rateOuter">
                                <div className="rate active">
                                    <p className="rateTitle">Outstanding</p>
                                    <img src={Checked} className="checked"></img> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Fantastic</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Average</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                               
                            </div>
                            <div className="rateOuter">
                                <div className="rate active">
                                    <p className="rateTitle">Outstanding</p>
                                    <img src={Checked} className="checked"></img> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Fantastic</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Average</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                                <div className="rate">
                                    <p className="rateTitle">Poor</p> 
                                </div>
                               
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
};

export default UserProfilePage; 