import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Popover, Select, Skeleton, Stack, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import { Search, SearchIconWrapper, accordianSVGstyle, getFormattedDate, getFormattedTime, isEmptyNullUndefined, textAreaStyle } from "../../../utils/utils.js";
import { dummy } from "../../requestFeedback/dummyData.js/index.js";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Avatar from "react-avatar";
import { IoMdSearch } from "react-icons/io";
import { RiAccountCircleFill, RiErrorWarningFill } from "react-icons/ri";
import scheduleImg from "../../../../assets/images/ratingCalib/addSession/schedule.png";
import hrbpImg from "../../../../assets/images/ratingCalib/addSession/hrbp.png";
import DialogPopup from "../../../common/Dialog/index.js";
import checkIcon from "../../../../assets/images/popup-check.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { IoCloseSharp } from "react-icons/io5";
import APIList from "../../../../api/index.js";
import { useSelector } from "react-redux";
import DateTimePicker from "../../../common/dateTimePicker/index.js";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";
import moment from "moment";
import HostSession from "./components/hostSession/index.js";
import Cohort from "./components/cohort/index.js";
import Panelist from "./components/panelist/index.js";

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    // border:"1px solid var(--primary)",
    boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.2rem !important",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
      width: '20rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 

const ViewSession = () => {

    const history = useHistory();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [expanded, setExpanded] = useState("");
    const [openDialog, setOpenDialog] = useState(false);


    const [search, setSearch] = useState("");
    const [searchedList, setsearchedList] = useState(null);
    const [searchLoader, setSearchLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [submitDraftLoader, setSubmitDraftLoader] = useState(false);
    const [value, setValue] = useState(null);

    const [initialEmptyPayload, setInitialEmptyPayload] = useState({
       
            name : null,
            organiser : {
                id : employeeDetails?.id
            },
            coHost: null,
            description : null,
            startTime : null,
            endTime : null,
            // meetingLink : null,
            status: "ACTIVE",
            participants : [],
            coHortEmployees: [],

        
    });

    const [sessionPayloadErr, setSessionPayloadErr] = useState({
       
            name : {isError: false, errMsg: ""},
            coHost : {isError: false, errMsg: ""},
            // description : {isError: false, errMsg: ""},
            startTime : {isError: false, errMsg: ""},
            endTime : {isError: false, errMsg: ""},
            // meetingLink : {isError: false, errMsg: ""},
            participants : {isError: false, errMsg: ""},
            coHortEmployees : {isError: false, errMsg: ""},
        
    });

    const [sessionPayload, setSessionPayload] = useState(null);
console.log(sessionPayload)
    // start for pop-over
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if(!isEmptyNullUndefined(location?.state?.id)){
            APIList.getSessionById({id: location?.state?.id})
        .then((res) => {
            setSessionPayload(res?.data)
            // console.log("ress", res)
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            history.push("/hrb")
        }) 
        } else {
            setSessionPayload(initialEmptyPayload);
        }
        
    }, [employeeDetails])
    

    // useEffect(() => {
    //     // setFilterPayload({});         
    //     // setCurrentPage(0);
    //         setSearchLoader(true);
    //     const getSearch = setTimeout(() => {
    //         // if(activeTab === "send") {
    //             // setIsSearch(true);
    //             if(!isEmptyNullUndefined(search)){
    //             APIList.getFilteredSearchPeers({
    //                 payload : {
    //                     employeeEligibilityDTO : {},
    //                     keyword : search,
    //                     employeeId: employeeDetails?.id,
    //                     managerId: employeeDetails?.manager?.id,
    //                     companyId : employeeDetails?.company?.id
    //                 },
    //                 page: 0,
    //                 size: 10000
    //             })  
    //             .then((res) => {
    //                 setsearchedList(res?.data?.data);
    //                 // setTotalPages(res?.data?.totalPages);
    //                 setSearchLoader(false);
    //                 // console.log(res)
    //             })
    //             .catch((err) => {
    //                 // console.log(err);
    //                 setSearchLoader(false);
    //             }
    //             )
    //         // }         
    //         } else {
    //             setsearchedList(null);
    //             setSearchLoader(false);
    //         }
              
    //     }, 500);    

    //     return () => clearTimeout(getSearch)
    //   }, [search])

  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // end for pop-over

    const handleChange = (index) => {
        // setCurrentPage(0);
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    }

    const autoSearchText = (e) => {
        // setCurrentPage(0);
        setSearch(e.target.value);
      }

      const getEmpForPayload = (data) => {
        const emp = {
            id : data?.employeeId,
            emailId : data?.emailId,
            profilePhotoPath : data?.profilePhotoPath,
            name : data?.employeeName,
            employeeDesignation : data?.employeeDesignation,
            relationship : data?.relationship,
        }

        return emp;
    } 

      console.log("sessionPayload",sessionPayload, sessionPayloadErr)

    const handleChangeSessionInfo = (data, action) => {

        let tempSession = structuredClone(sessionPayload);
        let tempErr = structuredClone(sessionPayloadErr);
        // console.log("sessionPayload aaaaaaa",data, action)

        if (action === "startDate"){
            tempSession["startTime"] = data;
            tempErr.startTime.isError = false;
        }
        else if (action === "endDate"){
            tempSession["endTime"] = data;
            tempErr.endTime.isError = false;
        }
        else if (action === "addHost"){            
            tempSession["coHost"] = getEmpForPayload(data);
            tempErr.coHost.isError = false;
        }
        else if (action === "editHost"){            
            tempSession["coHost"] = null;
        }

        else if (action === "addCohort"){
            if(tempSession?.coHortEmployees?.filter((x) => x?.id === data?.employeeId).length > 0){
                tempSession["coHortEmployees"] = tempSession?.coHortEmployees?.filter((x) => x?.id !== data?.employeeId);
            } else {
                tempSession.coHortEmployees.push(getEmpForPayload(data));
                tempErr.coHortEmployees.isError = false;
            }
        }
        else if (action === "removeCohort"){
            tempSession["coHortEmployees"] = tempSession?.coHortEmployees?.filter((x) => x?.id !== data?.id);            
        }
        else if (action === "addAllCohort"){
            data?.forEach((item) => {
                tempSession.coHortEmployees.push(getEmpForPayload(item));
            })
            tempErr.coHortEmployees.isError = false;
            
        }
        else if (action === "removeAllCohort"){
            data?.forEach((item) => {
                tempSession["coHortEmployees"] = tempSession?.coHortEmployees?.filter((x) => x?.id !== item?.employeeId);
            })
            tempErr.coHortEmployees.isError = false;
            
        }
        
        else if (action === "addPanelist"){
            if(tempSession?.participants?.filter((x) => x?.id === data?.employeeId).length > 0){
                tempSession["participants"] = tempSession?.participants?.filter((x) => x?.id !== data?.employeeId);
            } else {
                tempSession.participants.push(getEmpForPayload(data));
                tempErr.participants.isError = false;
            }
        }
        else if (action === "removePanelist"){
                tempSession["participants"] = tempSession?.participants?.filter((x) => x?.id !== data?.id);            
        }
        else if (action === "addAllPanelist"){
            data?.forEach((item) => {
                tempSession.participants.push(getEmpForPayload(item));
            })
            tempErr.participants.isError = false;
            
        }
        else if (action === "removeAllPanelist"){
            data?.forEach((item) => {
                tempSession["participants"] = tempSession?.participants?.filter((x) => x?.id !== item?.employeeId);
            })
            tempErr.participants.isError = false;
            
        }

        else {
            tempSession[data.target.name] = data.target.value;
            
            tempErr[data.target.name].isError = false;
        }

        setSessionPayload(tempSession);
        setSessionPayloadErr(tempErr);
    }  



    const isEmployeeAdded = (emp) => {
        let isAdded = false;

        sessionPayload?.attendees?.forEach((x) => {
            if(x?.id === emp?.employeeId){
                isAdded = true;
            }
        })

        return isAdded;
    }

    const isValid = () => {
        let tempPayload = structuredClone(sessionPayload);
        let tempErr = structuredClone(sessionPayloadErr);
        let valid = true;

        [
            {name: "name", section: "coHost", msg: "*Please enter session name"}, 
            {name: "coHost", section: "coHost", msg: "*Please select co-host"}, 
            {name: "coHortEmployees", section: "cohort", msg: "*Please select at least one employee"}, 
            {name: "participants", section: "panelist", msg: "*Please select at least one employee"}, 
            {name: "startTime", section: "schedule", msg: "*Please select start date and time"}, 
            {name: "endTime", section: "schedule", msg: "*Please select end date and time"}].forEach((item) => {
            if(isEmptyNullUndefined(tempPayload[item.name])){
                valid = false;
                tempErr[item.name].isError = true;
                tempErr[item.name].errMsg = item.msg;
                setExpanded(item.section);
            } else {
                tempErr[item.name].isError = false;
            }
        })
        
        setSessionPayloadErr(tempErr);
        return valid;
    }

    const handleAddSession = () => {
        
        if(isValid()){

            setSubmitLoader(true);

            if(!isEmptyNullUndefined(location?.state?.id)) {
            APIList.editSession(sessionPayload)
            .then((res) => {
                setOpenDialog(true);
                setSubmitLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitLoader(false);
            })
            } else {
            APIList.createSession(sessionPayload)
            .then((res) => {
                setOpenDialog(true);
                setSubmitLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitLoader(false);
            })
            }

        }
    }

    const handleAddSessionAsDraft = () => {
     
            setSubmitDraftLoader(true);

            if(!isEmptyNullUndefined(location?.state?.id)) {
            APIList.editSession({...sessionPayload, status: "ISDRAFT"})
            .then((res) => {
                setOpenDialog(true);
                setSubmitDraftLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitDraftLoader(false);
            })
            } else {
            APIList.createSession({...sessionPayload, status: "ISDRAFT"})
            .then((res) => {
                setOpenDialog(true);
                setSubmitDraftLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitDraftLoader(false);
            })
            }

    }

    return(
            <div className="add-session-main user-profile-page px-10 container">
            <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={expanded === "coHost"} onChange={() => handleChange("coHost")} data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                    alignItems:"center"
                                }}}
                            >
                                <p className="accordian-heading" >Host session</p>
                                <BsChevronDown
                                    style={{transform: expanded !== "coHost" ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem",
                                padding:"0px 2rem",
                                paddingBottom:"1rem"
                            }}
                            >
                                <HostSession
                                handleChangeSessionInfo={handleChangeSessionInfo}
                                sessionPayload={sessionPayload}
                                sessionPayloadErr={sessionPayloadErr}
                                 />
                               
                            </AccordionDetails>
                         
                            </Accordion>
                        </Stack>
          </div>
            <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={expanded === "cohort"} onChange={() => handleChange("cohort")} data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                    alignItems:"center"
                                }}}
                            >
                                <p className="accordian-heading" >Create employee cohort for calibration</p>
                                <BsChevronDown
                                    style={{transform: expanded !== "cohort" ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem",
                                padding:"0px 2rem",
                                paddingBottom:"1rem"
                            }}
                            >
                                <Cohort
                                handleChangeSessionInfo={handleChangeSessionInfo}
                                sessionPayload={sessionPayload}
                                sessionPayloadErr={sessionPayloadErr}
                                />                           
                               
                            </AccordionDetails>
                         
                            </Accordion>
                        </Stack>
          </div>
            <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={expanded === "panelist"} onChange={() => handleChange("panelist")} data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                    alignItems:"center"
                                }}}
                            >
                                <p className="accordian-heading" >Add panelist</p>
                                <BsChevronDown
                                    style={{transform: expanded !== "panelist" ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem",
                                padding:"0px 2rem",
                                paddingBottom:"1rem"
                            }}
                            >
                                <Panelist
                                handleChangeSessionInfo={handleChangeSessionInfo}
                                sessionPayload={sessionPayload}
                                sessionPayloadErr={sessionPayloadErr}
                                />                           
                               
                            </AccordionDetails>
                         
                            </Accordion>
                        </Stack>
          </div>
            <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={expanded === "schedule"} onChange={() => handleChange("schedule")} data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                    alignItems:"center"
                                }}}
                            >
                                <p className="accordian-heading" >Schedule session</p>
                                <BsChevronDown
                                    style={{transform: expanded !== "schedule" ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem",
                                padding:"0px 2rem",
                                paddingBottom:"1rem"
                            }}
                            >
                                <div className="schedule-form-outer-block">
                             

                                {/* <DateTimePicker
                                disabled
                                label="Start Time"
                                changeDate={(date) => handleChangeSessionInfo(date,"startDate")}
                                value={sessionPayload?.startTime}
                                error={sessionPayloadErr?.startTime?.isError}
                                 /> */}
                                    
                                 <div>Start time : &nbsp;{getFormattedDate(sessionPayload?.startTime, dateFormat)} &nbsp;{getFormattedTime(sessionPayload?.startTime, `hh:mm a`)}</div>
                                 <div>End time : &nbsp;{getFormattedDate(sessionPayload?.endTime, dateFormat)} &nbsp;{getFormattedTime(sessionPayload?.endTime, `hh:mm a`)}</div>

                                {/* <DateTimePicker
                                disabled
                                label="End Time"
                                changeDate={(date) => handleChangeSessionInfo(date,"endDate")}
                                value={sessionPayload?.endTime}
                                error={sessionPayloadErr?.endTime?.isError}
                                 />  */}
                                 {
                                    !isEmptyNullUndefined(location?.state?.id) && 
                                    <TextField
                                    // size="small"
                                    disabled={true}
                                    name="meetingLink"
                                    label="Connect with google meet"
                                    id="outlined-basic"
                                    onChange={(event) => handleChangeSessionInfo(event, "meetingLink")}
                                    variant="outlined"
                                    // error={sessionPayloadErr.meetingLink.isError}
                                    // helperText={formError.countryCode.errorMessage}
                                    value={sessionPayload?.meetingLink ? sessionPayload?.meetingLink : ""}
                                    sx={{
                                        width: "100%",
                                        backgroundColor: "var(--graybg)",
                                        "& fieldset": {
                                            border:  sessionPayloadErr?.meetingLink?.isError ? "1px solid red" : "none",
                                        },
                                        "& .MuiInputBase-input:focus": {
                                          border: "none", // focus
                                        },
                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                        },
                                    }}
                                    />
                                 }

                                </div>

                                {/* <TextField 
                                    name="description"
                                    label="Description" 
                                    variant="outlined" 
                                    placeholder="Type here..." 
                                    multiline
                                    rows={3}
                                    value={sessionPayload?.description ? sessionPayload?.description : ""}
                                    sx={{
                                        width:"100%",
                                        mt: 2,
                                        backgroundColor: "#F9F9F9",
                                        "& fieldset": {
                                            border:  sessionPayloadErr?.description?.isError ? "1px solid red" : "none",
                                          },
                                    }}
                                    onChange={(event) => handleChangeSessionInfo(event, "description")}
                                    /> */}
                               
                               
                            </AccordionDetails>
                            </Accordion>
                        </Stack>

              
          </div>

          {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    Session successfully {!isEmptyNullUndefined(location?.state?.id) ? "updated" : "created"}                
                </div>
                <button className="button"
                    onClick={() => { history.push("/hrb")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
</div>
    )
}

export default ViewSession;