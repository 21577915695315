import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import bluedot from "../../../../assets/images/blueDot.png";
import PlaneIcon from "../../../../assets/images/plane.png";

const Notes = ({ }) => {
    const dispatch = useDispatch();

    return (
        <div className="completeProfileCard">
            <Typography className="sidebarheading mb-0 pb-0">Notes</Typography>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="wrap">
                    <div class="ico-wrap">
                        <div className="blueCircleText">GM</div>
                        {/* <img src={bluedot} alt="MediBuddy" /> */}
                    </div>
                    <div class="text-wrap vcenter bottom-border1">
                        <Typography className="certification-title">
                            Ganesh Mehta
                        </Typography>
                        <Typography className="sidebarDate">
                            You <span>.</span> 06/02/2024
                        </Typography>
                        <Typography className="sidebarDesc">
                            Earn career credentials from industry leaders that
                            demonstrate your expertise.{" "}
                        </Typography>
                    </div>
                </div>
                <div class="wrap">
                    <div class="ico-wrap">
                        <div className="blueCircleText">AJ</div>
                        {/* <img src={bluedot} alt="MediBuddy" /> */}
                    </div>
                    <div class="text-wrap vcenter bottom-border1">
                        <Typography className="certification-title">
                            Anil Jain
                        </Typography>
                        <Typography className="sidebarDate">
                            You <span>.</span> 06/02/2024
                        </Typography>
                        <Typography className="sidebarDesc">
                            Earn career credentials from industry leaders that
                            demonstrate your expertise.{" "}
                        </Typography>
                    </div>
                </div>
                <div class="wrap">
                    <div class="ico-wrap">
                        <div className="blueCircleText">GM</div>
                        {/* <img src={bluedot} alt="MediBuddy" /> */}
                    </div>
                    <div class="text-wrap vcenter bottom-border1">
                        <Typography className="certification-title">
                            Ganesh Mehta
                        </Typography>
                        <Typography className="sidebarDate">
                            You <span>.</span> 06/02/2024
                        </Typography>
                        <Typography className="sidebarDesc">
                            Earn career credentials from industry leaders that
                            demonstrate your expertise.{" "}
                        </Typography>
                    </div>
                </div>
                <div class="wrap">
                    <div class="ico-wrap">
                        <div className="blueCircleText">AJ</div>
                        {/* <img src={bluedot} alt="MediBuddy" /> */}
                    </div>
                    <div class="text-wrap vcenter bottom-border1">
                        <Typography className="certification-title">
                            Anil Jain
                        </Typography>
                        <Typography className="sidebarDate">
                            You <span>.</span> 06/02/2024
                        </Typography>
                        <Typography className="sidebarDesc">
                            Earn career credentials from industry leaders that
                            demonstrate your expertise.{" "}
                        </Typography>
                    </div>
                </div>
            </div>
            <div class="input-group-custom mb-3">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your comment..."
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                />
                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                >
                    <img src={PlaneIcon} alt="MediBuddy" />
                </button>
            </div>
        </div>
    );
};

export default React.memo(Notes);
