import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack } from "@mui/material"
import { useSelector } from "react-redux";
import { breakCamelCase, getListItemDescription, isEmptyNullUndefined } from "../../utils/utils";
import PropTypes from 'prop-types';

const FilterSelect = ({title, filterPayload, setFilterPayload, dropdownData1}) =>{

    const label = breakCamelCase(title)

    const dd = useSelector((state) => state?.dropdownData?.dropdownData)
    
    const dropdownData = !isEmptyNullUndefined(dropdownData1) ? dropdownData1 : dd;
    // console.log("title",title)
const handleSelectFilters = (event) => {
    // console.log("event",event)
    let temp = structuredClone(filterPayload);
    let all = []

    if (event.target.value.includes("All")) {
        if(temp[event.target.name]?.length === dropdownData[event.target.name]?.length) {
            temp[event.target.name] = [];
        } else {
            dropdownData[event.target.name].forEach((x) => {
                    all.push(x.id)
            })
            temp[event.target.name] = all;  
        }

    } else {
            temp[event.target.name] = event.target.value;
    }   
    
    setFilterPayload(temp)
    // console.log("temp",temp)
}

    return(
        <Stack 
        sx={{
            width: '100%', 
            margin:"0.5rem auto"
        }}
        >
            <FormControl>
            <InputLabel size='small' id={title}>
                {/* <IntlMessages id='companyConfiguration.chooseEmployee' /> */}
                {title === "employementStatus" ? "Payroll Entity" : label}
            </InputLabel>
            <Select
                name={title}
                labelId={title}
                value={filterPayload[title] || []}
                // error={formError.documentApproverEmployees.isError}
                onChange={(event) => handleSelectFilters(event)}
                label={label}
                // label={
                //   <IntlMessages id='companyConfiguration.chooseEmployee' />
                // }
                variant='outlined'
                size='small'
                multiple
                // disabled={!CompanyConfigurationData.isDocumentsApprovalRequired}
                renderValue={(selected) =>
                  getListItemDescription(selected, dropdownData[title], title)
                }
                sx={{
                    width: "100%",
                    backgroundColor: "var(--graybg)",
                    "& fieldset": {
                      border: "1px solid var(--graybg)",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                }}
            >
                {dropdownData[title]?.length > 0 && (
                <MenuItem value='All'>
                    <ListItemIcon>
                    <Checkbox
                        checked={
                          dropdownData[title]?.length === filterPayload[title]?.length
                        }
                        indeterminate={
                            filterPayload[title]?.length > 0 &&
                            dropdownData[title]?.length < filterPayload[title]?.length
                        }
                    />
                    </ListItemIcon>
                    <ListItemText primary='Select All' />
                </MenuItem>
                )}
                {
                dropdownData[title]?.sort((a,b) => a.name < b.name ? -1 : 1).map((object) => (                        
                        <MenuItem
                        key={`${title}` + object.id}
                        value={object.id}
                        >
                            
                        <ListItemIcon>
                            <Checkbox
                            checked={
                                !isEmptyNullUndefined(filterPayload[title]) && filterPayload[title].filter(x => x === object.id)?.length > 0
                            }
                            />
                        </ListItemIcon>
                        <ListItemText primary={object.name} />
                        </MenuItem>
                ))}
            </Select>
      
            </FormControl>
        </Stack>
    )
};

FilterSelect.propTypes = {
    title: PropTypes.string,
    filterPayload: PropTypes.array,
    setFilterPayload: PropTypes.func,
    dropdownData1: PropTypes.array,
  };

export default FilterSelect;