import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.GET_COUNTS_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_COUNTS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        counts: payload || {},
    }),
    [types.GET_COUNTS_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),   
};

export default handleActions(actionHandler, {
    loader: false,
    counts: {},    
});
