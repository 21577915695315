import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ChartIcon from "../../../../assets/images/chartrp.png";


const FeedbackComparison = ({ }) => {
    const dispatch = useDispatch();

    return (
        <Grid className="chart-report-card card-100per">
            <Grid container className="feedback-comparison-grid">
                <Grid item xs={12} sm={12} md={12}>
                   <Typography className="feedback-comparison">{"Feedback Comparison"}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className='ta-center'>
                    <img src={ChartIcon} alt="MediBuddy" className="chart-icon" />
                </Grid>

                <Grid container>
                    <div className='bottom-d-flex'>
                        <div className="self-box" /> <Typography className="box-label">{"Self"}</Typography>

                        <div className="other-box" /> <Typography className="box-label">{"Others"}</Typography>

                        <div className="company-avg-box" /> <Typography className="box-label">{"Company average"}</Typography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(FeedbackComparison);
