import Checked from "../../../../../../assets/images/checked.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import DialogPopup from "../../../../../common/Dialog";
import Radio from "@mui/material/Radio";
import checkIcon from "../../../../../../assets/images/popup-check.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import {
  isEmptyNullUndefined,
  textAreaStyle,
  myAssesmentvalues as values,
  accordianSVGstyle,
  onlyAcceptWholeNumbers,
} from "../../../../../utils/utils";
import APIList from "../../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import AccSkeleton from "../../../../../common/akkSkeleton";
import { useDispatch, useSelector } from "react-redux";
import Info from "../../../../../../assets/images/info.svg";
import SideDrawer from "../../../../../common/sideDrawer";
import Avatar from "react-avatar";
import EmpProfile from "../../../../../common/employeeProfile";
import { MdCloudUpload } from "react-icons/md";

const parse = require('html-react-parser');

const SelfAssesmentAllinone = ({empId}) => {
  const dispatch = useDispatch();
  const drawerObj = {
    profile: false,
    info: false,
    peer: false,
    data: null,
  }
  const history = useHistory();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const [expanded, setExpanded] = useState(null);
  const [expandedTile, setExpandedTile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerData, setdrawerData] = useState(null);
  const [formData, setFormData] = useState([]);
  const [vertualFormPayload, setVertualPayload] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [vertualError, setVertualError] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [status, setStatus] = useState('ACTIVE')
  const [isDraftPopup, setIsDraftPopup] = useState(false); 
  const [empInfo, setEmpInfo] = useState(null);
  const [empInfoLoader, setEmpInfoLoader] = useState(false);
  const [viewProfileDrawerData, setviewProfileDrawerData] = useState(drawerObj) 
  const [readMoreId, setReadMoreId] = useState(null);
  const [isThereEmptyForm, setIsThereEmptyForm] = useState(false)
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([

  ])

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    // Handle dropped files here
    console.log('Dropped files:', files);

    handleImageChange({ target: { files } });
  };

  const handleDeleteDoc = (index) => {

    console.log(index)

    const tempFiles = structuredClone(files)
    tempFiles.splice(index, 1)

    console.log(index)

    setFiles(tempFiles)

    
  }
  
  const openDoc = (index) => {
    const tempFiles = structuredClone(files)
    if(tempFiles?.[index]?.path) {
      window.open(tempFiles[index].path);
    } 

  }

  const handleImageChange= (event)=>{
    const file = event.target.files.length > 0 ? event.target.files[0] : undefined;
    // console.log('file',file);
    if(validateImage(file)){
        UploadFile(file);
    }else{
       
    }
}
const validateImage = (file) => {
    if (!file) {
      setError('Please select an image.');
      toast.error(
        <div style={{display:"flex", flexDirection:"row"}}>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{'Please select an image.'}
        </div>
    );
      return false;
    }
    if (file.size > 2097152) {
      setError('Selected file exceeds 2MB limit.');
      toast.error(
        <div style={{display:"flex", flexDirection:"row"}}>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{'Selected file exceeds 2MB limit.'}
        </div>
    );
      return false;
    }
    // const allowedTypes = ['image/jpeg', 'image/png'];
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/csv'];
    if (!allowedTypes.includes(file.type)) {
      setError('Please select a JPEG or PNG image.');
      toast.error(
        <div style={{display:"flex", flexDirection:"row"}}>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{'Please select a JPEG or PNG image.'}
        </div>
    );
      return false;
    }
    setError(null);
    return true;
  };

  const UploadFile = async (file) => {
    setIsUploadingFile(() => true);
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    await APIList.fileUpload(formData , headers, employeeDetails?.company?.companyName, 'viewReportSelfAssesment')
    .then((res) => {

      let tempTotalDoc = structuredClone(files)
      tempTotalDoc.push({...res.data})

      console.log('res.data' ,{...res.data})
      console.log('tempTotalDoc', tempTotalDoc)

      setFiles(tempTotalDoc)
      setIsUploadingFile(() => false);




        // setSelectedImage(res.data)
        // APIList.changeProfilePhote({
        //     "profilePhoto":{
        //         "id":res.data.id
        //     }
        // } ,employeeDetails.id)
        // .then((res)=>{
        //     setIsUploadingFile(() => false);
        //     toast.success('Profile photo updated successfully');
        //     setTimeout(() => {
        //         // window.location.reload();
        //         dispatch(EmployeeDataActions.EmployeeDataRequest());
        //     }, 1000);

        // }).catch((err)=>{
        //     toast.error(
        //         <div style={{display:"flex", flexDirection:"row"}}>
        //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //           &nbsp;&nbsp;{err?.title}
        //         </div>
        //     );
        //     setIsUploadingFile(() => false);
        // })
    })
    .catch((err) => {
        // toast.error(
        //     <div style={{display:"flex", flexDirection:"row"}}>
        //       <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //       &nbsp;&nbsp;{err?.title}
        //     </div>
        // );
        setIsUploadingFile(() => false);
    })
  }; 

  function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  }

  const handleReadMore = (id) => {
    console.log('id', id)
    if(id == readMoreId) {
      setReadMoreId(null)
    } else {
      setReadMoreId(id)

    }
  }

  const checkIsStringUnderLimit = (event, tab, tabIndes, tileIndex, nestedSubSectionIndex, type, tempvertualFormError,) => {
    let isValid = true
    if (type == 'tab') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters. Please shorten your response.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
              tab.assessmentResponseObjectDTO.responseString.length ||
            tab.configObj.maxCharacterLimit <
              tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit} .`;
            isValid = false;
          }
        }
      }
    } else if (type == 'tile') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters. Please shorten your response.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
              tab.assessmentResponseObjectDTO.responseString
                .length ||
            tab.configObj.maxCharacterLimit <
              tab.assessmentResponseObjectDTO.responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length}/${tab.configObj.maxCharacterLimit}`;
            isValid = false;
          }
        }
      }
    } else if (type == 'nestedSubSection') {
      if (
        tab.configObj.minCharacterLimit ||
        tab.configObj.maxCharacterLimit
      ) {
        if (
          tab.configObj.minCharacterLimit &&
          !tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
            tab.assessmentResponseObjectDTO
              .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
            isValid = false;
          }
        } else if (
          !tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.maxCharacterLimit <
            tab.assessmentResponseObjectDTO
              .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters. Please shorten your response.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            isValid = false;
          }
        } else if (
          tab.configObj.minCharacterLimit &&
          tab.configObj.maxCharacterLimit
        ) {
          if (
            tab.configObj.minCharacterLimit >
              tab.assessmentResponseObjectDTO
                .responseString.length ||
            tab.configObj.maxCharacterLimit <
              tab.assessmentResponseObjectDTO
                .responseString.length
          ) {
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = true;
            tempvertualFormError[tabIndes].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
            isValid = false;
          } else {
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.isError = false;
            tempvertualFormError[
              tabIndes
            ].subSections[
              tileIndex
            ].subSections[
              nestedSubSectionIndex
            ].assessmentResponseObjectDTO.responseString.errorMessage = `${tab.configObj.maxCharacterLimit - tab.assessmentResponseObjectDTO.responseString.length} char left .`;
            isValid = false;
          }
        }
      }
    }
    if(isValid) {
      if(type == 'tab') {
        // tempvertualFormError[tabIndes].assessmentResponseObjectDTO[
        //     event.target.name
        //   ].isError = false;
          tempvertualFormError[tabIndes].assessmentResponseObjectDTO[
            event.target.name
          ].errorMessage = "";

      } else if (type == 'tile') {
        // tempvertualFormError[tabIndes].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO.responseString.isError = false;
        tempvertualFormError[tabIndes].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.responseString.errorMessage = '';
      } else if (type == 'nestedSubSection') {
        // tempvertualFormError[tabIndes].subSections[tileIndex].subSections[nestedSubSectionIndex].assessmentResponseObjectDTO.responseString.isError = false;
        tempvertualFormError[tabIndes].subSections[tileIndex].subSections[nestedSubSectionIndex].assessmentResponseObjectDTO.responseString.errorMessage = '';
      }
    }
    setVertualError(tempvertualFormError);
  }

  const handleChangeVertualPayload = (
    event,
    inputType,
    tabIndex,
    tileIndex,
    nestedSubsectionI
  ) => {
    const tempvertualFormPayload = structuredClone(vertualFormPayload);
    const tempvertualFormError = structuredClone(vertualError);
    if (nestedSubsectionI == null && tileIndex == null) {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = event.target.value;
        checkIsStringUnderLimit(event, tempvertualFormPayload[tabIndex], tabIndex, null, null, 'tab', tempvertualFormError)
        // tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ].isError = false;
        // tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {
        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }


        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ] = event.target.value;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].configObj.selectionType === "Single"
        ) {


          
          
          if (
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection = [event];
          }
            
          



        } else if (
          tempvertualFormPayload[tabIndex].configObj.selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO
              .selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{"You have reached the maximum selection limit."}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[
                      tabIndex
                    ].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[
                    tabIndex
                  ].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[
                  tabIndex
                ].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[
              tabIndex
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[
          tabIndex
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[
          tabIndex
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    } else if (nestedSubsectionI == null) {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;

        checkIsStringUnderLimit(event, tempvertualFormPayload[tabIndex].subSections[tileIndex], tabIndex, tileIndex, null, 'tile', tempvertualFormError)

        // tempvertualFormError[tabIndex].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO[event.target.name].isError = false;
        // tempvertualFormError[tabIndex].subSections[
        //   tileIndex
        // ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] =
          onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {

        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }

        // tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
        //   event.target.name
        // ] = (event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj
            .selectionType === "Single"
        ) {



          // tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];


          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection = [event];
          }



        } else if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj
            .selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex]
              .assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].subSections[tileIndex].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{'You have reached the maximum selection limit.'}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[tabIndex].subSections[
              tileIndex
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[tabIndex].subSections[
          tileIndex
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    } else {
      if (inputType === "textField") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = event.target.value;

        checkIsStringUnderLimit(event, tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI], tabIndex, tileIndex, nestedSubsectionI, 'nestedSubSection', tempvertualFormError)


        // tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
        //   nestedSubsectionI
        // ].assessmentResponseObjectDTO[event.target.name].isError = false;
        // tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
        //   nestedSubsectionI
        // ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "numberField") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name] =
          onlyAcceptWholeNumbers(event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === 'dropDownSelect') {
        if(isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name]) ) {
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        } else {
          if(
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name].id == event.target.value.id
          ) {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = null
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
          }
          
        }




        // tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO[event.target.name] = (event.target.value);
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[
          event.target.name
        ].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[
          event.target.name
        ].errorMessage = '';
      }
      if (inputType === "ratio") {
        tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name] = event.target.value;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO[event.target.name].errorMessage = "";
      }
      if (inputType === "selection") {
        if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
            nestedSubsectionI
          ].configObj.selectionType === "Single"
        ) {
          // tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
              }
            })
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection = [event];
          }
        } else if (
          tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
            nestedSubsectionI
          ].configObj.selectionType === "Multiple"
        ) {
          if (
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.length
          ) {
            let isPresent = false;
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.some((e) => {
              if (e.id == event.id) {
                let indexOfEvent = tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].subSections[
                  nestedSubsectionI
                ].assessmentResponseObjectDTO.selection.findIndex(
                  (e) => e.id == event.id
                );
                tempvertualFormPayload[tabIndex].subSections[
                  tileIndex
                ].subSections[
                  nestedSubsectionI
                ].assessmentResponseObjectDTO.selection.splice(indexOfEvent, 1);
                isPresent = true;
              } else {
              }
            });
            if (!isPresent) {
              if(!isEmptyNullUndefined(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit)) {
                if(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit != 0) {
                  if(tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].configObj.maxSelectionLimit <=  tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.length) {
                    // dispatch(fetchError('You have reached the maximum selection limit.'))
                    toast.error(
                      <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{'You have reached the maximum selection limit.'}
                      </div>
                    )
                  } else {
                    tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
                  }
                } else {
                  tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
                }
              } else {
                tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.selection.push(event);
              }
            }
          } else {
            tempvertualFormPayload[tabIndex].subSections[tileIndex].subSections[
              nestedSubsectionI
            ].assessmentResponseObjectDTO.selection.push(event);
          }
        }
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO.selection.isError = false;
        tempvertualFormError[tabIndex].subSections[tileIndex].subSections[
          nestedSubsectionI
        ].assessmentResponseObjectDTO.selection.errorMessage = "";
      }
    }
    setVertualPayload(tempvertualFormPayload);
    setVertualError(tempvertualFormError);
  };

  const getFormData = async (selectedFormFor) => {
    setisLoading(true);
    try {
      const response = await APIList.getAssessmentResponse({
        selectedFormFor: selectedFormFor,
        selectedEmployeeId: empId,
      });
      if (response.status === 200) {
        if(response?.data?.files && response.data.files.length) {
          setFiles(() => response?.data?.files)
        }
        let tempvertualFormError = structuredClone(
          response.data.assessmentFormDTO.sections
        ).map((tab, tabIndex) => {
          tab.subSections.length &&
            tab.subSections.map((tile, tileIndex) => {
              tile.subSections.length &&
                tile.subSections.map((nestedSubsection, nestedIndex) => {
                  // for response
                  nestedSubsection.assessmentResponseObjectDTO.responseString =
                    { isError: false, errorMessage: "" };
                  nestedSubsection.assessmentResponseObjectDTO.responseBoolean =
                    { isError: false, errorMessage: "" };
                  nestedSubsection.assessmentResponseObjectDTO.responseInteger =
                    { isError: false, errorMessage: "" };

                  //for selection
                  nestedSubsection.assessmentResponseObjectDTO.selection = {
                    isError: false,
                    errorMessage: "",
                  }; // array
                  nestedSubsection.assessmentResponseObjectDTO.rating = {
                    isError: false,
                    errorMessage: '',
                  };
                  return nestedSubsection;
                });

              tile.assessmentResponseObjectDTO.responseString = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.responseBoolean = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.responseInteger = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.selection = {
                isError: false,
                errorMessage: "",
              };
              tile.assessmentResponseObjectDTO.rating = {
                isError: false,
                errorMessage: '',
              };
              return tile;
            });

          tab.assessmentResponseObjectDTO.responseString = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.responseBoolean = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.responseInteger = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.selection = {
            isError: false,
            errorMessage: "",
          };
          tab.assessmentResponseObjectDTO.rating = {
            isError: false,
            errorMessage: '',
          };
          return tab;
        });
        setVertualError(tempvertualFormError);
        setVertualPayload(response.data.assessmentFormDTO.sections);
        setFormData(response.data);
        setStatus(response.data.status)
      } else {
      }
    } catch (error) {
      if(error.status = 400) {
        console.log('there is no saved user getting fresh new form, hitting empty form api')
        if(error.title == 'eventnotfound') {
          setErrorMessage(error?.message)
        //   toast.error(
        //     <div style={{ display: "flex", flexDirection: "row" }}>
        //       <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        //       &nbsp;&nbsp;{error?.message}
        //     </div>
        //   );
          setIsThereEmptyForm(true)
        } else {
          try {
            const response = await APIList.getAssessment({
              selectedFormFor: selectedFormFor,
              selectedCompanyId: employeeDetails?.company?.id,
            });
            if (response.status === 200) {
              let tempvertualFormError = structuredClone(
                response.data.sections
              ).map((tab, tabIndex) => {
                tab.subSections.length &&
                  tab.subSections.map((tile, tileIndex) => {
                    tile.subSections.length &&
                      tile.subSections.map((nestedSubsection, nestedIndex) => {
                        // for response
                        nestedSubsection.assessmentResponseObjectDTO.responseString =
                          { isError: false, errorMessage: "" };
                        nestedSubsection.assessmentResponseObjectDTO.responseBoolean =
                          { isError: false, errorMessage: "" };
                        nestedSubsection.assessmentResponseObjectDTO.responseInteger =
                          { isError: false, errorMessage: "" };
    
                        //for selection
                        nestedSubsection.assessmentResponseObjectDTO.selection = {
                          isError: false,
                          errorMessage: "",
                        }; // array
                        nestedSubsection.assessmentResponseObjectDTO.rating = {
                          isError: false,
                          errorMessage: '',
                        };
                        return nestedSubsection;
                      });
    
                    tile.assessmentResponseObjectDTO.responseString = {
                      isError: false,
                      errorMessage: "",
                    };
                    tile.assessmentResponseObjectDTO.responseBoolean = {
                      isError: false,
                      errorMessage: "",
                    };
                    tile.assessmentResponseObjectDTO.responseInteger = {
                      isError: false,
                      errorMessage: "",
                    };
                    tile.assessmentResponseObjectDTO.selection = {
                      isError: false,
                      errorMessage: "",
                    };
                    tile.assessmentResponseObjectDTO.rating = {
                      isError: false,
                      errorMessage: '',
                    };
                    return tile;
                  });
    
                tab.assessmentResponseObjectDTO.responseString = {
                  isError: false,
                  errorMessage: "",
                };
                tab.assessmentResponseObjectDTO.responseBoolean = {
                  isError: false,
                  errorMessage: "",
                };
                tab.assessmentResponseObjectDTO.responseInteger = {
                  isError: false,
                  errorMessage: "",
                };
                tab.assessmentResponseObjectDTO.selection = {
                  isError: false,
                  errorMessage: "",
                };
                tab.assessmentResponseObjectDTO.rating = {
                  isError: false,
                  errorMessage: '',
                };
                return tab;
              });
              setVertualError(tempvertualFormError);
              setVertualPayload(response.data.sections);
              setFormData(response.data);
              setStatus('INACTIVE')
            }
          } catch (error) {}
        }
      } else {
        // toast.error(
        //   <div style={{ display: "flex", flexDirection: "row" }}>
        //     <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        //     &nbsp;&nbsp;{error?.message}
        //   </div>
        // );
      }
      
    }
    setisLoading(false);
  };

  useEffect(() => {
    getFormData("SelfAppraisal");
    setEmpInfo(employeeDetails)
  }, []);

  useEffect(() => {
    // this effect use for save as draft
    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (formData?.assessmentFormDTO) {
        //  'update calls -------------'
        // handleUpdate('Draft');
        setIsSubmitDisabled(true)
      } else {
        // 'submit calls--------------'
        // handleSubmit('Draft');
        setIsSubmitDisabled(true)
      }
    }
    let handleClear = setTimeout(() => {
      if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
        if (formData?.assessmentFormDTO) {
          //  'update calls -------------'
          handleUpdate('Draft');
        } else {
          // 'submit calls--------------'
          handleSubmit('Draft');
        }
      }
    }, 10000)
    return () => {
      clearTimeout(handleClear)
      setIsSubmitDisabled(false)
    }
  }, [vertualFormPayload])



  const handleOpenviewProfileDrawer = (data, toShow) => {
    setdrawerData(() => data);
          
    if (toShow === "profile") {
        let temp = {
          ...drawerObj, 
          profile: true, 
          data: data && data,
          peer: false,
          info: false
        };
        setviewProfileDrawerData(temp);
    }
    if (toShow === "peer") {
        let temp = {
          ...drawerObj, 
          peer: true, 
          data: data && data,
          profile: false, 
          info: false
        };
        setviewProfileDrawerData(temp);
    }
    if (toShow === "info") {
        let temp = {
          ...drawerObj, 
          info: true, 
          data: data && data,
          profile: false, 
          peers: false, 
        };
        setviewProfileDrawerData(temp);
    }

    setIsOpenDrawer(true);
  };

  const handleSaveAsDraft = (submitType) => {
    setIsDraftPopup(true);
    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (formData?.assessmentFormDTO) {
        //  'update calls -------------'
        handleUpdate('Draft', submitType);
      } else {
        // 'submit calls--------------'
        handleSubmit('Draft', submitType);
      }
    }
  }

  const handleValidate = () => {
    let isValid = true;
    let tempFormData = structuredClone(formData);
    let tempvertualFormError = structuredClone(vertualError);
    let tempvertualFormPayload = structuredClone(vertualFormPayload);

    // check validation

    tempvertualFormPayload.forEach((tab, tabIndes) => {
      if (!tab.subSections.length) {
        if (
          tab.configObj.doesThisQuestionHaveResponse === true ||
          tab.configObj.doesThisQuestionHaveResponse === "true"
        ) {
          if (tab.configObj.responseType === "String") {
            if (
              isEmptyNullUndefined(
                tab.assessmentResponseObjectDTO.responseString
              )
            ) {
              if (
                tab.configObj.isResponseMandatory === true ||
                tab.configObj.isResponseMandatory === "true"
              ) {
                tempvertualFormError[
                  tabIndes
                ].assessmentResponseObjectDTO.responseString.isError = true;
                tempvertualFormError[
                  tabIndes
                ].assessmentResponseObjectDTO.responseString.errorMessage =
                  "Required field";
                isValid = false;
              }
            } else if (
              tab.configObj.minCharacterLimit ||
              tab.configObj.maxCharacterLimit
            ) {
              if (
                tab.configObj.minCharacterLimit &&
                !tab.configObj.maxCharacterLimit
              ) {
                if (
                  tab.configObj.minCharacterLimit >
                  tab.assessmentResponseObjectDTO.responseString.length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tab.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                  isValid = false;
                }
              } else if (
                !tab.configObj.minCharacterLimit &&
                tab.configObj.maxCharacterLimit
              ) {
                if (
                  tab.configObj.maxCharacterLimit <
                  tab.assessmentResponseObjectDTO.responseString.length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tab.configObj.maxCharacterLimit} characters. Please shorten your response.`;
                  isValid = false;
                }
              } else if (
                tab.configObj.minCharacterLimit &&
                tab.configObj.maxCharacterLimit
              ) {
                if (
                  tab.configObj.minCharacterLimit >
                    tab.assessmentResponseObjectDTO.responseString.length ||
                  tab.configObj.maxCharacterLimit <
                    tab.assessmentResponseObjectDTO.responseString.length
                ) {
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.isError = true;
                  tempvertualFormError[
                    tabIndes
                  ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tab.configObj.minCharacterLimit} and ${tab.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                  isValid = false;
                }
              }
            }
          } else if (
            tab.configObj.responseType === "Integer" &&
            (tab.configObj.isResponseMandatory === true ||
              tab.configObj.isResponseMandatory === "true") &&
            isEmptyNullUndefined(
              tab.assessmentResponseObjectDTO.responseInteger
            )
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseInteger.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseInteger.errorMessage =
              "Required field";
            isValid = false;
          } else if (
            tab.configObj.responseType === "Boolean" &&
            (tab.configObj.isResponseMandatory === true ||
              tab.configObj.isResponseMandatory === "true") &&
            (tab.assessmentResponseObjectDTO.responseBoolean == null ||
              tab.assessmentResponseObjectDTO.responseBoolean == undefined)
          ) {
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseBoolean.isError = true;
            tempvertualFormError[
              tabIndes
            ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
              "Please select yes / no";
            isValid = false;
          }
        }
        if (
          (tab.configObj.doesThisQuestionHaveSelection === true ||
            tab.configObj.doesThisQuestionHaveSelection === "true") &&
          (tab.configObj.isSelectionMandatory === true ||
            tab.configObj.isSelectionMandatory === "true")
        ) {
          if (tab.configObj.selectionType === "Single") {
            if (tab.assessmentResponseObjectDTO.selection.length == 0) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.errorMessage =
                "Please select any one";
              isValid = false;
            }
          } else if (tab.configObj.selectionType === "Multiple") {
            if (tab.assessmentResponseObjectDTO.selection.length == 0) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.selection.errorMessage =
                "Please select at least one. ";
              isValid = false;
            }
          }
        }
        if((tab.configObj.isRatingRequired === true ||
          tab.configObj.isRatingRequired === 'true') &&
        (tab.configObj.isRatingMandatory === true ||
          tab.configObj.isRatingMandatory === 'true')) {
            if(isEmptyNullUndefined(tab.assessmentResponseObjectDTO.rating)) {
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.rating.isError = true;
              tempvertualFormError[
                tabIndes
              ].assessmentResponseObjectDTO.rating.errorMessage =
                'Please select rating. ';
              isValid = false;
            }
          }
      } else {
        tab.subSections.forEach((tile, tileIndex) => {
          if (!tile.subSections.length) {
            if (
              tile.configObj.doesThisQuestionHaveResponse === true ||
              tile.configObj.doesThisQuestionHaveResponse === "true"
            ) {
              if (tile.configObj.responseType === "String") {
                if (
                  isEmptyNullUndefined(
                    tile.assessmentResponseObjectDTO.responseString
                  )
                ) {
                  if (
                    tile.configObj.isResponseMandatory === true ||
                    tile.configObj.isResponseMandatory === "true"
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].assessmentResponseObjectDTO.responseString.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].assessmentResponseObjectDTO.responseString.errorMessage =
                      "Required field";
                    isValid = false;
                  }
                } else if (
                  tile.configObj.minCharacterLimit ||
                  tile.configObj.maxCharacterLimit
                ) {
                  if (
                    tile.configObj.minCharacterLimit &&
                    !tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      tile.configObj.minCharacterLimit >
                      tile.assessmentResponseObjectDTO.responseString.length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${tile.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                      isValid = false;
                    }
                  } else if (
                    !tile.configObj.minCharacterLimit &&
                    tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      tile.configObj.maxCharacterLimit <
                      tile.assessmentResponseObjectDTO.responseString.length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${tile.configObj.maxCharacterLimit} characters. Please shorten your response.`;
                      isValid = false;
                    }
                  } else if (
                    tile.configObj.minCharacterLimit &&
                    tile.configObj.maxCharacterLimit
                  ) {
                    if (
                      tile.configObj.minCharacterLimit >
                        tile.assessmentResponseObjectDTO.responseString
                          .length ||
                      tile.configObj.maxCharacterLimit <
                        tile.assessmentResponseObjectDTO.responseString.length
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${tile.configObj.minCharacterLimit} and ${tile.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                      isValid = false;
                    }
                  }
                }
              } else if (
                tile.configObj.responseType === "Integer" &&
                (tile.configObj.isResponseMandatory === true ||
                  tile.configObj.isResponseMandatory === "true") &&
                isEmptyNullUndefined(
                  tile.assessmentResponseObjectDTO.responseInteger
                )
              ) {
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseInteger.isError = true;
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseInteger.errorMessage =
                  "Required field";
                isValid = false;
              } else if (
                tile.configObj.responseType === "Boolean" &&
                (tile.configObj.isResponseMandatory === true ||
                  tile.configObj.isResponseMandatory === "true") &&
                (tile.assessmentResponseObjectDTO.responseBoolean == null ||
                  tile.assessmentResponseObjectDTO.responseBoolean == undefined)
              ) {
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseBoolean.isError = true;
                tempvertualFormError[tabIndes].subSections[
                  tileIndex
                ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
                  "Please select yes / no";
                isValid = false;
              }
            }
            if (
              (tile.configObj.doesThisQuestionHaveSelection === true ||
                tile.configObj.doesThisQuestionHaveSelection === "true") &&
              (tile.configObj.isSelectionMandatory === true ||
                tile.configObj.isSelectionMandatory === "true")
            ) {
              if (tile.configObj.selectionType === "Single") {
                if (tile.assessmentResponseObjectDTO.selection.length == 0) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.errorMessage =
                    "Please select any one";
                  isValid = false;
                }
              } else if (tile.configObj.selectionType === "Multiple") {
                if (tile.assessmentResponseObjectDTO.selection.length == 0) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.selection.errorMessage =
                    "Please select at least one. ";
                  isValid = false;
                }
              }
            }
            if((tile.configObj.isRatingRequired === true ||
              tile.configObj.isRatingRequired === 'true') &&
            (tile.configObj.isRatingMandatory === true ||
              tile.configObj.isRatingMandatory === 'true')) {
                if(isEmptyNullUndefined(tile.assessmentResponseObjectDTO.rating)) {
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.rating.isError = true;
                  tempvertualFormError[tabIndes].subSections[
                    tileIndex
                  ].assessmentResponseObjectDTO.rating.errorMessage =
                    'Please select rating. ';
                  isValid = false;
                }
              }
          } else {
            tile.subSections.forEach(
              (nestedSubSection, nestedSubSectionIndex) => {
                if (
                  nestedSubSection.configObj.doesThisQuestionHaveResponse ===
                    true ||
                  nestedSubSection.configObj.doesThisQuestionHaveResponse ===
                    "true"
                ) {
                  if (nestedSubSection.configObj.responseType === "String") {
                    if (
                      isEmptyNullUndefined(
                        nestedSubSection.assessmentResponseObjectDTO
                          .responseString
                      )
                    ) {
                      if (
                        nestedSubSection.configObj.isResponseMandatory ===
                          true ||
                        nestedSubSection.configObj.isResponseMandatory ===
                          "true"
                      ) {
                        tempvertualFormError[tabIndes].subSections[
                          tileIndex
                        ].subSections[
                          nestedSubSectionIndex
                        ].assessmentResponseObjectDTO.responseString.isError = true;
                        tempvertualFormError[tabIndes].subSections[
                          tileIndex
                        ].subSections[
                          nestedSubSectionIndex
                        ].assessmentResponseObjectDTO.responseString.errorMessage =
                          "Required field";
                        isValid = false;
                      }
                    } else if (
                      nestedSubSection.configObj.minCharacterLimit ||
                      nestedSubSection.configObj.maxCharacterLimit
                    ) {
                      if (
                        nestedSubSection.configObj.minCharacterLimit &&
                        !nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          nestedSubSection.configObj.minCharacterLimit >
                          nestedSubSection.assessmentResponseObjectDTO
                            .responseString.length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback must be at least ${nestedSubSection.configObj.minCharacterLimit} characters long. Please provide a more detailed response.`;
                          isValid = false;
                        }
                      } else if (
                        !nestedSubSection.configObj.minCharacterLimit &&
                        nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          nestedSubSection.configObj.maxCharacterLimit <
                          nestedSubSection.assessmentResponseObjectDTO
                            .responseString.length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Your feedback exceeds the maximum allowed length of ${nestedSubSection.configObj.maxCharacterLimit} characters. Please shorten your response.`;
                          isValid = false;
                        }
                      } else if (
                        nestedSubSection.configObj.minCharacterLimit &&
                        nestedSubSection.configObj.maxCharacterLimit
                      ) {
                        if (
                          nestedSubSection.configObj.minCharacterLimit >
                            nestedSubSection.assessmentResponseObjectDTO
                              .responseString.length ||
                          nestedSubSection.configObj.maxCharacterLimit <
                            nestedSubSection.assessmentResponseObjectDTO
                              .responseString.length
                        ) {
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.isError = true;
                          tempvertualFormError[tabIndes].subSections[
                            tileIndex
                          ].subSections[
                            nestedSubSectionIndex
                          ].assessmentResponseObjectDTO.responseString.errorMessage = `Feedback must be between ${nestedSubSection.configObj.minCharacterLimit} and ${nestedSubSection.configObj.maxCharacterLimit} characters. Please enter a valid feedback within this range.`;
                          isValid = false;
                        }
                      }
                    }
                  } else if (
                    nestedSubSection.configObj.responseType === "Integer" &&
                    (nestedSubSection.configObj.isResponseMandatory === true ||
                      nestedSubSection.configObj.isResponseMandatory ===
                        "true") &&
                    isEmptyNullUndefined(
                      nestedSubSection.assessmentResponseObjectDTO
                        .responseInteger
                    )
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseInteger.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseInteger.errorMessage =
                      "Required field";
                    isValid = false;
                  } else if (
                    nestedSubSection.configObj.responseType === "Boolean" &&
                    (nestedSubSection.configObj.isResponseMandatory === true ||
                      nestedSubSection.configObj.isResponseMandatory ===
                        "true") &&
                    (nestedSubSection.assessmentResponseObjectDTO
                      .responseBoolean == null ||
                      nestedSubSection.assessmentResponseObjectDTO
                        .responseBoolean == undefined)
                  ) {
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseBoolean.isError = true;
                    tempvertualFormError[tabIndes].subSections[
                      tileIndex
                    ].subSections[
                      nestedSubSectionIndex
                    ].assessmentResponseObjectDTO.responseBoolean.errorMessage =
                      "Please select yes / no";
                    isValid = false;
                  }
                }
                if (
                  (nestedSubSection.configObj.doesThisQuestionHaveSelection ===
                    true ||
                    nestedSubSection.configObj.doesThisQuestionHaveSelection ===
                      "true") &&
                  (nestedSubSection.configObj.isSelectionMandatory === true ||
                    nestedSubSection.configObj.isSelectionMandatory === "true")
                ) {
                  if (nestedSubSection.configObj.selectionType === "Single") {
                    if (
                      nestedSubSection.assessmentResponseObjectDTO.selection
                        .length == 0
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.errorMessage =
                        "Please select any one";
                      isValid = false;
                    }
                  } else if (
                    nestedSubSection.configObj.selectionType === "Multiple"
                  ) {
                    if (
                      nestedSubSection.assessmentResponseObjectDTO.selection
                        .length == 0
                    ) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.selection.errorMessage =
                        "Please select at least one. ";
                      isValid = false;
                    }
                  }
                }
                if((nestedSubSection.configObj.isRatingRequired === true ||
                  nestedSubSection.configObj.isRatingRequired === 'true') &&
                (nestedSubSection.configObj.isRatingMandatory === true ||
                  nestedSubSection.configObj.isRatingMandatory === 'true')) {
                    if(isEmptyNullUndefined(nestedSubSection.assessmentResponseObjectDTO.rating)) {
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.rating.isError = true;
                      tempvertualFormError[tabIndes].subSections[
                        tileIndex
                      ].subSections[
                        nestedSubSectionIndex
                      ].assessmentResponseObjectDTO.rating.errorMessage =
                        'Please select rating. ';
                      isValid = false;
                    }
                  }
              }
            );
          }
        });
      }
    });

    if (isValid) {
      setShowConfirmationPopup(true);
    } else {
      // set form error
      setVertualError(tempvertualFormError);
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;{"Please enter all mandatory field"}
        </div>
      );
    }
  };

  const handleUpdate = (status, submitType) => {
    const payload = {
      employeeId: employeeDetails.id,
      assessmentFormDTO: {
        id: formData.assessmentFormDTO.id,
        isPaginationRequired: formData.assessmentFormDTO.isPaginationRequired,
        status: formData.assessmentFormDTO.state,
        formFor: formData.assessmentFormDTO.formFor,
        company: formData.assessmentFormDTO.company,
        sections: vertualFormPayload,
        // pmsCycle: formData.assessmentFormDTO.pmsCycle
        pmsCycleId: formData.assessmentFormDTO.pmsCycleId
      },
      // appraisalCycle: "2023-2024",
      formFor: formData.assessmentFormDTO.formFor,
      status: status,
      reviewedBy: {
        id: employeeDetails.id
      },
      pmsCycle: {id: formData.assessmentFormDTO.pmsCycleId},

      files: files
    };

    setSubmitLoader(() => true);
    APIList.putAssessmentResponse({
      responseFormId: formData.id,
      data: payload,
    })
      .then((res) => {
        if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
          setOpenDialog(true);
        } else {
          // setFormData(() => res.data)
        }
        // if(status == 'ACTIVE') {
        //   setOpenDialog(true);
        // }
        setSubmitLoader(() => false);
        setStatus(res.data.status)
      })
      .catch((err) => {
        // toast.error(
        //   <div style={{ display: "flex", flexDirection: "row" }}>
        //     <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        //     &nbsp;&nbsp;{err?.message}
        //   </div>
        // );
        setSubmitLoader(() => false);
      });
    // setSubmitLoader(false);
    setIsSubmitDisabled(false)
  };

  const handleSubmit = (status, submitType) => {
    const payload = {
      employeeId: employeeDetails.id,
      assessmentFormDTO: {
        id: formData.id,
        isPaginationRequired: formData.isPaginationRequired,
        status: formData.state,
        formFor: formData.formFor,
        company: formData.company,
        sections: vertualFormPayload,
        // pmsCycle: formData.pmsCycle
        pmsCycleId: formData.pmsCycleId
      },
      // appraisalCycle: "2023-2024",
      formFor: formData.formFor,
      status: status,
      reviewedBy: {
        id: employeeDetails.id
      },
      pmsCycle: {id: formData.pmsCycleId},
      files: files
    };

    setSubmitLoader(true);
    APIList.postAssessmentResponse(payload)
      .then((res) => {
        if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
          setOpenDialog(true);
        } else {
          setFormData(() => res.data)
          /////////// 
          // let tempSection = structuredClone(formData)
          // let tempRes = structuredClone(res)
          // tempRes.data.assessmentFormDTO.sections = tempSection.sections
          // setFormData(() => tempSection)
        }
        setSubmitLoader(false);
        setStatus(res.data.status)
      })
      .catch((err) => {
        // toast.error(
        //   <div style={{ display: "flex", flexDirection: "row" }}>
        //     <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        //     &nbsp;&nbsp;{err?.message}
        //   </div>
        // );
        setSubmitLoader(false);
      });
    // setSubmitLoader(false);
    setIsSubmitDisabled(false)
  };

  const handleSubmitFeedback = () => {
    setShowConfirmationPopup(false);
    if (formData?.assessmentFormDTO) {
      //  'update calls -------------'
      handleUpdate('ACTIVE');
    } else {
      // 'submit calls--------------'
      handleSubmit('ACTIVE');
    }
  }
  
  const handleSubmitDraft = () => {
    setShowConfirmationPopup(false);
    if(vertualFormPayload.length && ((formData?.assessmentFormDTO && formData?.assessmentFormDTO.id && formData?.assessmentFormDTO.formFor && formData?.assessmentFormDTO.company) || (formData.id && formData.formFor && formData.company))) {
      if (formData?.assessmentFormDTO) {
        //  'update calls -------------'
        handleUpdate('Draft', 'submitAsDraft');
      } else {
        // 'submit calls--------------'
        handleSubmit('Draft', 'submitAsDraft');
      }
    }
       
  }

  const handleChange = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  const handleChangeTile = (index) => {
    if (expandedTile === index) {
      setExpandedTile(null);
    } else {
      setExpandedTile(index);
    }
  };

  const handleOpenSideDrawer = (data) => {
    setdrawerData(() => data);
    setIsOpenDrawer(true);
  };

  const createDynamicForm = (data) => {
    if (!data) {
      return;
    }

    const tempData = structuredClone(data);
    let dom = [];

    if (tempData.sections && tempData.sections.length > 0) {
      return tempData.sections.map((TabElement, tabIndex) => (
        <div className={`disabledInput`} key={TabElement.id}>
          {TabElement.subSections.length ? (
            <>
              <div className="acc-wrapper">
                <Stack
                  sx={{
                    margin: "1rem",
                    "& .MuiAccordion-rounded": {
                      border: "1px solid #dad2d2",
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === `Tab-${tabIndex}`}
                    onChange={(e) => {
                      handleChange(`Tab-${tabIndex}`);
                      e.stopPropagation();
                    }}
                    data-value={`Tab-${tabIndex}`}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        width: "100%",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        "& .MuiAccordionSummary-content": {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <p className="accordian-heading">
                        {/* {TabElement.configObj.label} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: TabElement.configObj.label,
                          }}
                        ></div>
                      </p>
                      <div>
                        {/* {(TabElement.configObj.isInfoRequired == true ||
                          TabElement.configObj.isInfoRequired == "true") &&
                          TabElement.configObj.info && (
                            <Tooltip title="Info" placement="top-end">
                              <img
                                onClick={(e) => {
                                  // handleOpenSideDrawer(
                                  //   TabElement.configObj.info
                                  // );
                                  handleOpenviewProfileDrawer(TabElement.configObj.info, "info")
                                  e.stopPropagation();
                                }}
                                style={{ marginRight: "10px" }}
                                src={Info}
                              ></img>
                            </Tooltip>
                          )} */}
                        <BsChevronDown
                          style={{
                            ...accordianSVGstyle,
                            transform:
                              expanded !== `Tab-${tabIndex}`
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                          }}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        fontSize: "1rem",
                      }}
                    >
                      {TabElement.subSections.map((tile, tileIndex) => (
                        <div className="tab-element" key={tile.id}>
                          {tile.subSections.length ? (
                            <>
                              <Stack
                                sx={{
                                  margin: "1rem",
                                  "& .MuiAccordion-rounded": {
                                    border: "1px solid #dad2d2",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                <Accordion
                                  expanded={
                                    expandedTile === `Tile-${tileIndex}`
                                  }
                                  onChange={(e) => {
                                    handleChangeTile(`Tile-${tileIndex}`);
                                    e.stopPropagation();
                                  }}
                                  data-value={`Tile-${tileIndex}`}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    sx={{
                                      width: "100%",
                                      fontSize: "1.25rem",
                                      fontWeight: "600",
                                      "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      },
                                    }}
                                  >
                                    <p className="accordian-heading">
                                      {/* {tile.configObj.label} */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: tile.configObj.label,
                                        }}
                                      ></div>
                                    </p>
                                    <div>
                                      {/* {(tile.configObj.isInfoRequired == true ||
                                        tile.configObj.isInfoRequired ==
                                          "true") &&
                                        tile.configObj.info && (
                                          <Tooltip
                                            title="Info"
                                            placement="top-end"
                                          >
                                            <img
                                              onClick={(e) => {
                                                // handleOpenSideDrawer(
                                                //   tile.configObj.info
                                                // );
                                                handleOpenviewProfileDrawer(tile.configObj.info, "info")
                                                e.stopPropagation();
                                              }}
                                              style={{ marginRight: "10px" }}
                                              src={Info}
                                            ></img>
                                          </Tooltip>
                                        )} */}
                                      <BsChevronDown
                                        style={{
                                          ...accordianSVGstyle,
                                          transform:
                                            expandedTile !== `Tile-${tileIndex}`
                                              ? "rotate(0deg)"
                                              : "rotate(180deg)",
                                        }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {tile.subSections.map(
                                      (
                                        nestedSubsectionE,
                                        nestedSubsectionI
                                      ) => (
                                        <div
                                          className="tab-element"
                                          key={nestedSubsectionE.id}
                                        >
                                          {(((nestedSubsectionE.configObj
                                            .doesThisQuestionHaveSelection ===
                                            true ||
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveSelection ===
                                              "true") &&
                                            nestedSubsectionE.configObj
                                              .selectionType) ||
                                            ((nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse ===
                                              true ||
                                              nestedSubsectionE.configObj
                                                .doesThisQuestionHaveResponse ===
                                                "true") &&
                                              (nestedSubsectionE.configObj
                                                .responseType === "String" ||
                                                nestedSubsectionE.configObj
                                                  .responseType === "Boolean" ||
                                                nestedSubsectionE.configObj
                                                  .responseType ===
                                                  "Integer")) || (
                                                    ((nestedSubsectionE.configObj.isRatingRequired == true) || (nestedSubsectionE.configObj.isRatingRequired === 'true'))
                                                  )) && (
                                            <div className="accDetail-questions">
                                              <div style={{ display: "flex" }}>
                                                <p>
                                                  {/* {
                                                    nestedSubsectionE.configObj
                                                      .label
                                                  } */}
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: nestedSubsectionE.configObj.label,
                                                    }}
                                                  ></div>
                                                </p>
                                                {(nestedSubsectionE.configObj
                                                  .isResponseMandatory ===
                                                  true ||
                                                  nestedSubsectionE.configObj
                                                    .isResponseMandatory ===
                                                    "true" ||
                                                  nestedSubsectionE.configObj
                                                    .isSelectionMandatory ===
                                                    true ||
                                                  nestedSubsectionE.configObj
                                                    .isSelectionMandatory ===
                                                    "true" || 
                                                  nestedSubsectionE.configObj.isRatingMandatory === true ||
                                                  nestedSubsectionE.configObj.isRatingMandatory === 'true'
                                                    ) && (
                                                  <div style={{ color: "red" }}>
                                                    *
                                                  </div>
                                                )}
                                              </div>
                                              {(nestedSubsectionE.configObj
                                                .isInfoRequired == true ||
                                                nestedSubsectionE.configObj
                                                  .isInfoRequired == "true") &&
                                                nestedSubsectionE.configObj
                                                  .info && (
                                                  <Tooltip
                                                    title="Info"
                                                    placement="top-end"
                                                  >
                                                    <img
                                                      onClick={(e) => {
                                                        // handleOpenSideDrawer(
                                                        //   nestedSubsectionE
                                                        //     .configObj.info
                                                        // );
                                                        handleOpenviewProfileDrawer(nestedSubsectionE.configObj.info, "info")
                                                        e.stopPropagation();
                                                      }}
                                                      style={{
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                      }}
                                                      src={Info}
                                                    ></img>
                                                  </Tooltip>
                                                )}
                                            </div>
                                          )}
                                          {(nestedSubsectionE.configObj
                                            .doesThisQuestionHaveSelection ===
                                            true ||
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveSelection ===
                                              "true") &&
                                            nestedSubsectionE.configObj
                                              .selectionType && (
                                              <>
                                                <div className="rateOuter">
                                                  {nestedSubsectionE.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                                    (item, index) => {
                                                      return (
                                                        <div
                                                          className={`rate ${
                                                            vertualFormPayload[
                                                              tabIndex
                                                            ].subSections[
                                                              tileIndex
                                                            ].subSections[
                                                              nestedSubsectionI
                                                            ].assessmentResponseObjectDTO.selection.some(
                                                              (e) =>
                                                                e.id == item.id
                                                            )
                                                              ? "active"
                                                              : ""
                                                          } `}
                                                          onClick={(event) =>
                                                            handleChangeVertualPayload(
                                                              item,
                                                              "selection",
                                                              tabIndex,
                                                              tileIndex,
                                                              nestedSubsectionI
                                                            )
                                                          }
                                                        >
                                                          <p className="rateTitle">
                                                            {item.name}
                                                          </p>
                                                          {vertualFormPayload[
                                                            tabIndex
                                                          ].subSections[
                                                            tileIndex
                                                          ].subSections[
                                                            nestedSubsectionI
                                                          ].assessmentResponseObjectDTO.selection.some(
                                                            (e) =>
                                                              e.id == item.id
                                                          ) && (
                                                            <img
                                                              src={Checked}
                                                              className="checked"
                                                            ></img>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                                {vertualError[tabIndex]
                                                  .subSections[tileIndex]
                                                  .subSections[
                                                  nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                  .selection.isError && (
                                                  <FormHelperText className="Mui-error">
                                                    {
                                                      vertualError[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ]
                                                        .assessmentResponseObjectDTO
                                                        .selection.errorMessage
                                                    }
                                                  </FormHelperText>
                                                )}
                                              </>
                                            )}
                                          {nestedSubsectionE.configObj
                                            .doesThisQuestionHaveResponse &&
                                          nestedSubsectionE.configObj
                                            .responseType === "String" ? (
                                            <> 
                                            {
                                              (status === 'ACTIVE') ? (
                                                ((vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString?.length ?? 0) < 150) ? (
                                                  <div className="accDetal-xFeedback">
                                                    <div className="q-text like-text-field">
                                                    {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString)}
                                                    </div>
                                                  </div>
            
                                                ) : (
                                                  (vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id != readMoreId) ? (
                                                    <div className="accDetal-xFeedback">
                                                      <div className="q-text like-text-field">
                                                      {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString.substring(0, 140))}
                                                      <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id)}>
                                                          ...read more
                                                      </span>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="accDetal-xFeedback">
                                                      <div className="q-text like-text-field">
                                                      {parse(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString)}
                                                      <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.id)}>
                                                          read less
                                                      </span>
                                                      </div>
                                                    </div>
                                                  )
                                                )
                                              ) : (
                                                <>
                                                  <TextField
                                                    name="responseString"
                                                    value={
                                                      vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                                    }
                                                    onChange={(event) =>
                                                      handleChangeVertualPayload(
                                                        event,
                                                        "textField",
                                                        tabIndex,
                                                        tileIndex,
                                                        nestedSubsectionI
                                                      )
                                                    }
                                                    id="outlined-multiline-static"
                                                    label=""
                                                    variant="outlined"
                                                    placeholder={`Provide your feedback here ${
                                                      nestedSubsectionE.configObj
                                                        .minCharacterLimit ||
                                                      nestedSubsectionE.configObj
                                                        .maxCharacterLimit
                                                        ? `(limit: ${
                                                            nestedSubsectionE
                                                              .configObj
                                                              .minCharacterLimit
                                                              ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                                              : ""
                                                          }  ${
                                                            nestedSubsectionE
                                                              .configObj
                                                              .maxCharacterLimit
                                                              ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                                              : ""
                                                          }char)`
                                                        : ""
                                                    }`}
                                                    multiline
                                                    rows={3}
                                                    sx={textAreaStyle}
                                                    error={
                                                      vertualError[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                        .responseString.isError
                                                    }
                                                    helperText={
                                                      vertualError[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                        .responseString.isError &&
                                                      vertualError[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                        .responseString.errorMessage
                                                    }
                                                  />
                                                  {
                                                    !vertualError[tabIndex].subSections[tileIndex]
                                                      .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                      .responseString.isError && (
                                                        <FormHelperText
                                                          sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row-reverse'
                                                          }}
                                                        >
                                                          {vertualError[tabIndex].subSections[tileIndex]
                                                            .subSections[
                                                            nestedSubsectionI
                                                          ].assessmentResponseObjectDTO.responseString.errorMessage}
                                                        </FormHelperText>
                                                      )
                                                  }
                                                </>
                                                
                                              )
                                            }
                                            </>
                                          ) : nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse &&
                                            nestedSubsectionE.configObj
                                              .responseType === "Boolean" ? (
                                            <>
                                              <Stack sx={{ width: "50%" }}>
                                                <RadioGroup
                                                  name="responseBoolean"
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseBoolean
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      "ratio",
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI
                                                    )
                                                  }
                                                  row
                                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                                >
                                                  <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label={"Yes"}
                                                  />
                                                  <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label={"NO"}
                                                  />
                                                </RadioGroup>
                                              </Stack>
                                              {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[nestedSubsectionI]
                                                .assessmentResponseObjectDTO
                                                .responseBoolean.isError && (
                                                <FormHelperText className="Mui-error">
                                                  {
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseBoolean
                                                      .errorMessage
                                                  }
                                                </FormHelperText>
                                              )}
                                            </>
                                          ) : (
                                            nestedSubsectionE.configObj
                                              .doesThisQuestionHaveResponse &&
                                            nestedSubsectionE.configObj
                                              .responseType === "Integer" && (
                                              <>
                                                <TextField
                                                  name="responseInteger"
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      "numberField",
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI
                                                    )
                                                  }
                                                  id="outlined-multiline-static"
                                                  label=""
                                                  variant="outlined"
                                                  placeholder="Provide your feedback here "
                                                  multiline
                                                  rows={1}
                                                  sx={textAreaStyle}
                                                  error={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger.isError
                                                  }
                                                  helperText={
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                      .isError &&
                                                    vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ]
                                                      .assessmentResponseObjectDTO
                                                      .responseInteger
                                                      .errorMessage
                                                  }
                                                />
                                              </>
                                            )
                                          )}
                                          
                                            {(nestedSubsectionE.configObj.isRatingRequired === true ||
                                            nestedSubsectionE.configObj.isRatingRequired === 'true') && (
                                            <>
                                              <FormControl
                                                sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                              >
                                                <InputLabel id='demo-simple-select-label'>
                                                  Trending Rating
                                                </InputLabel>
                                                <Select
                                                  name='rating'
                                                  labelId='demo-simple-select-label'
                                                  id='demo-simple-select'
                                                  value={
                                                    vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                  ].assessmentResponseObjectDTO
                                                      .rating
                                                  }
                                                  renderValue={(selected) =>
                                                    selected.name
                                                  }
                                                  onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                      event,
                                                      'dropDownSelect',
                                                      tabIndex,
                                                      tileIndex,
                                                      nestedSubsectionI,
                                                    )
                                                  }
                                                  label='Trending Rating'
                                                >
                                                  {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                    (e) => {
                                                      return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                        .subSections[tileIndex]
                                                        .subSections[
                                                        nestedSubsectionI
                                                      ].assessmentResponseObjectDTO
                                                        .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                    },
                                                  )}
                                                </Select>
                                                {vertualError[tabIndex]
                                                  .subSections[tileIndex]
                                                  .subSections[
                                                  nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                  .rating.isError && (
                                                  <FormHelperText className='Mui-error'>
                                                    {
                                                      vertualError[tabIndex]
                                                      .subSections[tileIndex]
                                                      .subSections[
                                                      nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                        .rating.errorMessage
                                                    }
                                                  </FormHelperText>
                                                )}
                                              </FormControl>
                                            </>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </Stack>
                            </>
                          ) : (
                            <>
                              {(((tile.configObj
                                .doesThisQuestionHaveSelection === true ||
                                tile.configObj.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile.configObj.selectionType) ||
                                ((tile.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                  (tile.configObj.responseType === "String" ||
                                    tile.configObj.responseType === "Boolean" ||
                                    tile.configObj.responseType === "Integer")) || 
                                    ((tile.configObj.isRatingRequired == true) || (tile.configObj.isRatingRequired === 'true'))
                                    ) && (
                                <div className="accDetail-questions">
                                  <div style={{ display: "flex" }}>
                                    {/* <p>{tile.configObj.label}</p> */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: tile.configObj.label,
                                      }}
                                    ></div>
                                    {(tile.configObj.isResponseMandatory ===
                                      true ||
                                      tile.configObj.isResponseMandatory ===
                                        "true" ||
                                      tile.configObj.isSelectionMandatory ===
                                        true ||
                                      tile.configObj.isSelectionMandatory ===
                                        "true" || 
                                      tile.configObj.isRatingMandatory === true ||
                                      tile.configObj.isRatingMandatory === 'true'
                                        ) && (
                                      <div style={{ color: "red" }}>*</div>
                                    )}
                                  </div>
                                  {(tile.configObj.isInfoRequired == true ||
                                    tile.configObj.isInfoRequired == "true") &&
                                    tile.configObj.info && (
                                      <Tooltip title="Info" placement="top-end">
                                        <img
                                          onClick={(e) => {
                                            // handleOpenSideDrawer(
                                            //   tile.configObj.info
                                            // );
                                            handleOpenviewProfileDrawer(tile.configObj.info, "info")
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          src={Info}
                                        ></img>
                                      </Tooltip>
                                    )}
                                </div>
                              )}
                              {(tile.configObj.doesThisQuestionHaveSelection ===
                                true ||
                                tile.configObj.doesThisQuestionHaveSelection ===
                                  "true") &&
                                tile.configObj.selectionType && (
                                  <>
                                    <div className="rateOuter">
                                      {tile.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id))?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className={`rate ${
                                                vertualFormPayload[
                                                  tabIndex
                                                ].subSections[
                                                  tileIndex
                                                ].assessmentResponseObjectDTO.selection.some(
                                                  (e) => e.id == item.id
                                                )
                                                  ? "active"
                                                  : ""
                                              } `}
                                              onClick={(event) =>
                                                handleChangeVertualPayload(
                                                  item,
                                                  "selection",
                                                  tabIndex,
                                                  tileIndex,
                                                  null
                                                )
                                              }
                                            >
                                              <p className="rateTitle">
                                                {item.name}
                                              </p>
                                              {vertualFormPayload[
                                                tabIndex
                                              ].subSections[
                                                tileIndex
                                              ].assessmentResponseObjectDTO.selection.some(
                                                (e) => e.id == item.id
                                              ) && (
                                                <img
                                                  src={Checked}
                                                  className="checked"
                                                ></img>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    {vertualError[tabIndex].subSections[
                                      tileIndex
                                    ].assessmentResponseObjectDTO.selection
                                      .isError && (
                                      <FormHelperText className="Mui-error">
                                        {
                                          vertualError[tabIndex].subSections[
                                            tileIndex
                                          ].assessmentResponseObjectDTO
                                            .selection.errorMessage
                                        }
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                              {(tile.configObj.doesThisQuestionHaveResponse ===
                                true ||
                                tile.configObj.doesThisQuestionHaveResponse ===
                                  "true") &&
                              tile.configObj.responseType === "String" ? (
                                <>
                                {(status === 'ACTIVE') ? (
                                    ((vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString?.length ?? 0) < 150) ? (
                                      <div className="accDetal-xFeedback">
                                        <div className="q-text like-text-field">
                                        {parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString)}
                                        </div>
                                      </div>

                                    ) : (
                                    (vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id != readMoreId) ? (
                                      <div className="accDetal-xFeedback">
                                        <div className="q-text like-text-field">
                                        {parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString.substring(0, 140))}
                                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id)}>
                                            ...read more
                                        </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="accDetal-xFeedback">
                                        <div className="q-text like-text-field">
                                        {parse(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString)}
                                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.id)}>
                                            read less
                                        </span>
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <>
                                    <TextField
                                      name="responseString"
                                      value={
                                        vertualFormPayload[tabIndex].subSections[tileIndex].assessmentResponseObjectDTO.responseString
                                      }
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          "textField",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      id="outlined-multiline-static"
                                      label=""
                                      variant="outlined"
                                      placeholder={`Provide your feedback here ${
                                        tile.configObj.minCharacterLimit ||
                                        tile.configObj.maxCharacterLimit
                                          ? `(limit: ${
                                              tile.configObj.minCharacterLimit
                                                ? ` min: ${tile.configObj.minCharacterLimit}`
                                                : ""
                                            }  ${
                                              tile.configObj.maxCharacterLimit
                                                ? `max: ${tile.configObj.maxCharacterLimit} `
                                                : ""
                                            }char)`
                                          : ""
                                      }`}
                                      multiline
                                      rows={3}
                                      sx={textAreaStyle}
                                      error={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError
                                      }
                                      helperText={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError &&
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.errorMessage
                                      }
                                    />
                                    {
                                      !vertualError[tabIndex].subSections[
                                        tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseString.isError && (
                                          <FormHelperText
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row-reverse'
                                            }}
                                          >
                                            {vertualError[tabIndex].subSections[
                                              tileIndex
                                            ].assessmentResponseObjectDTO.responseString.errorMessage}
                                          </FormHelperText>
                                        )
                                    }
                                  </>
                                  

                                )}
                                </>
                              ) : (tile.configObj
                                  .doesThisQuestionHaveResponse === true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile.configObj.responseType === "Boolean" ? (
                                <>
                                  <Stack sx={{ width: "50%" }}>
                                    <RadioGroup
                                      name="responseBoolean"
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          .responseBoolean
                                      }
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          "ratio",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={"Yes"}
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={"NO"}
                                      />
                                    </RadioGroup>
                                  </Stack>
                                  {vertualError[tabIndex].subSections[tileIndex]
                                    .assessmentResponseObjectDTO.responseBoolean
                                    .isError && (
                                    <FormHelperText className="Mui-error">
                                      {
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseBoolean.errorMessage
                                      }
                                    </FormHelperText>
                                  )}
                                </>
                              ) : (
                                (tile.configObj.doesThisQuestionHaveResponse ===
                                  true ||
                                  tile.configObj
                                    .doesThisQuestionHaveResponse === "true") &&
                                tile.configObj.responseType === "Integer" && (
                                  <>
                                    <TextField
                                      name="responseInteger"
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          .responseInteger
                                      }
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          "numberField",
                                          tabIndex,
                                          tileIndex,
                                          null
                                        )
                                      }
                                      id="outlined-multiline-static"
                                      label=""
                                      variant="outlined"
                                      multiline
                                      placeholder="Provide your feedback here "
                                      rows={1}
                                      sx={textAreaStyle}
                                      error={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.isError
                                      }
                                      helperText={
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.isError &&
                                        vertualError[tabIndex].subSections[
                                          tileIndex
                                        ].assessmentResponseObjectDTO
                                          .responseInteger.errorMessage
                                      }
                                    />
                                  </>
                                )
                              )}
                              
                                {(tile.configObj.isRatingRequired === true ||
                                tile.configObj.isRatingRequired === 'true') && (
                                <>
                                  <FormControl
                                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                                  >
                                    <InputLabel id='demo-simple-select-label'>
                                      Trending Rating
                                    </InputLabel>
                                    <Select
                                      name='rating'
                                      labelId='demo-simple-select-label'
                                      id='demo-simple-select'
                                      onChange={(event) =>
                                        handleChangeVertualPayload(
                                          event,
                                          'dropDownSelect',
                                          tabIndex,
                                          tileIndex,
                                          null,
                                        )
                                      }
                                      value={
                                        vertualFormPayload[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                          .rating
                                      }
                                      renderValue={(selected) =>
                                        selected.name
                                      }
                                      label='Trending Rating'
                                    >
                                      {tile.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                        (e) => {
                                          return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                            .subSections[tileIndex]
                                            .assessmentResponseObjectDTO
                                            .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                        },
                                      )}
                                    </Select>
                                    { vertualError[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                      .rating.isError && (
                                      <FormHelperText className='Mui-error'>
                                        {
                                           vertualError[tabIndex]
                                          .subSections[tileIndex]
                                          .assessmentResponseObjectDTO
                                            .rating.errorMessage
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </div>
            </>
          ) : (
            <>
              {(((TabElement.configObj.doesThisQuestionHaveSelection === true ||
                TabElement.configObj.doesThisQuestionHaveSelection ===
                  "true") &&
                TabElement.configObj.selectionType) ||
                ((TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                  (TabElement.configObj.responseType === "String" ||
                    TabElement.configObj.responseType === "Boolean" ||
                    TabElement.configObj.responseType === "Integer")) || (
                      ((TabElement.configObj.isRatingRequired == true) || (TabElement.configObj.isRatingRequired === 'true'))
                    )) && (
                <div className="accDetail-questions">
                  <div style={{ display: "flex" }}>
                    {/* <p>{TabElement.configObj.label}</p> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: TabElement.configObj.label,
                      }}
                    ></div>
                    {(TabElement.configObj.isResponseMandatory === true ||
                      TabElement.configObj.isResponseMandatory === "true" ||
                      TabElement.configObj.isSelectionMandatory === true ||
                      TabElement.configObj.isSelectionMandatory === "true" ||
                      TabElement.configObj.isRatingMandatory === true ||
                      TabElement.configObj.isRatingMandatory === 'true'
                      ) && (
                      <div style={{ color: "red" }}>*</div>
                    )}
                  </div>
                  {(TabElement.configObj.isInfoRequired === true ||
                    TabElement.configObj.isInfoRequired === "true") &&
                    TabElement.configObj.info && (
                      <Tooltip title="Info" placement="top-end">
                        <img
                          onClick={(e) => {
                            // handleOpenSideDrawer(TabElement.configObj.info);
                            handleOpenviewProfileDrawer(TabElement.configObj.info, "info")
                            e.stopPropagation();
                          }}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          src={Info}
                        ></img>
                      </Tooltip>
                    )}
                </div>
              )}
              {(TabElement.configObj.doesThisQuestionHaveSelection === true ||
                TabElement.configObj.doesThisQuestionHaveSelection ===
                  "true") &&
                TabElement.configObj.selectionType && (
                  <>
                    <div className="rateOuter">
                      {(TabElement.configObj.selectableValues.sort((a, b) => a.id.localeCompare(b.id)))?.map(
                        (item, index) => {
                          return (
                            <div
                              className={`rate ${
                                vertualFormPayload[
                                  tabIndex
                                ].assessmentResponseObjectDTO.selection.some(
                                  (e) => e.id == item.id
                                )
                                  ? "active"
                                  : ""
                              } `}
                              onClick={(event) =>
                                handleChangeVertualPayload(
                                  item, //event
                                  "selection", //inputType
                                  tabIndex, //tabIndex
                                  null, //tileIndex
                                  null //nestedSubsectionI
                                )
                              }
                            >
                              <p className="rateTitle">{item.name}</p>
                              {vertualFormPayload[
                                tabIndex
                              ].assessmentResponseObjectDTO.selection.some(
                                (e) => e.id == item.id
                              ) && (
                                <img src={Checked} className="checked"></img>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .selection.isError && (
                      <FormHelperText className="Mui-error">
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .selection.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </>
                )}
              {(TabElement.configObj.doesThisQuestionHaveResponse === true ||
                TabElement.configObj.doesThisQuestionHaveResponse === "true") &&
              TabElement.configObj.responseType === "String" ? (
                <>
                {(status === 'ACTIVE') ? (
                    ((vertualFormPayload[tabIndex].assessmentResponseObjectDTO.responseString?.length ?? 0) < 150) ? (
                      <div className="accDetal-xFeedback">
                        <div className="q-text like-text-field">
                        {parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.responseString)}
                        </div>
                      </div>

                    ) : (
                    (vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id != readMoreId) ? (
                      <div className="accDetal-xFeedback">
                        <div className="q-text like-text-field">
                        {parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.responseString.substring(0, 140))}
                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id)}>
                            ...read more
                        </span>
                        </div>
                      </div>
                    ) : (
                      <div className="accDetal-xFeedback">
                        <div className="q-text like-text-field">
                        {parse(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.responseString)}
                        <span className="readmore-text" onClick={() => handleReadMore(vertualFormPayload[tabIndex].assessmentResponseObjectDTO.id)}>
                            read less
                        </span>
                        </div>
                      </div>
                    )
                    )
                ) : (
                  <>
                    <TextField
                      name="responseString"
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .responseString
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          "textField",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      id="outlined-multiline-static"
                      label=""
                      variant="outlined"
                      placeholder={`Provide your feedback here ${
                        TabElement.configObj.minCharacterLimit ||
                        TabElement.configObj.maxCharacterLimit
                          ? `(limit: ${
                              TabElement.configObj.minCharacterLimit
                                ? ` min: ${TabElement.configObj.minCharacterLimit}`
                                : ""
                            }  ${
                              TabElement.configObj.maxCharacterLimit
                                ? `max: ${TabElement.configObj.maxCharacterLimit} `
                                : ""
                            }char)`
                          : ""
                      }`}
                      multiline
                      rows={3}
                      sx={textAreaStyle}
                      error={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.isError
                      }
                      helperText={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.isError &&
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseString.errorMessage
                      }
                    />
                    {
                      !vertualError[tabIndex].assessmentResponseObjectDTO
                        .responseString.isError && (
                          <FormHelperText
                            sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse'
                            }}
                          >
                            {vertualError[tabIndex].assessmentResponseObjectDTO.responseString.errorMessage}
                          </FormHelperText>
                        )
                    }
                  </>
                  
                  
                )}
                </>
              ) : (TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                TabElement.configObj.responseType === "Boolean" ? (
                <>
                  <Stack sx={{ width: "50%" }}>
                    <RadioGroup
                      name="responseBoolean"
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .responseBoolean
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          "ratio",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={"NO"}
                      />
                    </RadioGroup>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .responseBoolean.isError && (
                      <FormHelperText className="Mui-error">
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .responseBoolean.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </Stack>
                </>
              ) : (
                (TabElement.configObj.doesThisQuestionHaveResponse === true ||
                  TabElement.configObj.doesThisQuestionHaveResponse ===
                    "true") &&
                TabElement.configObj.responseType === "Integer" && (
                  <>
                    <TextField
                      name="responseInteger"
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .responseInteger
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          "numberField",
                          tabIndex,
                          null,
                          null
                        )
                      }
                      id="outlined-multiline-static"
                      label=""
                      variant="outlined"
                      multiline
                      placeholder="Provide your feedback here "
                      rows={1}
                      sx={textAreaStyle}
                      error={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.isError
                      }
                      helperText={
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.isError &&
                        vertualError[tabIndex].assessmentResponseObjectDTO
                          .responseInteger.errorMessage
                      }
                    />
                  </>
                )
              )}
              
                {(TabElement.configObj.isRatingRequired === true ||
                TabElement.configObj.isRatingRequired === 'true') && (
                <>
                  <FormControl
                    sx={{width: {md: '50%', sm: '100%'}, marginTop: '1rem'}}
                  >
                    <InputLabel id='demo-simple-select-label'>
                      Trending Rating
                    </InputLabel>
                    <Select
                      name='rating'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={
                        vertualFormPayload[tabIndex].assessmentResponseObjectDTO
                          .rating
                      }
                      renderValue={(selected) =>
                        selected.name
                      }
                      onChange={(event) =>
                        handleChangeVertualPayload(
                          event,
                          'dropDownSelect',
                          tabIndex,
                          null,
                          null,
                        )
                      }
                      label='Trending Rating'
                    >
                      {TabElement.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                        (e) => {
                          return <MenuItem style={{background: (vertualFormPayload[tabIndex].assessmentResponseObjectDTO.rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                        },
                      )}
                    </Select>
                    {vertualError[tabIndex].assessmentResponseObjectDTO
                      .rating.isError && (
                      <FormHelperText className='Mui-error'>
                        {
                          vertualError[tabIndex].assessmentResponseObjectDTO
                            .rating.errorMessage
                        }
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
            </>
          )}
        </div>
      ));
    }
  };

  const styleSubmit = () => {
    if(status === 'ACTIVE') {
      return ({
        pointerEvents: 'none',
        opacity: '50%',
        transition: 'opacity 0.3s',
        visibility: 'hidden',
      })
    } else {
      if(isSubmitDisabled || submitLoader) {
        return ({
          pointerEvents: 'none',
          opacity: '50%',
          transition: 'opacity 0.3s'
        })
      } else {
        return ({
          pointerEvents: 'auto',
          opacity: '100%',
          transition: 'opacity 0.3s'
        })
      }
    }
  }

  const styleSaveAsDraft = () => {
    if(status === 'ACTIVE') {
      return ({
        pointerEvents: 'none',
        opacity: '50%',
        transition: 'opacity 0.3s',
        visibility: 'hidden',
      })
    } else {
      if(submitLoader) {
        return ({
          pointerEvents: 'none',
          opacity: '50%',
          transition: 'opacity 0.3s'
        })
      } else {
        return ({
          pointerEvents: 'auto',
          opacity: '100%',
          transition: 'opacity 0.3s'
        })
      }
    }
  }

  return (
    <div className="feedback-main11 selfAssesmentAllinone11" style={{padding:"0px !important", backgroundColor:"#ffffff"}}>
              {
                isThereEmptyForm ? (
                  <div className="feedbackAssessment" style={{
                    justifyContent: 'flex-start',
                    paddingTop: 0,

                    fontSize: '0.9rem',
                    fontWeight: '500',
                    lineHeight: '24px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: 'var(--tertiary)',
                    margin: 0,
                  }}>
                    {errorMessage ?? 'Assesment Form Not Found'}
                  </div>
                ) : (



                  <div className="feedback-main11" style={{padding:"0px !important", backgroundColor:"#ffffff"}}>
                    {isLoading ? (
                      <AccSkeleton />
                    ) : (
                      <div className="feedbackAssessment m-3">
                        {/* <div className="d-flex justify-content-between align-items-center bor-b">
                            <div className="left d-flex align-items-center gap-3">
                                {empInfo?.profilePhoto ?
                                    <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
                                    :
                                    <Avatar 
                                        name={empInfo?.name} 
                                        size="75" 
                                        className="userImg"
                                        color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                                    />
                                }
                                    <div>
                                        <p className="title">{empInfo?.name}</p>
                                        <p className="id">{empInfo?.employeeDesignation}</p>
                                    </div>
                            </div>
                            <div>                                
                            <button className="viewBtn" onClick={() => {
                              handleOpenviewProfileDrawer(empInfo, "profile")
                              setdrawerData(empInfo)
                              setIsOpenDrawer(true)
                            }}
                            >View Profile</button>
                            </div>
                        </div> */}
                        {/* <div className="peer-feed-btn" onClick={() => handleOpenviewProfileDrawer(null, 'peer')}>Peer Feedback</div> */}
                        <>
                          {(formData.assessmentFormDTO || formData) &&
                            createDynamicForm(
                              formData.assessmentFormDTO
                                ? formData.assessmentFormDTO
                                : formData
                            )}
                        </>

                        {/* <>
                        <div className="upload-document-container">
                          <div className="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-o4b71y-MuiAccordionSummary-content">

                            <div className="item-distributor">
                              <>
                                <div class="doc-distibute">
                                  {
                                    files.map((e, index) => (
                                      <>
                                        <div className="documents">
                                          <div onClick={() => openDoc(index)} className="documents-name">
                                            {e?.fname}
                                          </div>
                                          {
                                            (status != 'ACTIVE') && (
                                              <div onClick={() => handleDeleteDoc(index)} className="delete">
                                                X
                                              </div>
                                            )
                                          }

                                        </div>
                                      </>
                                    ))
                                  }


                                </div>
                              </>

                              {
                                (status != 'ACTIVE') && (
                                  <button
                                    style={styleSaveAsDraft()}
                                    className="upload-doc-button"
                                  >
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                      style={{ display: 'none' }}
                                      id="imageInput"
                                    />
                                    <label htmlFor="imageInput" className="btn btn-dark edit-button">
                                    <FaCamera />
                                    </label>
                                    insert
                                  </button>
                                )
                              }

                            </div>

                          </div>
                          </div>
                        
                        </> */}

                        <>
                              
                        </>
                        {/* <div className="d-flex justify-content-end gap-3 pt-5 main-btn">
                          <div className="valuesBtn draft" style={styleSaveAsDraft()}>Auto Save 2 sec</div>
                          <button
                            style={styleSaveAsDraft()}
                            className="valuesBtn save-draft"
                            onClick={() => handleSaveAsDraft('submitAsDraft')}
                          >
                            {submitLoader ? (
                              <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                              "Save as draft"
                            )}
                          </button>


                          




                          <button
                            style={styleSubmit()}
                            className="valuesBtn next"
                            onClick={() => handleValidate()}
                          >
                            {submitLoader ? (
                              <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div> */}
                      </div>
                    )}

{
            showConfirmationPopup && 
            <DialogPopup openDialog={showConfirmationPopup} setOpenDialog={setShowConfirmationPopup} redirect={false} >
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                Are you sure you want to submit? Once submitted you will not be able to Edit or make any further changes. Please click on Confirm to proceed or Cancel to edit.
                {/* Thank you for completing the appraisal. Your feedback is important. Please note that once submitted, revisions will not be feasible. Click 'Confirm' if you intend to proceed. If you want to further edit or revise, click 'Cancel' */}
                </div>
                <div className="multi-btn" >
                <button className="button"
                    onClick={() => {
                      // if(isDraftPopup){
                      //   handleSubmitDraft()
                      // } else {
                        handleSubmitFeedback()
                      // }
                    }}
                >Confirm</button>
                <button className="button"
                onClick={() => setShowConfirmationPopup(false)}
                >Cancel</button>
                </div>
                </div>
            </DialogPopup>
        }

                    {openDialog && (
                      <DialogPopup
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        redirect={true}
                        path="/my-team"
                      >
                        <div className="feedback-dialog-wrap">
                          <img src={checkIcon} className="checkIcon"></img>
                          <div className="content">
                            {!isDraftPopup ? 'You have successfully submitted your feedback' : 'You have successfully saved this as a draft.'}
                            
                          </div>
                          <button
                            className="button"
                            onClick={() => {
                              // history.push("/my-team");
                              setOpenDialog(false);
                              setIsDraftPopup(false)
                            }}
                          >
                            Done
                          </button>
                        </div>
                      </DialogPopup>
                    )}
                  </div>




                )
              }
      

      {isOpenDrawer && (
        // <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
        //   <div className="info">
        //     {drawerData && (
        //       <div dangerouslySetInnerHTML={{ __html: drawerData }} />
        //     )}
        //   </div>
        // </SideDrawer>
        <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
          
            {
              viewProfileDrawerData?.profile &&
              // <EmpProfile empInfo={viewProfileDrawerData?.data} />
              <EmpProfile empInfo={drawerData} />
            }
            {viewProfileDrawerData?.info && (
              <div className="info">

              <div dangerouslySetInnerHTML={{ __html: drawerData }} />
              </div>
            )}
            </SideDrawer>
      )}
    </div>
  );
};

export default SelfAssesmentAllinone;
