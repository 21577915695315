import React, { useState } from 'react';
import { Button, FormControl, Select, TextField, Dialog, DialogTitle, DialogActions, Typography } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FaAngleDown } from "react-icons/fa6";
import Notes from './Notes';
import IDPBannerUserProfile from './IDPBannerUserProfile';


const DevelopmentActionPlans = ({ }) => {
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(null);

    const handleOpen = (modalId) => {
        setOpenModal(modalId);
    };

    const handleClose = () => {
        setOpenModal(null);
    };

    return (

        <div className="dashboard-main">
        {/*----- Top Banner Section -----*/}
        <IDPBannerUserProfile />
  
        <div className="outer-layout">
          {/*----- Development Goals Section -----*/}
          <div className="first-column">
            {/* <DevelopmentGoals /> */}
  
            <div>
            <div className="first-row">
                <div className="form-development-goal">
                    <div className="goal-contant">
                        <div className="development-bottom-border">
                            <div className="field-row">
                                {/*----- Development Goal -----*/}
                                <div className="row-half">
                                    <div className="select-field">
                                        <Typography className="field-label">Development Goal</Typography>
                                        <TextField
                                            name="developmentGoal"
                                            id="outlined-multiline-static"
                                            placeholder="Development Goal"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: "100%",
                                                mt: "6px",
                                                backgroundColor: "#F9F9F9",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderRadius: "8px",
                                                    border: "none",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#979797",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "400"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                {/*----- Goal linked to -----*/}
                                <div className="row-half">
                                    <div className="select-field">
                                        <Typography className="field-label">Goal linked to</Typography>
                                        <div>
                                            <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                                <Select
                                                    sx={{
                                                        backgroundColor: "#F9F9F9",
                                                        width: "100%",
                                                        mt: "6px",
                                                        borderRadius: "8px",
                                                        "& fieldset": {
                                                            border: "none",
                                                            borderRadius: "8px",
                                                        },
                                                    }}
                                                    size="small"
                                                    name="rating"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={FaAngleDown}
                                                ></Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*----- Development & Add Button -----*/}
                        <div class="btnList mt-3">
                            <a href="#" class="btn planebtnActive" role="button">
                                Development Action plan 1
                            </a>

                            <a href="#" class="btn planebtn" role="button">
                                + Add
                            </a>
                        </div>

                        {/*----- Learning Activities -----*/}
                        <div className="field-row">
                            <div className="row-half">
                                <div className="select-field">
                                    <Typography className="field-label">Learning Activities</Typography>
                                    <div>
                                        <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                            <Select
                                                sx={{
                                                    backgroundColor: "#F9F9F9",
                                                    width: "100%",
                                                    mt: "6px",
                                                    borderRadius: "8px",
                                                    "& fieldset": {
                                                        border: "none",
                                                        borderRadius: "8px",
                                                    },
                                                }}
                                                size="small"
                                                name="rating"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                IconComponent={FaAngleDown}
                                            ></Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="formCard">
                            <div className="field-row">
                                {/*----- Course Name -----*/}
                                <div className="row-half">
                                    <div className="text-field">
                                        <Typography className="field-label">Course Name</Typography>
                                        <TextField
                                            name="responseString"
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                backgroundColor: "#F9F9F9",
                                                width: "100%",
                                                mt: "6px",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderRadius: "8px",
                                                    border: "none",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#979797",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "400",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>

                                {/*----- Course Link -----*/}
                                <div className="row-half">
                                    <div className="text-field">
                                        <Typography className="field-label">Course Link</Typography>
                                        <TextField
                                            name="responseString"
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                backgroundColor: "#F9F9F9",
                                                width: "100%",
                                                mt: "6px",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderRadius: "8px",
                                                    border: "none",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#979797",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    fontWeight: "400",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*----- Approved Status -----*/}
                            <div className="field-row">
                                <div className="text-field">
                                    <Typography className="field-label">Approved Status</Typography>
                                    <div className="field-row-checbox">
                                        <div>
                                            <input
                                                type="checkbox"
                                                class="item-checkbox"
                                                value="option1"
                                            />{" "}
                                            Manager
                                        </div>
                                        <div>
                                            <input
                                                type="checkbox"
                                                class="item-checkbox"
                                                value="option1"
                                            />{" "}
                                            HRBP
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----- Details -----*/}
                            <div className="field-row">
                                <div className="text-field">
                                    <div className="select-field">
                                        <Typography className="field-label">Details</Typography>
                                        <div>
                                            <TextField
                                                name="responseString"
                                                id="outlined-multiline-static"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    backgroundColor: "#F9F9F9",
                                                    width: "100%",
                                                    mt: "6px",
                                                    borderRadius: "8px",
                                                    "& fieldset": {
                                                        borderRadius: "8px",
                                                        border: "none",
                                                    },
                                                    "& .MuiInputBase-input::placeholder": {
                                                        color: "#979797",
                                                        opacity: 1,
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        fontWeight: "400",
                                                    },
                                                }}
                                                multiline
                                                minRows={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="field-row">
                                {/*----- Start Date -----*/}
                                <div className="row-half">
                                    <div className="date-field">
                                        <Typography className="field-label">Start Date</Typography>
                                        <div>
                                            <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        sx={{
                                                            backgroundColor: "#F9F9F9",
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: "8px",
                                                                border: "none",
                                                            },
                                                        }}
                                                        name="feedbackExpectedBy"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                size={"small"}
                                                                variant="outlined"
                                                                sx={{
                                                                    pointerEvents: "none",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        button: {
                                                                            pointerEvents: "all",
                                                                        },
                                                                    },
                                                                    mt: "6px",
                                                                    width: "100%",
                                                                    backgroundColor: "#F9F9F9",
                                                                    borderRadius: "8px",
                                                                    "& fieldset": {
                                                                        borderRadius: "8px",
                                                                        border: "none",
                                                                    },
                                                                    "& .css-k4qjio-MuiFormHelperText-root":
                                                                    {
                                                                        backgroundColor: "#ffffff",
                                                                        margin: "0px",
                                                                        paddingLeft: "0.5rem",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        border: "none",
                                                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                                                        borderRadius: "8px",
                                                                        button: {
                                                                            pointerEvents: "all",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input::placeholder": {
                                                                        color: "red",
                                                                        opacity: 1,
                                                                    },
                                                                }}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>

                                {/*----- End Date -----*/}
                                <div className="row-half">
                                    <div className="date-field">
                                        <Typography className="field-label">End Date</Typography>
                                        <div>
                                            <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        sx={{
                                                            backgroundColor: "#F9F9F9",
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: "8px",
                                                                border: "none",
                                                            },
                                                        }}
                                                        name="feedbackExpectedBy"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                size={"small"}
                                                                variant="outlined"
                                                                sx={{
                                                                    pointerEvents: "none",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        button: {
                                                                            pointerEvents: "all",
                                                                        },
                                                                    },
                                                                    mt: "6px",
                                                                    width: "100%",
                                                                    borderRadius: "8px",
                                                                    backgroundColor: "#F9F9F9",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        borderRadius: "8px",
                                                                    },
                                                                    "& .css-k4qjio-MuiFormHelperText-root":
                                                                    {
                                                                        backgroundColor: "#ffffff",
                                                                        margin: "0px",
                                                                        paddingLeft: "0.5rem",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        border: "none",
                                                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                                                        borderRadius: "8px",
                                                                        button: {
                                                                            pointerEvents: "all",
                                                                        },
                                                                        "& .MuiInputBase-input::placeholder":
                                                                        {
                                                                            color: "#979797",
                                                                            opacity: 1,
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            fontWeight: "400",
                                                                        },
                                                                    },
                                                                }}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----- Status -----*/}
                            <div className="field-row">
                                <div className="row-half">
                                    <div className="select-field">
                                        <Typography className="field-label">Status</Typography>
                                        <div>
                                            <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                                <Select
                                                    sx={{
                                                        backgroundColor: "#F9F9F9",
                                                        width: "100%",
                                                        mt: "6px",
                                                        borderRadius: "8px",
                                                        "& fieldset": {
                                                            border: "none",
                                                            borderRadius: "8px",
                                                        },
                                                        "& .MuiInputBase-input::placeholder": {
                                                            color: "#979797",
                                                            opacity: 1,
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            fontWeight: "400",
                                                        },
                                                    }}
                                                    size="small"
                                                    name="rating"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={FaAngleDown}
                                                ></Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----- Cancel & Save Button -----*/}
                <div className="d-flex pt-3 justify-content-end gap-3 main-btn formLastBtn">
                    <button className="valuesBtn save-draft">Cancel</button>
                    <a href="#" role="button" onClick={() => handleOpen("modal2")}>
                        <button className="valuesBtn next">Save</button>
                    </a>
                </div>

                {/*----- Save Modal -----*/}
                <Dialog
                    className="customModel"
                    open={openModal === "modal2"}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        Please save the changes before proceeding
                    </DialogTitle>
                    <DialogActions>
                        <Button className="btn planebtnActive" onClick={handleClose}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
          </div>
  
          {/*----- Notes Section -----*/}
          <div className="second-column1">
            <Notes />
          </div>
        </div>
      </div>


      
    );
};

export default React.memo(DevelopmentActionPlans);
