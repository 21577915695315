import React from 'react';
import { Dialog, Button, Grid, Typography, MenuItem, Select, Box, TextField, InputAdornment } from '@mui/material';
import RoleCloseIcon from "../../../../../assets/images/successionPlan/roleClose.svg";
import { IoSearchOutline } from "react-icons/io5";

const AddingRoleModal = ({ showAddingRoleEdit, handleAddingRoleNo }) => {

    const roleDataList = [
        { title: "Customer Obsession" },
        { title: "Ownership" },
        { title: "Invent And Simplify" },
        { title: "Invent And Simplify" },
        { title: "Hire and Develop the Best" },
        { title: "Ownership" },
        { title: "Customer Obsession" },
        { title: "Customer Obsession" },
        { title: "Customer Obsession" },
    ];

    const incumbentDataList = [
        { title: "Savannah Nguyen" },
        { title: "manisha Goal" },
    ];

    const readyNowDataList = [
        { title: "Floyd Miles" },
        { title: "Bessie Cooper" },
    ];

    const readyIn12MonthsDataList = [
        { title: "Dianne Russell" },
    ];

    const readyIn24MonthsDataList = [
        { title: "Courtney Henry" },
    ];

    const months24DataList = [
        { title: "Brooklyn Simmons" },
        { title: "Ronald Richards" },
    ];

    return (
        <Dialog
            open={showAddingRoleEdit}
            onClose={handleAddingRoleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="adding-role-edit">
                {/*----- Adding for Card -----*/}
                <Grid item xs={12} md={12} sm={12} className="adding-role-card">
                    <Typography className="search-field-label">{"Adding for"}</Typography>
                    <Typography className="role-value">{"CEO"}</Typography>
                </Grid>

                {/*----- Add role competencies -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="title-field-label">{"Add role competencies"}</Typography>
                    <TextField
                        name="addRoleCompetencies"
                        variant="outlined"
                        placeholder="Add role competencies"
                        fullWidth
                        size="small"
                        className="adding-text-field"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {roleDataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- Add successor names & Incumbent -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="title-field-label">{"Add successor names"}</Typography>
                    <Typography className="search-field-label adding-mt-10">{"Incumbent"}</Typography>
                    <TextField
                        name="incumbent"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        size="small"
                        className="adding-text-field"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {incumbentDataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- Ready Now -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="search-field-label adding-mt-20">{"Ready Now"}</Typography>
                    <TextField
                        name="readyNow"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        size="small"
                        className="adding-text-field"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {readyNowDataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- Ready in 12 months -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="search-field-label adding-mt-20">{"Ready in 12 months"}</Typography>
                    <TextField
                        name="readyIn12Months"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {readyIn12MonthsDataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- Ready in 24 Months -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="search-field-label adding-mt-20">{"Ready in 24 Months"}</Typography>
                    <TextField
                        name="readyIn24Months"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        size="small"
                        className="adding-text-field"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {readyIn24MonthsDataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- 24+ Months -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="search-field-label adding-mt-20">{"24+ Months"}</Typography>
                    <TextField
                        name="24Months"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        size="small"
                        className="adding-text-field"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoSearchOutline />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className='adding-role-container'>
                        {months24DataList.map((item, index) => (
                            <div className='adding-role-border'>
                                <div className='adding-role-div'>
                                    <Typography className='adding-role-type'>{item.title}</Typography>
                                    <img
                                        src={RoleCloseIcon}
                                        alt="RoleCloseIcon"
                                        height={12}
                                        width={12}
                                        className='adding-role-close'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                {/*----- Add short mitigation plans -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="title-field-label adding-mt-20">{"Add short mitigation plans"}</Typography>
                    <TextField
                        name="addShortPlans"
                        variant="outlined"
                        placeholder="Search successor"
                        fullWidth
                        multiline
                        rows={5}
                        value={"Will continue to keep his focus on his primarily development areas that he has been working over the last couple of years. Additionally, I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus). Development area carried from previous LPA -- Ability to Earn Trust across organizational boundaries and think outside the standard Amazon model and ability to understand the emotional."}
                        className="adding-text-area"
                    />
                </Grid>


                <Grid item xs={12} md={12} sm={12} className="adding-bottom-btn">
                    <button className="adding-cancel-btn">
                        {"Cancel"}
                    </button>

                    <button className="adding-save-btn">
                        {"Done"}
                    </button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AddingRoleModal;