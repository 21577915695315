export const EmpProfileData = {
  performanceForms: [
    {
      id: "6bc2b840-885e-4de5-b394-96881de38deb",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      fileName: "Vish3.2020.pdf",
      cycleName: "Annual Review : 2019-20",
      year: "2020",
      cyclePeriod: "2019-20",
      path: "https://pdfobject.com/pdf/sample.pdf",
    },
  ],
  feedbackReports: [
    {
      id: "6bc2b840-885e-4de5-b394-96881de38deb",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      fileName: "Vish3.2020.pdf",
      cycleName: "Annual Review : 2019-20",
      year: "2020",
      cyclePeriod: "2019-20",
      path: "https://pdfobject.com/pdf/sample.pdf",
    },
  ],
  ratingHistory: [
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.960697Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.960697Z",
      id: "3ba86884-273e-4b70-af2f-bbd7cb0a6ba4",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2020-21",
      ratingName: "OutputRating",
      trendingRating: "SP : Strong Performance",
      isApproved: null,
      reviewYear: "2021",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.962696Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.962696Z",
      id: "fd057e2e-e86a-4f72-bb88-ac8eed9822ed",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2022-23",
      ratingName: "InputRating",
      trendingRating: "SP : Strong Performance",
      isApproved: null,
      reviewYear: "2023",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.960697Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.960697Z",
      id: "ce6438d7-0793-4809-8da7-968013fbf702",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2021-22",
      ratingName: "InputRating",
      trendingRating: "PNI : Performance Need Improvement",
      isApproved: null,
      reviewYear: "2022",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.961696Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.961696Z",
      id: "c331d72d-68bd-4e19-ad3d-2bb65d94614a",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2021-22",
      ratingName: "OutputRating",
      trendingRating: "EP : Exceptional Performance",
      isApproved: null,
      reviewYear: "2022",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.962696Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.962696Z",
      id: "ea0c5408-5a74-457d-9749-9c31167ae5ad",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2022-23",
      ratingName: "OutputRating",
      trendingRating: "PNI : Performance Need Improvement",
      isApproved: null,
      reviewYear: "2023",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T08:03:01.145952Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T08:03:01.145952Z",
      id: "445c27b4-e975-41b3-8660-2f5a21ebf535",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2020-21",
      ratingName: "OverallRating",
      trendingRating: "SP : Strong Performance",
      isApproved: null,
      reviewYear: "2021",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T08:03:01.147952Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T08:03:01.147952Z",
      id: "6c9cddbf-04aa-4a1b-83ac-58337beb70f7",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2022-23",
      ratingName: "OverallRating",
      trendingRating: "SP : Strong Performance",
      isApproved: null,
      reviewYear: "2023",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T08:03:01.146952Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T08:03:01.146952Z",
      id: "4cf029a5-548b-43bc-aa22-eec123f17ec4",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2021-22",
      ratingName: "OverallRating",
      trendingRating: "PNI : Performance Need Improvement",
      isApproved: null,
      reviewYear: "2022",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T07:53:33.959697Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T07:53:33.959697Z",
      id: "773c846a-9c47-490f-9e7f-84d981fc2243",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2020-21",
      ratingName: "InputRating",
      trendingRating: "EP : Exceptional Performance",
      isApproved: null,
      reviewYear: "2021",
    },
    {
      createdBy: "anonymousUser",
      createdDate: "2024-06-13T08:03:01.145952Z",
      lastModifiedBy: "anonymousUser",
      lastModifiedDate: "2024-06-13T08:03:01.145952Z",
      id: "fff63ebf-297c-4cc3-8921-c02078db8bc4",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      cycleId: null,
      cycleType: null,
      cycleName: "Annual Review : 2019-20",
      ratingName: "OverallRating",
      trendingRating: "EP : Exceptional Performance",
      isApproved: null,
      reviewYear: "2020",
    },
  ],
  roles: [
    {
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
  ],
  competencyFeedback: [
    {
      competencyName: "Influencing / Building Consensus",
      reviewYear: "2023",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Execution Rigour",
      reviewYear: "2023",
      ratingDescription: "No View",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Organization Building",
      reviewYear: "2023",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Analytical Solutioning",
      reviewYear: "2023",
      ratingDescription: "Strength Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Problem Solving / Managing Ambiguity",
      reviewYear: "2023",
      ratingDescription: "No View",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Analytical Solutioning",
      reviewYear: "2022",
      ratingDescription: "No View",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Technical Skills",
      reviewYear: "2023",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2022-23",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Problem Solving / Managing Ambiguity",
      reviewYear: "2022",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Execution Rigour",
      reviewYear: "2022",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Influencing / Building Consensus",
      reviewYear: "2022",
      ratingDescription: "Strength Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Technical Skills",
      reviewYear: "2022",
      ratingDescription: "Development Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
    {
      competencyName: "Organization Building",
      reviewYear: "2022",
      ratingDescription: "Strength Area",
      companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
      sectionName: "Input / Competency",
      cycle: "2021-22",
      roleId: "2a2d1a04-01ba-4f7c-9fa9-51dbda15c4dc",
      roleName: "Business Analyst, Business Analytics",
    },
  ],
  movementInTheJourney: {
    "Execution Rigour": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Building Effective Teams (People Manager Only )": {
      expectedRating: 0,
      actualRatingLastYear: 0,
      actualRatingCurrentYear: 0,
    },
    "Analytical Solutioning": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Technical Skills": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Customer Centricity": {
      expectedRating: 0,
      actualRatingLastYear: 0,
      actualRatingCurrentYear: 0,
    },
    "Influencing / Building Consensus": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Organization Building": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Problem Solving / Managing Ambiguity": {
      expectedRating: 8,
      actualRatingLastYear: 5,
      actualRatingCurrentYear: 0,
    },
    "Analytics Technical Skills": {
      expectedRating: 0,
      actualRatingLastYear: 0,
      actualRatingCurrentYear: 0,
    },
  },
  qualitativeComments: [
    {
      id: "e8f190c7-93c3-47fa-941a-ca3bea2ea475",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      annualCycleId: "9b36f8c2-4cf8-4970-85da-6d5c9f4b0a44",
      annualCycleName: "2019-20",
      year: "2020",
      strengthAreaManager:
        "He constantly encourages me to work with my full potential and reminds me my responsibilities often when I seems offset from my goals.",
      strengthAreaPeers:
        "He constantly encourages me to work with my full potential and reminds me my responsibilities often when I seems offset from my goals.",
      developmentAreaManager:
        "Join our weekly stand-ups randomly [only when available] to have look on team's performance curve and suggest whenever he feels that something has to be improved on the spot.",
      developmentAreaPeers:
        "Join our weekly stand-ups randomly [only when available] to have look on team's performance curve and suggest whenever he feels that something has to be improved on the spot.",
      key: "104551_2020",
    },
    {
      id: "81e8665a-d3ad-4afd-bdbb-92dd25ee3498",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      annualCycleId: "9b36f8c2-4cf8-4970-85da-6d5c9f4b0a45",
      annualCycleName: "2020-21",
      year: "2021",
      strengthAreaManager:
        "log backup management is one task where it required a lot of back and forth handling issues across multiple teams.",
      strengthAreaPeers:
        "log backup management is one task where it required a lot of back and forth handling issues across multiple teams.",
      developmentAreaManager:
        "communication skills and push back on events where things are not on priority.",
      developmentAreaPeers:
        "communication skills and push back on events where things are not on priority.",
      key: "104551_2021",
    },
    {
      id: "7c553485-6201-4ff9-b0e9-c7199519d8ed",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      annualCycleId: "9b36f8c2-4cf8-4970-85da-6d5c9f4b0a46",
      annualCycleName: "2021-22",
      year: "2022",
      strengthAreaManager:
        "-> He has worked very well in overall all the parts. \n-> He has deep knowledge of the entire system\n-> He can easily identify impacted regions for particular problem",
      strengthAreaPeers:
        "'-> He has worked very well in overall all the parts. \n-> He has deep knowledge of the entire system\n-> He can easily identify impacted regions for particular problem",
      developmentAreaManager:
        "'-> Should allow other folks to learn more about core system and not only allow older folks to work on new tech/challenges.",
      developmentAreaPeers:
        "'-> Should allow other folks to learn more about core system and not only allow older folks to work on new tech/challenges.",
      key: "104551_2022",
    },
    {
      id: "bcb35eb1-54b9-4fdc-bfe2-9d66608260d5",
      employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
      annualCycleId: "9b36f8c2-4cf8-4970-85da-6d5c9f4b0a43",
      annualCycleName: "2022-23",
      year: "2023",
      strengthAreaManager:
        "'-> able to communicate easily\n-> regular 1:1 and feedback\n-> can share thoughts easily and can share view points with her\n-> she listen our view points and implement that",
      strengthAreaPeers:
        "'-> able to communicate easily\n-> regular 1:1 and feedback\n-> can share thoughts easily and can share view points with her\n-> she listen our view points and implement that",
      developmentAreaManager: "n/a",
      developmentAreaPeers: "n/a",
      key: "104551_2023",
    },
  ],
};

export const EmployeeInfoDetails = {
  employeeId: "6e534989-4f22-489e-88ff-2a96928b7d2c",
  employeeName: "Vish3",
  profilePhotoPath: null,
  appraisalStatus: "Inelligible",
  appraisalId: null,
  managerFeedbackstatus: "Inelligible",
  peerFeedbackstatus: "0-0",
  function: "Business Enablement",
  subFunction: null,
  employeeLevel: "Non Tech",
  employeeType: "Analytics",
  empstatus: "Live",
  company: "PhonePe",
  employeeGrade: "7",
  employeeDesignation: null,
  rating: null,
  employeeNo: "104551",
  emailId: "vish3@phonepe.com",
  subTeam: null,
  relationship: null,
  manager: null,
  isEligible: false,
  isManagerFormPresent: null,
  isManagerFeedbackCycleActive: false,
  eligibleForPromotion: null,
  isManagerPresent: true,
  isManager: true,
  calibratedRating: null,
  isFinalCalibiratedRating: null,
  isPromotionApproved: false,
  tenure: "17 years and 0 months",
  timeSinceLastPromotion: "28 months 21 days",
  eepCompletion: null,
  pod: "NULL",
  subPod: "NULL",
  idpIdle: "0 hours",
  timeInLevel: "2 years",
  paceOfPromotion: "3.7 years",
  mobileNumber: null,
  gender: "MALE",
  lastPerformanceRating: "PNI : Performance Need Improvement",
  hrbp: true,
};
