import React, { useState } from 'react';
import { Typography, TextField, Grid, InputAdornment, Box } from "@mui/material";
import ViewPlan from './components/ViewPlan';
import EditPlan from './components/EditPlan';


const HrViewSuccessionPlan = () => {
    const [isEditSuccessionPlan, setIsEditSuccessionPlan] = useState(false);

    const handleEditSuccessionPlanClick = () => {
        setIsEditSuccessionPlan(true);
      };

    return (
        <div className='hr-succession-plan-page'>
            {/*----- Session for Rating discussion & Back btn & Edit btn -----*/}
            <div className='d-flex ai-center jc-sb'>
                <Typography className='succession-heading'>{"Session for Rating discussion"}</Typography>

                {!isEditSuccessionPlan && (
                    <div className='d-flex'>
                        <button className="plan-cancel-btn">
                            {"Back"}
                        </button>

                        <button className="plan-save-btn" onClick={handleEditSuccessionPlanClick}>
                            {"Edit"}
                        </button>
                    </div>
                )}
            </div>

            {/*----- View Succession Plan -----*/}
            <div>
                {isEditSuccessionPlan ? <EditPlan /> : <ViewPlan />}
            </div>
        </div>
    );
};

export default HrViewSuccessionPlan;