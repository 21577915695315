import { Skeleton } from "@mui/material";

const EmpSkel = () => {
    return(
        <div className="emp-skel">
            <div className="empDetaila">
                <div className="empPic">
                <Skeleton variant="circular" width={50} height={50} />
                </div>
                <div className="userInfo">
                    <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"9rem"  }} /></p>
                    <p className="designation"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem" }} /></p>
                </div>
            </div>
        </div>
    )
}

export default EmpSkel;