import React, { useState } from 'react';
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const startingDay = firstDayOfMonth.getDay();

    const calendar = [];

    let dayCounter = 1;

    for (let row = 0; row < 6; row++) {
      const week = [];

      for (let col = 0; col < 7; col++) {
        if ((row === 0 && col < startingDay) || dayCounter > daysInMonth) {
          week.push(null);
        } else {
          const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayCounter);
          week.push(currentDay);
          dayCounter++;
        }
      }

      calendar.push(week);
    }

    return calendar;
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button className="arrows" onClick={handlePrevMonth}><MdOutlineArrowBackIos /></button>
        <div className='head'>{currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}</div>
        <button className="arrows" onClick={handleNextMonth}><MdArrowForwardIos /></button>
      </div>
      <div className="calendar-grid">
        <div className="days-of-week">
          {daysOfWeek.map(day => (
            <div key={day}>{day}</div>
          ))}
        </div>
        {generateCalendar().map((week, weekIndex) => (
          <div key={week} className="week">
            {week.map((day, dayIndex) => (
              <button 
              onClick={() => setCurrentDate(day)}
              key={`${week}1`} 
              className={`day ${day ? '' : 'empty'} ${(currentDate?.getDate() === day?.getDate()) && (currentDate?.getMonth() === day?.getMonth()) && (currentDate?.getFullYear() === day?.getFullYear()) ? 'today' : ''}`}
              >
                {day ? day.getDate() : ''}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;