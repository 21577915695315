import { useEffect, useState } from "react";
import APIList from "../../../../../api";
import { useSelector } from "react-redux";
import TableSkel from "../../../../common/tableSkeleton";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined } from "../../../../utils/utils";

const ChangedReport = ({sessionId}) => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [reportChangeData, setReportChangeData] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);

    useEffect(() => {
            getFinalReport();
      }, [])      
    
    const getFinalReport = () => {
        setDataLoader(true);
        APIList.getReportChanges({
            companyId: employeeDetails?.company?.id,
            sessionId : sessionId
        })
        .then((res) => {
            setReportChangeData(res?.data);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
      }
    
    return(
        <div className="changed-report">
            <div className="tablee">
            <table class="feedback-emp-list-table"> 
            
                <tr className="table-head-emp-list"> 
                    <th className="e-name">
                      E name 
                    </th> 
                    <th className="mid-cols">
                      Func/ sub-func
                    </th> 
                    <th className="mid-cols-small">
                    Is Recommended for Promotion
                    </th> 
                    <th className="mid-cols-small">
                    Is Promotion Approved
                    </th> 
                    <th className="mid-cols-small">
                    Rating Revised
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR MBB 
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR MBB
                    </th> 
                    
                    <th className="last-col-large">Reason</th> 
                </tr>
                
                {
                     dataLoader ? 
                     <TableSkel />
                     :
                  
                        reportChangeData?.map((data) => {
                            return(
                                <tr>
                                    <td>
                                        <div className="emp-i">
                                            <div className="name">{data?.name}</div>
                                            <div className="emp-id">{data?.employeeLevel}</div>
                                        </div>
                                    </td>
                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                    <td>
                                    <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                            {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                        </span>
                                    </td>
                                    <td>
                                         {/* {data?.promotionApproved} */}
                                    <span className={`${(data?.promotionApproved === "Yes") ? "revised" :"not-revised"}`}>
                                                 {(data?.eligibleForPromotion !== "Yes") ? "-" : isEmptyNullUndefined(data?.promotionApproved) ? "-" : data?.promotionApproved}
                                                </span>
                                    </td>
                                    <td>
                                        <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                        {
                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                        }
                                        </span>
                                    </td>
                                    <td>{data?.preRating?.preformanceRating}</td>
                                    <td>{data?.preRating?.mbbRating}</td>
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.preformanceRating}</td>
                                    }
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.mbbRating}</td>
                                    }
                                    <td><div className="the-reason">{data?.reason}</div></td>
                                </tr>
                            )
                        })
                   
                
                
                }
                
            </table> 

          </div>
        </div>
    )
}

export default ChangedReport;