import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// import profileBanner from "../../../../assets/images/profileBanner.png";


const ScoringBehaviours = ({ }) => {
    const dispatch = useDispatch();

    const itemScoring = [
        {
            id: 1,
            count: 1,
            title: 'I would recommend Benevolve as a great place to work.',
            ownAnswer: 4,
            averageFeedback: 5.0,
            companyAverage: 5.0,
        },
        {
            id: 2,
            count: 2,
            title: 'Applies common sens when making decisions',
            ownAnswer: 4,
            averageFeedback: 5.0,
            companyAverage: 5.0,
        },
        {
            id: 3,
            count: 3,
            title: 'Shows respect and friendliness to customers',
            ownAnswer: 4,
            averageFeedback: 5.0,
            companyAverage: 5.0,
        },
        {
            id: 4,
            count: 4,
            title: 'Remains calm, professional & focused, acting as a role model in difficult situations',
            ownAnswer: 4,
            averageFeedback: 5.0,
            companyAverage: 5.0,
        },
        {
            id: 5,
            count: 5,
            title: 'Can make considered decisions quickly when necessary',
            ownAnswer: 4,
            averageFeedback: 5.0,
            companyAverage: 5.0,
        },
    ];

    return (
        <Grid className="chart-report-card  card-100per">
            <Grid className="scoring-behaviours-grid">
                <Typography className="scoring-behaviours">{"Top 5 Scoring Behaviours"}</Typography>

                {itemScoring.map((item, index) => (
                    <Grid className="scoring-beha-container item-mt-10">
                        <Grid>
                            <Typography className="item-count">{item.count}</Typography>
                        </Grid>
                        <Grid className="item-bt-border">
                            <Typography className="item-title">{item.title}</Typography>
                            <Typography className="own-answer-avg item-mt-6">{"Own Answer: "}<span className="blue-dark-color">{item.ownAnswer}</span></Typography>
                            <Typography className="own-answer-avg item-mt-6 item-mb-10">{"Average Feedback: "}<span className="blue-dark-color">{item.averageFeedback}</span><span className="own-answer-avg item-mt-6 item-mb-10">{"| Company Average:"}<span className="blue-dark-color">{item.averageFeedback}</span></span></Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(ScoringBehaviours);
