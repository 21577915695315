import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KudosIcon1 from "../../../../assets/images/MyCommunity/kudosIcon1.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ViewFullNomination from '../Modal/ViewFullNomination';
import RejectTheNomination from '../Modal/RejectTheNomination';


const ManageRewards = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [openViewFullNomination, setOpenViewFullNomination] = useState(false);
    const [openRejectTheNomination, setOpenRejectTheNomination] = useState(false);

    const manageRewardsList = [
        {
            id: 1,
            userNameNomi: 'Annette Black',
            nominated: 'nominated',
            userNameFor: 'Savannah Nguyen',
            for: 'for',
            icon: KudosIcon1,
            userMsg: 'Positive Pat-on-the back',
            description: 'I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard of ...',
        },
        {
            id: 2,
            userNameNomi: 'Jenny Wilson',
            nominated: 'nominated',
            userNameFor: 'Wade Warren',
            for: 'for',
            icon: KudosIcon1,
            userMsg: 'Rockstar of the month',
            description: 'I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard of ...',
        },
        {
            id: 3,
            userNameNomi: 'Brooklyn Simmons',
            nominated: 'nominated',
            userNameFor: 'Guy Hawkins',
            for: 'for',
            icon: KudosIcon1,
            userMsg: 'Positive Pat-on-the back',
            description: 'I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard of ...',
        },
    ];

    return (
        <Grid container className="manage-rewards-card">
            {/*----- Heading & View All Btn -----*/}
            <Grid item xs={12} sm={12} md={12} className="heading-view-all">
                <Typography className="manage-rewards">{"Manage Rewards"}</Typography>
                <button className="view-all-btn" onClick={() => {history.push("/my-community-all-view")}}>{"View All"}</button>
            </Grid>

            {manageRewardsList.map((item, index) => (
                <Grid key={index} item xs={12} sm={12} md={12}>
                    <Grid className='manage-rewards-container'>
                        <Grid className="rewards-mt-5">
                            <div className="dot-circle" />
                        </Grid>
                        <Grid>
                            <Typography className="user-name">{item.userNameNomi}<span className="nominated-for">{item.nominated}</span><span className="user-name">{item.userNameFor}</span><span className="nominated-for">{item.for}</span></Typography>
                            <Grid className="user-msg-grid">
                                <img src={item.icon} alt="icon" className="user-msg-icon" />
                                <Typography className="user-msg">{item.userMsg}</Typography>
                            </Grid>
                            <Typography className="rewards-description">{item.description}<span className="view-full" onClick={() => setOpenViewFullNomination(true)}>{"View Full"}</span></Typography>

                            {/*----- Reject & Accept Btn -----*/}
                            <Grid className="reject-accept-btn-grid">
                                <button className="reject-btn" onClick={() => setOpenRejectTheNomination(true)}>{"Reject"}</button>
                                <button className="accept-btn">{"Accept"}</button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*----- Border -----*/}
                    <Grid className="item-bottom-border" />
                </Grid>
            ))}
            {
                openViewFullNomination &&
                <ViewFullNomination openViewFullNomination={openViewFullNomination} setOpenViewFullNomination={setOpenViewFullNomination} />
            }
            {
                openRejectTheNomination &&
                <RejectTheNomination openRejectTheNomination={openRejectTheNomination} setOpenRejectTheNomination={setOpenRejectTheNomination} />
            }
        </Grid>
    );
};

export default React.memo(ManageRewards);
