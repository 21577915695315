
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
// import Banner from "../../../../assets/images/banner.png";
import User from "../../../../assets/images/user.png"; 
import { BsChevronDown } from "react-icons/bs";
// import { useState } from "react";
import Info from "../../../../assets/images/info.svg";
import UpArrow from "../../../../assets/images/upArrow.svg";   
import Send from "../../../../assets/images/send.svg";
// import ArrowRight from "../../../../assets/images/arrowRight.svg"
// import UpArrow from "../../../../assets/images/upArrow.svg"
// import Checked from "../../../../assets/images/checked.svg"
// import Button from '@mui/material/Button';
// import PageTwo from './page-two';
// import PageThree from './Page-three';
// import PageFour from './page-four';
import Alert from "../../../../assets/images/alert.svg"
import Setting from "../../../../assets/images/setting.svg"
import BackArrow from "../../../../assets/images/backArrowSelf.svg"
import { Route, useHistory, useLocation } from "react-router-dom";
import { assesmentDummyData, isEmptyNullUndefined, textAreaStyle, accordianSVGstyle } from "../../../utils/utils";
import checkIcon from "../../../../assets/images/popup-check.png";

import DialogPopup from "../../../common/Dialog";
import { useSelector } from "react-redux";
import APIList from "../../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";

const UserProfilePage = () => {

    const history = useHistory();

    const selfApraisalData = useSelector((state) => state?.selfApraisalData?.selfApraisalData);
    const savedApraisalData = useSelector((state) => state?.selfApraisalData?.savedApraisalData);

    const [expanded, setExpanded] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);    
    const [apraisalPayload, setApraisalPayload] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    // console.log("apraisalPayload",apraisalPayload)
    useEffect(() => {        
        if(!isEmptyNullUndefined(savedApraisalData)){
            setApraisalPayload(savedApraisalData);
        }
    }, [])
    
    const handleChangeText = (event) => {
        let tempPayload = structuredClone(apraisalPayload);

        tempPayload.values[event.target.name] = event.target.value;

        setApraisalPayload(tempPayload);
    }

    const handleSubmitApraisal = () => {
        setSubmitLoader(true);
        if (isEmptyNullUndefined(selfApraisalData) || Object.keys(selfApraisalData)?.length === 0) {
            APIList.createSelfApraisal(apraisalPayload)
            .then((res) => {
                setSubmitLoader(false);
                setOpenDialog(true);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                      <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                      &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setSubmitLoader(false);
            })
        } else {            
            APIList.editSelfApraisal(apraisalPayload)
            .then((res) => {
                setSubmitLoader(false);
                setOpenDialog(true)
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setSubmitLoader(false);
            })
            }
    }

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    return(
        <div className="selfAssessment">
            <div className="row">
                <div className="col-12"> 
                    <div className="contentDiv borNone">
                        <div className="contentHead d-flex justify-content-between align-items-center">
                        </div>
                        <div className="values">
                            <div>
                                <Stack sx={{margin:"1rem",
                                    "& .MuiAccordion-rounded":{
                                        border:"1px solid #dad2d2",
                                        borderRadius:"1rem",
                                        boxShadow:"none"
                                    }
                                }}>
                                    <Accordion
                                    // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                    expanded={expanded === "rule1"} onChange={() => handleChange("rule1")} data-value={"rule1"}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                    sx={{
                                        width:"100%",   
                                        fontSize:"1.5rem",
                                        fontWeight:"600",
                                        "& .MuiAccordionSummary-content":{
                                            width:"100%", 
                                            display:"flex", 
                                            justifyContent:"space-between",
                                        }}}
                                    >
                                        <div className="accorTitle">What are your areas of development, where we can help you learn and grow at MediBuddy?
                                            {/* <img src={UpArrow} className="upArrowAccrodian"></img> */}
                                        </div>
                                        <BsChevronDown
                                            style={{...accordianSVGstyle ,transform: expanded !== "rule1"? "rotate(0deg)" : "rotate(180deg)"}}
                                        /> 
                                                        
                                    </AccordionSummary>
                                    <AccordionDetails
                                    sx={{
                                        fontSize:"1rem"
                                    }}
                                    >
                                        <div className="accorDetails">
                                            <div className="accordian-data">
                                                <div className="comment">
                                                    <div className="textAdd">
                                                    <TextField 
                                                        name="keyAchievement"
                                                        id="outlined-multiline-static" 
                                                        label="" 
                                                        variant="outlined" 
                                                        placeholder="Please provide feedback for MBB" 
                                                        multiline
                                                        rows={3}
                                                        value={apraisalPayload?.values?.keyAchievement || ""}
                                                        sx={textAreaStyle}
                                                        onChange={(e) => handleChangeText(e)}
                                                    />
                                                    </div>
                                                        
                                                </div>

                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                                </Stack>

                                <Stack sx={{margin:"1rem",
                                    "& .MuiAccordion-rounded":{
                                        border:"1px solid #dad2d2",
                                        borderRadius:"1rem",
                                        boxShadow:"none"
                                    }
                                }}>
                                    <Accordion
                                    // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                    expanded={expanded === "rule2"} onChange={() => handleChange("rule2")} data-value={"rule2"}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                    sx={{
                                        width:"100%",   
                                        fontSize:"1.5rem",
                                        fontWeight:"600",
                                        "& .MuiAccordionSummary-content":{
                                            width:"100%", 
                                            display:"flex", 
                                            justifyContent:"space-between",
                                        }}}
                                    >
                                        <div className="accorTitle">Highlight the learning support needed to excel and grow at Medibuddy
                                            {/* <img src={UpArrow} className="upArrowAccrodian"></img> */}
                                        </div>
                                        <BsChevronDown
                                            style={{...accordianSVGstyle ,transform: expanded !== "rule2"? "rotate(0deg)" : "rotate(180deg)"}}
                                        />
                                                           
                                    </AccordionSummary>
                                    <AccordionDetails
                                    sx={{
                                        fontSize:"1rem"
                                    }}
                                    >
                                        <div className="accorDetails">
                                            <div className="accordian-data">
                                                <div className="comment">
                                                    <div className="textAdd">
                                                    <TextField 
                                                        name="learningSupport"                                                        
                                                        id="outlined-multiline-static" 
                                                        label="" 
                                                        variant="outlined" 
                                                        placeholder="Please provide feedback for MBB" 
                                                        multiline
                                                        rows={3}
                                                        value={apraisalPayload?.values?.learningSupport || ""}                                                        
                                                        sx={textAreaStyle}
                                                        onChange={(e) => handleChangeText(e)}
                                                    />
                                                    </div>
                                                        
                                                </div>

                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                                </Stack>

                                <Stack sx={{margin:"1rem",
                                    "& .MuiAccordion-rounded":{
                                        border:"1px solid #dad2d2",
                                        borderRadius:"1rem",
                                        boxShadow:"none"
                                    }
                                }}>
                                    <Accordion
                                    // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                    expanded={expanded === "rule3"} onChange={() => handleChange("rule3")} data-value={"rule3"}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                    sx={{
                                        width:"100%",   
                                        fontSize:"1.5rem",
                                        fontWeight:"600",
                                        "& .MuiAccordionSummary-content":{
                                            width:"100%", 
                                            display:"flex", 
                                            justifyContent:"space-between",
                                        }}}
                                    >
                                        <div className="accorTitle">Describe overall challenges faced
                                            {/* <img src={UpArrow} className="upArrowAccrodian"></img> */}
                                        </div>
                                        <BsChevronDown
                                            style={{...accordianSVGstyle ,transform: expanded !== "rule3"? "rotate(0deg)" : "rotate(180deg)"}}
                                        />
                                                           
                                    </AccordionSummary>
                                    <AccordionDetails
                                    sx={{
                                        fontSize:"1rem"
                                    }}
                                    >
                                        <div className="accorDetails">
                                            <div className="accordian-data">
                                                <div className="comment">
                                                    <div className="textAdd">
                                                    <TextField 
                                                        name="challenges"
                                                        id="outlined-multiline-static" 
                                                        label="" 
                                                        variant="outlined" 
                                                        placeholder="Please provide feedback for MBB" 
                                                        multiline
                                                        rows={3}
                                                        value={apraisalPayload?.values?.challenges || ""}
                                                        sx={textAreaStyle}
                                                         onChange={(e) => handleChangeText(e)}
                                                    />
                                                    </div>
                                                        
                                                </div>

                                            </div>
                                        </div>
                                    </AccordionDetails>
                                    </Accordion>
                                </Stack>

                                                   
                            </div>
                                <div className="d-flex justify-content-end gap-3 pt-5">
                                <button className="valuesBtn draft" onClick={() => setOpenDialog(true)}>Save as draft</button>
                                <button className="valuesBtn next"
                                 onClick={handleSubmitApraisal}
                                 disabled={submitLoader}
                                    >
                                        {
                                            submitLoader ? 
                                            <CircularProgress size={29} sx={{color:"#ffffff"}} />
                                            :
                                            "Submit"
                                        }
                                        
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/profile">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                You have successfully submitted your feedback
                </div>
                <button className="button"
                    onClick={() => { history.push("/profile")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        </div>
    )
};

export default UserProfilePage; 
