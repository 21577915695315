import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { actions as logInActions } from "../../../redux/login/actions";
import ButtonM from "../Button";
import ReactDOM from 'react-dom';
import Popout from 'react-popout-v2';
import { isEmptyNullUndefined } from "../../utils/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginBg from "../../../assets/images/loginImg.png";
import mediLogo from "../../../assets/images/mdiLogo/loginLogo.png";

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameText, setUsernameText] = useState(true);
  const [loginPopup, setLoginPopup] = useState(false);

  const userToken = useSelector((state) => state?.user?.userToken);
  const loading = useSelector((state) => state?.user?.loader);

  const prodEnv = "https://medibuddy.benevolve.com/login";

  useEffect(() => {
    if (localStorage.getItem("loggedOutFromFooter")) {
      localStorage.removeItem("loggedOutFromFooter");
      window.location.reload();
    }
  }, []);

  // Handle SSO token in URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const token = pathSegments[pathSegments.length - 1];
    if (location.pathname.startsWith('/login/') && token) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughSSORedirect",
        password: token
      }));
    }
  }, [location, dispatch]);

  // Logging in through admin portal 
  useEffect(() => {
    if (params?.xyz?.length > 0) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: params?.xyz
      }));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (userToken?.id_token !== undefined) {      
      history.push('/profile');
    }
  }, [userToken, history]);

  useEffect(() => {
    if (isEmptyNullUndefined(userToken)) {
      setIsToken("no");
    }
  }, [submit, userToken]);
  
  const checkValidation = () => {
    setUsernameText(false);
    if (username && password) {
      dispatch(logInActions.LogInRequest({
        username: username,
        password: password
      }));
    } 
    setSubmit(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkValidation();
    }
  }

  useEffect(() => {
    const handleMessage = (e) => {
      if (e?.data?.id_token) {
        dispatch(logInActions.LogInRequest({
          username: "logInThroughPopupWindow",
          password: e?.data?.id_token
        }));
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  return (
    <div className="login-main">
      <div className="row" style={{maxWidth:"1200px", margin:"auto"}}>
        <div className="col-7 left">
          <img src={LoginBg} style={{width: '100%'}} className="loginBg" alt="Login Background" />
        </div>
        <div className="col-5 right">
          <div className="loginBlock">
            <div className="loginBlockInner">
              <img className="logo-img" src={mediLogo} alt="logo" />
              <div className="loginHeadingOuter">
                <p className="loginHeading">Login</p>
              </div>
              {window?.location?.href !== prodEnv && (
                <>
                  <div className="input-wrapper">
                    <div className="name-ip">
                      <input
                        name="email"
                        onChange={(event) => {setUsername(event.target.value); setIsToken(""); setSubmit(false);}}
                        className={(submit && (username.trim() === "" || (!loading && isToken === "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                        type="text"
                        placeholder="Username"                
                      />
                      {usernameText && (
                        <p className="helping-text" style={{ lineHeight: '0px'}}>
                          Your username is your company e-mail id.
                        </p>
                      )}
                      {submit && (username.trim() === "" || (!loading && isToken === "no")) && (
                        <p className="error-text">
                          Your username is your company e-mail id.<br/>
                          *Please provide a valid Username.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="password-wrap">
                    <div className="password-ip">
                      <div className="password-outer">
                        <input
                          name="password"
                          onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                          className={(submit && (password.trim() === "" || (!loading && isToken === "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <span className="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <FaEye /> : <FaEyeSlash />}               
                        </span>
                      </div>
                      {submit && (password.trim() === "" || (!loading && isToken === "no")) && (
                        <p className="error-text">
                          *Please provide a valid Password.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pt-4 pb-2">
                    <div className="form-check checkbox">
                      <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                      <label className="form-check-label">Remember Me</label>
                    </div>
                    <div className='forgot-pass w-full text-right'>
                      <Link to='/forgot-password' className='link'>
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-wrap pb-2">
                    <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                      <ButtonM text='Login' onpress={() => checkValidation()} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                    </div>
                  </div>
                  <div className="or-text">-------  OR  -------</div>
                </>
              )}
              <div className="flex flex-wrap pb-2">
                <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                  <ButtonM text='Login Via Company' onpress={() => setLoginPopup(true)} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loginPopup &&
        <Popout 
        url='/saml/login?disco=true' 
        reactDom={ReactDOM}
        title='SSO Login' 
        onClose={() => setLoginPopup(false)}
        >
        </Popout>
      }
    </div>
  );
};

export default LogIn;