import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AccessDenied = () => {

    const history = useHistory();

    return(
          <div className="access-denied">
            <div className="heading">oops ...</div>
            <div className="text">You're not authorized to access this page.</div>
            <button className="go-back-btn" onClick={() => history.goBack()}>Go back to previous page</button>
          </div>
    )
}

export default AccessDenied;