import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.ANNUALCYCLE_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.ANNUALCYCLE_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        annualcycle: payload || {},
    }),
    [types.ANNUALCYCLE_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),  

    [types.MAN_FEEDBACK_CLOSE_LOOP_REQUEST]: (state) => ({
        ...state,
        manfeedLader: true,
    }),
    [types.MAN_FEEDBACK_CLOSE_LOOP_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        manfeedLader: false,
        isManFeedbackCloseLoop: payload,
    }),
    [types.MAN_FEEDBACK_CLOSE_LOOP_REQUEST_FAILED]: (state) => ({
        ...state,
        manfeedLader: false,
    }),   
};

export default handleActions(actionHandler, {
    loader: false,
    annualcycle: {},   

    manfeedLader: false,
    isManFeedbackCloseLoop: false,
});
