import { useHistory } from "react-router-dom";

const Main = () => {

    const history = useHistory();
    return(
        <div className="main-page">
           <div onClick={() => history.push("/dashboard")}> Go to dashboard</div>
           <div onClick={() => history.push("/profile")}> Go to profile</div>
        </div>
    )
};

export default Main;