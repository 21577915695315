import React from 'react';
import { Dialog, Button, Grid, Typography, TextField } from '@mui/material';

const EnterReasonPositionModal = ({ showReasonPosition, handleReasonPositionNo }) => {

    return (
        <Dialog
            open={showReasonPosition}
            onClose={handleReasonPositionNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="sm">
            <Grid container className="profile-edit modal-pb-10">
                {/*----- Heading & Close Button -----*/}
                <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "10px" }}>
                    <Typography className="modal-reason-position-heading">{"Enter reason for position change"}</Typography>

                    <TextField
                        name="reasonPosition"
                        id="outlined-multiline-static"
                        placeholder="To discuss succession plan for"
                        variant="outlined"
                        size="small"
                        sx={{
                            width: '100%',
                            mt: '6px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                        multiline
                        minRows={3}
                    />

                    <div className="modal-save-btn-grid">
                        <button className="modal-save-btn">
                            {"Save"}
                        </button>
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default EnterReasonPositionModal;