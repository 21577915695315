import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);


const BarGraph = ({ data, bar, average, count, yAxisTitle }) => {
    const barBackgroundColor = 'rgba(75, 192, 192, 0.2)';
    const barBorderColor = 'rgba(75, 192, 192, 1)';
    const lineBorderColor = 'rgba(255, 99, 132, 1)';
    const lineBackgroundColor = 'rgba(255, 99, 132, 0.2)';

    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: []
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            // y: {
            //     beginAtZero: true,
            //     suggestedMax: 100,
            //     ticks: {
            //         stepSize: 10,
            //     },
            // },
           'y-axis-1': {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: yAxisTitle,
            font: {
                size: 16,
                family:'poppins'
            },
          },
          ticks: {
            beginAtZero: true
          }
        },
        'y-axis-2': {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'Average',
            font: {
                size: 16,
                family:'poppins'
            },
          },
          ticks: {
            beginAtZero: true
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
            x: {
                title: {
                    display: true,
                    text: 'Ratings',
                    font: {
                        size: 16,
                        family:'poppins'
                    }
                  },
            },
        },
    };
    

    useEffect(() => {
        if (data) {
            
            const tempGraphData = {
                labels: bar,
                datasets: [
                    {
                        type: 'bar',
                        label: 'Count',
                        data: count,
                        backgroundColor: barBackgroundColor,
                        borderColor: barBorderColor,
                        borderWidth: 1,
                        yAxisID: 'y-axis-1'
                    },
                    {
                        type: 'line',
                        label: 'Average',
                        data: average,
                        borderColor: lineBorderColor,
                        backgroundColor: lineBackgroundColor,
                        fill: false,
                        yAxisID: 'y-axis-2'
                    },
                ],
            };
            setGraphData(tempGraphData);
        }
    }, [data]);

    return (
        <div>
            <div>
                <Bar options={options} data={graphData} />
            </div>
        </div>
    );
}

BarGraph.propTypes = {
    data: PropTypes.object,
    bar: PropTypes.array,
    average: PropTypes.array,
    count: PropTypes.array,
    yAxisTitle: PropTypes.string,
  };

export default BarGraph;
