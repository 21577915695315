import axios from "axios"
import { history } from "../store"
// import { loadedStore } from "../index";
import { actions as logInActions } from "../redux/login/actions";
import { isEmptyNullUndefined } from "../components/utils/utils";

// export const BASE_URL = "https://tm-portal-uat.benevolve.com"; //UAT
export const BASE_URL = "";
// export const BASE_URL = "https://medibuddy.benevolve.com/";  //PROD

const AUTHORISED_URL = `${BASE_URL}/services/benevolvemedibuddy/api/`;
const AUTHORISED_URL_CMS = `${BASE_URL}/services/benevolvecms/api/`;
const URL_WORKFLOW = `${BASE_URL}/services/workflow/api`
const URL_NOTIFICATION = `${BASE_URL}/services/notification/api`
export const AUTHENTICATE_URL = `${BASE_URL}/api/`;
// const AUTHORISED_URL_CMS = `http://216.48.180.207:8080/services/benevolvecms/api/`;

let store
export const injectStore = _store => {
  store = _store
}

const isUserToken = () => {
  const state = store && store.getState();
  const userToken = state && state.user && state.user.userToken;
  return !!(userToken && Object.keys(userToken).length > 0);
}

const getBaseUrl = () => {
  const isToken = isUserToken()
  if (isToken) {
    // const state = store.getState();
    // const user = state && state.user && state.user.user;
    // if (user && Object.keys(user).length > 0) {
      return AUTHORISED_URL;
    // } else {
    //   return AUTHENTICATE_URL;
    // }
  } else {
    return AUTHENTICATE_URL;
  }
}

const getBaseUrlCms = () => {
  const isToken = isUserToken()
  if (isToken) {
    const state = store.getState();
    // const user = state && state.user && state.user.user;
    // if (user && Object.keys(user).length > 0) {
      return AUTHORISED_URL_CMS;
    // } else {
    //   return AUTHENTICATE_URL;
    // }
  } else {
    return AUTHENTICATE_URL;
  }
}

const getBaseUrlNotification = () => {
  const isToken = isUserToken()
  if (isToken) {
    const state = store.getState();
    // const user = state && state.user && state.user.user;
    // if (user && Object.keys(user).length > 0) {
      return URL_NOTIFICATION;
    // } else {
    //   return AUTHENTICATE_URL;
    // }
  } else {
    return AUTHENTICATE_URL;
  }
}
const getBaseUrlWorkFlow = () => {
  const isToken = isUserToken()
  if (isToken) {
    const state = store.getState();
    // const user = state && state.user && state.user.user;
    // if (user && Object.keys(user).length > 0) {
      return URL_WORKFLOW;
    // } else {
    //   return AUTHENTICATE_URL;
    // }
  } else {
    return AUTHENTICATE_URL;
  }
}

const getBaseUrlAcc = () => {
  const isToken = isUserToken()
  if (isToken) {
    const state = store.getState();
    // const user = state && state.user && state.user.user;
    // if (user && Object.keys(user).length > 0) {
      return AUTHENTICATE_URL;
    // } else {
    //   return AUTHENTICATE_URL;
    // }
  } else {
    return AUTHENTICATE_URL;
  }
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl()
});

export const axiosInstanceCms = axios.create({
  baseURL: getBaseUrlCms()
});

export const axiosInstanceNotification = axios.create({
  baseURL: getBaseUrlNotification()
});

export const axiosInstanceWorkFlow = axios.create({
  baseURL: getBaseUrlWorkFlow()
});

export const axiosInstanceAcc = axios.create({
  baseURL: getBaseUrlAcc()
});

const errorHandler = (error) => {
  // console.log("errorrrrr",error?.response)
  let errorMsg = {};
  if (isEmptyNullUndefined(error?.response?.status) || error?.response?.status === 401) {
    // 401 unauthorized error
    // Handle the error here
    errorMsg = {title:"Your session has expired. Please log in again to continue."}
    store.dispatch(logInActions.clearLogInToken());
    history.push('/login');
    // window.location.reload();
  } else {
  if (error.response && error.response.data && error.response.data) {
    errorMsg = error.response.data;
  }
  }
  return Promise.reject(errorMsg);
};
const errorHandlerCms = (error) => {
  // console.log("errorrrrr",error)
  let errorMsg = {};
  if (isEmptyNullUndefined(error?.response?.status)) {
    // 401 unauthorized error
    // Handle the error here
    errorMsg = "Something went wrong, please try after some time."
    store.dispatch(logInActions.clearLogInToken())
    history.push('/login')
  } else {
  if (error.response && error.response.data && error.response.data) {
    errorMsg = error.response.data;
  }
  }
  return Promise.reject(errorMsg);
};
const errorHandlerAcc = (error) => {
  // console.log("errorrrrr",error)
  let errorMsg = {};
  if (isEmptyNullUndefined(error?.response?.status)) {
    // 401 unauthorized error
    // Handle the error here
    errorMsg = "Something went wrong, please try after some time."
    store.dispatch(logInActions.clearLogInToken())
    history.push('/login')
  } else {
  if (error.response && error.response.data && error.response.data) {
    errorMsg = error.response.data;
  }
  }
  return Promise.reject(errorMsg);
};

axiosInstance.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    request.baseURL = getBaseUrl()
    const isToken = isUserToken()
    if (isToken) {
      const state = store.getState();
      const userToken = state && state.user && state.user.userToken && state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {}
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstanceCms.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    request.baseURL = getBaseUrlCms()
    const isToken = isUserToken()
    if (isToken) {
      const state = store.getState();
      const userToken = state && state.user && state.user.userToken && state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {}
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstanceNotification.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    request.baseURL = getBaseUrlNotification()
    const isToken = isUserToken()
    if (isToken) {
      const state = store.getState();
      const userToken = state && state.user && state.user.userToken && state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {}
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstanceWorkFlow.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    request.baseURL = getBaseUrlWorkFlow()
    const isToken = isUserToken()
    if (isToken) {
      const state = store.getState();
      const userToken = state && state.user && state.user.userToken && state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {}
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstanceAcc.interceptors.request.use(
  (request) => {
    // Do something before request is sent
    request.baseURL = getBaseUrlAcc()
    const isToken = isUserToken()
    if (isToken) {
      const state = store.getState();
      const userToken = state && state.user && state.user.userToken && state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {}
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    // console.log("response",response)
    // for Unauthorise Access push user to login page
    if (response.status === 401) {
      store.dispatch(logInActions.clearLogInToken())
      history.push('/login')
    }
    return response;
  },
  (error) => errorHandler(error)
);

axiosInstanceCms.interceptors.response.use(
  (response) => {
    // for Unauthorise Access push user to login page
    if (response.status === 401) {
      store.dispatch(logInActions.clearLogInToken())
      history.push('/login')
    }
    return response;
  },
  (error) => errorHandlerCms(error)
);

axiosInstanceWorkFlow.interceptors.response.use(
  (response) => {
    // for Unauthorise Access push user to login page
    if (response.status === 401) {
      store.dispatch(logInActions.clearLogInToken())
      history.push('/login')
    }
    return response;
  },
  (error) => errorHandlerCms(error)
);

axiosInstanceAcc.interceptors.response.use(
  (response) => {
    // for Unauthorise Access push user to login page
    if (response.status === 401) {
      store.dispatch(logInActions.clearLogInToken())
      history.push('/login')
    }
    return response;
  },
  (error) => errorHandlerAcc(error)
);

export default axiosInstance;
