import { useState } from "react";
import Minus from "../../../assets/images/compensation/minus.svg";
import Plus from "../../../assets/images/compensation/plus.svg";
import { isEmptyNullUndefined } from "../../utils/utils";
import { Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import filterIcon from "../../../assets/images/filter.svg";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import { FaSort } from "react-icons/fa6";

const myTeamData = [
    {
        empCode: "123456",
        empName: "John Doe",
        dateOfJoining: "2021-01-01",
        function: "Software Engineer",
        empDesignation: "Software Engineer",
        location: "New York, NY",
        costCenter: "123456",
    },
    {
        empCode: "123457",
        empName: "Jane Smith",
        dateOfJoining: "2021-02-01",
        function: "Product Manager",
        empDesignation: "Product Manager",
        location: "San Francisco, CA",
        costCenter: "123457",
    },
    {
        empCode: "123458",
        empName: "Alice Johnson",
        dateOfJoining: "2021-03-01",
        function: "UX Designer",
        empDesignation: "UX Designer",
        location: "Austin, TX",
        costCenter: "123458",
    },
    {
        empCode: "123459",
        empName: "Bob Brown",
        dateOfJoining: "2021-04-01",
        function: "Data Scientist",
        empDesignation: "Data Scientist",
        location: "Seattle, WA",
        costCenter: "123459",
    },
    {
        empCode: "123460",
        empName: "Charlie Davis",
        dateOfJoining: "2021-05-01",
        function: "DevOps Engineer",
        empDesignation: "DevOps Engineer",
        location: "Chicago, IL",
        costCenter: "123460",
    },
    {
        empCode: "123461",
        empName: "Diana Evans",
        dateOfJoining: "2021-06-01",
        function: "QA Engineer",
        empDesignation: "QA Engineer",
        location: "Los Angeles, CA",
        costCenter: "123461",
    },
    {
        empCode: "123462",
        empName: "Ethan Foster",
        dateOfJoining: "2021-07-01",
        function: "System Analyst",
        empDesignation: "System Analyst",
        location: "Miami, FL",
        costCenter: "123462",
    },
    {
        empCode: "123463",
        empName: "Fiona Green",
        dateOfJoining: "2021-08-01",
        function: "Network Engineer",
        empDesignation: "Network Engineer",
        location: "Boston, MA",
        costCenter: "123463",
    },
    {
        empCode: "123464",
        empName: "George Harris",
        dateOfJoining: "2021-09-01",
        function: "Security Analyst",
        empDesignation: "Security Analyst",
        location: "Denver, CO",
        costCenter: "123464",
    },
    {
        empCode: "123465",
        empName: "Hannah Ives",
        dateOfJoining: "2021-10-01",
        function: "Business Analyst",
        empDesignation: "Business Analyst",
        location: "Phoenix, AZ",
        costCenter: "123465",
    },
    {
        empCode: "123466",
        empName: "Ian Jones",
        dateOfJoining: "2021-11-01",
        function: "Software Architect",
        empDesignation: "Software Architect",
        location: "Atlanta, GA",
        costCenter: "123466",
    },
]

const columns = ["Date of Joining", "Function", "Designation", "Location", "Cost Center"];

const MyTeamPage = () => {

    const [activeTab, setActiveTab] = useState("My Team");
    const [selectedConfigColumns, setSelectedConfigColumns] = useState(columns);

    const [search, setSearch] = useState("");
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    const [myTeamDataLoader, setMyTeamDataLoader] = useState(false);

    const handleCollapse = (column) => {
        let temp = structuredClone(selectedConfigColumns);
        temp = temp?.filter((x) => x !== column);
        setSelectedConfigColumns(temp);
    }

    const handleConfigColumnsChange = (event) => {
        // console.log("event.target.value", event.target)
        const value = event.target.value;
        let preventDuplicate = value.filter(
            (v, i, a) => a.findIndex((t) => t === v) === i,
        );
        if (value[value?.length - 1] === 'All') {
            preventDuplicate =
                preventDuplicate?.length - 1 === columns?.length
                    ? []
                    : columns;
        }
        setSelectedConfigColumns(preventDuplicate);
        // setCollapsedList(columns?.filter(x => !value?.includes(x)))
    };

    return (
        <div className="my-team-page-main-container">
            <div className="tabs-outer">

                <div className="tabs">

                    <button className={`tab ${activeTab === "My Team" ? "active" : ""}`} onClick={() => { setActiveTab("My Team") }}>My Team</button>
                    <button className={`tab ${activeTab === "My Org" ? "active" : ""}`} onClick={() => { setActiveTab("My Org") }}>My Org</button>
                    <button className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => { setActiveTab("Entire Org") }}>Entire Org</button>

                </div>

            </div>

            <div className="table-bg">
                <div className="table-top-layout">
                    {/*----- Search Bar -----*/}
                    <div className="table-top-column">
                        <div className="search-bar-div">
                            <TextField
                                id="input-with-icon-textfield"
                                placeholder="Search..."
                                // size="small"
                                value={search}
                                fullWidth
                                className="search-bar"
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IoIosSearch />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "20rem",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                            {
                                !isEmptyNullUndefined(search) &&
                                <MdOutlineClose className="close-icon" onClick={() => setSearch("")} />
                            }
                        </div>
                        <div className="table-top-column">

                            <div className="table-filter-dropdown">
                                <button className="filter-tab"
                                // onClick={() => setIsOpenFilter(true)}
                                >
                                    <img src={filterIcon} alt="filter" />{' '}
                                    Filter
                                </button>
                            </div>
                        </div>

                    </div>

                    {/*----- Table Fillter -----*/}

                    {/* <div className="table-top-column"> */}
                        <FormControl size="small"
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #00425A",
                                    borderRadius: "10px",
                                },
                                "& .MuiInputBase-root": {
                                    fontSize: "12px",
                                    color: "#00425A",
                                    fontWeight: 400,
                                }, 
                                width: "14rem",
                            }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}>Config. Columns</InputLabel>
                            <Select
                                fullWidth
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Config. Columns"
                                multiple
                                value={selectedConfigColumns}
                                onChange={(e) => handleConfigColumnsChange(e)}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            "& .MuiMenuItem-root": {
                                                fontSize: "12px",
                                                color: "#00425A",
                                                fontWeight: 400,
                                            }
                                        }
                                    }
                                }}
                            >
                                {
                                columns?.map((report) => {
                                    return (
                                        <MenuItem key={report} value={report}>
                                            <Checkbox checked={selectedConfigColumns?.includes(report)} />
                                            <ListItemText primary={report} />
                                        </MenuItem>
                                    )
                                })
                                }
                                <MenuItem key={"All"} value={"All"}>
                                    <Checkbox checked={columns?.length === selectedConfigColumns?.length} />
                                    <ListItemText primary={"Select All"} />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    {/* </div> */}

                    {/*----- Expand All CheckBox -----*/}
                    {/* <div className="table-top-column expand-all" >
                        <Checkbox
                            checked={ columns?.length === selectedConfigColumns?.length }
                            // disabled={ columns?.length === selectedConfigColumns?.length }
                            onChange={() => setSelectedConfigColumns(columns)}
                        />
                        Expand All
                    </div> */}


                </div>

                <div className="table-container-sample">

                    <table>
                        <thead>

                            <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                        <FaSort className="sort-icon" />
                                    </div>
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                        <FaSort className="sort-icon" />
                                    </div>
                                </th>
                                {
                                    columns?.map((column, index) => {
                                        return (

                                            selectedConfigColumns?.includes(column) &&
                                            <th className={`${(column === "Emp Code") && "sticky"} ${((column === "Emp Name")) && "sticky2"}`}>
                                                <div className="col-head-outer">
                                                    {((column === "Emp Code") || (column === "Emp Name")) ? "" : <img className="mr-5" src={!selectedConfigColumns?.includes(column) ? Plus : Minus} alt="Minus" onClick={() => handleCollapse(column)} />}
                                                    <span className="table-heading">{!selectedConfigColumns?.includes(column) ? "" : column}</span>
                                                </div>
                                            </th>

                                        )
                                    })
                                }

                                {/* <th className="sticky-right">
                           <div className="col-head-outer">
                               <span className="table-heading">Action</span>
                           </div>    
                       </th> */}

                            </tr>
                        </thead>
                        {
                            <tbody>
                                {
                                    myTeamData?.map((row) => (

                                        <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>


                                            <td className="table-value sticky" component="th" scope="row">

                                                {row?.empCode}
                                            </td>

                                            <td className="table-value-emp-name sticky2" align="left">
                                                {row?.empName}&nbsp;
                                            </td>

                                            {
                                                selectedConfigColumns?.includes("Date of Joining") &&
                                                <td className="table-value" align="left">{row?.dateOfJoining}</td>
                                            }
                                            {
                                                selectedConfigColumns?.includes("Function") &&
                                                <td className="table-value" align="left">{row?.function}</td>
                                            }
                                            {
                                                selectedConfigColumns?.includes("Designation") &&
                                                <td className="table-value" align="left">{row?.empDesignation}</td>
                                            }
                                            {
                                                selectedConfigColumns?.includes("Location") &&
                                                <td className="table-value" align="left">{row?.location}</td>
                                            }
                                            {
                                                selectedConfigColumns?.includes("Cost Center") &&
                                                <td className="table-value" align="left">{row?.costCenter}</td>
                                            }



                                        </tr>

                                    ))}
                            </tbody>
                        }
                        <tbody>

                        </tbody>
                    </table>
                    {
                        !myTeamDataLoader && isEmptyNullUndefined(myTeamData) &&
                        <div className="no-data">No data found</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyTeamPage;
