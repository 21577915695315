import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import profileBanner from "../../../../assets/images/profileBanner.png";
import pencile from "../../../../assets/images/pencile.png";
import userProfile from "../../../../assets/images/userProfile.png";
import managerIcon from "../../../../assets/images/manager.png";
import locationIcon from "../../../../assets/images/location.png";


const IDPBannerUserProfile = ({ }) => {
    const dispatch = useDispatch();

    return (
        <div>
            <section class="col-12">
                <div class="pb-3 topbannerGrid">
                    <div class="card-header border-0 pt-3 pb-5 p-relative">
                        <img
                            className="img-resposive"
                            src={profileBanner}
                            alt="MediBuddy"
                        />
                        <img src={pencile} alt="Pencile" className="pencileIcon" />
                    </div>
                    <div class="cardBody">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <div class="profile-div">
                                    <img
                                        src={userProfile}
                                        alt="MediBuddy"
                                        width="140" height="140"
                                        className="user-profile"
                                    />
                                    <div class="bottom-right">
                                        <img
                                            src={pencile}
                                            alt="MediBuddy"
                                            width="24" height="24"
                                        />
                                    </div>
                                </div>

                                <div className="imageRightText">
                                    <Typography class="userTitle">
                                        Harry mustain <span>(Emp Id: AB703)</span>
                                    </Typography>
                                    <div class="d-flex flex-row justify-content-left mt-1">
                                        <span class="px-3 postionTitle">
                                            <img src={managerIcon} alt="MediBuddy" /> Manager
                                        </span>
                                        <span class="px-3 postionTitle">
                                            <img src={locationIcon} alt="MediBuddy" /> Las Vegas,
                                            USA
                                        </span>
                                        <span class="px-3 postionTitle">
                                            <a class="btn contactInfoBtn" href="#" role="button">
                                                Contact Info
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default React.memo(IDPBannerUserProfile);
