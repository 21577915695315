
  import { BsFillCheckCircleFill } from "react-icons/bs";
  import React, { useState } from "react";
  import { useSelector } from "react-redux";
  import { toast } from "react-toastify";
  import { isEmptyNullUndefined } from "../../../../utils/utils";
  import { AUTHENTICATE_URL } from "../../../../../api/apiServices";
  import axios from "axios";
  import { Button, CircularProgress, TextField } from "@mui/material";
  import { FaEye, FaEyeSlash } from "react-icons/fa";
  import PropTypes from 'prop-types';
  
  const PopupChangePassword = ({setOpenDialog}) => {
      // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
    
    const [showPassword, setShowPassword] = useState({
      old:false,
      new:false,
      reWrite:false,
    })
   
    const state = useSelector((state) => state);
  
    const changePasswordApi = axios.create({baseURL: AUTHENTICATE_URL,});
  
    const isUserToken = () => {
      const userToken = state?.user?.userToken;
      return !!(userToken && Object.keys(userToken).length > 0);
    };
  
    changePasswordApi.interceptors.request.use((request) => {
      // Do something before request is sent
      request.baseURL = AUTHENTICATE_URL;
      const isToken = isUserToken();
      if (isToken) {
        const userToken = state?.user?.userToken?.id_token;
        request.headers.Authorization = "Bearer " + userToken;
      } else {
        request.headers = {};
      }
      return request;
    });
  
    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [retypeNewPassword, setRetypeNewPassword] = useState(null)
    const [submitLoader, setSubmitLoader] = useState(false);
    // console.log(`oldPassword`,oldPassword)
    // console.log(`newPassword`,newPassword)
    // console.log(`retypeNewPassword`,retypeNewPassword)
    const [formError, setFormError] = React.useState({
      oldPassword: {isError: false, errorMessage: ''},
      newPassword: {isError: false, errorMessage: ''},
      retypeNewPassword: {isError: false, errorMessage: ''}
    })
  
    const handleOldPassword = (event) => {
      const tempFormError = {...formError}
      tempFormError.oldPassword.isError = false
      tempFormError.oldPassword.errorMessage = ''
      setFormError(tempFormError)
      setOldPassword(event.target.value)
    }
    const handleNewPassword = (event) => {
      const tempFormError = {...formError}
      tempFormError.newPassword.isError = false
      tempFormError.newPassword.errorMessage = ''
      setFormError(tempFormError)
      setNewPassword(event.target.value)
    }
    const handleRetypeNewPassword = (event) => {
      const tempFormError = {...formError}
      tempFormError.retypeNewPassword.isError = false
      tempFormError.retypeNewPassword.errorMessage = ''
      setFormError(tempFormError)
      setRetypeNewPassword(event.target.value)
    }
    // console.log('oldPassword', oldPassword)
  
  
  
    const getValidate = () => {
      // console.log('in validate')
      let isValid = true;
  
      const tempOldPassword = oldPassword
      const tempNewPassword = newPassword
      const tempRetypeNewPassword = retypeNewPassword
      const tempError = { ...formError };
  
      if (!isEmptyNullUndefined(tempNewPassword)) {
        if (
          !tempNewPassword.match(passwordRegex)
        ) {
          // console.log('!tempNewPassword.match(passwordRegex)', !tempNewPassword.match(passwordRegex))
          tempError.newPassword.isError = true;
          tempError.newPassword.errorMessage =
            'must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
          isValid = false;
        }
      }
      if ((!isEmptyNullUndefined(tempNewPassword)) && (!isEmptyNullUndefined(tempRetypeNewPassword))) {
        if((tempNewPassword) !== (tempRetypeNewPassword)) {
          tempError.retypeNewPassword.isError = true;
          tempError.retypeNewPassword.errorMessage = 'Passwords must match'
          isValid = false
        }
      }
      if (isEmptyNullUndefined(tempOldPassword)) {
        tempError.oldPassword.isError = true;
        tempError.oldPassword.errorMessage = 'Please Enter old password';
        isValid = false;
      }
      if (isEmptyNullUndefined(tempNewPassword)) {
        tempError.newPassword.isError = true;
        tempError.newPassword.errorMessage = 'Please Enter new password'
        isValid = false
      }
      if (isEmptyNullUndefined(tempRetypeNewPassword)) {
        tempError.retypeNewPassword.isError = true;
        tempError.retypeNewPassword.errorMessage = 'Please Re-enter new password'
        isValid = false
      }
  
      if (isValid) {     
          submitChangePassword()
      } else {
        setFormError(() => tempError)
      // console.log('validate not successfull')
        
      }
    }
  
    const submitChangePassword = () => {
      setSubmitLoader(true);
        let data = {
        "currentPassword": oldPassword,
        "newPassword": newPassword
        }
  
        changePasswordApi
          .post("/account/change-password", data)
          .then((res) => {
            setOldPassword(null);
            setNewPassword(null);
            setRetypeNewPassword(null);
            setOpenDialog(false);
            setSubmitLoader(false);
            console.log(` changed successfully`)
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Password
                {` changed successfully`}
              </div>
            );
          })
          .catch((e) => {
            toast.error(e?.response?.data?.message);
            setSubmitLoader(false);
          });
    }
    
    const handleShowPasword = (value) => {
      let temp = structuredClone(showPassword);
      
      if (temp[value]) {
        temp[value] = false;
      } else {
        temp[value] = true;
      }
      setShowPassword(temp);
    }
  
  
    return (
        <div className="form-row-popup-pass-change">
          <div className="user-change-password-page">
            <div className="row">
                <div className="popup-pass-change-text1">**Welcome! Time to Secure Your Account**</div>
                <div className="popup-pass-change-text">You've successfully logged in for the first time. For added security, please update your password now.</div>
                <div className="popup-pass-change-text">Click "Change Password" to get started.</div>
              <div className="col-12">
                <div
                  // style={{height: '70vh'}}
                  className="accorDetails personalInfo"
                >
                  {/* <p className="accordian-title">Change Password </p> */}
                  <div className="accordian-data">
                    <div className="col-12">
                      <div
                        // style={{ backgroundColor: "#F9F9F9" }}
                        className="accorDetails personalInfo"
                      >
                        {/* <p className="accordian-title">Old Password</p> */}
                        {/* <div class="col-md-12 mb-3"> */}
                        <TextField
                            size="small"
                            name="Old Password"
                            type={showPassword?.old ? "text" : "password"}
                            // label="First Name"
                            label="Old Password"
                            id="outlined-basic"
                            onChange={(event) =>
                                handleOldPassword(event)
                            }
                            variant="outlined"
                            error={formError.oldPassword.isError}
                            helperText={formError.oldPassword.errorMessage}
                            value={oldPassword || ""}
                            sx={{
                                my: 2,
                                width: "100%",
                                //backgroundColor: "#dedede",
                                "& fieldset": {
                                border: "1px solid #dedede",
                                },
                                // "& .MuiInputBase-input:focus": {
                                //   border: "2px solid #fcd25e", // focus
                                // },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                                }
                            }}
                        />
                        <span class="eyeIcon" onClick={() => handleShowPasword("old")} role="button" tabindex="0">
                    {
                      showPassword?.old ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        // style={{ backgroundColor: "#F9F9F9" }}
                        className="accorDetails personalInfo"
                      >

                        <TextField
                            size="small"
                            name="New Password"
                            type={showPassword?.new ? "text" : "password"}
                            // label="First Name"
                            label="New Password"
                            id="outlined-basic"
                            onChange={(event) =>
                                handleNewPassword(event)
                            }
                            variant="outlined"
                            error={formError.newPassword.isError}
                            helperText={formError.newPassword.errorMessage}
                            value={newPassword || ""}
                            sx={{
                                my: 2,
                                width: "100%",
                                //backgroundColor: "#dedede",
                                "& fieldset": {
                                border: "1px solid #dedede",
                                },
                                // "& .MuiInputBase-input:focus": {
                                //   border: "2px solid #fcd25e", // focus
                                // },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                                }
                            }}
                        />      
                  <span class="eyeIcon" onClick={() => handleShowPasword("new")} role="button" tabindex="0">
                    {
                      showPassword?.new ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        // style={{ backgroundColor: "#F9F9F9" }}
                        className="accorDetails personalInfo"
                      >

                        <TextField
                            size="small"
                            name="Retype New Password"
                            type={showPassword?.reWrite ? "text" : "password"}
                            // label="First Name"
                            label="Retype New Password"
                            id="outlined-basic"
                            onChange={(event) =>
                                handleRetypeNewPassword(event)
                            }
                            variant="outlined"
                            error={formError.retypeNewPassword.isError}
                            helperText={formError.retypeNewPassword.errorMessage}
                            value={retypeNewPassword || ""}
                            sx={{
                                my: 2,
                                width: "100%",
                                //backgroundColor: "#dedede",
                                "& fieldset": {
                                border: "1px solid #dedede",
                                },
                                // "& .MuiInputBase-input:focus": {
                                //   border: "2px solid #fcd25e", // focus
                                // },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                                }
                            }}
                        />      
                        <span class="eyeIcon" onClick={() => handleShowPasword("reWrite")} role="button" tabindex="0">
                    {
                      showPassword?.reWrite ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center pt-2 main-btn">
                    {/* <div className="valuesBtn draft">Auto Save 30 sec</div> */}
                    <Button
                      className="valuesBtn next"
                      disabled={submitLoader}
                      onClick={() => getValidate()}
                    >
                        {
                            submitLoader ? 
                            <CircularProgress size={29} sx={{color:"#ffffff"}} />
                            :
                            "Change Password"
                        }
                      
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  PopupChangePassword.propTypes = {
    setOpenDialog: PropTypes.func,
  };
  
  export default PopupChangePassword;
  