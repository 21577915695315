import { Skeleton, Stack, TextField } from "@mui/material"

// export const TextFieldStr = () => {
//     return (
//         <div className="text-field">

//             <div className="field-label" >
//                 {TileElement[0].label}
//             </div>
//             <TextField
//                 name={TileElement[0].name}
//                 value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.stringResponse}
//                 onChange={(event) =>
//                     handleChangeVertualPayloadContainer(
//                         JSON.parse(JSON.stringify(vertualPayload)),
//                         event,
//                         "textField",
//                         tabIndex,
//                         0, //tab row position
//                         TabElement,
//                         tileIndex,
//                         TileElement,
//                         0, //tile row position
//                         groupContainerI,
//                         groupContainerE,
//                         0, //groupContainer row position


//                     )
//                 }
//                 multiline={TileElement[0].isMultiTextFieldRow ?? false}
//                 rows={TileElement[0].textFieldRowSize ?? 1}
//                 id="outlined-multiline-static"
//                 variant="outlined"
//                 size="small"
//                 sx={{
//                     width: '100%',
//                     mt: '6px',
//                     "& fieldset": {
//                         borderRadius: '8px',                              
//                         },
//                 }}
//             />
//         </div>
//     )
// }

export const DndFormSkeleton = () => {
    return (
        <Stack width={'100%'}>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
                <Stack flexDirection={'row-reverse'} width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
                <Stack flexDirection={'row-reverse'} width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'100%'}><Skeleton variant="rounded" width={'100%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
                <Stack flexDirection={'row-reverse'} width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
                <Stack flexDirection={'row-reverse'} width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
                <Stack flexDirection={'row-reverse'} width={'50%'}><Skeleton variant="rounded" width={'99%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'100%'}><Skeleton variant="rounded" width={'100%'} height={42} /></Stack>
            </Stack>
            <Stack sx={{margin: '0.5rem 0'}} justifyContent={'space-between'} direction={'row'} width={'100%'}>
                <Stack width={'100%'}><Skeleton variant="rounded" width={'100%'} height={42} /></Stack>
            </Stack>
        </Stack>
    )
}