import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.LOGIN_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.LOGIN_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        user: null,
        userToken: payload ,
    }),
    [types.LOGIN_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.CLEAR_LOGIN_TOKEN]: (state) => ({
        ...state,
        userToken: null,
        user: null
    }),
    [types.USER_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.USER_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        user: payload ,
    }),
    [types.USER_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    userToken: null,
    user:null,
});
