import { Accordion, AccordionDetails, AccordionSummary, Stack, TextField } from "@mui/material";
import Banner from "../../../../assets/images/banner.png";
import User from "../../../../assets/images/user.png"; 
import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import ArrowRight from "../../../../assets/images/arrowRight.svg"
import FeedbackGiven from "../../../../assets/images/feedbackGiven.svg"
// import Accordion from 'react-bootstrap/Accordion';
import UpArrow from "../../../../assets/images/upArrow.svg"
import Checked from "../../../../assets/images/checked.svg"
import Info from "../../../../assets/images/info.svg"
import Send from "../../../../assets/images/send.svg"
import { Route, useHistory, useLocation } from "react-router-dom";
import Alert from "../../../../assets/images/alert.svg"
import Setting from "../../../../assets/images/setting.svg"
import BackArrow from "../../../../assets/images/backArrowSelf.svg"
import { assesmentDummyData, isEmptyNullUndefined, ratings, textAreaStyle, values, accordianSVGstyle } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import AccSkeleton from "../../../common/akkSkeleton";
import {actions as selfApraisalDataActions} from "../../../../redux/selfApraisal/actions";

const UserProfilePage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selfApraisalData = useSelector((state) => state?.selfApraisalData?.selfApraisalData);
    const selfApraisalDataLoader = useSelector((state) => state?.selfApraisalData?.loader);
    const savedApraisalData = useSelector((state) => state?.selfApraisalData?.savedApraisalData);

    const [expanded, setExpanded] = useState(null);
    const [selectedDemonstrated, setSelectedDemonstrated] = useState([]);
    const [selectedNotDemonstrated, setSelectedNotDemonstrated] = useState([]);
    const [apraisalPayload, setApraisalPayload] = useState(null);

// console.log("selectedDemonstrated",apraisalPayload)

    useEffect(() => {

        if(!isEmptyNullUndefined(savedApraisalData)){
            setApraisalPayload(savedApraisalData);
            if(!isEmptyNullUndefined(savedApraisalData?.values?.demonstratedValues)){
                setSelectedDemonstrated(savedApraisalData?.values?.demonstratedValues);
            }
            if(!isEmptyNullUndefined(savedApraisalData?.values?.notDemonstratedValues)){
                setSelectedNotDemonstrated(savedApraisalData?.values?.notDemonstratedValues);
            }
            
            
        }
        // if(!isEmptyNullUndefined(selfApraisalData)){
        //     setSelectedDemonstrated(selfApraisalData?.values?.demonstratedValues);
        //     setSelectedNotDemonstrated(selfApraisalData?.values?.notDemonstratedValues);
        // }
    
    }, [])


    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleSelectValue = (value) => {
        let tempSelecteddemonstrated = structuredClone(selectedDemonstrated);

        if (tempSelecteddemonstrated?.includes(value)) {
            tempSelecteddemonstrated = tempSelecteddemonstrated.filter(x => x!==value);
        } else {
            if(!selectedNotDemonstrated?.includes(value)) {
            tempSelecteddemonstrated.push(value);
        }
        }       

        setSelectedDemonstrated(tempSelecteddemonstrated);
    }

    const handleSelectValueNotDemo = (value) => {
        let tempSelectedNotdemonstrated = structuredClone(selectedNotDemonstrated);

        if (tempSelectedNotdemonstrated?.includes(value)) {
            tempSelectedNotdemonstrated = tempSelectedNotdemonstrated.filter(x => x!==value);
        } else {
            if(!selectedDemonstrated?.includes(value)) {
                tempSelectedNotdemonstrated.push(value);
            }            
        }       

        setSelectedNotDemonstrated(tempSelectedNotdemonstrated);
    }

    const handleChangeText = (event) => {
        let tempPayload = structuredClone(apraisalPayload);
        let key = event.target.name;

        tempPayload.values[key] = event.target.value;

        setApraisalPayload(tempPayload);
    }

    const handleNext = () => {

        let tempApraisalPayload = structuredClone(apraisalPayload);

        tempApraisalPayload.values.demonstratedValues = selectedDemonstrated;
        tempApraisalPayload.values.notDemonstratedValues = selectedNotDemonstrated;

        dispatch(selfApraisalDataActions.SaveApraisalDataRequest(tempApraisalPayload));
        history.push('/self-assesment/page-three')
    }


    return(
        <div className="selfAssessment">
        {/* <div className="page-header-main-heading">Self Assesment</div> */}
            <div className="row">
            {
                    selfApraisalDataLoader ? 
                     <AccSkeleton />
                     :
                    <div className="feedbackAssessment">
                                {/* <div className="d-flex justify-content-between align-items-center bor-b">
                                    <div className="left d-flex align-items-center gap-3">
                                        <img src={User} className="userImg"></img>
                                        <div>
                                            <p className="title">Micheal william</p>
                                            <p className="id">Employee Id - 234</p>
                                        </div>
                                    </div>
                                    <button className="viewBtn">View Assesment</button>
                                </div> */}
                                {/* <p className="heading">OKR</p> */}
                                <div className="trendingRating trendingRating-o">
                                
                                    <div className="values">
                                        <div>
                                            <Stack sx={{margin:"1rem",
                                                "& .MuiAccordion-rounded":{
                                                    border:"1px solid #dad2d2",
                                                    borderRadius:"1rem",
                                                    boxShadow:"none"
                                                }
                                            }}>
                                                <Accordion
                                                // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                                expanded={expanded === "rule1"} onChange={() => handleChange("rule1")} data-value={"rule1"}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                                sx={{
                                                    width:"100%",   
                                                    fontSize:"1.5rem",
                                                    fontWeight:"600",
                                                    "& .MuiAccordionSummary-content":{
                                                        width:"100%", 
                                                        display:"flex", 
                                                        justifyContent:"space-between",
                                                    }}}
                                                >
                                                    <div className="accorTitle">
                                                    Give examples of a time when you demonstrated one or more of the MediBuddy Core Cultural Values
                                                        {/* <img src={UpArrow} className="upArrowAccrodian"></img> */}
                                                    </div>
                                                    <BsChevronDown
                                                        style={{...accordianSVGstyle ,transform: expanded !== "rule1"? "rotate(0deg)" : "rotate(180deg)"}}
                                                    />
                                                                      
                                                </AccordionSummary>
                                                <AccordionDetails
                                                sx={{
                                                    fontSize:"1rem"
                                                }}
                                                >
                                                    <div className="trendingRating">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="title"></p>
                                                            {/* <img src={UpArrow} ></img> */}
                                                        </div>
                                                        <div className="rateOuter">
                                                            
                                                            {
                                                                values?.map((item,index) => {
                                                                    return(
                                                                        <div className={`rate ${selectedDemonstrated?.includes(item) ? "active" : ""} ${selectedNotDemonstrated?.includes(item) ? "deactive" : ""}`} onClick={() => handleSelectValue(item)}>
                                                                            <p className="rateTitle">{item}</p>
                                                                            {
                                                                                selectedDemonstrated?.includes(item) && <img src={Checked} className="checked"></img>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        
                                                        
                                                        </div>
                                                    
                                                        <div className="contentDiv-o">
                                                            <div className="contentHead d-flex justify-content-between align-items-center">
                                                                {/* <p className="title">Give examples of a time when you demonstrated one or more of the MediBuddy Core Cultural Values</p> */}
                                                                {/* <img src={Info}></img> */}
                                                            </div>
                                                            <div className="values d-flex align=items-center gap-3">
                                                            <TextField 
                                                                name="demonstrated"
                                                                id="outlined-multiline-static" 
                                                                label="" 
                                                                variant="outlined" 
                                                                placeholder="Please provide Overall feedback" 
                                                                multiline
                                                                rows={3}
                                                                value={apraisalPayload?.values?.demonstrated || ""}
                                                                sx={textAreaStyle}
                                                                onChange={(e) => handleChangeText(e)}
                                                                />
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                                </Accordion>
                                            </Stack>

                                            <Stack sx={{margin:"1rem",
                                                "& .MuiAccordion-rounded":{
                                                    border:"1px solid #dad2d2",
                                                    borderRadius:"1rem",
                                                    boxShadow:"none"
                                                }
                                            }}>
                                                <Accordion
                                                // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                                expanded={expanded === "rule2"} onChange={() => handleChange("rule2")} data-value={"rule2"}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                                sx={{
                                                    width:"100%",   
                                                    fontSize:"1.5rem",
                                                    fontWeight:"600",
                                                    "& .MuiAccordionSummary-content":{
                                                        width:"100%", 
                                                        display:"flex", 
                                                        justifyContent:"space-between",
                                                    }}}
                                                >
                                                    <div className="accorTitle">Give examples of a time when you have not demonstrated one or more of the MediBuddy Core Cultural Values</div>
                                                    <BsChevronDown
                                                        style={{...accordianSVGstyle ,transform: expanded !== "rule2"? "rotate(0deg)" : "rotate(180deg)"}}
                                                    />
                                                                      
                                                </AccordionSummary>
                                                <AccordionDetails
                                                sx={{
                                                    fontSize:"1rem"
                                                }}
                                                >
                                                    <div className="trendingRating">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="title"></p>
                                                            {/* <img src={UpArrow} ></img> */}
                                                        </div>
                                                        <div className="rateOuter">
                                                    
                                                            {
                                                                values?.map((item,index) => {
                                                                    return(
                                                                        <div className={`rate ${selectedNotDemonstrated?.includes(item) ? "active" : ""} ${selectedDemonstrated?.includes(item) ? "deactive" : ""}`} onClick={() => handleSelectValueNotDemo(item)}>
                                                                            <p className="rateTitle">{item}</p>
                                                                            {
                                                                                selectedNotDemonstrated?.includes(item) && <img src={Checked} className="checked"></img>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            
                                                        
                                                        
                                                        </div>
                                                    
                                                        <div className="contentDiv-o">
                                                            <div className="contentHead d-flex justify-content-between align-items-center">
                                                                {/* <p className="title">Give examples of a time when you demonstrated one or more of the MediBuddy Core Cultural Values</p> */}
                                                                {/* <img src={Info}></img> */}
                                                            </div>
                                                            <div className="values d-flex align=items-center gap-3">
                                                            <TextField 
                                                                name="notDemonstrated"
                                                                id="outlined-multiline-static" 
                                                                label="" 
                                                                variant="outlined" 
                                                                placeholder="Please provide Overall feedback" 
                                                                multiline
                                                                rows={3}
                                                                value={apraisalPayload?.values?.notDemonstrated || ""}
                                                                sx={textAreaStyle}
                                                                onChange={(e) => handleChangeText(e)}
                                                                />
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                                </Accordion>
                                            </Stack>                    
                                        </div>
                                        
                                        {/* <div className="d-flex justify-content-end gap-3 pt-5">
                                            <button className="valuesBtn draft">Save as draft</button>
                                            <button className="valuesBtn next" onClick={() => incrementPage()}>Next</button>
                                        </div> */}
                                        <div className="d-flex justify-content-end gap-3 pt-5">
                                        <button className="valuesBtn draft">Save as draft</button>
                                        <button className="valuesBtn next"
                                        onClick={handleNext}
                                            >Next</button>
                                    </div>
                                    </div>
                                </div>
                            
                    </div>
           }
            </div>
        </div>
    )
};

export default UserProfilePage; 
