
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CurrencyFormat } from "../../utils/utils";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({label1, label2, data1, data2, color1, color2, percent1, percent2}) => {
  
  const currency = useSelector((state) => state?.empData?.empData?.company?.currency);

    const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: (chart) => {
          const { width, height, ctx } = chart;
          ctx.restore();
          
          const fontSize = `${(height / 475).toFixed(2)}rem`;
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = 'middle';
          ctx.fillStyle = Number(data1) < 1 ? 'red' : "black";

          const lines = [`${currency} ${CurrencyFormat(Number(data1)?.toFixed(0), currency)}`, 'Available'];
          const lineHeight = height / 15;
          
          lines.forEach((line, index) => {
            const textX = Math.round((width - ctx.measureText(line).width) / 2);
            const textY = height / 2.45 - lineHeight + (index * lineHeight);
            ctx.fillText(line, textX, textY);
          });
      
          ctx.save();
        },
      };

    const chartData = {
        labels: [`${label1} : ${currency} ${CurrencyFormat((Number(data1)), currency)} (${percent1}%)`, `${label2} : ${currency} ${CurrencyFormat((Number(data2)), currency)} (${percent2}%)`, `Total Budget : ${currency} ${CurrencyFormat(Number(data1) + Number(data2), currency)}`],

        datasets: 
        Number(data1) < 1 ? 
        [
          {
            // label: "# of Votes",
            data: [0,Number(data1),0],
            backgroundColor: [
                color1, 
                color2,
                "#ffffff"
            ],
            borderColor: [
              "#ffffff", 
              color2, 
               "#ffffff"
            ],
            borderWidth: 1,
          },
        ]
        :
        [
          {
            // label: "# of Votes",
            data: [Number(data1), Number(data2), 0],
            backgroundColor: [
                color1, 
                color2,
                "#ffffff"
            ],
            borderColor: [
                "#ffffff", 
                "#ffffff", 
                "#ffffff"
            ],
            borderWidth: 1,
          },
        ],
      };


    const chartOptions = {
        cutout: '70%', // This value controls the cutout percentage
        radius:"90",
        responsive: true,
        plugins: {
            legend: {
              position: 'bottom', // You can change this to 'top', 'left', 'right', or customize it based on your needs
              // align: 'start', 
              labels: {
                font: {
                  family: 'poppins', // Specify font family
                  size: 12,                    // Font size
                  // style: 'italic',             // Font style (normal, italic, oblique)
                  // weight: 'bold',              // Font weight (normal, bold, etc.)
                  color: '#00425A',               // Font color
                },
                padding: 20,                  // Optional: adds padding around the labels
              },
            },
          },
      };

    return(
        <div className="pie-main">
            <Doughnut data={chartData} options={chartOptions} plugins={[centerTextPlugin]} />
        </div>
    )
}

DoughnutChart.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  data1: PropTypes.number,
  data2: PropTypes.number,
  color1: PropTypes.string,
  color2: PropTypes.string,
  percent1: PropTypes.number,
  percent2: PropTypes.number,
};

export default DoughnutChart;