import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import badgeIcon from "../../../../assets/images/badge.png";

const Competencies = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div className="first-row">
      <div className="header-section">
        <Typography className="aboutheading competencies-mb-12">Top Competencies </Typography>
      </div>
      <div className="main-b eeppage">
        <div className="row">
          {/*----- Skills and Knowledge Base -----*/}
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="blueCard">
              <div className="content-section">
                <div className="header-section">
                  <Typography className='primary-txt'>{"Primary"}</Typography>
                  <img src={badgeIcon} alt="MediBuddy" />
                </div>
                <Typography className="competencies-heading">{"Skills and Knowledge Base"}</Typography>
              </div>
              <div className="bottom-section">
                <Typography className="competencies-sub-heading">{"Self Declaration: NA"}</Typography>
              </div>
            </div>
          </div>

          {/*----- Technical Systems -----*/}
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="blueCard">
              <div className="content-section">
                <div className="header-section">
                  <Typography className='primary-txt'>{"Primary"}</Typography>
                  <img src={badgeIcon} alt="MediBuddy" />
                </div>
                <Typography className="competencies-heading">{"Technical Systems"}</Typography>
              </div>
              <div className="bottom-section">
                <Typography className="competencies-sub-heading">{"Self Declaration: NA"}</Typography>
              </div>
            </div>
          </div>

          {/*----- Managerial Systems -----*/}
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="blueCard">
              <div className="content-section">
                <div className="header-section">
                  <Typography className='primary-txt'>{"Primary"}</Typography>
                  <img src={badgeIcon} alt="MediBuddy" />
                </div>
                <Typography className="competencies-heading">{"Managerial Systems"}</Typography>
              </div>
              <div className="bottom-section">
                <Typography className="competencies-sub-heading">{"Self Declaration: NA"}</Typography>
              </div>
            </div>
          </div>

          {/*----- Values and Norms -----*/}
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="blueCard">
              <div className="content-section">
                <div className="header-section">
                  <Typography className='primary-txt'>{"Primary"}</Typography>
                  <img src={badgeIcon} alt="MediBuddy" />
                </div>
                <Typography className="competencies-heading">{"Values and Norms"}</Typography>
              </div>
              <div className="bottom-section">
                <Typography className="competencies-sub-heading">{"Self Declaration: NA"}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Competencies);
