import React, { useState } from 'react';
import { Typography, TextField, Grid, InputAdornment, Box } from "@mui/material";
import EditSuccessionPlan from './components/EditSuccessionPlan';
import ViewSuccessionPlan from './components/ViewSuccessionPlan';
import DelegateDialogPopup from "../../common/Dialog";
import SearchIcon from "../../../assets/images/successionPlan/searchIcon.svg";
import RoleCloseIcon from "../../../assets/images/successionPlan/roleClose.svg";

const SuccessionPlan = () => {
  const [showDelegatePopup, setShowDelegatePopup] = useState(false);
  const [isEditSuccessionPlan, setIsEditSuccessionPlan] = useState(false);


  const handleEditSuccessionPlanClick = () => {
    setIsEditSuccessionPlan(true);
  };

  const handleDelegate = () => {
    setShowDelegatePopup(true);
  }

  const delegateList = [
    { userName: "Manisha Goal" },
    { userName: "Isha" },
    { userName: "Jagbir Singh" },
    { userName: "Angie Mary J." },
    { userName: "Gaurav Malviya" },
    { userName: "Devesh Sharma" },
    { userName: "Monika Jain" },
  ];

  return (
    <div className='successionPlanPage'>
      {/*----- John Doe's team succession plan & Delegate btn & Edit btn -----*/}
      <div className='d-flex ai-center jc-sb'>
        <Typography className='succession-heading'>{"John Doe's team succession plan"}</Typography>

        {!isEditSuccessionPlan && (
          <div className='d-flex'>
            <button className="plan-cancel-btn" onClick={() => handleDelegate()}>
              {"Delegate"}
            </button>

            <button className="plan-save-btn" onClick={handleEditSuccessionPlanClick}>
              {"Edit"}
            </button>
          </div>
        )}
      </div>

      {/*----- View Succession Plan & Edit Succession Plan -----*/}
      <div>
        {isEditSuccessionPlan ? <EditSuccessionPlan /> : <ViewSuccessionPlan />}
      </div>


      {showDelegatePopup &&
        <DelegateDialogPopup openDialog={showDelegatePopup} setOpenDialog={setShowDelegatePopup} redirect={false} >
          <div className="delegate-modal">
            {/*----- Search Delegate Field -----*/}
            <TextField
              name="searchDelegate"
              placeholder='Search delegate'
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& fieldset": {
                  border: '1px solid #D9D9D9',
                  borderRadius: '10px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      height={15}
                      width={15}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <div className='user-border-div'>
              <div className='user-d-flex-ai-center'>
                <Typography className='border-user-name'>{"Manisha Goal"}</Typography>
                <img
                  src={RoleCloseIcon}
                  alt="RoleCloseIcon"
                  height={12}
                  width={12}
                  className='user-close-Icon'
                />
              </div>
            </div>

            {/*----- Delegate List -----*/}
            {delegateList.map((item, index) => (
              <Typography className="delegate-user-name">{item.userName}</Typography>
            ))}

            {/*----- Horizontal Border -----*/}
            <Grid className='horizontal-border' />

            {/*----- Delegate Button -----*/}
            <div className="delegate-div">
              <button className="delegate-btn">
                {"Delegate"}
              </button>
            </div>
          </div>
        </DelegateDialogPopup>
      }
    </div>
  );
};

export default SuccessionPlan;