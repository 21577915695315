import React from 'react';
import { Dialog, Button, Grid, Typography, MenuItem, Select, Box, TextField } from '@mui/material';
import EditUserProfile from "../../../../../../assets/images/successionPlan/editUserProfile.png";
import RoleCloseIcon from "../../../../../../assets/images/successionPlan/roleClose.svg";


const ProfileViewModal = ({ showProfileView, handleProfileViewNo }) => {

    const totalNumbers = [1, 2, 3, 4, 5];
    return (
        <Dialog
            open={showProfileView}
            //onClose={handleProfileViewNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="profile-edit">
                <Grid container item xs={12} md={12} sm={12} sx={{ justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className="modal-heading">{"Readiness Profile Card"}</Typography>
                    <img
                        src={RoleCloseIcon}
                        alt="RoleCloseIcon"
                        height={12}
                        width={12}
                        className="modal-close-Icon"
                        onClick={handleProfileViewNo}
                    />
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="profile-card">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2} sm={2} className="ta-center right-border">
                            <img
                                src={EditUserProfile}
                                alt="EditUserProfile"
                                height={50}
                                width={50}
                            />
                            <Typography className="profile-name">{"Akshay C "}</Typography>
                            <Typography className="profile-field-label">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Current Role"}</Typography>
                                    <Typography className="profile-field-value">{"VP Retail - Category"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Tenure in Role (# months)"}</Typography>
                                    <Typography className="profile-field-value">{"9 Months"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Tenure in Level (# months)"}</Typography>
                                    <Typography className="profile-field-value">{"9 Months"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Mentor if existing or known"}</Typography>
                                    <Typography className="profile-field-value">{"-"}</Typography>
                                </Grid>

                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Geographic Mobility (if yes, regions)"}</Typography>
                                    <Typography className="profile-field-value">{"Yes, Moving to Seattle in Summer"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Performance Rating 2024"}</Typography>
                                    <Typography className="profile-field-value">{"B"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Behavior Rating 2024"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Performance Rating 2023:"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>

                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Behavior Rating 2023"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*----- Rate Competency -----*/}
                <Grid item xs={12} md={12} sm={12} className="rate-competency-card">
                    <Grid container sx={{ marginBottom: "10px" }}>
                        <Typography className="hr-rate-competency">{"Rate Competency"}</Typography>
                        <Grid container className="rate-competency-count-grid">
                            <Typography className="count">{"1"}<span className="count-msg">{" - Does not exhibit"}</span></Typography>
                            <Typography className="count ml-10">{"5"}<span className="count-msg">{" - Strongly exhibit"}</span></Typography>
                        </Grid>
                    </Grid>
                    <Grid className="rate-competency-grid">
                        <Grid>
                            <Typography className="select-field-label">{"Customer Obsession"}</Typography>
                            <Typography className="select-field-value">{"5"}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Ownership"}</Typography>
                            <Typography className="select-field-value">{"3"}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Invent And Simplify"}</Typography>
                            <Typography className="select-field-value">{"3"}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Are  Right A Lot"}</Typography>
                            <Typography className="select-field-value">{"4"}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Learn and Be Curious"}</Typography>
                            <Typography className="select-field-value">{"3"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid className="rate-competency-grid">
                        <Grid>
                            <Typography className="select-field-label">{"Hire and Develop the Best"}</Typography>
                            <Typography className="select-field-value">{"2"}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Insist on the Highest Standards"}</Typography>
                            <Typography className="select-field-value">{"5"}</Typography>
                        </Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                    </Grid>
                </Grid>

                {/*----- Development Opportunity Areas -----*/}
                <Grid item xs={12} md={12} sm={12} className="rate-competency-card">
                    <Typography className="hr-rate-competency">{"Development Opportunity Areas"}</Typography>
                    <Typography className="field-description-label">{"I'll be sharing over 200 business names ideas and examples to help you come up with business names that are catchy, personal, cryptic, thought-provoking, bold"}</Typography>
                </Grid>

                {/*----- Action steps to address the Gaps -----*/}
                <Grid item xs={12} md={12} sm={12} className="rate-competency-card">
                    <Typography className="hr-rate-competency">{"Action steps to address the Gaps"}</Typography>
                    <Typography className="field-description-label">{"He has used an external coach and has tools to mitigate the trigger points. He has done well practicing that and should continue to do so in future as well."}</Typography>
                </Grid>

                {/*----- Does the current role maximize the person's strengths? -----*/}
                <Grid item xs={12} md={12} sm={12} className="rate-competency-card">
                    <Typography className="hr-rate-competency">{"Does the current role maximize the person's strengths?"}</Typography>
                    <Typography className="field-description-label">{"He has used an external coach and has tools to mitigate the trigger points. He has done well practicing that and should continue to do so in future as well."}</Typography>
                </Grid>

                {/*----- How & when could this person potentially outpace the role? -----*/}
                <Grid item xs={12} md={12} sm={12} className="rate-competency-card">
                    <Typography className="hr-rate-competency">{"How & when could this person potentially outpace the role?"}</Typography>
                    <Typography className="field-description-label">{"He has used an external coach and has tools to mitigate the trigger points. He has done well practicing that and should continue to do so in future as well."}</Typography>
                </Grid>

            </Grid>
        </Dialog>
    );
};

export default ProfileViewModal;