import Avatar from "react-avatar";
import { getFormattedDate, isEmptyNullUndefined } from "../../utils/utils";
import { useSelector } from "react-redux";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { CircularProgress } from "@mui/material";

const EmpProfile = ({empInfo}) => {

    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [ratingsHistory, setRatingsHistory] = useState(null);
    const [ratingsHistoryLoader, setRatingsHistoryLoader] = useState(false);

    console.log("empInfo",empInfo);

    useEffect(() => {
        if(!isEmptyNullUndefined(empInfo?.id)) {
            setRatingsHistoryLoader(true);
            APIList.getRatingHistory(empInfo?.id)
            .then((res) => {
                console.log("res---------->",res)
                setRatingsHistory(res?.data);
                setRatingsHistoryLoader(false);
             }).catch((err) => {
               toast.error(
                   <div style={{display:"flex", flexDirection:"row"}}>
                     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                     &nbsp;&nbsp;{err?.title}
                   </div>
                 )
                 setRatingsHistoryLoader(false);
             })
        }
    }, []);

    const groupedData = !isEmptyNullUndefined(ratingsHistory) && ratingsHistory.reduce((acc, currentValue) => {
        const { cycleName, ratingName, trendingRating } = currentValue;
        if (!acc[cycleName]) {
          acc[cycleName] = { cycleName, MBB: '', OKR: '' };
        }
        if (ratingName === 'MBB') {
          acc[cycleName].MBB = trendingRating;
        } else if (ratingName === 'OKR') {
          acc[cycleName].OKR = trendingRating;
        }
        return acc;
      }, {});
    

    return(
        <div className="common-emp-profile">
            <div className="user-profile-sidedrawer-img">
          {empInfo?.profilePhoto ?
            <img src={empInfo?.profilePhoto?.path} className="userImg" alt="profilePic"></img>
            :
            <Avatar 
                name={empInfo?.name} 
                size="75" 
                className="userImg"
                color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
            />
        }
        </div>

        <div className="user-info">
        <div className="accorDetails personalInfo">
                        <p className="accordian-title">Personal Details</p>
                        <div className="accordian-data">
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Name</p>
                                <p className="subTitle">{empInfo?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Id</p>
                                <p className="subTitle">{empInfo?.employeeId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Email Id</p>
                                <p className="subTitle">{empInfo?.emailId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee type</p>
                                <p className="subTitle">{empInfo?.employeeType?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Date of joining</p>
                                <p className="subTitle">{getFormattedDate(empInfo?.dateOfJoining, dateFormat)}</p>
                            </div>
                            {
                               window.location.pathname !== "/self-assesment/aprove" && 
                                <div className="d-flex justify-content-between">
                                    <p className="title">Level</p>
                                    <p className="subTitle">{empInfo?.employeeLevel?.name}</p>
                                </div>
                            }                            
                            <div className="d-flex justify-content-between">
                                <p className="title">Designation</p>
                                <p className="subTitle">{empInfo?.employeeDesignation}</p>
                            </div>
 
                        </div>
                    </div>

                    <div className="accorDetails personalInfo">
                        <p className="accordian-title">Organisational Details</p>
                        <div className="accordian-data">
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Function</p>
                                <p className="subTitle">
                                    {/* {isObject(empInfo?.employeeFunction) ? empInfo?.employeeFunction?.name : empInfo?.employeeFunction}</p> */}
                                    {empInfo?.employeeFunction?.name}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Sub function</p>
                                <p className="subTitle">{empInfo?.employeeSubFunction?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Name</p>
                                <p className="subTitle">{empInfo?.manager?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Id</p>
                                <p className="subTitle">{empInfo?.manager?.employeeId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Email Id</p>
                                <p className="subTitle">{empInfo?.manager?.emailId}</p>
                            </div>
                            

                        </div>
                    </div>

                    {/* <div className="accorDetails personalInfo">
                        <p className="accordian-title">Rating History </p>
                        <div className="accordian-data table">
                             <div className="d-flex justify-content-between">
                                <p className="title">Performance Cycle</p>
                                <p className="title middle">Performance</p>
                                <p className="title right">MBB Rating</p>
                            </div>
                            {
                              !isEmptyNullUndefined(empInfo?.ratings) && Object.keys(empInfo?.ratings).map((key) => {
                                return(
                                    <div className="d-flex justify-content-between">
                                        <p className="subTitle">{key}</p>
                                        <p className="subTitle middle">{empInfo?.ratings[key]?.performance}</p>
                                        <p className="subTitle right">{empInfo?.ratings[key]?.values}</p>
                                    </div>
                                )
                              })
                            }
                        </div>
                    </div> */}

                    {
                        window.location.pathname !== "/self-assesment/aprove" && 
                        <div className="accorDetails personalInfo">
                        <p className="accordian-title">Rating History</p>
                        <div className="accordian-data">

                            {
                                ratingsHistoryLoader ? 
                                <CircularProgress />
                                :
                                 <table className="emp-profile-ratings">
                                    <thead className="table-header-1">
                                    <tr>
                                        <th className="table-header" align='center'>Cycle Name</th>
                                        <th className="table-header" align='center' >MBB</th>
                                        <th className="table-header" align='center'>OKR</th>
                                    </tr> 
                                    </thead>
                                    <tbody>
                                    {Object.values(groupedData).map((row, index) => (
                                        <tr key={row.cycleName}>
                                        <td align='center' >{row.cycleName}</td>
                                        <td align='center'>{row.MBB}</td>
                                        <td align='center'>{row.OKR}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>      
                            }
                            
                                             

                        </div>
                    </div>
                    }

                    

                
        </div>
       
        </div>
    );
};

EmpProfile.propTypes = {
    empInfo: PropTypes.node,
  };

export default EmpProfile;