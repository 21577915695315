const { createAction } = require('redux-actions');
const GET_COUNTS_REQUEST = 'MEDIBUDDY/GET_COUNTS_REQUEST';
const GET_COUNTS_REQUEST_SUCCESS = 'MEDIBUDDY/GET_COUNTS_REQUEST_SUCCESS';
const GET_COUNTS_REQUEST_FAILED = 'MEDIBUDDY/GET_COUNTS_REQUEST_FAILED';

const getCountsRequest = createAction(GET_COUNTS_REQUEST);
const getCountsRequestSuccess = createAction(GET_COUNTS_REQUEST_SUCCESS);
const getCountsRequestFailed = createAction(GET_COUNTS_REQUEST_FAILED);

export const actions = {
    getCountsRequest,
    getCountsRequestSuccess,
    getCountsRequestFailed,
};

export const types = {
    GET_COUNTS_REQUEST,
    GET_COUNTS_REQUEST_SUCCESS,
    GET_COUNTS_REQUEST_FAILED,
};
