import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { isEmptyNullUndefined } from "../../utils/utils";
import LoginBg from "../../../assets/images/loginImg.png"
import ButtonM from "../../common/Button";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

const LogIn = () => {
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [usernameText, setUsernameText] = useState(true);

  const userToken = useSelector((state) => state && state.user && state.user.userToken);
  const loading = useSelector((state) => state && state.user && state.user.loader);

  const [email, setEmail] = useState("");
  const emptyEmailError = {
    isError: false,
    errorMessage: "",
  };
  const [emailError, setEmailError] = useState(emptyEmailError);

  useEffect(() => {
   if(localStorage.getItem("loggedOutFromFooter")){ 
    localStorage.removeItem("loggedOutFromFooter");
    window.location.reload();
   }
  }, []);

 useEffect(() => {     
    if (userToken?.id_token !== undefined) {      
      history.push('/profile');
    }
  }, [userToken]);

  useEffect(() => {

      if(isEmptyNullUndefined(userToken)){
        setIsToken("no");
      }
  }, [submit]);
  

  const handleValidateEmailAddress = async () => {
    let isValid = true;
    const tempError = JSON.parse(JSON.stringify(emailError));

    if (isEmptyNullUndefined(email)) {
      tempError.isError = true;
      tempError.errorMessage = "*Please enter your company E-Mail ID";
      isValid = false;
    }

    if (!isEmptyNullUndefined(email)) {
      if (!RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).exec(email)) {
        tempError.isError = true;
        tempError.errorMessage = "Please enter a valid E-Mail ID";
        isValid = false;
      }
    }

    //do Submit
    if (isValid) {
      submitButtonClick();
    } else {
      setEmailError(tempError);
    }
  };

  const submitButtonClick = () => {
    APIList.forgotPassword(email)
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {"Reset password instuction sent to your email address."}
          </div>
        );
        setEmail("");
        setEmailError(emptyEmailError);
      })
      .catch((err) =>
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        )
      );
  };


  return (
    <div className="login-main">
      <div className="row">
        <div className="col-6 left">
          <img src={LoginBg} style={{width: '100%'}} className="loginBg" alt="loginbg" ></img>
        </div>
        <div className="col-6 right">
          <div className="loginBlock">
            <div className="loginBlockInner">
              <div className="loginHeadingOuter">
                <p className="loginHeading">Reset Your Password?</p>
              </div>
              <div className="input-wrapper">
                <div className="name-ip">
                  <input
                    name="email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    className={(emailError.isError) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    // className={(submit && (username.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    // emailError.isError
                    // ? "input border border-red-500 focus:outline-none"
                    // : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                    type="text"
                    placeholder="Username"                
                  />
                    {emailError.isError && (
                        <p className="" style={{color:"#ff0000"}}>
                        {emailError.errorMessage}
                        </p>
                    )}
                  {
                    usernameText &&
                    <p className="helping-text" style={{ lineHeight: '0px'}}>
                      Please provide your company E-Mail ID.
                    </p>
                  }
                  {submit && (username.trim() === "" || (!loading && isToken == "no")) && (
                    <p className="error-text">
                      Please provide your company E-Mail ID.<br/>
                      *Please provide a valid Username.
                    </p>
                  )}
                </div>
              </div>
              <div className="password-wrap">
                <div className="password-ip">
                  <div className="password-outer">
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap pb-2">
                <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                  <ButtonM text='Send Email' onpress={() => handleValidateEmailAddress()} loading={loading} disabled={loading} bgcolor="#2e8004" color="#ffffff" bghover="#2e8004" />       
                </div>
                <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                  <ButtonM text='Go to Login page' onpress={() => history.push("/login")} loading={loading} disabled={loading} bgcolor="#2e8004" color="#ffffff" bghover="#2e8004" />       
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default LogIn;
