import React, { useState } from 'react';
import { Grid, Typography, TextField, Box, Popover, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MinusIcon from "../../../../assets/images/successionPlan/minusIcon.svg";
import PlusIcon from "../../../../assets/images/successionPlan/plusIcon.svg";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import RoleMenuIcon from "../../../../assets/images/successionPlan/roleMenuIcon.svg";
import VpnDotIcon from "../../../../assets/images/successionPlan/vpnDot.svg";
import HeadIcon from "../../../../assets/images/successionPlan/headIcon.svg";
import ProfileEditModal from './ProfileEditModal/index';
import AddingRoleModal from './AddingRoleModal/index';


const EditSuccessionPlan = ({ }) => {
  const dispatch = useDispatch();

  const [popOver, setPopOver] = useState(null);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [showAddingRoleEdit, setShowAddingRoleEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteModal(false);
  };

  const handleProfileEdit = () => {
    setShowProfileEdit(true);
  };

  const handleProfileEditNo = () => {
    setShowProfileEdit(false);
  };

  const handleAddingRoleEdit = () => {
    setShowAddingRoleEdit(true);
  };

  const handleAddingRoleNo = () => {
    setShowAddingRoleEdit(false);
  };

  const roleDataList = [
    { roleType: "Customer Obsession" },
    { roleType: "Ownership" },
    { roleType: "Invent And Simplify" },
    { roleType: "Invent And Simplify" },
    { roleType: "Hire and Develop the Best" },
    { roleType: "Ownership" },
    { roleType: "Customer Obsession" },
    { roleType: "Customer Obsession" },
    { roleType: "Customer Obsession" },
  ];

  const handlePopOverClick = (event) => {
    setPopOver(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setPopOver(null);
  };

  const open = Boolean(popOver);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/*----- Strategies/Initiatives -----*/}
      <div className='d-flex mt-20'>
        <Typography className='succession-sub-heading'>{"Strategies/Initiatives"}</Typography>
        <img
          src={HeadIcon}
          alt="HeadIcon"
          height={15}
          width={15}
          className='headIcon'
        />
      </div>
      <div className="goals-form-page-container">
        <div className="field-row">
          <div className="text-field">
            <Typography className="field-label">
              What are the top business strategies/initiatives in the next 3 years?
            </Typography>
            <TextField
              name="responseString"
              id="outlined-multiline-static"
              placeholder='Write your strategies here'
              variant="outlined"
              size="small"
              sx={{
                width: '100%',
                mt: '6px',
                "& fieldset": {
                  borderRadius: '8px',
                },
              }}
              multiline
              minRows={3}
            // rows={0}
            />
          </div>
        </div>
      </div>

      {/*----- Critical roles -----*/}
      <div className='d-flex mt-20'>
        <Typography className='succession-sub-heading'>{"Critical roles"}</Typography>
        <img
          src={HeadIcon}
          alt="HeadIcon"
          height={15}
          width={15}
          className='headIcon'
        />
      </div>
      <Grid className='critical-roles-container'>
        <Grid className='mt-20'>
          <img
            src={MinusIcon}
            alt="MinusIcon"
            height={20}
            width={20}
            className='minusPlusIcon'
          />
          <Box className='vertical-border' />
        </Grid>

        {/*----- CEO| -----*/}
        <Grid>
          <Grid className="goals-form-page-container">
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <div className='d-flex'>
                  <div className='ceo-border'>
                    <Typography className='ceo-edit-text'>{"CEO|"}</Typography>
                  </div>
                  <Typography className='add-position-text'>{"+ Add Position"}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className='ac-center'>
                <div className='d-flex jc-end'>
                  <Typography className='delete-position' onClick={handleDeleteOpen}>{"Delete Position"}</Typography>
                  <div className='edit-border' onClick={handleAddingRoleEdit}>
                    <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid className='horizontal-border mtb-20' />

            {/*----- Role Competencies -----*/}
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Role Competencies"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-container'>
                  {roleDataList.map((item, index) => (
                    <div className='role-border-div'>
                      <div className='d-flex ai-center'>
                        <Typography className='role-type'>{item.roleType}</Typography>
                        <img
                          src={RoleCloseIcon}
                          alt="RoleCloseIcon"
                          height={12}
                          width={12}
                          className='role-close-Icon'
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Grid className='horizontal-border mtb-5' />
              </Grid>
            </Grid>

            {/*----- Incumbent -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Incumbent"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Savannah Nguyen"}</Typography>
                    <img
                      src={RoleMenuIcon}
                      alt="RoleMenuIcon"
                      height={12}
                      width={12}
                      className='role-close-Icon'
                      aria-describedby={id}
                      onClick={handlePopOverClick}
                    />
                  </div>
                </div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={popOver}
                  onClose={handlePopOverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  <Typography sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, cursor: "pointer", p: 2, paddingBottom: 0 }} onClick={handleProfileEdit}>{"Edit Readiness Profile Card "}</Typography>
                  <Typography sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, cursor: "pointer", p: 2 }}>{"Remove"}</Typography>
                </Popover>
              </Grid>
            </Grid>

            {/*----- Ready Now -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready Now"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='d-flex'>
                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Floyd Miles"}</Typography>
                      <img
                        src={RoleMenuIcon}
                        alt="RoleMenuIcon"
                        height={12}
                        width={12}
                        className='role-close-Icon'
                      />
                    </div>
                  </div>

                  <div className='role-border-div ml-10'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Bessie Cooper"}</Typography>
                      <img
                        src={RoleMenuIcon}
                        alt="RoleMenuIcon"
                        height={12}
                        width={12}
                        className='role-close-Icon'
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- Ready in 12 months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready in 12 months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Dianne Russell"}</Typography>
                    <img
                      src={RoleMenuIcon}
                      alt="RoleMenuIcon"
                      height={12}
                      width={12}
                      className='role-close-Icon'
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- Ready in 24 Months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Ready in 24 Months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='role-border-div'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Courtney Henry"}</Typography>
                    <img
                      src={RoleMenuIcon}
                      alt="RoleMenuIcon"
                      height={12}
                      width={12}
                      className='role-close-Icon'
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            {/*----- 24+ Months -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"24+ Months"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <div className='d-flex'>
                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Brooklyn Simmons"}</Typography>
                      <img
                        src={RoleMenuIcon}
                        alt="RoleMenuIcon"
                        height={12}
                        width={12}
                        className='role-close-Icon'
                      />
                    </div>
                  </div>

                  <div className='role-border-div ml-10'>
                    <div className='d-flex ai-center'>
                      <Typography className='role-type'>{"Ronald Richards"}</Typography>
                      <img
                        src={RoleMenuIcon}
                        alt="RoleMenuIcon"
                        height={12}
                        width={12}
                        className='role-close-Icon'
                      />
                    </div>
                  </div>
                </div>

                <Grid className='horizontal-border mtb-5' />
              </Grid>
            </Grid>

            {/*----- Mitigation Plan -----*/}
            <Grid container className='mt-8'>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Mitigation Plan"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <Typography className='mitigation-plan-field-label'>{"What are the short term contingency plans"}</Typography>
                <div className='role-border-div mt-5'>
                  <div className='d-flex ai-center'>
                    <Typography className='role-type'>{"Will continue to keep his focus on his primarily development areas that he has been working over the last couple of years. Additionally, I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus). Development area carried from previous LPA -- Ability to Earn Trust across organizational boundaries and think outside the standard Amazon model and ability to understand the emotional."}</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/*----- CFO Add Position -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              <Box className='vertical-border' />
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='d-flex'>
                      <div>
                        <Typography className='ceo-view-text'>{"CFO"}</Typography>
                      </div>
                      <Typography className='add-position-text'>{"+ Add Position"}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className='ac-center'>
                    <div className='d-flex jc-end'>
                      <Typography className='delete-position'>{"Delete Position"}</Typography>
                      <div className='edit-border'>
                        <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {/*----- Financial controller -----*/}
              <Grid className='critical-roles-container mt-20'>
                <Grid className='mt-20'>
                  <img
                    src={PlusIcon}
                    alt="PlusIcon"
                    height={20}
                    width={20}
                    className='minusPlusIcon'
                  />
                  <Box className='vertical-border' />
                </Grid>
                <Grid>
                  <Grid className="goals-form-page-container">
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className='d-flex'>
                          <div>
                            <Typography className='ceo-view-text'>{"Financial controller"}</Typography>
                          </div>
                          {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className='ac-center'>
                        <div className='d-flex jc-end'>
                          <Typography className='delete-position'>{"Delete Position"}</Typography>
                          <div className='edit-border'>
                            <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*----- VP Fin. India -----*/}
                  <Grid className='critical-roles-container mt-20'>
                    <Grid className='mt-20'>
                      <img
                        src={VpnDotIcon}
                        alt="VpnDotIcon"
                        height={10}
                        width={10}
                        className='dotIcon'
                      />
                    </Grid>
                    <Grid className="goals-form-page-container">
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className='d-flex'>
                            <div>
                              <Typography className='ceo-view-text'>{"VP Fin. India"}</Typography>
                            </div>
                            {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='ac-center'>
                          <div className='d-flex jc-end'>
                            <Typography className='delete-position'>{"Delete Position"}</Typography>
                            <div className='edit-border'>
                              <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*----- VP Fin. UK -----*/}
                  <Grid className='critical-roles-container mt-20'>
                    <Grid className='mt-20'>
                      <img
                        src={VpnDotIcon}
                        alt="VpnDotIcon"
                        height={10}
                        width={10}
                        className='dotIcon'
                      />
                    </Grid>
                    <Grid className="goals-form-page-container">
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className='d-flex'>
                            <div>
                              <Typography className='ceo-view-text'>{"VP Fin. UK"}</Typography>
                            </div>
                            {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='ac-center'>
                          <div className='d-flex jc-end'>
                            <Typography className='delete-position'>{"Delete Position"}</Typography>
                            <div className='edit-border'>
                              <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*----- SVP shared services -----*/}
              <Grid className='critical-roles-container mt-20'>
                <Grid className='mt-20'>
                  <img
                    src={PlusIcon}
                    alt="PlusIcon"
                    height={20}
                    width={20}
                    className='minusPlusIcon'
                  />
                  {/* <Box className='vertical-border' /> */}
                </Grid>
                <Grid>
                  <Grid className="goals-form-page-container">
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className='d-flex'>
                          <div>
                            <Typography className='ceo-view-text'>{"SVP shared services"}</Typography>
                          </div>
                          {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className='ac-center'>
                        <div className='d-flex jc-end'>
                          <Typography className='delete-position'>{"Delete Position"}</Typography>
                          <div className='edit-border'>
                            <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*----- CFO -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              {/* <Box className='vertical-border' /> */}
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='d-flex'>
                      <div>
                        <Typography className='ceo-view-text'>{"CFO"}</Typography>
                      </div>
                      {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className='ac-center'>
                    <div className='d-flex jc-end'>
                      <Typography className='delete-position'>{"Delete Position"}</Typography>
                      <div className='edit-border'>
                        <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/*----- CRO -----*/}
          <Grid className='critical-roles-container mt-20'>
            <Grid className='mt-20'>
              <img
                src={PlusIcon}
                alt="PlusIcon"
                height={20}
                width={20}
                className='minusPlusIcon'
              />
              {/* <Box className='vertical-border' /> */}
            </Grid>
            <Grid>
              <Grid className="goals-form-page-container">
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='d-flex'>
                      <div>
                        <Typography className='ceo-view-text'>{"CRO"}</Typography>
                      </div>
                      {/* <Typography className='add-position-text'>{"+ Add Position"}</Typography> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className='ac-center'>
                    <div className='d-flex jc-end'>
                      <Typography className='delete-position'>{"Delete Position"}</Typography>
                      <div className='edit-border'>
                        <Typography className='ceo-edit-text'>{"Edit"}</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="d-flex jc-end mt-20">
        <button className="plan-cancel-btn">
          {"Cancel"}
        </button>

        <button className="plan-save-btn">
          {"Save"}
        </button>
      </div>


      {showProfileEdit && (
        <ProfileEditModal
          showProfileEdit={showProfileEdit}
          handleProfileEditNo={handleProfileEditNo}
        />
      )}

      {showAddingRoleEdit && (
        <AddingRoleModal
          showAddingRoleEdit={showAddingRoleEdit}
          handleAddingRoleNo={handleAddingRoleNo}
        />
      )}


      {/*----- Delete Modal -----*/}
      <Dialog className='customModel' open={openDeleteModal === true}>
        <DialogTitle sx={{ color: "#00425A", fontSize: "20px", fontWeight: "400", textAlign: "center" }}>{"Are you sure to delete this Position?"}</DialogTitle>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: '#FFFFFF',
              padding: '10px 20px',
              margin: '0 10px 0px 0px',
              color: '#00425A',
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              border: "1px solid #00425A",
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: '#DB3535',
              padding: '10px 20px',
              margin: '0 10px 0px 0px',
              color: '#FFFFFF',
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              border: "1px solid #DB3535",
              borderRadius: "10px",
              textTransform: "capitalize",
              '&:hover': {
                backgroundColor: '#DB3535', 
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(EditSuccessionPlan);
