import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import SideDrawer from "../../../../common/sideDrawer";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import axios from "axios";
import { BASE_URL } from "../../../../../api/apiServices";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

const parse = require('html-react-parser');

const Trial = ({ fileId, isDownloadPdf, setIsDownloadPdf, showFor }) => {
    const printRef = useRef();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [expanded, setExpanded] = useState(null);
    const [showPeerFeedback, setShowPeerFeedback] = useState(false);
    const [peerFeedbackData, setPeerFeedbackData] = useState([]);

    useEffect(() => {        
     if(isDownloadPdf){
        handleGeneratePdf();
     }
    }, [isDownloadPdf])

    useEffect(() => {        
        getPDFData();
    }, [])    

    const handleChange = (index) => {
        if (expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index);
        }
    };

    const handleShowPeerFeedback = (question) => {
          setShowPeerFeedback(true);
          setPeerFeedbackData(question);
    }

    const handleGeneratePdf = async () => {
        axios.get(showFor ==="performance" ? `${BASE_URL}/services/benevolvemedibuddy/api/assessment-response-new/feedback-pdf-by-file-id/${fileId}` : `${BASE_URL}/services/benevolvemedibuddy/api/letter/compensation-pdf-by-file-id/${fileId}`,
            
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            })
            .then((response) => 
            {

               // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                // Create an anchor element and trigger a download
                const link = document.createElement('a');
                link.href = url;
                link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                document.body.appendChild(link);
                link.click();

                // Clean up by removing the anchor element and revoking the object URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

            setIsDownloadPdf(false);
                
            })
            .catch((err) => {
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setIsDownloadPdf(false);
            });    

      };

      const [PDFData, setPDFData] = useState([]);
      const [getPDFLoader, setGetPDFLoader] = useState(false);

      console.log("PDFData", PDFData);

      const [pdfUrl, setPdfUrl] = useState(null);

    //   useEffect(() => {
    //       if (PDFData.length > 0) {
    //           const blob = new Blob([new Uint8Array(PDFData)], { type: 'application/pdf' });
    //           const url = URL.createObjectURL(blob);
    //           setPdfUrl(url);
    //       }
    //   }, [PDFData]);

    const getPDFData = () => {
        setGetPDFLoader(true);

        axios.get(showFor === "performance" ? `${BASE_URL}/services/benevolvemedibuddy/api/assessment-response-new/feedback-pdf-by-file-id/${fileId}` : `${BASE_URL}/services/benevolvemedibuddy/api/letter/compensation-pdf-by-file-id/${fileId}`,
            
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            })
            .then((response) => 
            {

               // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);

                // // Create an anchor element and trigger a download
                // const link = document.createElement('a');
                // link.href = url;
                // link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                // document.body.appendChild(link);
                // link.click();

                // // Clean up by removing the anchor element and revoking the object URL
                // document.body.removeChild(link);
                // URL.revokeObjectURL(url);

            setPDFData(response?.data);
              setGetPDFLoader(false);
                
            })
            .catch((err) => {
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setGetPDFLoader(false);
            });    
    }

 
    return (
        <div className="performance-rate">

            {
            getPDFLoader ? 
            (
                <CircularProgress />
            )
            :
            (
                pdfUrl &&
                <div className="pdf-viewer">
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    />
                </div>
            )
            }

            {/* <div className="ratings-annual">
                    <p className="header-annualcycle">
                    <span>{sections?.annualReviewCycleName}</span>                    
                    </p>
            {
                sections?.ratings?.map((rating) => {
                return(
                    <p className="rating-outer">
                        <span>{rating?.name}</span> : <span>{rating?.rating}</span>                    
                    </p>
                )
                })
            }
            </div>
                                            
            {
                sections?.sections?.map((section, index) => {
                    return(<Stack
                        sx={{
                            margin: "1rem 0px",
                            "& .MuiAccordion-rounded": {
                                border: "1px solid #dad2d2",
                                borderRadius: "10px",
                                boxShadow: "none",
                            },
                        }}
                    >
                        <Accordion
                            expanded={expanded === `Tab-${index}`}
                            onChange={(e) => {
                                handleChange(`Tab-${index}`);
                                e.stopPropagation();
                            }}
                            data-value={`Tab-${index}`}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.25rem",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    },
                                }}
                            >
                                <p className="accordian-heading">
                                 {parse(section?.label)}
                                </p>
                                <BsChevronDown
                                    style={{
                                        ...accordianSVGstyle,
                                        transform:
                                            expanded !== `Tab-${index}`
                                                ? "rotate(0deg)"
                                                : "rotate(180deg)",
                                    }}
                                />
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    fontSize: "1rem",
                                }}
                            >
                                <div className="detail-outer">

                                    {
                                        section?.subSections?.map((question, qIndex) => {
                                            return(
                                                <div className={`question-outer ${((qIndex + 1) !== section?.subSections?.length) ? "border-b" : ""}`}>
                                                    <div className="que">{parse(question?.label)}</div>

                                                    {
                                                        !isEmptyNullUndefined(question?.peerFeedbacks) && 

                                                        <div className="peer-feedback-section">
                                                            <div className="peer-feedback-btn-outer">
                                                                <button className="show-peer-feedback-button" onClick={() => handleShowPeerFeedback(question)}>Peer Feedback</button>
                                                            </div>
                                                        </div>

                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.selfAppraisalResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {employeeDetails?.profilePhoto ? 
                                                                    <img src={employeeDetails?.profilePhoto?.path} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={employeeDetails.name} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{employeeDetails?.employeeDesignation? employeeDetails?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">{console.log("question", question)}
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseString) && 
                                                                question?.selfAppraisalResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseBoolean) ? 
                                                                question?.selfAppraisalResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseInteger) && 
                                                                question?.selfAppraisalResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.rating) && 
                                                                question?.selfAppraisalResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.selfAppraisalResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.managerResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {sections?.managerProfilePhotoPath ? 
                                                                    <img src={sections?.managerProfilePhotoPath} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={sections?.managerName} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{sections?.managerName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{sections?.managerDesignation ? sections?.managerDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseString) && 
                                                                question?.managerResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseBoolean) ? 
                                                                question?.managerResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseInteger) && 
                                                                question?.managerResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.rating) && 
                                                                question?.managerResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.managerResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>)
                })
            }
            
            {
                showPeerFeedback && 
                <div className="peer-feedback-in-sidebar">
                    <SideDrawer isOpen={showPeerFeedback} setIsOpen={setShowPeerFeedback} >
                    <div className="heading">Peer Feedback</div>
                    <div className="detail-outer mt-4">
                    <div className="que-peer">{parse(peerFeedbackData?.label)}</div>
                                    {
                                        peerFeedbackData?.peerFeedbacks?.map((peer, qIndex) => {
                                            return(
                                                <div className={`question-outer`}>

                                                    <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {peer?.profilePhotoPath ? 
                                                                    <img src={peer?.profilePhotoPath} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={peer.employeeName} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{peer?.employeeName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{peer?.employeeDesignation? peer?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseString) && 
                                                                peer?.responseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseBoolean) ? 
                                                                peer?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseInteger) && 
                                                                peer?.responseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.rating) && 
                                                                peer?.responseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(peer?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        peer?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                  
                                                    
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                    </SideDrawer>
                </div>
            } */}



        {/* <div className="pdf-content" ref={printRef} >
           <div className="ratings-annual">
                    <p className="header-annualcycle">
                    <span>{sections?.annualReviewCycleName}</span>                    
                    </p>
            {
                sections?.ratings?.map((rating) => {
                return(
                    <p className="rating-outer">
                        <span>{rating?.name}</span> : <span>{rating?.rating}</span>                    
                    </p>
                )
                })
            }
            </div>
            {
                sections?.sections?.map((section, index) => {
                    return(
                    <Stack
                        sx={{
                            margin: "1rem 0px",
                            "& .MuiAccordion-rounded": {
                                border: "1px solid #dad2d2",
                                borderRadius: "10px",
                                boxShadow: "none",
                            },
                        }}
                    >                               
                                <p className="accordian-heading">
                                  {parse(section?.label)}
                                </p>
                                <div className="detail-outer">
                                    {
                                        section?.subSections?.map((question, qIndex) => {
                                            return(
                                                <div className={`question-outer ${((qIndex + 1) !== section?.subSections?.length) ? "border-b" : ""}`}>
                                                    <div className="que">{parse(question?.label)}</div>                                                   

                                                    {
                                                        !isEmptyNullUndefined(question?.selfAppraisalResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                         
                                                            <div className="title-container">
                                                                <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{employeeDetails?.employeeDesignation? employeeDetails?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>

                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">{console.log("question", question)}
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseString) && 
                                                                question?.selfAppraisalResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseBoolean) ? 
                                                                question?.selfAppraisalResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseInteger) && 
                                                                question?.selfAppraisalResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.rating) && 
                                                                question?.selfAppraisalResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.selfAppraisalResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.managerResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            
                                                            <div className="title-container">
                                                                <div className="upper-title">{sections?.managerName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{sections?.managerDesignation ? sections?.managerDesignation : ""}</div>
                                                            </div>

                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseString) && 
                                                                question?.managerResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseBoolean) ? 
                                                                question?.managerResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseInteger) && 
                                                                question?.managerResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.rating) && 
                                                                question?.managerResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.managerResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.peerFeedbacks) && 

                                                        <div className="peer-feedback-in-sidebar">
                                                            
                                                            <div className="heading">Peer Feedback</div>
                                                        
                                                       
                                                        <div className="detail-outer">
                                                                        {
                                                                            question?.peerFeedbacks?.map((peer, qIndex) => {
                                                                                return(
                                                                                    <div className={`question-outer`}>
                                    
                                                                                        <div className="emp-response-outer">
                                                                                            <div className="emp-details">
                                                                                            <div className="user-container">
                                                                                          
                                                                                                <div className="title-container">
                                                                                                    <div className="upper-title">{peer?.employeeName?.split(' ').slice(0,2).join(' ')}</div>
                                                                                                    <div className="lower-title">{peer?.employeeDesignation? peer?.employeeDesignation : ""}</div>
                                                                                                </div>

                                                                                            </div>
                                                                                            </div>
                                                                                            <div className="emp-response">
                                                                                                <div className="text-responses">
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseString) && 
                                                                                                    peer?.responseObject?.responseString
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseBoolean) ? 
                                                                                                    peer?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                                                    :
                                                                                                    ""
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseInteger) && 
                                                                                                    peer?.responseObject?.responseInteger
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.rating) && 
                                                                                                    peer?.responseObject?.rating
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.selection) && 
                                                                                                    <div className="selections">
                                                                                                        {
                                                                                                            peer?.responseObject?.selection?.map((selected, sIndex) => {
                                                                                                                return(
                                                                                                                    <div className="selected-value">{selected?.name}</div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                    
                                                                                      
                                                                                        
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                               
                                                       
                                                     
                                                    </div>

                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                           
                    </Stack>)
                })
            }
            

        </div> */}

        </div>
    )
}

export default Trial;