import React, { useState } from 'react';
import { Typography, Grid, Box } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ScheduledSessions from './components/ScheduledSessions';
import SuccessionAnalytics from './components/SuccessionAnalytics';
import DelegateList from './components/DelegateList';


const HrSuccessionPlan = () => {
  const history = useHistory();

  const [isScheduledSessions, setIsScheduledSessions] = useState(true);
  const [isDelegateList, setIsDelegateList] = useState(false);
  const [isSuccessionAnalytics, setIsSuccessionAnalytics] = useState(false);

  const handleScheduledSessionsClick = () => {
    setIsScheduledSessions(true);
    setIsDelegateList(false);
    setIsSuccessionAnalytics(false);
  };

  const handleDelegateListClick = () => {
    setIsScheduledSessions(false);
    setIsDelegateList(true);
    setIsSuccessionAnalytics(false);
  };

  const handleSuccessionAnalyticsClick = () => {
    setIsScheduledSessions(false);
    setIsDelegateList(false);
    setIsSuccessionAnalytics(true);
  };

  return (
    <div className='hrSuccessionPlanPage'>

      <div className="d-flex jc-sb">
        {/*----- Scheduled Sessions & Delegate List &  Succession Analytics Btn -----*/}
        <div className='top-tabs'>
          <button className={isScheduledSessions === true ? "active" : "deactive"} onClick={handleScheduledSessionsClick}>
            {"Scheduled Sessions"} <span className="tab-count">03</span>
          </button>

          <button className={isDelegateList === true ? "active" : "deactive"} onClick={handleDelegateListClick}>
            {"Delegate List"} <span className="tab-count">02</span>
          </button>

          <button className={isSuccessionAnalytics === true ? "active" : "deactive"} onClick={handleSuccessionAnalyticsClick}>
            {"Succession Analytics"}
          </button>
        </div>

        {/*----- Schedule Session Button -----*/}
        <button className="schedule-session-btn" onClick={() => { history.push("/hr-scheduling-session")}}>
          {"Schedule Session"}
        </button>
      </div>
      {/*----- Scheduled Sessions -----*/}
      <div>
        {isScheduledSessions && <ScheduledSessions />}
      </div>

      {/*----- Delegate List -----*/}
      <div>
        {isDelegateList && <DelegateList />}
      </div>

      {/*----- Succession Analytics -----*/}
      <div>
        {isSuccessionAnalytics && <SuccessionAnalytics />}
      </div>

    </div>
  );
};

export default HrSuccessionPlan;