import "./formBuilder.css";

const FormBuilder = () => {
    return(
        <div className="form-builder-main">
            <div className="page-heading">Form Builder</div>
            <div className=""></div>

        </div>
    )
}

export default FormBuilder;