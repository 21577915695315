import React, { useState } from 'react';
import { Button, FormControl, Select, TextField, Dialog, DialogTitle, DialogActions, Typography } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoIcon from "../../../../assets/images/infoIcon.png";
import PencileEdit from "../../../../assets/images/pencileEdit.png";
import AccordiandownArrow from "../../../../assets/images/accordiandownArrow.png";
import AccordianUpArrow from "../../../../assets/images/accordianUpArrow.png";
import { FaAngleDown } from "react-icons/fa6";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const DevelopmentGoals = ({ }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [developmentHideAndShowView, setDevelopmentHideAndShowView] = useState(false);
  const [developmentHideEditView, setDevelopmentHideEditView] = useState(true);
  const [developmentShowEditView, setDevelopmentShowEditView] = useState(false);
  const [openModal, setOpenModal] = useState(null);


  const handleUpDownArrow = () => {
    setDevelopmentHideAndShowView(!developmentHideAndShowView);
  };

  const handleEditIcon = () => {
    setDevelopmentHideEditView(false);
    setDevelopmentShowEditView(true);
  };

  const handleOpen = (modalId) => {
    setOpenModal(modalId);
  };

  const handleClose = () => {
    setOpenModal(null);
  };

  return (
    <div>
      <div className="infoTitle">
        Development Goal <img src={InfoIcon} alt="MediBuddy" />
      </div>
      <div className="headtBtnTitle">
        <div>
          <button onClick={() => history.push("/idp-goal-developement-form")} className="btn devGoalBtn" role="button">
            Add A Development Goal
          </button>
        </div>
        Back
      </div>

      <div className="first-row">
        {developmentHideEditView && (
          <div className="container">
            <div className="row iconAlignment">
              <div className="col-md-7 py0">
                <Typography className="cardBlueTitle">
                  Development Goals
                </Typography>
                <Typography className="description desc-color">
                  Professional goals are anything you hope to achieve during
                  your career
                </Typography>
              </div>
              <div className="col-md-3">
                <Typography className="cardBlueTitle">
                  Goal linked to
                </Typography>
                <Typography className="description desc-color">
                  Current Role
                </Typography>
              </div>
              <div className="col-md-2">
                <div className="iconList">
                  <div onClick={handleEditIcon} className="icon-cursor-pointer">
                    <img src={PencileEdit} alt="MediBuddy" />
                  </div>
                  <img src={AccordiandownArrow} alt="MediBuddy" />
                </div>
              </div>
            </div>
          </div>
        )}

        {/*----- Edit Development -----*/}
        {developmentShowEditView && (
          <div>
            <div className="form-development-goal">
              <div className="goal-contant">
                <div className="development-bottom-border">
                  <div className="field-row">
                    {/*----- Development Goal -----*/}
                    <div className="row-half">
                      <div className="select-field">
                        <Typography className="field-label">Development Goal</Typography>
                        <TextField
                          name="developmentGoal"
                          id="outlined-multiline-static"
                          placeholder="Development Goal"
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "100%",
                            mt: "6px",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "8px",
                            "& fieldset": {
                              borderRadius: "8px",
                              border: "none",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#979797",
                              opacity: 1,
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "400"
                            }
                          }}
                        />
                      </div>
                    </div>

                    {/*----- Goal linked to -----*/}
                    <div className="row-half">
                      <div className="select-field">
                        <Typography className="field-label">Goal linked to</Typography>
                        <div>
                          <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                            <Select
                              sx={{
                                backgroundColor: "#F9F9F9",
                                width: "100%",
                                mt: "6px",
                                borderRadius: "8px",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "8px",
                                },
                              }}
                              size="small"
                              name="rating"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              IconComponent={FaAngleDown}
                            ></Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*----- Development & Add Button -----*/}
                <div class="btnList mt-3">
                  <a href="#" class="btn planebtnActive" role="button">
                    Development Action plan 1
                  </a>
                  <a href="#" class="btn planebtn" role="button">
                    Development Action plan 2
                  </a>
                  <a href="#" class="btn planebtn" role="button">
                    + Add
                  </a>
                </div>

                {/*----- Learning Activities -----*/}
                <div className="field-row">
                  <div className="row-half">
                    <div className="select-field">
                      <Typography className="field-label">Learning Activities</Typography>
                      <div>
                        <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                          <Select
                            sx={{
                              backgroundColor: "#F9F9F9",
                              width: "100%",
                              mt: "6px",
                              borderRadius: "8px",
                              "& fieldset": {
                                border: "none",
                                borderRadius: "8px",
                              },
                            }}
                            size="small"
                            name="rating"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={FaAngleDown}
                          ></Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                {/*----- Card Border -----*/}
                <div className="formCard">
                  <div className="field-row">
                    {/*----- Course Name -----*/}
                    <div className="row-half">
                      <div className="text-field">
                        <Typography className="field-label">Course Name</Typography>
                        <TextField
                          name="responseString"
                          id="outlined-multiline-static"
                          placeholder="Project Management Chapter 1"
                          variant="outlined"
                          size="small"
                          sx={{
                            backgroundColor: "#F9F9F9",
                            width: "100%",
                            mt: "6px",
                            borderRadius: "8px",
                            "& fieldset": {
                              borderRadius: "8px",
                              border: "none",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#979797",
                              opacity: 1,
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "400"
                            }
                          }}
                        />
                      </div>
                    </div>

                    {/*----- Course Link -----*/}
                    <div className="row-half">
                      <div className="text-field">
                        <Typography className="field-label">Course Link</Typography>
                        <TextField
                          name="responseString"
                          id="outlined-multiline-static"
                          variant="outlined"
                          placeholder="https://www.coursera.org/professional..."
                          size="small"
                          sx={{
                            backgroundColor: "#F9F9F9",
                            width: "100%",
                            mt: "6px",
                            borderRadius: "8px",
                            "& fieldset": {
                              borderRadius: "8px",
                              border: "none",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#979797",
                              opacity: 1,
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "400"
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/*----- Approved Status -----*/}
                  <div className="field-row">
                    <div className="text-field">
                      <Typography className="field-label">Approved Status</Typography>
                      <div className="field-row-checbox">
                        <div>
                          <input type="checkbox" class="item-checkbox" value="option1" /> Manager
                        </div>
                        <div>
                          <input type="checkbox" class="item-checkbox" value="option1" /> HRBP
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*----- Details -----*/}
                  <div className="field-row">
                    <div className="text-field">
                      <div className="select-field">
                        <Typography className="field-label">Details</Typography>
                        <div>
                          <TextField
                            name="responseString"
                            id="outlined-multiline-static"
                            variant="outlined"
                            size="small"
                            placeholder="Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project"
                            sx={{
                              backgroundColor: "#F9F9F9",
                              width: "100%",
                              mt: "6px",
                              borderRadius: "8px",
                              "& fieldset": {
                                borderRadius: "8px",
                                border: "none",
                              },
                              "& .MuiInputBase-input::placeholder": {
                                color: "#979797",
                                opacity: 1,
                                fontSize: "16px",
                                lineHeight: "24px",
                                fontWeight: "400"
                              }
                            }}
                            multiline
                            minRows={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="field-row">
                    {/*----- Start Date -----*/}
                    <div className="row-half">
                      <div className="date-field">
                        <Typography className="field-label">Start Date</Typography>
                        <div>
                          <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                sx={{
                                  backgroundColor: "#F9F9F9",
                                  width: "100%",
                                  borderRadius: "8px",
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    border: "none",
                                  },
                                }}
                                name="feedbackExpectedBy"
                                renderInput={(params) => (
                                  <TextField
                                    size={"small"}
                                    variant="outlined"
                                    sx={{
                                      pointerEvents: "none",
                                      "& .MuiOutlinedInput-root": {
                                        button: {
                                          pointerEvents: "all",
                                        },
                                      },
                                      mt: "6px",
                                      width: "100%",
                                      backgroundColor: "#F9F9F9",
                                      borderRadius: "8px",
                                      "& fieldset": {
                                        borderRadius: "8px",
                                        border: "none",
                                      },
                                      "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        border: "none",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        borderRadius: "8px",
                                        button: {
                                          pointerEvents: "all",
                                        }
                                      },
                                      "& .MuiInputBase-input::placeholder": {
                                        color: "red",
                                        opacity: 1,
                                      },
                                    }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    {/*----- End Date -----*/}
                    <div className="row-half">
                      <div className="date-field">
                        <Typography className="field-label">End Date</Typography>
                        <div>
                          <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                sx={{
                                  backgroundColor: "#F9F9F9",
                                  width: "100%",
                                  borderRadius: "8px",
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    border: "none",
                                  },
                                }}
                                name="feedbackExpectedBy"
                                renderInput={(params) => (
                                  <TextField
                                    size={"small"}
                                    variant="outlined"
                                    sx={{
                                      pointerEvents: "none",
                                      "& .MuiOutlinedInput-root": {
                                        button: {
                                          pointerEvents: "all",
                                        },
                                      },
                                      mt: "6px",
                                      width: "100%",
                                      borderRadius: "8px",
                                      backgroundColor: "#F9F9F9",
                                      "& fieldset": {
                                        border: "none",
                                        borderRadius: "8px",
                                      },
                                      "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        border: "none",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        borderRadius: "8px",
                                        button: {
                                          pointerEvents: "all",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                          color: "#979797",
                                          opacity: 1,
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                          fontWeight: "400"
                                        }
                                      },
                                    }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*----- Status -----*/}
                  <div className="field-row">
                    <div className="row-half">
                      <div className="select-field">
                        <Typography className="field-label">Status</Typography>
                        <div>
                          <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                            <Select
                              sx={{
                                backgroundColor: "#F9F9F9",
                                width: "100%",
                                mt: "6px",
                                borderRadius: "8px",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "8px",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                  color: "#979797",
                                  opacity: 1,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  fontWeight: "400"
                                }
                              }}
                              size="small"
                              name="rating"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              IconComponent={FaAngleDown}
                            ></Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*----- Delete this Plan & Cancel & Delete Btn -----*/}
            <div className="headtBtnTitle pt-3">
              <div className="head-delete">
                <div className="title">
                  <a href="#" role="button" onClick={() => handleOpen('modal1')}>Delete this Plan</a>
                </div>

                <Dialog className='customModel' open={openModal === 'modal1'} onClose={handleClose}>
                  <DialogTitle>Are you sure to delete this plan?</DialogTitle>
                  <DialogActions>
                    <Button className="btn planebtn mr-2" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button className="btn redbtnActive" onClick={handleClose}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div className="d-flex justify-content-end gap-3 main-btn formLastBtn">
                <button className="valuesBtn save-draft">Cancel</button>
                <button className="valuesBtn next">Update</button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="first-row">
        <div className="container">
          <div className="row iconAlignment">
            <div className="col-md-7 py0">
              <Typography className="cardBlueTitle">
                Development Goals
              </Typography>
              <Typography className="description desc-color">
                Professional goals are anything you hope to achieve during
                your career
              </Typography>
            </div>
            <div className="col-md-3">
              <Typography className="cardBlueTitle">
                Goal linked to
              </Typography>
              <Typography className="description desc-color">
                Current Role
              </Typography>
            </div>
            <div className="col-md-2">
              <div className="iconList">
                <img src={PencileEdit} alt="MediBuddy" />
                <div onClick={handleUpDownArrow} className="icon-cursor-pointer">
                  <img src={developmentHideAndShowView ? AccordianUpArrow : AccordiandownArrow} alt="MediBuddy" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----- Development Data Hide and Show -----*/}
        {developmentHideAndShowView && (
          <div className='accordiandata'>
            <div class="btnList mt-3">
              <a href="#" class="btn planebtnActive" role="button">Development Action plan 1</a>
              <a href="#" class="btn planebtn" role="button">Development Action plan 2</a>
              <a href="#" class="btn planebtn" role="button">Development Action plan 3</a>

            </div>
            <div className="container">
              <div className="row iconAlignment mt-4">
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Learning Activities</Typography>
                  <Typography className="description desc-color">
                    Courses
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Course Name</Typography>
                  <Typography className="description desc-color">
                    Project Management Chapter 1
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Course Link</Typography>
                  <Typography className="description desc-color">
                    https://www.coursera.org/learn/python-for-applied-data-science-ai
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Approved Status</Typography>
                  <Typography className="description desc-color">
                    Manager, HRBP
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Start Date</Typography>
                  <Typography className="description desc-color">
                    10/01/2023
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Due Date</Typography>
                  <Typography className="description desc-color">
                    06/02/2024
                  </Typography>
                </div>
                <div className="col-md-6 py0">
                  <Typography className="cardBlueTitle">Status</Typography>
                  <Typography className="description desc-color">
                    On going
                  </Typography>
                </div>
                <div className="col-md-12 py0">
                  <Typography className="cardBlueTitle">Details</Typography>
                  <Typography className="descriptionlong desc-color mb-1">
                    Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.
                  </Typography>
                  <Typography className="descriptionlong desc-color">
                    Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="first-row">
        <div className="container">
          <div className="row iconAlignment">
            <div className="col-md-7 py0">
              <Typography className="cardBlueTitle">
                Development Goals
              </Typography>
              <Typography className="description desc-color">
                Professional goals are anything you hope to achieve during
                your career
              </Typography>
            </div>
            <div className="col-md-3">
              <Typography className="cardBlueTitle">
                Goal linked to
              </Typography>
              <Typography className="description desc-color">
                Current Role
              </Typography>
            </div>
            <div className="col-md-2">
              <div className="iconList">
                <img src={PencileEdit} alt="MediBuddy" />
                <img src={AccordiandownArrow} alt="MediBuddy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DevelopmentGoals);
