const { createAction } = require('redux-actions');
const SELF_APRAISAL_DATA_REQUEST = 'MEDIBUDDY/SELF_APRAISAL_DATA_REQUEST';
const SELF_APRAISAL_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/SELF_APRAISAL_DATA_REQUEST_SUCCESS';
const SELF_APRAISAL_DATA_REQUEST_FAILED = 'MEDIBUDDY/SELF_APRAISAL_DATA_REQUEST_FAILED';

const SelfApraisalDataRequest = createAction(SELF_APRAISAL_DATA_REQUEST);
const SelfApraisalDataRequestSuccess = createAction(SELF_APRAISAL_DATA_REQUEST_SUCCESS);
const SelfApraisalDataRequestFailed = createAction(SELF_APRAISAL_DATA_REQUEST_FAILED);

const SAVE_APRAISAL_DATA_REQUEST = 'MEDIBUDDY/SAVE_APRAISAL_DATA_REQUEST';

const SaveApraisalDataRequest = createAction(SAVE_APRAISAL_DATA_REQUEST);

export const actions = {
    SelfApraisalDataRequest,
    SelfApraisalDataRequestSuccess,
    SelfApraisalDataRequestFailed,

    SaveApraisalDataRequest,
};

export const types = {
    SELF_APRAISAL_DATA_REQUEST,
    SELF_APRAISAL_DATA_REQUEST_SUCCESS,
    SELF_APRAISAL_DATA_REQUEST_FAILED,

    SAVE_APRAISAL_DATA_REQUEST,
};
