const { createAction } = require('redux-actions');
const EMPLOYEE_DATA_REQUEST = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST';
const EMPLOYEE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST_SUCCESS';
const EMPLOYEE_DATA_REQUEST_FAILED = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST_FAILED';

const EmployeeDataRequest = createAction(EMPLOYEE_DATA_REQUEST);
const EmployeeDataRequestSuccess = createAction(EMPLOYEE_DATA_REQUEST_SUCCESS);
const EmployeeDataRequestFailed = createAction(EMPLOYEE_DATA_REQUEST_FAILED);

export const actions = {
    EmployeeDataRequest,
    EmployeeDataRequestSuccess,
    EmployeeDataRequestFailed,
};

export const types = {
    EMPLOYEE_DATA_REQUEST,
    EMPLOYEE_DATA_REQUEST_SUCCESS,
    EMPLOYEE_DATA_REQUEST_FAILED,
};
