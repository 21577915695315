import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, IconButton, Popover, Stack, Typography, alpha, styled } from "@mui/material";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Avatar from "react-avatar";
import { IoIosArrowForward } from "react-icons/io";
import { accordianSVGstyle, getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../utils/utils";
import InputBase from '@mui/material/InputBase';
import { IoMdSearch } from "react-icons/io";
import { BiSort } from "react-icons/bi";
import PaginationMedi from "../../common/pagination";
// import EmplistSkel from "./components/skel";
// import FilterSelect from "./components/filterSelect";
import TableSkel from "../../common/tableSkeleton";
import EmplistSkel from "../feedback/components/skel";
// import { dummy } from "../requestFeedback/dummyData.js";
import { BiDotsVerticalRounded } from "react-icons/bi";
import addsession from "../../../assets/images/ratingCalib/addSession.svg";
import { IoPlaySharp } from "react-icons/io5";
import FilterSelect from "../../common/filterSelect";
import filterIcon from "../../../assets/images/filter.svg";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import SideDrawer from "../../common/sideDrawer/index.js";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    // border:"1px solid var(--primary)",
    boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.2rem !important",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));  

const RatintCollab = () => {

    const sortObj = {
        employeeName:{
            active:false,
            value:""
        },
        appraisalStatus:{
            active:false,
            value:""
        },
        feedbackstatus:{
            active:false,
            value:""
        },
    }

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);

    const [expanded, setExpanded] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [sortInfo, setSortInfo] = useState(sortObj);
    const [empListLoader, setEmpListLoader] = useState(false);
    // const [showAppraisal, setshowAppraisal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [selectedPeer, setSelectedPeer] = useState(null);
    const [activeTab, setActiveTab] = useState("upComingSessions");

    const [draftCount, setDraftCount] = useState(0);
    const [doneCount, setDoneCount] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
    
    const handleClickAction = (event, emp) => {
        setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
      getCounts();
    }, [])

    const getCounts = () => {
        APIList.getDraftReportCount({empId: employeeDetails?.id})
        .then((res) => {
          setDraftCount(res?.data?.count);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
        })
  
        APIList.getDoneReportCount({empId: employeeDetails?.id})
        .then((res) => {
          setDoneCount(res?.data?.count);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
        })
    }
    

// console.log("currentPage",currentPage);

    //   useEffect(() => {
        
    //     !firstLoad && setSearchLoader(true);
    //     const getSearch = setTimeout(() => {
    //         if(!firstLoad){
    //         APIList.getMyTeam({
    //             payload : {
    //                 employeeEligibilityDTO : filterPayload,
    //                 keyword : search,
    //                 managerId : employeeDetails?.id,
    //                 companyId : employeeDetails?.company?.id
    //             },
    //             page: currentPage,
    //             size: 10
    //         })  
    //         .then((res) => {
    //             setEmpList(res?.data?.data);
    //             setTotalPages(res?.data?.totalPages);
    //             setSearchLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setSearchLoader(false);
    //         }
    //         )
    //     }            
    //     }, 500);    

    //     return () => clearTimeout(getSearch)
    //   }, [search])

    //   useEffect(() => {
    //     if(!firstLoad){
    //             setSearchLoader(true);   
    //         APIList.getMyTeam({
    //             payload : {
    //                 employeeEligibilityDTO : filterPayload,
    //                 keyword : search,
    //                 managerId : employeeDetails?.id,
    //                 companyId : employeeDetails?.company?.id
    //             },
    //             page: currentPage,
    //             size: 10
    //         })  
    //         .then((res) => {
    //             setEmpList(res?.data?.data);
    //             setTotalPages(res?.data?.totalPages);
    //             setSearchLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setSearchLoader(false);
    //         }
    //         )
    //     }
    //   }, [currentPage])

// useEffect(() => {
//    if(!isEmptyNullUndefined(employeeDetails?.company?.showAppraisal)){
//     setshowAppraisal(employeeDetails?.company?.showAppraisal)
//    }   
//    getPageData();
//   }, [employeeDetails]);  

useEffect(() => {   
   getPageData();
  }, [activeTab, currentPage]);  

    const getPageData = () => {
        // console.log("getPageData");
        if(activeTab === "lastSessions") {
            setEmpListLoader(true);
            APIList.getDoneSession({
                empId: employeeDetails?.id,
                page: currentPage,
                companyId: employeeDetails?.company?.id,
                size: 10
            })        
            .then((res) => {
                // console.log("res------------>",res)
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
                setEmpList(null);
            })
        } else {
            setEmpListLoader(true);
            APIList.getAllSession({
                empId: employeeDetails?.id,
                status: "Due" ,
                sessoinStatus: activeTab === "upComingSessions" ? "ACTIVE" : "Draft",
                page: currentPage,
                size: 10
            })        
            .then((res) => {
                // console.log("res------------>",res)
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
                setEmpList(null);
            })
        }
        
        // setEmpList(dummy?.data?.filter(x => !x.sentStatus));
    }
    
    useEffect(() => {
      setSortEmpList(empList);
    }, [empList])

    const handleSubmitFilter = () => {
        // setSearchLoader(true);
        // APIList.getMyTeam({
        //     payload : {
        //         employeeEligibilityDTO : filterPayload,
        //         keyword : search,
        //         managerId : employeeDetails?.id,
        //         companyId : employeeDetails?.company?.id
        //     },
        //     page: currentPage,
        //     size: 10
        // })   
        // .then((res) => {
        //     console.log("res",res)
        //     setEmpList(res?.data?.data);
        //     setTotalPages(res?.data?.totalPages);
        //     setSearchLoader(false);
        //     setExpanded(null)
        // })
        // .catch((err) => {
        //     toast.error(
        //         <div style={{display:"flex", flexDirection:"row"}}>
        //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //           &nbsp;&nbsp;{err?.title}
        //         </div>
        //     );
        //     setSearchLoader(false);
        // })
    }
    

    const handleChange = (index) => {
        setCurrentPage(0);
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleFeedback = (emp) => {
        
        
    };

  
  const autoSearchText = (e) => {
    setCurrentPage(0)
    setSearch(e.target.value);
  };

  const handlePeerList = (value) => {    
    setCurrentPage(0);
    setActiveTab(value);
  }

  const handleViewSession = () => {
    history.push({pathname:"/view-session", state:{id: selectedPeer?.id}})
  }

  const handleEditSession = (isActive) => {
    history.push({pathname:"/add-session", state:{id: selectedPeer?.id, isActive: isActive}})
  }

  const handleDeleteSession = () => {
    setDeleteLoader(true);
    APIList.deleteSession({id: selectedPeer?.id})
    .then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp; deleted successfully
            </div>
          );
          handleClose();
          setDeleteLoader(false);
          getPageData();
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDeleteLoader(false);
    })
  }

  const isSessionStarted = (startDate) => {
    const currentDate = new Date();
    const sDate = new Date(startDate)
    if((sDate.getFullYear() === currentDate.getFullYear())) {
        if(sDate.getMonth() === currentDate.getMonth()) {
            if(sDate.getDate() < currentDate.getDate()){
            return true;
            } else if(sDate.getDate() === currentDate.getDate()){
                if(sDate.getTime() < currentDate.getTime()){
                    return true;
                } else {
                    return false;
                }
            } else if(sDate.getDate() > currentDate.getDate()){
                return false;
            }
        } else if(sDate.getMonth() < currentDate.getMonth()) {
            return true;
        } else if(sDate.getMonth() > currentDate.getMonth()) {
            return false;
        }
        
    } else if(sDate.getFullYear() < currentDate.getFullYear()) {
        return true;
    } else if(sDate.getFullYear() > currentDate.getFullYear()) {
        return false;
    }
    
  }

  const isExpired = (endTime) => {
    const currentDate = new Date();
    const eDate = new Date(endTime)
    if(eDate.getFullYear() === currentDate.getFullYear()) {
        if(eDate.getMonth() < currentDate.getMonth()){
            return true;
        } else if(eDate.getMonth() > currentDate.getMonth()){
            return false;
        } else  if(eDate.getMonth() === currentDate.getMonth()){
            if(eDate.getDate() < currentDate.getDate()){
                return true;
            } else if(eDate.getDate() === currentDate.getDate()){
                if(eDate.getTime() < currentDate.getTime()){
                    return true;
                } else {
                    return false;
                }
            } else if(eDate.getDate() > currentDate.getDate()){
                return false;
            }
        }
    } else if(eDate.getFullYear() > currentDate.getFullYear()){
        return false;
    } else if(eDate.getFullYear() < currentDate.getFullYear()){
        return true;
    }
    
    
  }

// Get the user's time format preferences
const userTimeFormat = Intl.DateTimeFormat().resolvedOptions();
const is12HourFormat = userTimeFormat.hour12;
    return(
        <div className="rating-collab">

        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={deleteLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
     
          <div className="search-wrapper">
            <div className="r-f-tabs">
                <div className={`tab ${activeTab === "upComingSessions" && "active"}`} onClick={() => handlePeerList("upComingSessions")}>Sessions</div>
                <div className={`tab ${activeTab === "draftSessions" && "active"}`} onClick={() => handlePeerList("draftSessions")}>Draft session <span>{(draftCount > 0 && draftCount < 10) ? `0${draftCount}` : draftCount}</span></div>
                <div className={`tab ${activeTab === "lastSessions" && "active"}`} onClick={() => handlePeerList("lastSessions")}>Last session report <span>{(doneCount > 0 && doneCount < 10) ? `0${doneCount}` : doneCount}</span></div>
            </div>
            <div className="second-div">
                {/* Hidding button if calibration cycle has been ended */}
                {new Date(annualcycleData?.calibrationCycle?.calibrationEndDate) >= new Date() &&
                <button className="add-session" onClick={() => history.push("/add-session")}>
                    <img src={addsession} alt="" />
                    Add session
                </button> }
                
                {/* <button className="add-session" onClick={() => history.push("/add-session")}>
                    <img src={addsession} alt="" />
                    Add session
                </button> */}
          
                    {/* <div className="filter-tab"
                        onClick={() => setIsOpenFilter(true)}
                    >
                        <img src={filterIcon} alt="" />
                        Filter
                        {
                            isFilterOn &&
                            <div className="red-dot"></div>
                        }
                    </div> */}
          <div className="page-i-btn" 
          onClick={() => setIsOpenDrawerForIbtn(true)}
          >
            i
          </div>
          </div>
            
          </div>

         {
          <div className="row">
            <div className="col-12">
            <div className="session-tables">



            <div className="tablee">
            <table class="feedback-emp-list-table"> 
            {
                activeTab === "upComingSessions" ?
                <tr className="table-head-emp-list"> 
                    <th className="first-col">Session Title 
                        {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                    </th> 
                    <th className="mid-cols">
                     HRBP
                        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Total Panelist</th> 
                    <th className="mid-cols">Cohort Count</th> 
                    <th className="mid-cols">Schedule</th> 
                    <th className="mid-cols">Status</th> 
                    <th className="mid-cols">Action</th> 
                    <th></th> 
                </tr>
                :
                <tr className="table-head-emp-list"> 
                    <th>Session Title 
                        {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                    </th> 
                    <th className="mid-cols">
                     HRBP
                        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Total Panelist</th> 
                    <th className="mid-cols">
                    Schedule
                        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">
                    Status
                        </th> 
                    <th className="mid-cols">Action</th> 
                    <th></th> 
                </tr>
            }
                
                
                {
                     searchLoader || empListLoader ? 
                     <TableSkel />
                     :
                sortEmpList?.map((emp,index) => {
                        return(   
                            activeTab === "upComingSessions" ?
                                <tr> 
                                    <td>
                                        <div className="empDetaila">
                                           
                                            <div className="userInfo">
                                                <p className="name">{emp?.name}</p>
                                                <p className="designation">code - 
                                                {
                                                    isSessionStarted(emp?.startTime) &&
                                                    !isExpired(emp?.endTime) &&
                                                    <a href={emp?.meetingLink} target="_blank" rel="noopener noreferrer">
                                                        {emp?.meetingLink}
                                                    </a>   
                                                }
                                                {
                                                    !isSessionStarted(emp?.startTime) &&
                                                    <span>
                                                        {emp?.meetingLink}
                                                    </span>
                                                }
                                                {
                                                    isExpired(emp?.endTime) &&
                                                    <span>
                                                        {emp?.meetingLink}
                                                    </span>
                                                }
                                                </p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{emp?.organiser?.name}</div>
                                    </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{emp?.participantCount}</div>
                                        {/* <div>{emp?.participants?.length + (emp?.coHost ? 1 : 0) + (emp?.organiser ? 1 : 0)}</div> */}
                                    </div>                                    
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{emp?.coHortCount}</div>
                                    </div>
                                    </td>
                                    <td>
                                    <div className= "btns">        
                                    <div>{emp?.startTime ? getFormattedDate(emp?.startTime, dateFormat) : "-"}</div>
                                    {/* <div>{getFormattedTime(emp?.startTime, 
                                        // is12HourFormat ? "hh:mm A" : "HH:mm"
                                        "hh:mm A"
                                        )} - {getFormattedTime(emp?.endTime, 
                                            // is12HourFormat ? "hh:mm A" : "HH:mm"
                                            "hh:mm A"
                                            )}</div> */}

                                    <div>
                                        {
                                            (emp?.startTime && emp?.endTime) ? (

                                                `${getFormattedTime(emp?.startTime, 
                                                    "hh:mm A"
                                                    )} - ${getFormattedTime(emp?.endTime, 
                                                        "hh:mm A"
                                                        )}`


                                            ) : ("-")
                                        }
                                    </div>
                                </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{isExpired(emp?.endTime) ? "EXPIRED" : emp?.status}</div>
                                    </div>
                                    </td>
                                    <td>                                    
                                    <div className= "btns">
                                        {
                                            isSessionStarted(emp?.startTime) ? 
                                            !isExpired(emp?.endTime) &&
                                            <button className="send-btn" onClick={() => history.push({pathname: "/view-report", state:{sessionid: emp?.id}})} >
                                                <IoPlaySharp />
                                                    Start
                                            </button>
                                            :
                                            !isExpired(emp?.endTime) &&
                                            <button className="send-btn" onClick={() => history.push({pathname: "/view-audit-report", state:{sessionid: emp?.id}})} >
                                                {/* <IoPlaySharp /> */}
                                                    Audit
                                            </button>
                                        }
                                    
                                    </div>    
                                    </td> 
                                    <td>
                                        <div className="action">
                                            <IconButton variant="outlined" onClick={(event) => handleClickAction(event, emp)}>
                                            {/* Provide Feedback */}
                                            <BiDotsVerticalRounded /> 
                                            </IconButton>
                                            <Popover
                                                id={id} 
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    }
                                                }}
                                            >
                                                <div className="popover-btns" onClick={() => handleViewSession()}>View session</div>
                                                <div className="popover-btns" onClick={() => handleEditSession(true)}>Reschedule </div>
                                                <div className="popover-btns red" onClick={() => handleDeleteSession()}>Delete</div>
                                            </Popover>
                                        </div>
                                    </td> 
                                </tr>
                                
                                :
                                <tr> 
                                <td>
                                    <div className="empDetaila">
                                       
                                        <div className="userInfo">
                                            <p className="name">{emp?.name}</p>
                                            {/* <p className="designation">code-2bcm</p> */}
                                        </div>
                                    </div>
                                </td> 
                                <td>
                                <div className= "btns">
                                    <div>{emp?.organiser?.name}</div>
                                </div>
                                </td> 
                                <td>
                                <div className= "btns">
                                    <div>{emp?.participantCount} </div>
                                    {/* <div>{emp?.participants?.length + (emp?.coHost ? 1 : 0) + (emp?.organiser ? 1 : 0)}</div> */}
                                </div>
                                </td> 
                                <td>
                                {/* <div className= "btns">        
                                    <div>{getFormattedDate(emp?.startTime, dateFormat)}</div>
                                    <div>{getFormattedTime(emp?.startTime, 
                                        // is12HourFormat ? "hh:mm A" : "HH:mm"
                                        "hh:mm A"
                                        )}</div>
                                </div> */}
                                <div className= "btns">        
                                    <div>{emp?.startTime ? getFormattedDate(emp?.startTime, dateFormat) : '-'}</div>
                                    {/* <div>{getFormattedTime(emp?.startTime, 
                                        // is12HourFormat ? "hh:mm A" : "HH:mm"
                                        "hh:mm A"
                                        )}</div> */}
                                        <div>{emp?.startTime ? getFormattedTime(emp?.startTime, 
                                        "hh:mm A"
                                        ) : "-"}</div>
                                </div>
                                </td> 
                                <td>
                                <div className= "btns">        
                                    {emp?.status}
                                </div>
                                </td> 
                                <td>                                    
                                <div className= "btns">
                                    {
                                        activeTab === "draftSessions" ?
                                        <></>
                                        :
                                        <button className="send-btn" 
                                          onClick={() => history.push({pathname: "/read-report", state:{sessionid: emp?.id}})}
                                        >
                                            View report
                                        </button>
                                    }
                                
                                </div>    
                                </td> 
                                <td>
                                    <div className="action">
                                        <IconButton variant="outlined" onClick={(event) => handleClickAction(event, emp)}>
                                        {/* Provide Feedback */}
                                        <BiDotsVerticalRounded /> 
                                        </IconButton>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }}
                                            sx={{
                                                "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                    boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                    border:"1px solid  #e9e9e9",
                                                    // padding:"0.25rem",
                                                    fontFamily:"poppins",
                                                    fontSize:"0.75rem",
                                                    borderRadius: "10px",
                                                    padding: "5px 0px",
                                                    cursor:"pointer",
                                                    marginLeft:"-1rem"                                                        
                                                },
                                                "& .MuiPopover-paper" : {
                                                    boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                    border:"1px solid  #e9e9e9",
                                                    // padding:"0.25rem",
                                                    fontFamily:"poppins",
                                                    fontSize:"0.75rem",
                                                    borderRadius: "10px",
                                                    padding: "5px 0px",
                                                    cursor:"pointer",
                                                    marginLeft:"-1rem"                                                        
                                                }
                                            }}
                                        >
                                            <div className="popover-btns" onClick={() => handleViewSession()}>View session</div>
                                            {
                                                activeTab !== "lastSessions" && 
                                                <div className="popover-btns" onClick={() => handleEditSession(false)}>Edit</div>
                                            }                                            
 
                                            {/* <div className="popover-btns red">Delete</div> */}
                                        </Popover>
                                    </div>
                                </td> 
                            </tr>
                            
                        )
                    }
                   
                // }
                )
                }
                
            </table> 
            {
                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
                    
                } 

                {
                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }

      
          </div>
          
          </div>
          </div>
          </div>
         }
         {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                {/* <div className="i-btn-cintent">
                    Please provide content
                </div> */}
                <InfoContent pageName='rating-calibration'/>
            </SideDrawer>
         } 
        </div>
        
    )
};

export default RatintCollab;