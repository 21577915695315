import React, { useState } from "react";
import IDPBannerUserProfile from './components/IDPBannerUserProfile';
import DevelopmentGoals from './components/DevelopmentGoals';
import DevelopmentActionPlans from './components/DevelopmentActionPlans';
import Notes from './components/Notes';


const IDPPage = () => {

  return (
    <div className="dashboard-main">
      {/*----- Top Banner Section -----*/}
      <IDPBannerUserProfile />

      <div className="outer-layout">
        {/*----- Development Goals Section -----*/}
        <div className="first-column">
          <DevelopmentGoals />

          {/* <DevelopmentActionPlans /> */}
        </div>

        {/*----- Notes Section -----*/}
        <div className="second-column1">
          <Notes />
        </div>
      </div>
    </div>
  );
};

export default IDPPage;
