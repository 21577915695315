import Avatar from "react-avatar";
import { getFormattedDate, isEmptyNullUndefined } from "../../utils/utils";

const EmpProfile = ({empInfo}) => {
    return(
        <div className="common-emp-profile">
            <div className="user-profile-sidedrawer-img">
          {empInfo?.profilePhoto ?
            <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
            :
            <Avatar 
                name={empInfo?.name} 
                size="75" 
                className="userImg"
                color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
            />
        }
        </div>

        <div className="user-info">
        <div className="accorDetails personalInfo">
                        <p className="accordian-title">Personal Details</p>
                        <div className="accordian-data">
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Name</p>
                                <p className="subTitle">{empInfo?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Id</p>
                                <p className="subTitle">{empInfo?.employeeId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Email Id</p>
                                <p className="subTitle">{empInfo?.emailId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee type</p>
                                <p className="subTitle">{empInfo?.employeeType?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Date of joining</p>
                                <p className="subTitle">{getFormattedDate(empInfo?.dateOfJoining, "DD MMM YYYY")}</p>
                            </div>
                            {
                                !window.location.pathname.includes("peer-feedBack") && 
                                <div className="d-flex justify-content-between">
                                <p className="title">Level</p>
                                <p className="subTitle">{empInfo?.employeeLevel?.name}</p>
                            </div>
                            }                            

                        </div>
                    </div>

                    <div className="accorDetails personalInfo">
                        <p className="accordian-title">Organisational Details</p>
                        <div className="accordian-data">
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Function</p>
                                <p className="subTitle">
                                    {/* {isObject(empInfo?.employeeFunction) ? empInfo?.employeeFunction?.name : empInfo?.employeeFunction}</p> */}
                                    {empInfo?.employeeFunction?.name}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Employee Sub function</p>
                                <p className="subTitle">{empInfo?.employeeSubFunction?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Name</p>
                                <p className="subTitle">{empInfo?.manager?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Id</p>
                                <p className="subTitle">{empInfo?.manager?.employeeId}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="title">Manager Email Id</p>
                                <p className="subTitle">{empInfo?.manager?.emailId}</p>
                            </div>
                            

                        </div>
                    </div>

                    {/* <div className="accorDetails personalInfo">
                            <p className="accordian-title">Rating History </p>
                        <div className="accordian-data table">
                            <div className="d-flex justify-content-between">
                                <p className="title">Performance Cycle</p>
                                <p className="title middle">Performance</p>
                                <p className="title right">MBB Rating</p>
                            </div>
                            {
                              !isEmptyNullUndefined(empInfo?.ratings) && Object.keys(empInfo?.ratings).map((key) => {
                                return(
                                    <div className="d-flex justify-content-between">
                                        <p className="subTitle">{key}</p>
                                        <p className="subTitle middle">{empInfo?.ratings[key]?.performance}</p>
                                        <p className="subTitle right">{empInfo?.ratings[key]?.values}</p>
                                    </div>
                                )
                              })
                            }
                        </div>
                    </div> */}
        </div>
       
        </div>
    );
};

export default EmpProfile;