import { useCallback, useEffect, useState } from "react";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined, Search, SearchIconWrapper, StyledInputBase } from "../../utils/utils";
import TableSkel from "../../common/tableSkeleton";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SideDrawer from "../../common/sideDrawer";
import EmpInfo from "../ratingCalibration/viewReport/empInfo";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import PaginationMedi from "../../common/pagination";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

const FunctionalLeader = () => {

    const annualcycleData = useSelector((state) => state.annualcycleReducer.annualcycle[0]);
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [requestCounts, setRequestCounts] = useState(null);
    const [requestCountsLoader, setRequestCountsLoader] = useState(false);

    const [promotionData, setPromotionData] = useState([]);
    const [promotionDataLoader, setPromotionDataLoader] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("approver");
    const [selectedEmps, setSelectedEmps] = useState([]);

    const [search, setSearch] = useState("");
    const [filterPayload, setFilterPayload] = useState({});

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [empToView, setEmpToView] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [firstTimeLoad, setFirstTimeLoad] = useState(true);

    useEffect(() => {
        if(!isEmptyNullUndefined(employeeDetails?.id)){
            getCounts();
            getPromotionData(0, {});
            setFirstTimeLoad(false);
        }
    }, [employeeDetails, activeTab]);

    useEffect(() => {
       
        const getdd = setTimeout(() => {
            if (!firstTimeLoad) {
                getPromotionData(currentPage, filterPayload);
            }
        }, 1000);
    
        return () => clearTimeout(getdd);
    
    }, [currentPage])

    useEffect(() => {
        const getSearch = setTimeout(() => {
            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    getPromotionData(0, filterPayload);
                }
            }
        }, 1000);
    
        return () => clearTimeout(getSearch);
    
    }, [search])

    const getCounts = () => {
        setRequestCountsLoader(true);
        APIList.approvePromotionsByFunctionalLeaderCounts({
            employeeId: employeeDetails?.id,
            companyId: employeeDetails?.company?.id
        })
        .then((res) => {
            setRequestCounts(res?.data);
        })
        .catch((error) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
        })
        .finally(() => {
            setRequestCountsLoader(false);
        })
    }

    const getPromotionData = (page, fPayload) => {
        setPromotionDataLoader(true);
        APIList.getPromotionAppeovalData({
            payload : {
                employeeEligibilityDTO: fPayload,
                keyword: search,
                employeeId: employeeDetails?.id,
                companyId: employeeDetails?.company?.id,
                page: activeTab
            },
            pageNo: page,
            pageSize: itemsPerPage            
        })
        .then((res) => {
            setPromotionData(res?.data?.result);
            setTotalPages(Math.ceil(res?.data?.totalSize/itemsPerPage));
        })
        .catch((error) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
        })
        .finally(() => {
            setPromotionDataLoader(false);
        })
    }

    const handleApprove = (data, type) => {
        setApproveLoader(true);
        APIList.approvePromotionsByFunctionalLeader({
                id: selectedEmps, // if isApprovedAll is false or []
                isApprovedAll: false, // or false
                entityId: annualcycleData?.calibrationCycle?.id, // Mandatory
                isApproved: true,  // or False 
                entityName: "PROMOTION", //Mandatory
                employeeId: employeeDetails?.id //Mandatory
        })
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;
                  {` Approved successfully`}
                </div>
            );
            getCounts();
            getPromotionData(0, filterPayload);
            setSelectedEmps([]);
        })
        .catch((error) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
        })
        .finally(() => {
            setApproveLoader(false);
        })
    }

    const autoSearchText = (e) => {
        setSearch(e.target.value);
    }

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    }

    const handleViewReport = (empId) => {
        let emp = {id : empId};
        setIsOpenDrawer(true); 
        setEmpToView(emp);
    }

    const handleSelectEmps = (empId) => {

        let temp = structuredClone(selectedEmps);

            if (!temp?.includes(empId)) {
                temp?.push(empId);
            } else {
                temp = temp?.filter((x) => x !== empId);
            }

        setSelectedEmps(temp);

    }

    const handleSelectAll = () => {
        let temp = structuredClone(selectedEmps);
        let promotionIds = promotionData?.map((emp) => emp?.promotionEmployeeId);
        if(!isAllAdded()){
           promotionIds?.forEach((id) => {
            if(!temp?.includes(id)){
                temp?.push(id);
            }
           });
        } else {
            temp = temp?.filter((id) => !promotionIds?.includes(id));
        }
        setSelectedEmps(temp);
    }

    const isAllAdded = useCallback(() => {
        let allAdded = true;

        promotionData?.forEach((emp) => {
            if(!selectedEmps?.includes(emp?.promotionEmployeeId)){
                allAdded = false;
            }
        });

        return allAdded;
    }, [selectedEmps, promotionData]);

    const getCheckBox = (empId) => {
        if(activeTab === "approver"){
            return selectedEmps?.includes(empId) ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />
        } 
    }

    return (
        <div className="promo-page-rating-calibration">
        {/* <div className="rating-calibration-report-view">
            <div className="view-report-row">
                <div className="blockBlue">
                    <div className="number">{sessionInfo?.organiser?.name}</div>
                    <div className="text">Host<br /> Name</div>
                </div>
                <div className="block">
                    <div className="number">{sessionInfo?.coHost?.name}</div>
                    <div className="text">Co Host<br /> Name</div>
                </div>
                <div className="block">
                    <div className="number">{sessionInfo?.name}</div>
                    <div className="text">Session<br /> Name</div>
                </div>
                <div className="block">
                    <div className="number">{sessionInfo?.participantCount}</div>
                    <div className="text">Total<br /> Participants</div>
                </div>
                <div className="block">
                    <div className="number">{sessionInfo?.coHortCount}</div>
                    <div className="text">Cohort<br /> Count</div>
                </div>
            </div>
        </div> */}

        <div className="page-title">Promotion Review</div>

        <div className="search-wrapper">
            
            <div className="r-f-tabs">

                <button className={`tab ${activeTab === "approver" && "active"}`} onClick={() => handleChangeTab("approver")}>Pending For Approval 
                    {requestCountsLoader ? <CircularProgress size={20} /> : <span> {(requestCounts?.approvalCount > 0 && requestCounts?.approvalCount < 10 )? `0${requestCounts?.approvalCount}` : requestCounts?.approvalCount }</span>}
                    {/* <span>{requestCounts?.ApproveRequestCount}</span> */}
                </button>

                <button className={`tab ${activeTab === "history" && "active"}`} onClick={() => handleChangeTab("history")}>Approved 
                    {requestCountsLoader ? <CircularProgress size={20} /> : <span>{ (requestCounts?.historyCount > 0 && requestCounts?.historyCount < 10 )? `0${requestCounts?.historyCount}` : requestCounts?.historyCount }</span>}
                    {/* <span>{requestCounts?.ApproveRequestCount}</span> */}
                </button>

                {/* <div className={`tab ${activeTab === "Suggest" && "active"}`} onClick={() => handlePeerList("Suggest")}>Suggest
                    <span>{(requestCounts?.suggestRequestCount > 0 && requestCounts?.suggestRequestCount < 10) ? `0${requestCounts?.suggestRequestCount}` : requestCounts?.suggestRequestCount}</span>
                    <span>{requestCounts?.suggestRequestCount}</span>
                </div> */}
           
            </div>
            


            <div className="second-div">
            {
                approveLoader ?
                    <div className="second-div">
                        <button className="approve-all-btn" disabled>
                            <CircularProgress size={27} />
                        </button>
                    </div>
                    :
                    <div className="second-div">
                        {/* {
                            isEmptyNullUndefined(pData) ?
                                <button className="approve-next-btn" onClick={() => history.push({ pathname: `/changed-report`, state: { viewReport: location?.state?.viewReport, sessionid: location?.state?.sessionid } })}>
                                    Next
                                </button>
                                : */}
                                <button className="approve-all-btn" 
                                onClick={() => handleApprove(null, "multiple")}
                                disabled={isEmptyNullUndefined(selectedEmps)}
                                >
                                    Approve
                                </button>
                        {/* } */}

                    </div>
            }
          </div>
          </div>

          <Search  
          >
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by username or email"
              inputProps={{ 'aria-label': 'search' }}
              onChange={autoSearchText}
              value={search}
            />
            {
                !isEmptyNullUndefined(search) && 
                <IoCloseSharp onClick={() => {setSearch("")}} style={{position:"absolute", left:"17rem", top:"34%", color:"gray", cursor:"pointer"}} />
            }

          </Search>

        

        <div className="tablee">
            <table class="feedback-emp-list-table">

                <tr className="table-head-emp-list">
                    <th className="e-name" 
                    onClick={handleSelectAll}
                    >
                        {
                            activeTab === "approver" ?
                            <>
                                {
                                    isAllAdded() ?
                                    <MdOutlineCheckBox />
                                    :
                                    <MdOutlineCheckBoxOutlineBlank />
                                }
                            </>
                            :
                            <></>
                        }
                       
                        Emp Code
                    </th>
                    <th className="e-name" 
                    // onClick={handleSelectAll}
                    >                       
                        Name
                    </th>
                    <th className="mid-cols">
                        Func/ sub-func
                    </th>
                    <th className="mid-cols-small">
                        Ratings
                    </th>
                    <th className="mid-cols-small">
                        <div>
                          Is Promotion Approved
                        </div>
                        {/* <FormControl sx={{ width: "6rem" }}>
                            <InputLabel id="demo-simple-select-label" sx={{ borderWidth: "0px" }}>Select</InputLabel>
                            <Select
                                // size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isAllAdded() ? "allYes" : (selectedEmps?.length === 0) ? "allNo" : null}
                                label="Select"
                                onChange={(e) => handleSelectAll(e)}
                                size="small"
                                sx={{
                                    width: "100%",
                                    //backgroundColor: "#dedede",
                                    "& fieldset": {
                                        border: "1px solid #ffffff",
                                        borderColor: "#ffffff",
                                        borderWidth: "0px",
                                    },
                                    "& .MuiInputBase-input:select": {
                                        border: "1px solid #ffffff",
                                        borderColor: "#ffffff",
                                        borderWidth: "0px",
                                    },
                                    "& .MuiInputBase-input:focus": {
                                        border: "2px solid #ffffff", // focus
                                    },
                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                    },
                                    //   ...requiredSelectStyled
                                }}
                            >
                                <MenuItem value="allYes" sx={{ fontSize: "0.9rem", fontFamily: "poppins" }}>All Yes</MenuItem>
                                <MenuItem value="allNo" sx={{ fontSize: "0.9rem", fontFamily: "poppins" }}>All No</MenuItem>
                            </Select>
                        </FormControl> */}

                    </th>
                    <th className="mid-cols-small">
                        Manager name
                    </th>
                    <th className="mid-cols-medium">
                        {/* Manager comments  */}
                    </th>
                </tr>

                {
                    promotionDataLoader ?
                        <TableSkel />
                        :

                        promotionData?.map((data) => {
                            // if(!data?.eligibleForPromotionApproval){
                            //     return null;
                            // }
                            return (
                                <tr>
                                    <td>
                                    <div className="title" 
                                        onClick={() => handleSelectEmps(data?.promotionEmployeeId)}
                                        >
                                        {
                                            getCheckBox(data?.promotionEmployeeId)
                                        }
                                        <div className="emp-code">
                                            {data?.empCode}
                                        </div>
                                    </div>
                                    </td>
                                    <td>
                                        <div className="title" 
                                        onClick={() => handleViewReport(data?.employeeId)}
                                        >
                                            
                                            <div className="emp-i">
                                                <div className="name">{data?.name}</div>
                                                <div className="emp-id">{data?.employeeLevel}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                    <td>
                                        {Object.keys(data?.postRating).map((rating, index) => {
                                            return (
                                                <span>
                                                    {data?.postRating[rating]} {index !== Object.keys(data?.postRating).length - 1 && "-"}
                                                </span>
                                            )
                                        })}
                                    </td>
                                    <td>
                                    <span 
                                    // className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}
                                    >
                                        {/* {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion} */}
                                        {data?.promotionApproved}
                                    </span>
                                    </td>
                                    <td>{data?.manager}</td>
                                    <td>
                                        <button className="view-details-btn" onClick={() => handleViewReport(data?.employeeId)}>View</button>
                                    </td>
                                    {/* <td>
                                        <FormControl sx={{ width: "6rem" }}>
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedEmps?.includes(data?.employeeId) ? "Yes" : "No"}
                                                label="Age"
                                                onChange={(e) => handleSelectEmps(e, data)}
                                                sx={{
                                                    color: selectedEmps?.includes(data?.employeeId) ? "#69C88E" : "red",
                                                    width: "100%",
                                                    //backgroundColor: "#dedede",
                                                    "& fieldset": {
                                                        border: "1px solid #ffffff",
                                                    },
                                                    "& .MuiInputBase-input:focus": {
                                                        border: "2px solid #ffffff", // focus
                                                    },
                                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                                        backgroundColor: "#ffffff",
                                                        margin: "0px",
                                                        paddingLeft: "0.5rem",
                                                    },
                                                }}
                                            >
                                                <MenuItem value="Yes" sx={{ color: "#69C88E" }}>Yes</MenuItem>
                                                <MenuItem value="No" sx={{ color: "red" }}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </td>
                                    <td>
                                        <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                        {
                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                        }
                                        </span>
                                    </td>
                                    <td>{data?.preRating?.preformanceRating}</td>
                                    <td>{data?.preRating?.mbbRating}</td>
                                    <td>{data?.postRating?.preformanceRating}</td>
                                    <td>{data?.postRating?.mbbRating}</td>
                                    <td><div className="the-reason">{data?.reason}</div></td> */}
                                </tr>
                            )
                        })



                }

            </table>
            {
                (!promotionDataLoader && isEmptyNullUndefined(promotionData)) ?
                    <div className="no-data">No data found</div>
                    :
                    <></>
            }
        </div>

        {
            !isEmptyNullUndefined(promotionData) && (totalPages > 1) &&
            <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        }

        {
            isOpenDrawer && 
            <div className="calibration-side-drawer">
                <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                    <EmpInfo emp={empToView} />
                </SideDrawer>
            </div>
            }
    </div>
    )
}

export default FunctionalLeader;