import { Skeleton } from "@mui/material";

const EmpInfoSkel = () => {
    return(
        <div>
            <div className="empDetailaa" style={{display:"flex"}}>
                <div className="empPic">
                <Skeleton variant="circular" width={50} height={50} />
                </div>
                <div className="userInfo" style={{marginLeft:"1rem"}}>
                    <div className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></div>
                    <div className="designation"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"3rem" }} /></div>
                </div>
            </div>
        </div>
    )
}

export default EmpInfoSkel;