import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack } from "@mui/material";
import { BiSort } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";

const EmplistSkel = () => {
    return(
        <div className="row">
            <div className="col-12">
            <div className="feedback-one">
                    {/* <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion data-value={"filters"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                }}}
                            >
                                <p className="accordian-heading" >Filters</p>
                                <BsChevronDown
                                    style={{...accordianSVGstyle }}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem"
                            }}
                            >
                               
                                    
                            </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </div> */}
                

            

          <div className="search-wrapper" disabled>
          <Skeleton variant="text" sx={{ fontSize: '2.7rem', width:"7rem", marginBottom:"0.5rem" }} />
          </div>

            <div className="tablee">
            <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list"> 
                    <th>Employee &nbsp;<BiSort /></th> 
                    <th className="mid-cols">
                        Self Appraisal 
                        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Peer Feedback</th> 
                    <th className="mid-cols">
                        Manager Feedback 
                        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Rating</th> 
                    <th></th> 
                </tr>
                
                {
                    [...Array(10).keys()].map((x) => {
                        return(
                            <tr>
                        <td>
                        <div className="empDetaila">
                                            <div className="empPic">
                                            <Skeleton variant="circular" width={50} height={50} />
                                            </div>
                                            <div className="userInfo">
                                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                                <p className="designation"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"3rem" }} /></p>
                                            </div>
                                        </div>
                        </td>
                        <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
                        <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
                        <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
                        <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
                        <td></td>
                    </tr>
                        )
                        
                    })
                }
               
                
            </table> 
           
          </div>
          
          </div>
          </div>
          </div>
    )
};

export default EmplistSkel;