import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import { isEmptyNullUndefined } from '../../utils/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const DateTimePicker = ({label, changeDate, value, error, disabled, maxDate}) => {
  
  const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

  // State for date and time
  const [selectedDate, setSelectedDate] = useState(null);

useEffect(() => {
 if(!isEmptyNullUndefined(value)){
    setSelectedDate(value);
 }
}, [])


  // Handler for date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    changeDate(date);
  };


  return (
    <div className={`custom-date-time-picker ${error ? "error" : ""}`}>
      
      {/* Date picker */}
      <div className='heading'>{label}</div>
      <DatePicker
        selected={selectedDate ? moment(selectedDate).toDate() : null}
        onChange={handleDateChange}
        minDate={new Date()}
        maxDate={maxDate}
        placeholderText='Select Date And Time'
        showTimeSelect
        dateFormat= {`${dateFormat} hh:mm a`}
        disabled={disabled}
      />
      
    </div>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  changeDate: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string,
};

export default DateTimePicker;