import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

var yLabels = {
	0 : 'newb',
    2 : 'codecademy',
    4 : 'code-school',
    6 : 'bootcamp',
    8 : 'junior-dev',
    10 : 'mid-level',
    12 : 'senior-dev',
    14 : 'full-stack-dev',
    16 : 'famous-speaker',
    18 : 'unicorn',
    20 : 'harambe'
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
//   scales: {      
//     y: {
//       beginAtZero: true,
//       ticks: {
//               //displays only 6 ticks on y axis at specific distance
//               maxTicksLimit: 11,
             
//               callback: (val, index) => {
              
//               //will show ticks for y-axis that lies between 0-150
//               return  yLabels[val] ; 
             
//         }
//       }
//     }
// },
scales: {
  A: {
      type: 'linear',
      position: 'left',
  },
  B: {
      type: 'linear',
      position: 'right',
      ticks: {
          max: 1,
          min: 0
      }
  }
}
};



const LineGraph = ({ratingsHistory}) => { 
  console.log("ratingsHistory",ratingsHistory);

  const labels = ratingsHistory?.map((x) => x?.cycleType);

const data = {
  labels,
  
  // datasets: [
  //   {
  //     label: 'Dataset 1',
  //     data: [0, 4, 2, 8, 2],
  //     borderColor: 'rgb(255, 99, 132)',
  //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //   },
  //   {
  //     label: 'Dataset 2',
  //     data: [4, 12, 8, 2, 6],
  //     borderColor: 'rgb(53, 162, 235)',
  //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //   },    
  // ],

  datasets: [{
    label: 'A',
    yAxisID: 'A',
    data: [100, 96, 84, 76, 69],
    fill: true,
    tension: 0.4,
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
}, {
    label: 'B',
    yAxisID: 'B',
    data: [1, 1, 1, 1, 0],
    fill: true,
    tension: 0.4,
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
}]
};

    return <Line options={options} data={data} />;
}

export default LineGraph;
