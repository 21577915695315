import { useState, useEffect } from 'react';
import moment from 'moment';

const TimePassedDisplay = ({ startDate }) => {

  const [timePassed, setTimePassed] = useState('');

  useEffect(() => {

      const currentTime = moment();
      const difference = moment.duration(currentTime.diff(startDate));

      let formattedTimePassed = '';

      if (difference.days() > 0) {
        formattedTimePassed = `${difference.days()} days`;
      } else if (difference.hours() > 0) {
        formattedTimePassed = `${difference.hours()} hours`;
      } else if (difference.minutes() > 0) {
        formattedTimePassed = `${difference.minutes()} minutes`;
      } else {
        formattedTimePassed = `${difference.seconds()} seconds`;
      }

      setTimePassed(formattedTimePassed);
   
  }, [startDate]);

  return (
    <div>
      <p>{timePassed} ago</p>
    </div>
  );
}

export default TimePassedDisplay;