import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import BlueSlider from "../../../../assets/images/blue-slider.png";
import PinkSlider from "../../../../assets/images/pink-slider.png";
import GreenSlider from "../../../../assets/images/green-slider.png";

const SummaryOfCompetencies = ({ }) => {
    const dispatch = useDispatch();

    const competencies = [
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
        {
            title: "Leadership and Inspiring Others",
            description: "The ability to use personal skilss to guide and inspire individuals/groups towards achieving goals",
            selfScore: 3.7,
            feedbackScore: 4.5,
            companyAverage: 4.5,
            blueSlider: BlueSlider,
            pinkSlider: PinkSlider,
            greenSlider: GreenSlider,
        },
    ];

    return (
        <Grid container className="summary-of-competencies-grid">
            <Typography className="summary-of-competencies">{"Summary Of Competencies"}</Typography>

            <Grid item xs={12} sm={12} md={12} className="competencies-container">
                <Grid container direction="row" wrap="nowrap" className="competencies-scroll">
                    {competencies.map((competency, index) => (
                        <Grid key={index} className="competencies-card">
                            <Typography className="competencies-title">{competency.title}</Typography>
                            <Typography className="competencies-description">{competency.description}</Typography>

                            <Grid container className="jc-sb">
                                <Typography className="slider-label">{"Your self score"}</Typography>
                                <Typography className="slider-count">{competency.selfScore}</Typography>
                            </Grid>
                            <img src={competency.blueSlider} alt="Slider" />

                            <Grid container className="jc-sb">
                                <Typography className="slider-label">{"Feedback score"}</Typography>
                                <Typography className="slider-count">{competency.feedbackScore}</Typography>
                            </Grid>
                            <img src={competency.pinkSlider} alt="Slider" />

                            <Grid container className="jc-sb">
                                <Typography className="slider-label">{"Company average"}</Typography>
                                <Typography className="slider-count">{competency.companyAverage}</Typography>
                            </Grid>
                            <img src={competency.greenSlider} alt="Slider" />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(SummaryOfCompetencies);
