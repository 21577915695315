import React, { useState } from 'react';
import { Grid, Typography, Select, FormControl, MenuItem, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaAngleDown } from "react-icons/fa6";
import EventUser1 from "../../../../assets/images/MyCommunity/eventUser1.svg";
import EventUser2 from "../../../../assets/images/MyCommunity/eventUser2.svg";
import EventUser3 from "../../../../assets/images/MyCommunity/eventUser2.svg";


const CalendarEvents = ({ setSendAGreetingCardModal }) => {
    const dispatch = useDispatch();

    const [selectedIndex, setSelectedIndex] = useState(null);
    
    const handleItemClick = (index) => {
        setSelectedIndex(index);
    };

    const dateList = [
        {
            id: 1,
            dateTitle: '01',
            dayTitle: 'Mon',
        },
        {
            id: 2,
            dateTitle: '02',
            dayTitle: 'Tue',
        },
        {
            id: 3,
            dateTitle: '03',
            dayTitle: 'Wed',
        },
        {
            id: 4,
            dateTitle: '04',
            dayTitle: 'Thu',
        },
        {
            id: 5,
            dateTitle: '05',
            dayTitle: 'Fri',
        },
        {
            id: 6,
            dateTitle: '06',
            dayTitle: 'Sat',
        },
        {
            id: 7,
            dateTitle: '07',
            dayTitle: 'Sun',
        },
        {
            id: 8,
            dateTitle: '08',
            dayTitle: 'Mon',
        },
    ];

    return (
        <Grid container className="calender-events-card">
            <Grid item xs={12} sm={12} md={12}>
                <Typography className="calendar-events">{"Calendar Events"}</Typography>

                <div>
                    <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                        <Select
                            sx={{
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #D9D9D9",
                                width: "100%",
                                mt: "17px",
                                borderRadius: "8px",
                                "& fieldset": {
                                    border: "none",
                                    borderRadius: "8px",
                                },
                            }}
                            size="small"
                            name="rating"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={FaAngleDown}
                        >
                            <MenuItem value={10}>October, 2024</MenuItem>
                            <MenuItem value={20}>November, 2024</MenuItem>
                            <MenuItem value={30}>December, 2024</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="date-container">
                <Grid container direction="row" wrap="nowrap" className="date-scroll">
                    {dateList.map((item, index) => (
                        <Grid
                            key={index}
                            item
                            className="date-item"
                            onClick={() => handleItemClick(index)}
                            style={{
                                backgroundColor: selectedIndex === index ? "#004259" : "#FFFFFF",
                                cursor: 'pointer'
                            }}
                        >
                            <Typography className="date-title" style={{ color: selectedIndex === index ? "#FFFFFF" : "#00425A" }}>{item.dateTitle}</Typography>
                            <Typography className="day-title" style={{ color: selectedIndex === index ? "#FFFFFF" : "#7E7E7E" }}>{item.dayTitle}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {/*----- Events -----*/}
            <Grid item xs={12} sm={12} md={12}>
                <Typography className="events-label">{"3 events"}</Typography>

                <Grid className='events-container'>
                    <Grid className="event-mt-5">
                        <div className="dot-circle" />
                        <Box className='vertical-border' />
                    </Grid>
                    <Grid>
                        <Typography className='date-month'>{"06 October"}</Typography>
                        <Grid className="birthday-grid">
                            <Typography className="birthday-anniversary event-mt-10">{"Birthday of"}</Typography>

                            <Grid className="d-flex event-mt-10">
                                <img src={EventUser1} alt="MediBuddy" className='event-user-profile' />
                                <Typography className="birthday-anniversary" onClick={() => setSendAGreetingCardModal(true)}>{"Leslie Alexander,"}</Typography>
                            </Grid>

                            <Grid className="d-flex event-mt-10">
                                <img src={EventUser2} alt="MediBuddy" className='event-user-profile' />
                                <Typography className="birthday-anniversary" onClick={() => setSendAGreetingCardModal(true)}>{"John Doe"}</Typography>
                            </Grid>

                            <Grid className="d-flex event-mt-10">
                                <img src={EventUser3} alt="MediBuddy" className='event-user-profile' />
                                <Typography className="birthday-anniversary" onClick={() => setSendAGreetingCardModal(true)}>{"Jessy George"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className='events-container'>
                    <Grid className="event-mt-5">
                        <div className="dot-circle" />
                        <Box className='vertical-border' />
                    </Grid>
                    <Grid>
                        <Typography className='date-month'>{"06 October"}</Typography>
                        <Grid className="birthday-grid">
                            <Typography className="birthday-anniversary event-mt-10">{"Work Anniversary of"}</Typography>

                            <Grid className="d-flex event-mt-10">
                                <img src={EventUser1} alt="MediBuddy" className='event-user-profile' />
                                <Typography className="birthday-anniversary" onClick={() => setSendAGreetingCardModal(true)}>{"John Doe"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className='events-container'>
                    <Grid className="event-mt-5">
                        <div className="dot-circle" />
                        <Box className='vertical-border' />
                    </Grid>
                    <Grid>
                        <Typography className='date-month'>{"06 October"}</Typography>
                        <Grid className="birthday-grid">
                            <Typography className="birthday-anniversary event-mt-10">{"Work Anniversary of"}</Typography>

                            <Grid className="d-flex event-mt-10">
                                <img src={EventUser2} alt="MediBuddy" className='event-user-profile' />
                                <Typography className="birthday-anniversary" onClick={() => setSendAGreetingCardModal(true)}>{"John Doe"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(CalendarEvents);
